import { handleAction, isNilOrEmpty, isArray } from '../../utils/helpers';
import {
    REAL_ESTATE_TYPE, OFFER_TYPE, REAL_ESTATE_DISPOSITION, REAL_ESTATE_STATE, REGION, DISTRICT, HOUSE_TYPE, LOT_TYPE,
    REAL_ESTATE_OWNERSHIP, COMMERCIAL_TYPE, BUILDING_MATERIAL_TYPE, DISTRICT_BY_REGION, initialPayload
} from '../../utils/constant';
import { pipe, indexBy, map, prop } from 'ramda';

const REAL_ESTATE_TYPE_SUCCESS = 'REAL_ESTATE_TYPE_SUCCESS';
const OFFER_TYPE_SUCCESS = 'OFFER_TYPE_SUCCESS';
const REAL_ESTATE_DISPOSITION_SUCCESS = 'REAL_ESTATE_DISPOSITION_SUCCESS';
const REAL_ESTATE_STATE_SUCCESS = 'REAL_ESTATE_STATE_SUCCESS';
const LOT_TYPE_SUCCESS = 'LOT_TYPE_SUCCESS';
const BUILDING_MATERIAL_TYPE_SUCCESS = 'BUILDING_MATERIAL_TYPE_SUCCESS';
const REAL_ESTATE_OWNERSHIP_SUCCESS = 'REAL_ESTATE_OWNERSHIP_SUCCESS';
const COMMERCIAL_TYPE_SUCCESS = 'COMMERCIAL_TYPE_SUCCESS';
const HOUSE_TYPE_SUCCESS = 'HOUSE_TYPE_SUCCESS';
const REGION_SUCCESS = 'REGION_SUCCESS';
const DISCTRICT_SUCCESS = 'DISTRICT_SUCCESS';

export const GET_CODEBOOK = 'GET_CODEBOOK';
const GET_CODEBOOK_SUCCESS = 'GET_CODEBOOK_SUCCESS';

export const GET_ALL_CODEBOOKS = 'GET_ALL_CODEBOOKS';
export const GET_ALL_CODEBOOKS_REQUEST = 'GET_ALL_CODEBOOKS_REQUEST';
export const GET_ALL_CODEBOOKS_SUCCESS = 'GET_ALL_CODEBOOKS_SUCCESS';
export const GET_ALL_CODEBOOKS_FAILED = 'GET_ALL_CODEBOOKS_FAILED';

const DISTRICT_BY_REGION_SUCCESS = 'DISTRICT_BY_REGION_SUCCESS';

const initCodebooks = {
    [REAL_ESTATE_TYPE]: [],
    [OFFER_TYPE]: [],
    [REAL_ESTATE_DISPOSITION]: [],
    [REAL_ESTATE_STATE]: [],
    [LOT_TYPE]: [],
    [BUILDING_MATERIAL_TYPE]: [],
    [REAL_ESTATE_OWNERSHIP]: [],
    [COMMERCIAL_TYPE]: [],
    [HOUSE_TYPE]: [],
    [REGION]: [],
    [DISTRICT]: [],
    [DISTRICT_BY_REGION]: {}
};

const getAllCodebooks = (data) => {
    if (isNilOrEmpty(data) || !isArray(data)) {
        return initCodebooks;
    }
    return pipe(indexBy(prop('name')), map(prop('items')))(data);
};

export const initialState = {
    ...initCodebooks,
    isLoading: false,
    isLoaded: false
};

export function reducer(state = initialState, action) {
    const handlers = {
        [GET_ALL_CODEBOOKS_REQUEST]: {
            ...state,
            isLoading: true
        },
        [GET_ALL_CODEBOOKS_SUCCESS]: {
            ...state,
            isLoading: false,
            isLoaded: true,
            ...getAllCodebooks(action.payload?.data)
        },
        [GET_ALL_CODEBOOKS_FAILED]: {
            ...state,
            isLoading: false,
            isLoaded: false,
        },
        [GET_CODEBOOK_SUCCESS]: {
            ...state,
            [action.meta?.codebookName]: action.payload?.data
        },
        [REAL_ESTATE_TYPE_SUCCESS]: {
            ...state,
            [REAL_ESTATE_TYPE]: action.payload?.data
        },
        [OFFER_TYPE_SUCCESS]: {
            ...state,
            [OFFER_TYPE]: action.payload?.data
        },
        [REAL_ESTATE_DISPOSITION_SUCCESS]: {
            ...state,
            [REAL_ESTATE_DISPOSITION]: action.payload?.data
        },
        [REAL_ESTATE_STATE_SUCCESS]: {
            ...state,
            [REAL_ESTATE_STATE]: action.payload?.data
        },
        [LOT_TYPE_SUCCESS]: {
            ...state,
            [LOT_TYPE]: action.payload?.data
        },
        [BUILDING_MATERIAL_TYPE_SUCCESS]: {
            ...state,
            [BUILDING_MATERIAL_TYPE]: action.payload?.data
        },
        [REAL_ESTATE_OWNERSHIP_SUCCESS]: {
            ...state,
            [REAL_ESTATE_OWNERSHIP]: action.payload?.data
        },
        [COMMERCIAL_TYPE_SUCCESS]: {
            ...state,
            [COMMERCIAL_TYPE]: action.payload?.data
        },
        [HOUSE_TYPE_SUCCESS]: {
            ...state,
            [HOUSE_TYPE]: action.payload?.data
        },
        [REGION_SUCCESS]: {
            ...state,
            [REGION]: action.payload?.data
        },
        [DISCTRICT_SUCCESS]: {
            ...state,
            [DISTRICT]: action.payload?.data
        },
        [DISTRICT_BY_REGION_SUCCESS]: {
            ...state,
            [DISTRICT_BY_REGION]: {
                ...state[DISTRICT_BY_REGION],
                [action.meta?.qp1]: action.payload?.data
            }
        }
    };

    return handleAction(action.type, handlers) || state;
}
