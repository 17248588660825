import {createAction, fetchCodebookParent} from "../../store/actions";
import {SET_FILTER_PARAM} from "../../store/reducers/search";
import {useSelector} from "react-redux";
import {DISTRICT, DISTRICT_BY_REGION, REDUCER_CODEBOOK, REDUCER_SEARCH, REGION} from "../../utils/constant";
import React, {useEffect} from "react";
import {
    checkboxCodebookHandler,
    isNilOrEmpty,
    isNotNilOrEmpty,
    isNotNilOrEmptyArray,
    isSelectedCodebookItem
} from "../../utils/helpers";
import {ICodebookItem} from "../../utils/types";
import {prop, propEq, sortBy, filter, flatten} from "ramda";
import {Checkbox, FormControlLabel, Grid} from "@material-ui/core";

const DistrictFilter = () => {
    const setFilterParam = createAction(SET_FILTER_PARAM);
    const districts = useSelector((state: any) => state[REDUCER_CODEBOOK][DISTRICT_BY_REGION]);
    const selectedRegions = useSelector((state: any) => state[REDUCER_SEARCH].filter?.regions);
    const selectedDistricts = useSelector((state: any) => state[REDUCER_SEARCH].filter?.districts);

    const fetchDistricts = fetchCodebookParent(DISTRICT, DISTRICT_BY_REGION);

    useEffect(() => {
        const filteredDistricts = selectedRegions?.map((region: ICodebookItem) => isNotNilOrEmptyArray(selectedDistricts) ? filter(propEq('parentCode', region?.code), selectedDistricts) : []);
        setFilterParam({districts: isNotNilOrEmptyArray(filteredDistricts) ? flatten(filteredDistricts) : []});
        if (isNotNilOrEmptyArray(selectedRegions)) {
            selectedRegions.map((region: ICodebookItem) => {
                if (isNilOrEmpty(districts[region.code])) {
                    fetchDistricts({params: {qp1: region.code}});
                }
            });
        }
    }, [selectedRegions?.length]);

    const onChange = (item: ICodebookItem) => {
        checkboxCodebookHandler(item, (newValue: any[]) => setFilterParam({districts: newValue}), selectedDistricts);
    };

    if (isNilOrEmpty(districts) || isNilOrEmpty(selectedRegions)) {
        return null;
    }

    return (
        <>
            {selectedRegions.map((region: ICodebookItem) => {
                    const disctricts = isNotNilOrEmpty(districts[region.code]) ? sortBy(prop('code'), districts[region.code]) as ICodebookItem[] : [];

                    return (
                        <Grid container className="districts" key={region.code}>
                            <Grid className="left blueText" item xs={12}>{region?.value}</Grid>
                            {disctricts.map((item: ICodebookItem) => (
                                <Grid className="left" item xs={12} sm={6} key={item.order}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                checked={isSelectedCodebookItem(item, selectedDistricts) || false}
                                                color="primary"
                                                onClick={() => onChange(item)}
                                            />
                                        )}
                                        label={item.value}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )
                }
            )}
        </>
    );
}

export default DistrictFilter;
