import React from 'react';
import ReactDOM from 'react-dom';
import App, {setConfig} from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";

axios.get('/env.json').then(response => {
    setConfig(response.data);
    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root')
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
