import {
    any,
    clone,
    concat,
    equals,
    filter,
    find,
    flatten,
    has,
    includes,
    isEmpty,
    isNil,
    join,
    keys,
    map,
    omit,
    pick,
    pickBy,
    pipe,
    pluck,
    prop,
    propEq,
    replace,
    sort,
    startsWith,
    values, reject
} from 'ramda';
import {
    BUILDING_MATERIAL_TYPE,
    COMMERCIAL_TYPE,
    DISTRICT,
    HOUSE_TYPE,
    LOT_TYPE,
    OFFER_TYPE,
    REAL_ESTATE_DISPOSITION,
    REAL_ESTATE_OWNERSHIP,
    REAL_ESTATE_STATE,
    REAL_ESTATE_TYPE,
    REAL_ESTATE_TYPES,
    REGION
} from './constant';
import t from '../texts';
import {isDate} from 'moment';
import {ERouteParameters, ERoutePaths, ImageType} from '../enumerators';
import {matchPath} from 'react-router-dom';
import {eq, isBoolean, isObject} from 'lodash';

const moment = require('moment');

export const isNilOrEmpty = value => isNil(value) || isEmpty(value);
export const isNotNilOrEmpty = value => !isNilOrEmpty(value);
export const isNilOrEmptyArray = value => {
    let array = [];
    if (isArray(value)) {
        array = reject(isNilOrEmpty, value);
        return isNilOrEmpty(array);
    }
    return isNilOrEmpty(value);
};
export const isNotNilOrEmptyArray = value => !isNilOrEmptyArray(value);
export const isNilOrEmptyOrZero = value => isNilOrEmpty(value) || value === 0;
export const isNotNilOrEmptyOrZero = value => !isNilOrEmpty(value) && value !== 0;
export const isArray = values => Array.isArray(values);
export const isNotArray = values => !isArray(values);

export const isFilterEmpty = (filter) => {
    return isNilOrEmpty(filter?.offerType) && isNilOrEmpty(filter?.realEstateType) && isNilOrEmptyArray(filter?.realEstateDispositions) &&
        isNilOrEmptyArray(filter?.realEstateOwnership) && isNilOrEmptyArray(filter?.realEstateStates) &&
        isNilOrEmptyArray(filter?.buildingMaterialTypes) && isNilOrEmptyArray(filter?.realEstateStates) &&
        isNilOrEmptyArray(filter?.houseTypes) && isNilOrEmptyArray(filter?.lotTypes) && isNilOrEmptyArray(filter?.commercialType);
}


export const removeDiacritics = value => value.normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');

export const isBetween = (n, a, b) => {
    return (n - a) * (n - b) <= 0;
};

export const lowerCasedFirstLetter = (str) => {
    return str.charAt(0)
        .toLowerCase() + str.slice(1);
};

export const findByCode = (values, code) => isNotNilOrEmpty(code) && isNotNilOrEmpty(values) ? find(propEq('code', code), values) : undefined;

export const findByCodeOrDefault = (values, code) => {
    if (isNilOrEmpty(values) || isNilOrEmpty(code)) {
        return undefined;
    }
    return find(propEq('code', code), values) || undefined;
};

export const reduceObject = (list, propName) => isNotNilOrEmpty(list) && isNotNilOrEmpty(propName) && has(propName, list[0]) ? map(prop(propName), list) : list;

export const isSelectedCodebookItem = (item, values) => isNotNilOrEmptyArray(values) && isNotNilOrEmpty(item?.code) ? any(propEq('code', item?.code), values) : false;

export const checkboxCodebookHandler = (item, set, array = []) => {
    let modified = [];
    if (includes(item, array)) {
        modified = filter(x => x.code !== item.code, array);
    } else {
        array.push(item);
        modified = clone(array);
    }
    set(modified);
};

export const minusDaysToDate = days => {
    const today = moment();
    return today.subtract(days, 'days');
};

export const onChangeNumberHandler = method => ({target: {value}}) => method(parseInt(value, 10));
export const onChangeCurrencyHandler = method => ({target: {value}}) => {
    const format = num => String(num)
        .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
    method(parseInt(value, 10));
};
export const onChangePublishedHandler = method => ({target: {value}}) => {
    method(minusDaysToDate(value));
};

export const onChangeStringHandler = method => ({target: {value}}) => {
    method(value);
};

export const onChangeCodebookHandler = (method, codebook) => ({target: {value}}) => {
    const newValue = find(propEq('code', value), codebook);
    method(newValue);
};

export const defaultType = {
    order: 0,
    code: 'APARTMENT',
    value: 'Byt'
};


export const replacePathParams = (path, params) => {
    return path.replace(/:[a-zA-Z0-9?]+/g, (match) => {
        return prop(replace(':', '', match), params);
    });
};

export const clearNilOrEmpty = value => {
    return pipe(pickBy(isNotNilOrEmpty))(value);
};

export const addQueryParams = (params) => {
    let result = '?';
    // eslint-disable-next-line array-callback-return
    Object.keys(clearNilOrEmpty(params))
        .forEach((key, index) => {
            result = concat(result, `${index > 0 ? '&' : ''}${key}=${params[key]}`);
        });
    return result;
};

export const formatPrice = price => {
    if (isNilOrEmptyOrZero(price)) {
        return '';
    }
    if (-1 === price) {
        return 'Cena na vyžiadanie';
    }
    const formatter = new Intl.NumberFormat('sk-SK', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0
    });

    return formatter.format(price);
};

export const formatDate = date => {
    return moment(date * 1000)
        .format('DD. MM. yyyy');
};

export const formatAddress = address => {
    if (isNilOrEmpty(address)) {
        return;
    }
    const {
        street = '',
        registrationNumber = '',
        buildingNumber,
        city = ''
    } = address;
    // 'Československého exilu, Praha 4 - Modřany'
    // eslint-disable-next-line consistent-return
    const streetPart = `${street || ''}${isNotNilOrEmpty(street) && isNotNilOrEmpty(registrationNumber) ? ' ' + registrationNumber : ''}${(isNotNilOrEmpty(street) && isNotNilOrEmpty(registrationNumber) && isNotNilOrEmpty(buildingNumber)) ? '/' : ''}${isNotNilOrEmpty(street) && isNotNilOrEmpty(buildingNumber) ? buildingNumber : ''}`;
    const cityPart = `${city || ''}`;
    return `${streetPart && cityPart ? streetPart + ', ' + cityPart : ''}${!streetPart && cityPart ? cityPart : ''}`;
};

export const todayTomorrowDate = date => {
    if (!isDate(date)) {
        return 'Neuvedené';
    }
    const today = moment();
    const diff = moment(date)
        .diff(today, 'days');
    if (diff === 0) {
        return t.TODAY;
    } else if (diff === 1) {
        return t.YESTERDAY;
    } else if (diff === -1) {
        return t.TOMORROW;
    } else {
        return formatDate(date);
    }
};

export const formatOfferItemStatus = offerItem => {
    return `Vložené: ${todayTomorrowDate(offerItem.publishedAt)} | Aktualizované: Dnes | Exspirácia: ${todayTomorrowDate(offerItem.publishedTo)}`;
};

export const m2 = 'm²';

export const formatSpaceSize = size => `${size}${m2}`;
export const formatLevels = (floor, maxFloor) => `${floor} z ${maxFloor}`;
export const formatBoolean = value => value ? t.YES : t.NO;

export const formatApartmentDesc = item => {
    // Byt 3+kk, 56m2
    const result = `${item.realEstateType?.value}, ${item.realEstateDisposition?.value || ''}`;
    if (isNotNilOrEmpty(item.surfaceArea)) {
        return `${result}, ${item.surfaceArea}${m2}`;
    }
    return result;
};

export const formatCommercialDesc = item => {
    let result = item.realEstateType.value;
    if (isNotNilOrEmpty(item.commercialType?.value)) {
        result = `${result}, ${item.commercialType?.value}`;
    }
    if (isNotNilOrEmpty(item.surfaceArea)) {
        result = `${result}, ${item.surfaceArea}${m2}`;
    }
    return result;
};

export const formatLotDesc = item => {
    let result = item.realEstateType.value;
    if (isNotNilOrEmpty(item.lotTypes)) {
        result = `${result}, ${item.lotTypes?.value}`;
    }
    if (isNotNilOrEmpty(item.lotArea)) {
        result = `${result}, ${item.lotArea}${m2}`;
    }
    return result;
};

export const formatOfferType = offerType => {
    return isNotNilOrEmpty(offerType) ? `${offerType.value}` : '';
};

export const formatRealEstateDesc = item => {
    if (isNilOrEmpty(item)) {
        return '';
    }
    switch (item.realEstateType?.code) {
        case REAL_ESTATE_TYPES.APARTMENT:
            return formatApartmentDesc(item);
        case REAL_ESTATE_TYPES.HOUSE:
            return formatApartmentDesc(item);
        case REAL_ESTATE_TYPES.COMMERCIAL:
            return formatCommercialDesc(item);
        case REAL_ESTATE_TYPES.LOT:
            return formatLotDesc(item);
        default:
            return '';
    }
};

export const formatOfferItemDesc = (offerType, item) => {
    return `${formatOfferType(offerType)}, ${formatRealEstateDesc(item)}`;
};

const getValuesByCodebook = (items, codebook) => {
    if (isNilOrEmpty(codebook) || isNilOrEmpty(items)) {
        return '';
    }
    return isArray(items)
        ? map(item => pipe(i => findByCode(codebook, i), pick(['value']), values, flatten, join(', '))(item), items)
        : pipe(i => findByCode(codebook, i), pick(['value']), values, flatten, join(', '))(items);

};

export const getRealEstateType = (item, codebook) => {
    if (isNilOrEmpty(codebook) || isNilOrEmpty(item)) {
        return '';
    }
    if (isNotNilOrEmpty(item.apartment)) {
        return findByCode(codebook, REAL_ESTATE_TYPES.APARTMENT);
    }
    if (isNotNilOrEmpty(item.house)) {
        return findByCode(codebook, REAL_ESTATE_TYPES.HOUSE);
    }
    if (isNotNilOrEmpty(item.lot)) {
        return findByCode(codebook, REAL_ESTATE_TYPES.LOT);
    }
    if (isNotNilOrEmpty(item.commercial)) {
        return findByCode(codebook, REAL_ESTATE_TYPES.COMMERCIAL);
    }
    return '';
};

export const getRealEstateTypeValue = (item, codebook) => {
    if (isNilOrEmpty(codebook) || isNilOrEmpty(item)) {
        return '';
    }
    if (isNotNilOrEmpty(item.apartment)) {
        return findByCode(codebook, REAL_ESTATE_TYPES.APARTMENT)?.value;
    }
    if (isNotNilOrEmpty(item.house)) {
        return findByCode(codebook, REAL_ESTATE_TYPES.HOUSE)?.value;
    }
    if (isNotNilOrEmpty(item.lot)) {
        return findByCode(codebook, REAL_ESTATE_TYPES.LOT)?.value;
    }
    if (isNotNilOrEmpty(item.commercial)) {
        return findByCode(codebook, REAL_ESTATE_TYPES.COMMERCIAL)?.value;
    }
    return '';
};

export const arrayResultToString = pipe(flatten, filter(x => isNotNilOrEmpty(x) && !isBoolean(x)), join(', '));

export const formatSavedSearch = (item, codebooks) => {
    const main = [];
    const result = [];
    let price = '';
    let published = '';

    const realEstateType = item.apartment || item.house || item.lot || item.commercial;

    main.push(getRealEstateTypeValue(item, codebooks[REAL_ESTATE_TYPE]));
    main.push(getValuesByCodebook(item.offerTypes, codebooks[OFFER_TYPE]));

    result.push(getValuesByCodebook(realEstateType?.realEstateDispositions, codebooks[REAL_ESTATE_DISPOSITION]));
    result.push(getValuesByCodebook(realEstateType?.realEstateStates, codebooks[REAL_ESTATE_STATE]));
    result.push(getValuesByCodebook(realEstateType?.realEstateOwnerships, codebooks[REAL_ESTATE_OWNERSHIP]));
    result.push(getValuesByCodebook(realEstateType?.buildingMaterialTypes, codebooks[BUILDING_MATERIAL_TYPE]));
    result.push(getValuesByCodebook(item.districts, codebooks[DISTRICT]));
    result.push(getValuesByCodebook(item.regions, codebooks[REGION]));
    result.push(getValuesByCodebook(realEstateType?.commercialType, codebooks[COMMERCIAL_TYPE]));
    result.push(getValuesByCodebook(realEstateType?.houseTypes, codebooks[HOUSE_TYPE]));
    result.push(getValuesByCodebook(realEstateType?.lotTypes, codebooks[LOT_TYPE]));

    if (isNotNilOrEmptyOrZero(item.priceMin) || isNotNilOrEmptyOrZero(item.priceMax)) {
        price = `Cena: ${isNotNilOrEmptyOrZero(item.priceMin) ? 'od ' : ''} ${formatPrice(item?.priceMin)}  ${isNotNilOrEmptyOrZero(item.priceMax) ? 'do ' : ''} ${formatPrice(item?.priceMax)}`;
    }
    if (isNotNilOrEmpty(item.publishedAtFrom)) {
        published = `Publikovano od: ${formatDate(item.publishedAtFrom)}`;
    }

    return {
        main: isNotNilOrEmpty(main) ? arrayResultToString(main) : '',
        details: isNotNilOrEmpty(result) ? arrayResultToString(result) : '',
        price,
        published
    };
};

export const findByCodeArray = (items, codebook) => isNotNilOrEmpty(codebook) ? items?.map(i => findByCode(codebook, i)) : null;

export const reConstructFilter = (search, codebooks) => {
    if (isNilOrEmpty(search)) {
        return null;
    }
    const omitArray = ['apartment', 'house', 'lot', 'commercial', 'offerTypes'];
    const filter = search.savedSearch;
    const realEstateTypeObject = filter.apartment || filter.house || filter.lot || filter.commercial;
    const realEstate = {
        ...realEstateTypeObject,
        realEstateDispositions: findByCodeArray(realEstateTypeObject?.realEstateDispositions, codebooks[REAL_ESTATE_DISPOSITION]),
        realEstateStates: findByCodeArray(realEstateTypeObject?.realEstateStates, codebooks[REAL_ESTATE_STATE]),
        realEstateOwnerships: findByCodeArray(realEstateTypeObject?.realEstateOwnerships, codebooks[REAL_ESTATE_OWNERSHIP]),
        buildingMaterialTypes: findByCodeArray(realEstateTypeObject?.buildingMaterialTypes, codebooks[BUILDING_MATERIAL_TYPE]),
        districts: findByCodeArray(filter.districts, codebooks[DISTRICT]),
        regions: findByCodeArray(filter.regions, codebooks[REGION]),
        commercialType: findByCodeArray(realEstateTypeObject?.commercialType, codebooks[COMMERCIAL_TYPE]),
        houseTypes: findByCodeArray(realEstateTypeObject?.houseTypes, codebooks[HOUSE_TYPE]),
        lotTypes: findByCodeArray(realEstateTypeObject?.lotTypes, codebooks[LOT_TYPE])
    };

    const publishedAtFrom = moment()
        .diff(moment.unix(filter.publishedAtFrom), 'days');
    return {
        ...search,
        savedSearch: omit(omitArray, {
            ...filter,
            realEstateType: getRealEstateType(filter, codebooks[REAL_ESTATE_TYPE]),
            offerType: findByCodeArray(filter.offerTypes, codebooks[OFFER_TYPE])?.[0],
            publishedAtFrom: publishedAtFrom > 30 ? 0 : publishedAtFrom,
            ...realEstate
        })
    };
};

export const formatFavoriteText = item => {
    let result = [`${formatOfferType(item?.offerType)}, ${item?.realEstateType?.value}`];
    if (isNotNilOrEmpty(item?.priceMax)) {
        result.push(`${formatPrice(item?.priceMin) || 0} - ${formatPrice(item?.priceMax)}`);
    }
    if (isNotNilOrEmpty(item?.realEstateDispositions)) {
        result.push(pipe(pluck('value'), join(', '))(item.realEstateDispositions));
    }
    if (isNotNilOrEmpty(item?.regions)) {
        result.push(pipe(pluck('value'), join(', '))(item.regions));
    }

    return result;
};

export const normalizeFilter = (filter) => {
    return pipe(pickBy(isNotNilOrEmptyArray), pickBy(item => item !== false && item !== 0))(filter);
};

export const normalizeSavedSearch = (search) => {
    return {
        ...search,
        savedSearch: normalizeFilter(search.savedSearch)
    };
};

export const formatToRequestBody = filter => {
    const result = {};
    Object.keys(filter)
        .forEach(key => {
            const value = filter[key];
            if (Array.isArray(value)) {
                result[key] = reduceObject(value, 'code');
            } else if (value?.code && value?.value) {
                result[key] = value?.code;
            } else if (key === 'publishedAtFrom') {
                result[key] = isNilOrEmpty(value) || value === 0 ? undefined : minusDaysToDate(value)
                    .toISOString();
            } else if (moment.isMoment(value) || moment.isDate(value)) {
                result[key] = moment(value)
                    .toISOString();
            } else {
                result[key] = value;
            }
        });
    return result;
};

export const ImagePriority = [ImageType.OVERVIEW, ImageType.OTHER];

const sortByPriority = (a, b) => {
    return ImagePriority[a?.type] - ImagePriority[b?.type];
};

export const filterFileByType = (type, images) => isNotNilOrEmpty(images) && isArray(images) ? filter((image) => equals(image.type, type), images) : [];

export const sortImagesByPriority = images => sort(sortByPriority, images);

export const formatDistance = meters => meters < 1000 ? `${meters}m` : `${Math.round(meters / 1000, 10)}km ${meters % 1000}m`;

export const replaceOfferItemIdPath = (path, offerItem) => replace(`:${ERouteParameters.OFFER_ITEM_ID}`, offerItem, path);

export const isAuth0Redirect = (location) => matchPath(location, {
    path: ERoutePaths.AUTH0,
    params: {}
});

export const canBeSplit = (str) => {
    return (str || '').split('/').length > 1;
};

export const formatFullName = person => `${person?.firstName} ${person?.lastName}`;

export const formatAcronym = str => str ? str.split(' ')
    .map(s => String.fromCodePoint(s.codePointAt(0) || '')
        .toUpperCase())
    .join('') : '';

export const ContactFormInitState = ({
    name: '',
    email: '',
    phone: '',
    message: '',
    offerItemUrl: '',
    copyToMail: false
});

export const hasUserValidRole = (roles, role) => {
    if (isNilOrEmpty(role)) {
        return true;
    }
    return isNotNilOrEmpty(roles) && any(equals(role), roles);
};

export const handleAction = (type, handlers) => {
    if (isNotNilOrEmpty(handlers[type])) {
        return handlers[type];
    }
    const clearType = replace(/_REQUEST|_SUCCESS|_FAILED/, '', type);
    return pipe(keys, find(startsWith(clearType)), x => prop(x, handlers))(handlers);
};

export const convertNoAgencyUsers = (response, type) => {
    if (type === 'GET_NO_AGENCY_USERS_SUCCESS' && response.data) {
        return response.data.map(user => {
            return {
                user: {...user},
                selected: false
            };
        });
    }
    return [];
};

export const deepEqual = (a, b) => {
    const keys1 = Object.keys(a);
    const keys2 = Object.keys(b);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = a[key];
        const val2 = b[key];
        const areObjects = isObject(val1) && isObject(val2);

        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && !equals(val1, val2))) {
            return false;
        }
    }
    return true;
};

export const isFavoriteSearch = (filter, list) => {
    if (isNilOrEmpty(filter) || isNilOrEmpty(list)) {
        return false;
    }
    const normalized = normalizeFilter(filter);
    const favorite = find((a) => deepEqual(a, normalized), list);
    return isNotNilOrEmpty(favorite);
};
