import {
    ERoutePaths,
    OtherRealEstateProps,
    OtherRealEstatePropsDescription,
    UrlSearchParamsPrefixes
} from "../enumerators";
import useCodebooks from "./useCodebooks";
import {useMemo} from "react";
import {useLocation} from "react-router-dom";
import {
    BUILDING_MATERIAL_TYPE,
    COMMERCIAL_TYPE,
    DISTRICT,
    HOUSE_TYPE,
    LOT_TYPE,
    OFFER_TYPE,
    REAL_ESTATE_DISPOSITION,
    REAL_ESTATE_OWNERSHIP,
    REAL_ESTATE_STATE,
    REAL_ESTATE_TYPE,
    REGION
} from "../utils/constant";
import {formatAddress, formatPrice, isNilOrEmpty, isNotNilOrEmpty} from "../utils/helpers";
import {filter, join, pipe} from "ramda";

export const useFilterBuilder = () => {
    const {codebooks} = useCodebooks();
    const location = useLocation();
    return useMemo(() => {

        const urlFromFilter = (filter: any): string => {
            let result = `${ERoutePaths.SEARCH_RESULT_BASE}/${filter.realEstateType?.urlFriendlyValue}/${filter.offerType.urlFriendlyValue}`;
            let districts = filterCBArrayToUrlString(filter.districts, UrlSearchParamsPrefixes.DISTRICTS);
            let regions = filterCBArrayToUrlString(filter.regions, UrlSearchParamsPrefixes.REGIONS);
            let disposition = filterCBArrayToUrlString(filter.realEstateDispositions, UrlSearchParamsPrefixes.DISPOSITION);
            let materials = filterCBArrayToUrlString(filter.buildingMaterialTypes, UrlSearchParamsPrefixes.MATERIAL);
            let ownership = filterCBArrayToUrlString(filter.realEstateOwnership, UrlSearchParamsPrefixes.OWNERSHIP);
            let state = filterCBArrayToUrlString(filter.realEstateStates, UrlSearchParamsPrefixes.STATE);
            let houseTypes = filterCBArrayToUrlString(filter.houseTypes, UrlSearchParamsPrefixes.HOUSE_TYPES);
            let lotTypes = filterCBArrayToUrlString(filter.lotTypes, UrlSearchParamsPrefixes.LOT_TYPES);
            let commercialTypes = filterCBArrayToUrlString(filter.commercialType, UrlSearchParamsPrefixes.COMMERCIAL_TYPES);
            let floor = filterRangeValuesToUrlString(filter.minFloor, filter.maxFloor, UrlSearchParamsPrefixes.FLOOR);
            let surface = filterRangeValuesToUrlString(filter.surfaceAreaMin, filter.surfaceAreaMax, UrlSearchParamsPrefixes.SURFACE);
            let price = filterRangeValuesToUrlString(filter.priceMin, filter.priceMax, UrlSearchParamsPrefixes.PRICE);
            let lotArea = filterRangeValuesToUrlString(filter.lotAreaMin, filter.lotAreaMax, UrlSearchParamsPrefixes.LOT_AREA);
            const otherProps = processOtherProps(filter);
            const pathParams = result + regions + districts;
            const urlParams = disposition + materials + price + ownership + state + houseTypes + lotTypes + commercialTypes + floor
                + surface + lotArea + otherProps;
            return `${pathParams}${!!urlParams ? '?' + urlParams.substring(1) : ''}`;
        };


        const userSearchTextFromFilter = (param: any): string => {
            if (isNilOrEmpty(param)) return '';

            const realEstateType = param.realEstateType?.userFriendlyValue;
            const offerType = param.offerType?.userFriendlyValue;
            const dispositions = filterCBArrayToUserFriendlyString(param.realEstateDispositions, ' ');
            const districts = filterCBArrayToUserFriendlyString(param.districts, ' v okresoch ', 'Okres ');

            const materials = filterCBArrayToUserFriendlyString(param.buildingMaterialTypes, ' z materiálu ');
            const ownership = filterCBArrayToUserFriendlyString(param.realEstateOwnership, ', v ',' vlastníctve');
            const state = filterCBArrayToUserFriendlyString(param.realEstateStates, ', v stave ');
            const houseTypes = filterCBArrayToUserFriendlyString(param.houseTypes, ', typu ');
            const lotTypes = filterCBArrayToUserFriendlyString(param.lotTypes, ', typu ');
            const commercialTypes = filterCBArrayToUserFriendlyString(param.commercialType, ', typu ');
            const floor = filterRangeValuesToUserFriendly(param.minFloor, param.maxFloor, ', podlažie');
            const surface = filterRangeValuesToUserFriendly(param.surfaceAreaMin, param.surfaceAreaMax, ' s plochou', '㎡');
            const price = filterRangeValuesToUserFriendly(param.priceMin, param.priceMax, ', cena', '€');
            const lotArea = filterRangeValuesToUserFriendly(param.lotAreaMin, param.lotAreaMax, ' s plochou', '㎡');
            const otherProps = processOtherPropsToUserFriendlyValues(param);
            const desc: string[] = [realEstateType, dispositions, offerType, districts, materials, ownership, state, houseTypes, lotTypes, commercialTypes, floor
                , surface, lotArea, price, otherProps];
            // @ts-ignore
            return pipe<string[], string[], string>(filter(isNotNilOrEmpty), join(' '))(desc);
        };

        const offerItemDetailTitleText = (item: any): string => {
            const realEstateType = item.realEstate.realEstateType.value;
            const houseType = item.realEstate.houseType?.value ? ' - ' + item.realEstate.houseType?.userFriendlyValue : '';
            const disposition = item.realEstate.realEstateDisposition?.value;
            const surfaceArea = emptyIfZero('', item.realEstate.surfaceArea, ' ㎡')
            const lotArea = emptyIfZero(' s plochou pozemku', item.realEstate.lotArea, ' ㎡')
            const builtArea = emptyIfZero(' so zastavanou plochou', item.realEstate.builtArea, ' ㎡')
            const offerType = item.offerType?.userFriendlyValue ? item.offerType.userFriendlyValue : '';
            const address = formatAddress(item.address);

            return `${realEstateType}${houseType} ${disposition}${builtArea}${lotArea} ${surfaceArea} ${offerType} ${address}`;
        };

        //Byt 3+1 78 m² k prodeji Na Dlouhých, Plzeň - Lobzy; 4 150 000 Kč (právní servis zajištěn u advokátní kanceláře), parkovací místo, panelová stavba, osobní vlastnictví, ve velmi dobrém stavu.
        const offerItemDetailDescriptionText = (item: any): string => {
            const price = formatPrice(item.price);
            const priceInfo = item.priceInfo ? ' (' + item.priceInfo + ') ' : '';
            const props = processOtherPropsToDescription(item.realEstate);
            return `${offerItemDetailTitleText(item)}, ${price}${priceInfo} ${props}`;
        };

        const emptyIfZero = (prefix: string, val: number, postfix: string): string => {
            return val !== 0 ? `${prefix} ${val} ${postfix}` : '';
        };

        const filterFromUrl = (): any => {
            const filter: any = {};

            let baseUrl = location.pathname.replace(ERoutePaths.SEARCH_RESULT_BASE + '/', '');
            const realEstateType = parseFromUrl(baseUrl, REAL_ESTATE_TYPE, '/');
            filter['realEstateType'] = realEstateType.cbValue;
            baseUrl = baseUrl.replace(realEstateType.valueString + '/', '');

            const offerType = parseFromUrl(baseUrl, OFFER_TYPE, '/');
            filter['offerType'] = offerType.cbValue;
            baseUrl = baseUrl.replace(offerType.valueString, '');

            if (baseUrl.indexOf(UrlSearchParamsPrefixes.REGIONS.PREFIX) >= 0) {
                baseUrl = baseUrl.replaceAll(UrlSearchParamsPrefixes.REGIONS.PREFIX, '');
                const regionsString = substringUntil(baseUrl, '/');
                const regionStrings = regionsString.split(UrlSearchParamsPrefixes.REGIONS.SEPARATOR);
                filter['regions'] = [];
                regionStrings.forEach(value => {
                    filter['regions'].push(findInCodebookValue(value, REGION));
                });
                baseUrl = baseUrl.replaceAll(regionsString, '');
            }

            if (baseUrl.indexOf(UrlSearchParamsPrefixes.DISTRICTS.PREFIX) >= 0) {
                baseUrl = baseUrl.replaceAll(UrlSearchParamsPrefixes.DISTRICTS.PREFIX, '');
                filter['districts'] = [];
                codebooks[DISTRICT].forEach((value: any) => {
                    if (baseUrl.indexOf(value.urlFriendlyValue) >= 0) {
                        filter['districts'].push(value);
                    }
                });
            }

            const searchParams = location.search;
            filter['realEstateDispositions'] = stringToCBArray(searchParams, UrlSearchParamsPrefixes.DISPOSITION, REAL_ESTATE_DISPOSITION);
            filter['buildingMaterialTypes'] = stringToCBArray(searchParams, UrlSearchParamsPrefixes.MATERIAL, BUILDING_MATERIAL_TYPE);
            filter['realEstateOwnership'] = stringToCBArray(searchParams, UrlSearchParamsPrefixes.OWNERSHIP, REAL_ESTATE_OWNERSHIP);
            filter['realEstateStates'] = stringToCBArray(searchParams, UrlSearchParamsPrefixes.STATE, REAL_ESTATE_STATE);
            filter['houseTypes'] = stringToCBArray(searchParams, UrlSearchParamsPrefixes.HOUSE_TYPES, HOUSE_TYPE);
            filter['lotTypes'] = stringToCBArray(searchParams, UrlSearchParamsPrefixes.LOT_TYPES, LOT_TYPE);
            filter['commercialType'] = stringToCBArray(searchParams, UrlSearchParamsPrefixes.COMMERCIAL_TYPES, COMMERCIAL_TYPE);

            fillRangeValuesToFilter(filter, searchParams, UrlSearchParamsPrefixes.FLOOR, 'minFloor', 'maxFloor');
            fillRangeValuesToFilter(filter, searchParams, UrlSearchParamsPrefixes.SURFACE, 'surfaceAreaMin', 'surfaceAreaMax');
            fillRangeValuesToFilter(filter, searchParams, UrlSearchParamsPrefixes.PRICE, 'priceMin', 'priceMax');
            fillRangeValuesToFilter(filter, searchParams, UrlSearchParamsPrefixes.LOT_AREA, 'lotAreaMin', 'lotAreaMax');
            fillFilterWithOtherProps(filter, searchParams);

            return filter;
        }

        const processOtherPropsToUserFriendlyValues = (filter: any): string => {
            const result: string[] = [];
            Object.keys(OtherRealEstateProps).forEach(prop => {
                const val = filter[prop];
                if (val) {
                    // @ts-ignore
                    result.push(OtherRealEstateProps[prop]);
                }
            });
            if (result.length > 0) {
                return ' s ďalšími vlastnosťami ako je ' + result.join(', ');
            }
            return '';
        }

        const processOtherPropsToDescription = (item: any): string => {
            const result: string[] = [];
            Object.keys(OtherRealEstatePropsDescription).forEach(prop => {
                const val = item[prop];
                if (val) {
                    // @ts-ignore
                    result.push(OtherRealEstatePropsDescription[prop]);
                }
            });
            if (result.length > 0) {
                return result.join(', ');
            }
            return '';
        }

        const processOtherProps = (filter: any): string => {
            const result: string[] = [];
            Object.keys(OtherRealEstateProps).forEach(prop => {
                const val = filter[prop];
                if (val) {
                    // @ts-ignore
                    result.push(OtherRealEstateProps[prop]);
                }
            });
            if (result.length > 0) {
                return '&dalsie-vlastnosti=' + result.join(',');
            }
            return '';
        }

        const fillFilterWithOtherProps = (filter: any, url: string) => {
            Object.keys(OtherRealEstateProps).forEach(prop => {
                // @ts-ignore
                const value = OtherRealEstateProps[prop];
                if (url.indexOf(value) > -1) {
                    filter[prop] = true;
                }
            });
        }

        const substringUntil = (str: string, until: string) => {
            const end = str.indexOf(until) === -1 ? str.length : str.indexOf(until);
            return str.substring(0, end);
        }

        const parseFromUrl = (url: string, cbName: string, separator: string) => {
            const valueString = substringUntil(url, separator);
            return {valueString, cbValue: findInCodebookValue(valueString, cbName)};
        }

        const findInCodebookValue = (urlFriendlyValue: string, cbName: string) => {
            return codebooks[cbName].find((cb: any) => cb.urlFriendlyValue === urlFriendlyValue);
        }

        const stringToCBArray = (str: string, config: { PREFIX: string, SEPARATOR: string }, cbName: string) => {
            const prefix = config.PREFIX.replace('&', '');
            const searchString = str.substring(str.indexOf(prefix) + prefix.length, str.length);
            const positionOfAnd = searchString.indexOf('&');
            const value = searchString.substring(0, positionOfAnd < 0 ? searchString.length : positionOfAnd);
            const values = value.split(config.SEPARATOR);
            const result: any[] = [];
            values.forEach(val => {
                const cb = findInCodebookValue(val, cbName);
                if (cb) {
                    result.push(cb);
                }
            });
            return result;
        };

        const fillRangeValuesToFilter = (filter: any, value: string, config: { PREFIX: string, LOWER: string, UPPER: string, BOTH: string }, minParamName: string, maxParamName: string) => {
            const prefix = config.PREFIX.replaceAll('&', '');
            const prefixIndex = value.indexOf(prefix);
            if (prefixIndex < 0) {
                return;
            }
            let searchString = value.substring(prefixIndex + prefix.length, value.length);
            let endIndex = searchString.indexOf('&');
            if (endIndex < 0) {
                endIndex = searchString.length;
            }
            searchString = searchString.substring(0, endIndex);
            if (searchString.indexOf(config.BOTH) > -1) {
                searchString = searchString.replaceAll('=', '');
                const values = searchString.split(config.BOTH);
                filter[minParamName] = values[0];
                filter[maxParamName] = values[1];
            } else if (searchString.indexOf(config.LOWER) > -1) {
                searchString = searchString.replaceAll(config.LOWER, '');
                filter[minParamName] = searchString;
            } else if (searchString.indexOf(config.UPPER) > -1) {
                searchString = searchString.replaceAll(config.UPPER, '');
                filter[maxParamName] = searchString;
            }
        };

        const filterCBArrayToUrlString = (cbArray: any[], config: { PREFIX: string, SEPARATOR: string }) => {
            return `${cbArray && cbArray.filter(c => c != null || c !== undefined).length > 0 ? config.PREFIX + cbArray.map(d => d.urlFriendlyValue || d)
                .join(config.SEPARATOR) : ''}`;
        };

        const filterCBArrayToUserFriendlyString = (cbArray: any[], prefix: string, postfix: string = '', replace: string = '') => {
            return `${cbArray && cbArray.filter(c => c != null || c !== undefined).length > 0 ?
                prefix + cbArray.map(d => d.userFriendlyValue || d.value)
                    .map(d => replace ? d.replaceAll(replace, '') : d)
                    .join(', ') + postfix : ''}`;
        };

        const filterRangeValuesToUrlString = (lowerBoundary: any, upperBoundary: any, config: { PREFIX: string, LOWER: string, UPPER: string, BOTH: string }) => {
            if (!lowerBoundary && !upperBoundary) {
                return '';
            } else if (!lowerBoundary && upperBoundary) {
                return config.PREFIX + config.UPPER + upperBoundary;
            } else if (lowerBoundary && !upperBoundary) {
                return config.PREFIX + config.LOWER + lowerBoundary;
            } else {
                return `${config.PREFIX}=${lowerBoundary}${config.BOTH}${upperBoundary}`;
            }
        }

        const filterRangeValuesToUserFriendly = (lowerBoundary: any, upperBoundary: any, prefix: string, unit: string = '') => {
            if (!lowerBoundary && !upperBoundary) {
                return '';
            } else if (!lowerBoundary && upperBoundary) {
                return prefix + ' do ' + formatPrice(upperBoundary);
            } else if (lowerBoundary && !upperBoundary) {
                return prefix + ' od ' + formatPrice(lowerBoundary);
            } else {
                return `${prefix} ${formatPrice(lowerBoundary)} až ${formatPrice(upperBoundary)}`;
            }
        }

        return {
            urlFromFilter,
            filterFromUrl,
            userSearchTextFromFilter,
            offerItemDetailTitleText,
            offerItemDetailDescriptionText
        }
    }, [codebooks, location]);
};
