import React, {useEffect, useMemo, useState} from 'react';
import Leaflet from 'leaflet';
import {AttributionControl, MapContainer, Marker, TileLayer, Tooltip} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {formatOfferItemDesc, formatPrice, isNotNilOrEmpty} from '../../utils/helpers';
import './style.scss';
import {useHistory} from "react-router-dom";
import {Box, Button, Hidden} from "@material-ui/core";
import {getConfig} from "../../App";
import {any, propEq} from "ramda";
import {ISearchResultContent} from "../../utils/types";
import {useSelector} from "react-redux";
import {REDUCER_CONTEXT} from "../../utils/constant";
import t from '../../texts';
import {createAction} from "../../store/actions";
import {SET_IS_MAP_EXPANDED} from "../../store/reducers/context";

const selectedMarkerIcon = process.env.PUBLIC_URL + '/images/marker-selected.png';
const markerIcon = process.env.PUBLIC_URL + '/images/marker.png';

const selectedMarker = Leaflet.icon({
    iconUrl: selectedMarkerIcon,
    shadowUrl: selectedMarkerIcon,
    iconSize: [44, 71], // size of the icon
});

const marker = Leaflet.icon({
    iconUrl: markerIcon,
    shadowUrl: markerIcon,
    iconSize: [24, 39], // size of the icon
});

const defaultMapCenter = [48.737622616158006, 19.150187199248194] as [number, number];

const Map = ({
                 onClick,
                 markers,
                 centerMarker,
                 width,
                 linkToDetail
             }: { onClick: any, markers: any[], centerMarker: any, width: number | undefined, linkToDetail: (offerItem: ISearchResultContent) => string }) => {

    const {push} = useHistory();
    const [map, setMap] = useState(null as unknown as Leaflet.Map);
    const [center, setCenter] = useState<[number, number]>(defaultMapCenter);
    const {isMapExpanded} = useSelector((state: any) => state[REDUCER_CONTEXT]);

    const setIsMapExpanded = createAction(SET_IS_MAP_EXPANDED);
    const isCenterMarker = useMemo(() => isNotNilOrEmpty(centerMarker?.address?.latitude) && isNotNilOrEmpty(centerMarker?.address?.longitude), [centerMarker]);
    const includeCenter = useMemo(() => isNotNilOrEmpty(markers) && isNotNilOrEmpty(centerMarker) && any(propEq('extId', centerMarker?.extId), markers), [centerMarker, markers])

    useEffect(() => {
        if (isNotNilOrEmpty(map)) {
            map.invalidateSize();
        }
    }, [map, width]);

    useEffect(() => {
        if (isCenterMarker) {
            setCenter([centerMarker?.address?.latitude, centerMarker?.address?.longitude]);
        }
    }, [centerMarker?.address?.latitude, centerMarker?.address?.longitude])

    useEffect(() => {
        if (isNotNilOrEmpty(map)) {
            if (isNotNilOrEmpty(markers)) {
                map.fitBounds(markers.map(m => [m.address?.latitude, m.address?.longitude]));
            } else if (isNotNilOrEmpty(center)) {
                map.fitBounds([center]);
            }
            if (isCenterMarker) {
                map.fitBounds([center]);
            }
        }
    }, [map, markers, center]);

    const onDetail = (item: any) => {
        if(isMapExpanded){
            setIsMapExpanded(false);
        }
        push(linkToDetail(item));
    };

    return (
        <Box data-component="Map" width={width}>
            <Hidden mdUp>
                <Button variant="text" color="primary" className="button"
                        onClick={onClick}>{isMapExpanded ? t.HIDE_MAP : t.SHOW_MAP}</Button>
            </Hidden>
            <MapContainer
                center={center}
                whenCreated={setMap}
                className="container"
            >
                <AttributionControl position="bottomleft" prefix={false}/>
                <TileLayer
                    attribution={'Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'}
                    url={`https://maps.geoapify.com/v1/tile/osm-bright/{z}/{x}/{y}.png?apiKey=${getConfig().geoApifyApiKey}`}
                />
                <MarkerClusterGroup>
                    {isCenterMarker && !includeCenter && (
                        <Marker position={[centerMarker.address?.latitude, centerMarker.address?.longitude]}
                                key={centerMarker.extId}
                                eventHandlers={{
                                    click: () => onDetail(centerMarker)
                                }}
                                icon={selectedMarker}
                        >
                            <Tooltip>
                                <div>
                                    <h3>{formatOfferItemDesc(centerMarker?.offerType, centerMarker?.realEstate)}</h3>
                                    <p>{formatPrice(centerMarker?.price)}</p>
                                </div>
                            </Tooltip>
                        </Marker>
                    )}
                    {markers?.map((item: any) => (
                        <Marker position={[item.address?.latitude, item.address?.longitude]} key={item.extId}
                                eventHandlers={{
                                    click: () => onDetail(item)
                                }}
                                icon={centerMarker?.extId === item.extId ? selectedMarker : marker}
                        >
                            <Tooltip>
                                <div>
                                    <h3>{formatOfferItemDesc(item?.offerType, item?.realEstate)}</h3>
                                    <p>{formatPrice(item?.price)}</p>
                                </div>
                            </Tooltip>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
            </MapContainer>
        </Box>
    );
};

export default Map;
