import Advertisement from '../../components/Advertisement';
import Header from '../../components/Header';
import Layout from '../../layouts/AdministrationTemporary';
import React from 'react';
import { Box } from '@material-ui/core';
import './style.scss';
import BusinessConditions from '../../components/BusinessConditions';
import { AdministrationMenuItems } from '../../utils/constant';


export default () => (
    <Box data-component="Business_Conditions">
        <Layout menuItems={AdministrationMenuItems}>
            <Header />
            <Advertisement />
            <BusinessConditions />
        </Layout>
    </Box>
);
