import { Box, FormControl, Grid, Paper } from '@material-ui/core';
import Advertisement from '../../components/Advertisement';
import Header from '../../components/Header';
import Layout from '../../layouts/NewAdvert';
import React, { useEffect, useMemo } from 'react';
import './style.scss';
import t from '../../texts';
import { withCodebook, withFilteredDistrict, withState } from '../../components/Form/InputHOC';
import { CheckboxInputComp, NumberInputComp, SelectCodebookComp, TextInputComp } from '../../components/Form';
import { SET_ADDRESS_PROPERTY, SET_ADDRESS_REGION } from '../../store/reducers/advert';
import { REDUCER_ADVERT} from '../../utils/constant';
import { isNotNilOrEmpty } from '../../utils/helpers';
import AddressAutocomplete from '../AdvertAddress/AddressAutocomplete';
import { Alert } from '@material-ui/lab';
import { useApiDataSelector } from '../../hooks/hooks';
import { createAction } from '../../store/actions';

const SelectCodebook = withCodebook(SelectCodebookComp, 'address', SET_ADDRESS_REGION);
const SelectFilteredCodebook = withFilteredDistrict(SelectCodebookComp, 'address', SET_ADDRESS_PROPERTY);
const CheckboxInput = withState(CheckboxInputComp, 'address', SET_ADDRESS_PROPERTY);
const TextInput = withState(TextInputComp, 'address', SET_ADDRESS_PROPERTY);
const NumberInput = withState(NumberInputComp, 'address', SET_ADDRESS_PROPERTY);

const AdvertAddress = () => {
    const address = useApiDataSelector((state: any) => state[REDUCER_ADVERT].address);
    const isAddressValid = useMemo(() => isNotNilOrEmpty(address?.street) && isNotNilOrEmpty(address?.buildingNumber) && address?.buildingNumber !== 0, [address]);
    const setAddressProperty = createAction(SET_ADDRESS_PROPERTY);

    useEffect(() => {
        if (!isAddressValid && address?.autocomplete && !address.manually) {
            setAddressProperty({ manually: true });
        }
    }, [address]);

    return (
        <Layout>
            <Header />
            <Advertisement />

            <Box data-component="AdvertAddress">

                <h3>{t.STEP_ADDRESS}</h3>
                <Paper elevation={0}>
                    <Grid className="noMargin" container spacing={1} justify="flex-end" alignItems="center">
                        <Grid className="left blueText" item xs={12} sm={4}>{t.ADDRESS}</Grid>
                        <Grid item xs={12} sm={8}>
                            <AddressAutocomplete />
                        </Grid>

                        <Grid className="left blueText" item xs={12}>
                            <CheckboxInput label={t.MANUALLY} property="manually" />
                        </Grid>
                    </Grid>
                    <Grid className="noMargin" container spacing={1} justify="flex-end" alignItems="center">
                        <Grid item xs={12} sm={8}>
                            <TextInput label={t.STREET} property="street" disabled={!address?.manually} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <NumberInput
                                label={t.REGISTRATION_NUMBER}
                                property="registrationNumber"
                                disabled={!address?.manually}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <NumberInput
                                label={t.BUILDING_NUMBER}
                                property="buildingNumber"
                                disabled={!address?.manually}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextInput label={t.CITY} property="city" required disabled={!address?.manually} />
                        </Grid>
                    </Grid>
                    <Grid className="noMargin" container spacing={1} justify="flex-end" alignItems="center">

                        <Grid className="left blueText" item xs={12} sm={3}>{t.REGION}</Grid>
                        <Grid className="left" item xs={12} sm={9}>
                            <FormControl variant="outlined" size="small">
                                <SelectCodebook codebookName="REGION" property="region" disabled={!address?.manually} required />
                            </FormControl>
                        </Grid>
                        <Grid className="left blueText" item xs={12} sm={3}>{t.DISTRICT}</Grid>
                        <Grid className="left" item xs={12} sm={9}>
                            <FormControl variant="outlined" size="small">
                                {isNotNilOrEmpty(address?.region) && (
                                    <SelectFilteredCodebook
                                        codebookName="DISTRICT"
                                        property="district"
                                        filter="region"
                                        disabled={!address?.manually}
                                        required
                                    />
                                )}
                            </FormControl>
                        </Grid>
                        {!isAddressValid && (
                            <Grid item xs={12} alignItems="center">
                                <Alert className="noMargin" severity="warning">
                                    <p>Nehnuteľnosť nebude zobrazená na mape ak nevyplníte ulicu a číslo popisné.</p>
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </Paper>

            </Box>
        </Layout>
    );
};

export default AdvertAddress;
