// @ts-nocheck
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Hidden,
    InputLabel,
    MenuItem,
    Paper,
    Select, Switch
} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {NumberInputComp, SelectCodebookComp} from '../Form';
import {useSelector} from 'react-redux';
import {
    OFFER_TYPE,
    REDUCER_CODEBOOK,
    REDUCER_CONTEXT
} from '../../utils/constant';
import {createAction, fetchCodebook} from '../../store/actions';
import {isNilOrEmpty, isNotArray, isNotNilOrEmpty} from '../../utils/helpers';
import {SET_MY_ADVERT_FILTER} from '../../store/reducers/context';
import {ComponentFilterParams} from '../../utils/types';
import t from '../../texts';
import {OfferItemStatus} from '../../enumerators';
import {Search} from '@material-ui/icons';


export const withCodebookFilter = (Component: any) => ({
                                                           label,
                                                           codebookName,
                                                           filter,
                                                           selector = codebookName,
                                                           ...rest
                                                       }: ComponentFilterParams) => {
    const {[filter]: value} = useSelector((state: any) => state[REDUCER_CONTEXT].myAdvertFilter);
    const setFilterParam = createAction(SET_MY_ADVERT_FILTER);
    const fetch = fetchCodebook(codebookName);
    const values = useSelector((state: any) => state[REDUCER_CODEBOOK][selector]);

    useEffect(() => {
        if (isNilOrEmpty(values)) {
            fetch();
        }
    }, []);

    if (isNilOrEmpty(values) || isNotArray(values)) {
        return null;
    }
    return (
        <Component
            label={label}
            onChange={(newValue: any) => setFilterParam({[filter]: newValue})}
            values={values}
            currentValue={value}
            emptyValue
            {...rest}
        />
    );
};

export const withFilter = (Component: any) => ({label, param, ...rest}: { label: string, param: string }) => {
    const {filter, [param]: value} = useSelector((state: any) => state[REDUCER_CONTEXT].myAdvertFilter);
    const [localValue, setLocalValue] = useState();
    const setFilterParam = createAction(SET_MY_ADVERT_FILTER);

    useEffect(() => {
        if (isNotNilOrEmpty(value) && value !== localValue) {
            setLocalValue(value);
        }
    }, []);

    const onSave = useCallback(() => {
        if (isNotNilOrEmpty(localValue) && localValue !== value) {
            setFilterParam({...filter, [param]: localValue});
        }
    }, [setFilterParam, localValue, value]);

    return (
        <Component
            onChange={setLocalValue}
            onBlur={onSave}
            label={label}
            currentValue={localValue}
            {...rest}
        />
    );
};

const SelectCodebookFilter = withCodebookFilter(SelectCodebookComp);
const NumberFilter = withFilter(NumberInputComp);

const Filter = ({onFilter}: { onFilter: () => void }) => {
    const filter = useSelector((state: any) => state[REDUCER_CONTEXT].myAdvertFilter);
    const setFilterParam = createAction(SET_MY_ADVERT_FILTER);

    return (
        <Box my="0.5rem">
            <Paper className="paperPadding" elevation={0}>
                <div style={{width: '100%'}}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Box flexGrow={2} p={1} minWidth={150}>
                            <SelectCodebookFilter
                                codebookName={OFFER_TYPE}
                                filter="offerType"
                                label={t.OFFER_TYPE}
                                fullWidth
                            />
                        </Box>
                        <Box flexGrow={2} p={1} minWidth={150}>
                            <FormControl fullWidth>
                                <InputLabel id="select-state-label">Stav</InputLabel>
                                <Select
                                    labelId="select-state-label"
                                    label="Stav"
                                    value={filter?.status ?? ''}
                                    onChange={({target: {value}}) => setFilterParam({status: value || ''})}
                                >
                                    <MenuItem value=""><em>{t.ALL}</em></MenuItem>
                                    {Object.keys(OfferItemStatus).map((item, index) => (
                                        <MenuItem
                                            key={`menu-item-${index}`}
                                            value={item}
                                        >
                                            {t[item]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box flexGrow={2} maxWidth={180} p={1} minWidth={150}>
                            <NumberFilter
                                label="Cena od"
                                param="priceMin"
                                min={0}
                            />
                        </Box>
                        <Box flexGrow={2} maxWidth={180} p={1} minWidth={150}>
                            <NumberFilter
                                label="Do"
                                param="priceMax"
                                min={0}
                            />
                        </Box>
                      {/*
                        TODO Tak isto potrebujeme pridat checkbox "skryt importovane" vo filtracii.
                        <Box flexGrow={1} maxWidth={250} p={1} minWidth={250}>
                            <FormControlLabel
                                label={t.HIDE_IMPORTED}
                                control={(
                                    <Switch
                                        color="primary"
                                        checked={filter.hideImported}
                                        onChange={() => setFilterParam({hideImported: !filter.hideImported})}
                                    />)}
                            />
                        </Box>*/}
                        <Box flexGrow={1} maxWidth={100}>
                            <Button onClick={() => onFilter()} color="primary" variant="outlined">
                                <Hidden mdUp><Search/></Hidden>
                                <Hidden smDown>{t.FILTERING}</Hidden>
                            </Button>
                        </Box>
                    </Box>
                </div>
            </Paper>
        </Box>
    );
};

export default Filter;
