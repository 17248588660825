import {Box, Paper} from '@material-ui/core';
import Advertisement from '../../components/Advertisement';
import Header from '../../components/Header';
import Layout from '../../layouts/NewAdvert';
import React, {useCallback, useEffect, useState} from 'react';
import './style.scss';
import {uploadFile, useApiRequest} from '../../store/api';
import {REDUCER_IMAGES} from '../../utils/constant';
import t from '../../texts';
import {ERouteParameters, ImageType} from '../../enumerators';
import {filterFileByType, isNilOrEmpty, isNotNilOrEmpty} from '../../utils/helpers';
import {fileToFormData} from '../../utils/imageUtils';
import {DELETE_IMAGE, FETCH_IMAGES, UPLOAD_IMAGE} from '../../store/reducers/images';
import {useParamName} from "../../hooks/hooks";
import {useSelector} from "react-redux";
import Dropzone from "../../components/Dropzone/Dropzone";

const AdvertPhoto = () => {
    const offerItemId = useParamName(ERouteParameters.OFFER_ITEM_ID);
    const uploadImage = uploadFile(REDUCER_IMAGES, 'offer-item/:offerItemId/real-estate/image', UPLOAD_IMAGE, {
        method: 'post'
    });
    const {data: allImages, isLoading} = useSelector((state: any) => state[REDUCER_IMAGES].allImages);

    const fetchImages = useApiRequest(REDUCER_IMAGES, 'offer-item/:offerItemId/real-estate/image', FETCH_IMAGES, {method: 'get'});
    const deleteImage = useApiRequest(REDUCER_IMAGES, 'offer-item/:offerItemId/real-estate/image/:imageId', DELETE_IMAGE, {method: 'delete'});

    const [overviewImages, setOverviewImages] = useState([]);
    const [otherImages, setOtherImages] = useState([]);

    const fetchAllImages = useCallback(() => {
        fetchImages({params: {offerItemId}});
    }, [fetchImages]);

    useEffect(() => {
        if (isNotNilOrEmpty(offerItemId)) {
            fetchAllImages();
        }
    }, [offerItemId]);

    useEffect(() => {
        if (isNotNilOrEmpty(allImages) && !isLoading) {
            setOverviewImages(filterFileByType(ImageType.OVERVIEW, allImages) as never[]);
            setOtherImages(filterFileByType(ImageType.OTHER, allImages) as never[]);
        } else if (isNilOrEmpty(allImages) && !isLoading) {
            setOverviewImages([]);
            setOtherImages([]);
        }
    }, [allImages]);

    const uploadImages = useCallback((images, type) => {
        return Promise.all(images.map(async (image: any) => {
            const formData = await fileToFormData(image);
            return uploadImage({
                params: {offerItemId},
                queryParams: {type},
                body: formData
            });
        }));
    }, [uploadImage, offerItemId]);

    const uploadOverview = useCallback(async (images: any[]) => {
        if (isNotNilOrEmpty(images)) {
            try {
                await uploadImages(images, ImageType.OVERVIEW);
                fetchAllImages();
            } catch (e) {
                console.error(e);
            }
        }
    }, [uploadImages]);

    const uploadOther = useCallback(async (images: any[]) => {
        if (isNotNilOrEmpty(images)) {
            try {
                await uploadImages(images, ImageType.OTHER);
                fetchAllImages();
            } catch (e) {
                console.error(e);
            }
        }
    }, [fetchAllImages]);

    const onDeleteOverview = useCallback(async (image: any) => {
        await deleteImage({params: {offerItemId, imageId: image.extId}});
    }, [deleteImage]);

    const onDeleteOther = useCallback(async (image: any) => {
        await deleteImage({params: {offerItemId, imageId: image.extId}});
    }, [deleteImage]);

    return (
        <Layout>
            <Header />
            <Advertisement />

            <Box data-component="AdvertPhoto">
                <h3>{t.PHOTOS_UPLOAD}</h3>
                <Paper elevation={0}>
                    <h3>{t.OVERVIEW_IMAGES}</h3>
                    <Dropzone maxFiles={3} onUpload={uploadOverview} onDelete={onDeleteOverview}
                              uploadedFiles={overviewImages} />
                </Paper>
                <Paper elevation={0}>
                    <h3>{t.DETAIL_IMAGES}</h3>
                    <Dropzone maxFiles={25} onUpload={uploadOther} onDelete={onDeleteOther}
                              uploadedFiles={otherImages} />
                </Paper>

            </Box>
        </Layout>
    );
};

export default AdvertPhoto;
