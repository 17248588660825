import {Box, Button, Grid, Paper, TextField} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import './style.scss';
import t from '../../texts';
import {isNilOrEmpty, onChangeStringHandler} from '../../utils/helpers';
import {FORGOTTEN_PASSWORD} from '../../store/reducers/auth';
import {authRequest} from '../../store/api';
import {validateEmail} from '../Form/validation';
import {ERoutePaths} from '../../enumerators';
import {useHistory} from 'react-router-dom';
import Recaptcha from "../Recaptcha";

const ForgottenPassword = () => {
    const {push, goBack} = useHistory();
    const [email, setEmail] = useState('');

    const forgottenPassword = authRequest('forgotten-password', FORGOTTEN_PASSWORD, {method: 'post'});

    const onClick = useCallback((event) => {
        event.preventDefault();
        forgottenPassword({body: {email}}).then(() => {
            push(ERoutePaths.LOGIN);
        });
    }, [forgottenPassword, email]);

    return (
        <Box data-component="ForgottenPassword">

            <Paper elevation={1}>
                <Box pt="2.3rem" pb="2rem" className="container">
                    <Box className="section">
                        <Grid container justify="center">
                            <Grid className="logo" item>
                                <img src={process.env.PUBLIC_URL + '/images/logos/black-on-transparent.png'} width="128" height="32" alt={t.HOMEPAGE_TITLE} />
                            </Grid>
                            <Grid item>
                                <h2>{t.HOMEPAGE_TITLE}</h2>
                            </Grid>
                        </Grid>

                        <Grid container justify="center">
                            <Grid item>
                                <h3>{t.LOST_PASSWORD}</h3>
                            </Grid>
                        </Grid>

                        <Box my="1.5rem">
                            <Grid container spacing={2} alignItems="center" justify="flex-end">
                                <Grid className="left blueText" item xs={12} sm={3}>{t.EMAIL}</Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        name="email"
                                        variant="outlined"
                                        size="small"
                                        value={email}
                                        type="email"
                                        onChange={onChangeStringHandler(setEmail)}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid container spacing={2} alignItems="center">
                            <Grid item sm={12} md={6}>
                                <Button className="backButton" color="primary" variant="outlined" onClick={goBack}>
                                    {t.BACK}
                                </Button>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Button
                                    className="button"
                                    onClick={onClick}
                                    disabled={isNilOrEmpty(email) || !validateEmail(email)}
                                >
                                    {t.SEND}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Paper>
            <Recaptcha actionName="forgottenPassword" />
        </Box>
    );
};

export default ForgottenPassword;
