import React from 'react';
import t from '../../texts';
import AddUserDialogBody from '../EstateAgencyAdministration/AddUserDialogBody';
import ConfirmDialog from '../ConfirmDialog';
import { ChipUser, RealEstateCompanyDto } from '../../utils/types';
import { useApiDataSelector } from '../../hooks/hooks';
import {REDUCER_REAL_ESTATE, REDUCER_REALITY_COMPANY, REDUCER_USER} from '../../utils/constant';
import { useApiRequest } from '../../store/api';
import { GET_MY_AGENCY_USERS } from '../../store/reducers/realEstate';
import {USER_PRIVILEGES} from "../../enumerators";

const AddUserDialog = () => {

    const potentialUsers: Array<ChipUser> = useApiDataSelector((state: any) => state[REDUCER_USER].usersWithoutAgency);
    const myAgency: RealEstateCompanyDto = useApiDataSelector((state: any) => state[REDUCER_REAL_ESTATE].myRealEstateAgency) || {};

    const addSellerToAgency = useApiRequest(REDUCER_REALITY_COMPANY, 'reality-company/:realityCompanyId/user/:userId/role/:roleType', 'ADD_REALITY_COMPANY_USER', {method: 'post'});
    const getMyAgencyUsers = useApiRequest(REDUCER_REAL_ESTATE, 'reality-company/:realityCompanyId/users', GET_MY_AGENCY_USERS, {method: 'get'});

    const selectedUser: ChipUser | undefined = potentialUsers?.find(reUser => reUser.selected);

    const handleAddSeller = () => {
        addSellerToAgency({
            params: {
                realityCompanyId: myAgency?.extId,
                userId: selectedUser?.user.extId,
                roleType: USER_PRIVILEGES.COMPANY_SELLER
            }
        }).then(() => {
            getMyAgencyUsers({params: {realityCompanyId: myAgency?.extId}});
        });
    };

    return (
        <ConfirmDialog
            name={t.ADD_AGENT}
            body={<AddUserDialogBody potentialUsers={potentialUsers} realityCompanyId={myAgency?.extId} />}
            title={t.ADD_AGENT}
            closeButtonName={t.BACK}
            confirmButtonName={t.ADD_AGENT}
            confirmButtonFn={handleAddSeller}
            confirmButtonDisabled={!selectedUser}
            maxWidth="sm"
        />
    );
};

export default AddUserDialog;
