import t from "../../texts";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Grid,
    Switch
} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {getBooleanItem, setStorageItem} from "../../utils/storage";
import {
    REDUCER_CONTEXT,
    USER_COOKIES_FUNCTIONAL,
    USER_COOKIES_MARKETING,
    USER_COOKIES_STATISTICS
} from "../../utils/constant";
import usePageTracking from "../../usePageTracking";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {useSelector} from "react-redux";
import {createAction} from "../../store/actions";
import {COOKIE_DIALOG} from "../../store/reducers/context";
import {isNotNilOrEmpty} from "../../utils/helpers";

const CookiesDialog = () => {
    const {initiateGA, removeGA} = usePageTracking() || {};
    const [isMarketing, setIsMarketing] = useState<boolean>();
    const [isStatistics, setIsStatistics] = useState<boolean>();

    const isDialogOpen = useSelector((state: any) => state[REDUCER_CONTEXT].isCookieDialogOpen);
    const openCloseCookieDialog = createAction(COOKIE_DIALOG);


    useEffect(() => {
        if (isDialogOpen) {
            setIsMarketing(getBooleanItem(USER_COOKIES_MARKETING));
            setIsStatistics(getBooleanItem(USER_COOKIES_STATISTICS));
        }
    }, [isDialogOpen])

    useEffect(() => {
        if (isNotNilOrEmpty(isMarketing)) {
            setStorageItem(USER_COOKIES_MARKETING, isMarketing);
        }
    }, [isMarketing]);

    useEffect(() => {
        if (isNotNilOrEmpty(isStatistics)) {
            setStorageItem(USER_COOKIES_STATISTICS, isStatistics);
        }
        if (isStatistics) {
            initiateGA();
        } else {
            removeGA();
        }
    }, [isStatistics]);

    const onClose = () => {
        setStorageItem(USER_COOKIES_FUNCTIONAL, 'true');
        openCloseCookieDialog(false);
    };

    const onAgreeAll = () => {
        setStorageItem(USER_COOKIES_MARKETING, 'true');
        setStorageItem(USER_COOKIES_STATISTICS, 'true');
        initiateGA();
        openCloseCookieDialog(false);
    }

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isDialogOpen}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{t.COOKIES_SETTINGS_MODAL}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <p>{t.COOKIES_MODAL_TITLE}</p>
                    <Grid item xs={12}>
                        <Accordion className="accordion">
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <FormControlLabel
                                    className="bold"
                                    aria-label={t.USER_COOKIES_FUNCTIONAL}
                                    onFocus={(event: any) => event.stopPropagation()}
                                    control={
                                        <Switch
                                            checked={true}
                                            disabled={true}
                                            color="primary"
                                        />}
                                    label={t.USER_COOKIES_FUNCTIONAL}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="left">{t.USER_COOKIES_FUNCTIONAL_DESC}</p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="accordion">
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <FormControlLabel
                                    className="bold"
                                    aria-label={t.USER_COOKIES_MARKETING}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        setIsMarketing(!isMarketing)
                                    }}
                                    onFocus={(event: any) => event.stopPropagation()}
                                    control={<Switch color="primary" checked={isMarketing} />}
                                    label={t.USER_COOKIES_MARKETING}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>{t.USER_COOKIES_MARKETING_DESC}</p>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="accordion">
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                            >
                                <FormControlLabel
                                    className="bold"
                                    aria-label={t.USER_COOKIES_STATISTICS}
                                    onClick={(event: any) => {
                                        event.stopPropagation();
                                        setIsStatistics(!isStatistics)
                                    }}
                                    onFocus={(event: any) => event.stopPropagation()}
                                    control={<Switch color="primary" checked={isStatistics} />}
                                    label={t.USER_COOKIES_STATISTICS}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>{t.USER_COOKIES_STATISTICS_DESC}</p>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions id="buttons">
                <Button autoFocus onClick={onClose} className="agreeAndSave" color="primary" variant="outlined">
                    {t.SAVE_AND_CLOSE}
                </Button>
                <Button onClick={onAgreeAll} className="agreeAll" color="primary" variant="contained" autoFocus>
                    {t.AGREE_WITH_ALL}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CookiesDialog;
