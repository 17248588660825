import React, {useCallback, useEffect} from 'react';
import {useApiRequest} from '../../store/api';
import {REDUCER_OFFER_ITEM} from '../../utils/constant';
import {DELETE_SEARCH, UPDATE_SEARCH} from '../../store/reducers/offerItem';
import {Box, Button, Grid, Paper} from '@material-ui/core';
import t from '../../texts';
import {
    formatSavedSearch, isNilOrEmpty,
    isNotNilOrEmpty
} from '../../utils/helpers';
import {SavedSearch} from '../../utils/types';
import {useSelector} from 'react-redux';
import {ERouteParameters, ERoutePaths} from '../../enumerators';
import {useHistory} from 'react-router-dom';
import useCodebooks from '../../hooks/useCodebooks';
import {Alert} from '@material-ui/lab';
import Loading from '../Loading';
import useSavedSearch from "../../hooks/useSavedSearch";

const MyWatchdog = () => {
    const history = useHistory();
    const {codebooks} = useCodebooks();
    const {fetchSavedSearchesForUser} = useSavedSearch();

    const deleteSearch = useApiRequest(REDUCER_OFFER_ITEM, 'offer-item/saved-search/:extId', DELETE_SEARCH, {method: 'delete'});
    const updateSavedSearch = useApiRequest(REDUCER_OFFER_ITEM, 'offer-item/saved-search/:extId', UPDATE_SEARCH, {method: 'put'});

    const {data: mySearches, isLoading} = useSelector((state: any) => state[REDUCER_OFFER_ITEM].searches);

    useEffect(() => {
        if (!isLoading && isNilOrEmpty(mySearches)) {
            fetchSavedSearchesForUser();
        }
    }, []);

    const removeSearch = useCallback(async (item) => {
        await deleteSearch({params: {extId: item.extId}});
        fetchSavedSearchesForUser();
    }, []);

    const activateDeactivateSearch = useCallback(async (item) => {
        await updateSavedSearch({params: {extId: item.extId}, body: {...item, active: !item.active}});
        fetchSavedSearchesForUser();
    }, []);


    return (
        <>
            <Box className="section">
                <h2>{t.SEARCH_NOTIFICATION}</h2>
                <Loading isLoading={isLoading} />
                {!isLoading && mySearches?.length === 0 &&
                    <Alert className="alert" severity="info">{t.NO_RESULT_MY_WATCHDOGS}</Alert>}
                {!isLoading && mySearches?.length > 0 && mySearches.map((item: SavedSearch) => {
                    const formatted = formatSavedSearch(item.savedSearch, codebooks);
                    return (
                        <Box key={item.extId} className="section">
                            <Paper className="paper" elevation={0}>
                                <Grid container spacing={3}>
                                    <Grid id="h6" className="left" item sm={12}>
                                        <h6>{formatted?.main}</h6>
                                    </Grid>
                                </Grid>

                                {(isNotNilOrEmpty(formatted.price) || isNotNilOrEmpty(formatted.published)) && (
                                    <Grid container spacing={1}>
                                        <Grid id="price" className="left" item xs={12} sm={4}>
                                            <p>{formatted.price}</p>
                                        </Grid>

                                        <Grid id="address" className="right" item xs={12} sm={8}>
                                            <p>{formatted.published}</p>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid className="left adStateTable withBorder" container>
                                    <Grid item xs={12}>
                                        <Box display="flex" flexWrap="wrap">
                                            {formatted?.details?.split(',').map((i, index) => (
                                                <Box key={`detail-${index}`} p={1}>{i}</Box>
                                            ))}
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1}>
                                    <Grid item xs={6} sm={3}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => history.push(ERoutePaths.SEARCH_ID.replace(`:${ERouteParameters.SEARCH_ID}`, item.extId))}
                                        >
                                            {t.EDIT_SEARCH}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => activateDeactivateSearch(item)}
                                        >
                                            {item.active ? t.DEACTIVAT_SEARCH : t.ACTIVAT_SEARCH}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => history.push(ERoutePaths.SEARCH_RESULT_ID.replace(`:${ERouteParameters.SEARCH_ID}`, item.extId))}
                                        >
                                            {t.SHOW_SEARCH}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={() => removeSearch(item)}
                                        >
                                            {t.DELETE_SEARCH}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    );
                })}
            </Box>
        </>
    );
};

export default MyWatchdog;
