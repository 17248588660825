import Advertisement from '../../components/Advertisement';
import Header from '../../components/Header';
import Layout from '../../layouts/AdministrationTemporary';
import React from 'react';
import { Box } from '@material-ui/core';
import './style.scss';
import DocumentsPage from '../../components/DocumentsPage';
import { AdministrationMenuItems } from '../../utils/constant';

export default () => (
    <Box data-component="Documents_Page">
        <Layout menuItems={AdministrationMenuItems}>
            <Header />
            <Advertisement />
            <DocumentsPage />
        </Layout>
    </Box>
);
