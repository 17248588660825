import { Box } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import './style.scss';
import {Link} from "react-router-dom";

export default () => {
    return (
        <Box data-component="DocumentsPage">
                <Box className={cx('container')}>
                    <Box className="section">
                        <h2>Dokumenty</h2>

                        <p>

                        </p>
                        <p>
                            <Link
                                to={process.env.PUBLIC_URL + '/files/Informovany-suhlas-na-marketingove-ucely.docx'}
                                target="_blank" className="link">Súhlas pre marketingové účely</Link>
                        </p>
                        <p>
                            <Link
                                to={process.env.PUBLIC_URL + '/files/reklamacny-formular.docx'}
                                target="_blank" className="link">Reklamačný formulár</Link>
                        </p>
                        <p>
                            <Link
                                to={process.env.PUBLIC_URL + '/files/odstupenie-od-zmluvy.docx'}
                                target="_blank" className="link">Odstúpenie od zmluvy</Link>
                        </p>
                        <p>
                            <Link
                                to={process.env.PUBLIC_URL + '/files/vseobecne-obchodne-podmienky-fo.docx'}
                                target="_blank" className="link">Všeobecné obchodné podmienky pre fyzické osoby</Link>
                        </p>
                        <p>
                            <Link
                                to={process.env.PUBLIC_URL + '/files/vseobecne-obchodne-podmienky-po.docx'}
                                target="_blank" className="link">Všeobecné obchodné podmienky pre právnické osoby a fyzické
                                osoby - podnikateľov</Link>
                        </p>


                    </Box>
                </Box>
            </Box>
    );
};
