import {Button, Grid} from '@material-ui/core';
import t from '../../../texts';
import React from 'react';
import {BookOpen, Star, Upload} from 'react-feather';
import './style.scss';
import {ERouteParameters, ERoutePaths} from '../../../enumerators';
import {Link} from 'react-router-dom';
import {ADMIN_SECTIONS, ALERT_SEVERITY, REDUCER_USER} from '../../../utils/constant';
import {useApiDataSelector} from "../../../hooks/hooks";
import {createAction} from "../../../store/actions";
import {SET_ALERT} from "../../../store/reducers/context";

export default () => {
    const currentUser = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser);
    const setAlert = createAction(SET_ALERT);

    const items = [{
        label: t.ADD_OFFER,
        link: ERoutePaths.ADVERT_INFORMATION,
        iconElement: <Upload width={16} height={16}/>,
        disabled: !currentUser?.emailVerified,
    }, {
        label: t.FAVORITE_OFFERS_SHORT,
        iconElement: <Star width={16} height={16}/>,
        link: {
            pathname: ERoutePaths.ACCOUNT,
            state: {[ERouteParameters.ADMIN_ACTIVE_SECTION]: ADMIN_SECTIONS.MY_FAVORITE}
        }
    }, {
        label: t.AGENCIES_LIST_SHORT,
        link: ERoutePaths.ESTATE_AGENCY_LIST,
        iconElement: <BookOpen width={16} height={16}/>
    }];

    return (
        <Grid container justify="center" data-component="UpperMenu">
            {items.map(({label, iconElement, link, disabled}) => (
                <Grid key={label} item xs={12} sm={4} className={disabled ? 'disabled' : ''}>
                    {disabled ?
                        <Button size="small" startIcon={iconElement}
                                onClick={() => setAlert({
                                    severity: ALERT_SEVERITY.WARNING,
                                    message: t.BEFORE_LOGIN_ERROR
                                })}
                        >{label}</Button>
                        : (
                            <Link to={link}>
                                <Button size="small" startIcon={iconElement}>{label}</Button>
                            </Link>
                        )}
                </Grid>
            ))}
        </Grid>
    );
};
