import { find, filter, equals, endsWith } from 'ramda';
import { isNotNilOrEmpty } from './helpers';
import Compress from 'compress.js';

const compress = new Compress();

export const getImage = (image, images) => find(file => file.info.extId === image.extId, images);
export const removeImage = (extId, images) => isNotNilOrEmpty(images) ? filter((image) => !equals(image?.extId, extId), images) : [];
export const removeFile = (extId, images) => isNotNilOrEmpty(images) ? filter((image) => !equals(image.info?.extId, extId), images) : [];

export const isPngOrJpg = image => endsWith('png', image.name) || endsWith('jpg', image.name) || endsWith('jpeg', image.name);

export const fileToFormData = async (image) => {
    const formData = new FormData();
    let compressedFiles = [];
    if (isPngOrJpg(image)) {
        compressedFiles = await compress.compress([image], {
            size: 4, // the max size in MB, defaults to 2MB
            quality: 0.75, // the quality of the image, max is 1,
            maxWidth: 1400, // the max width of the output image, defaults to 1920px
            maxHeight: 800, // the max height of the output image, defaults to 1920px
            resize: true // defaults to true, set false if you do not want to resize the image width and height
        });
        const file = compressedFiles[0];
        const base64str = file.data;
        const imgExt = file.ext;
        const result = Compress.convertBase64ToFile(base64str, imgExt);
        formData.set('files', result, image.name);
    } else {
        formData.set('files', image, image.name);
    }

    return formData;
};

export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

export const getImageType = (type) => {
    switch (type) {
        case 'jpg':
            return 'image/jpeg';
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        default:
            return 'image/*';
    }
};
