import useCompanySearchAdmin from "../../hooks/useCompanySearchAdmin";
import {useApiDataSelector, usePrevious} from "../../hooks/hooks";
import {useScrollToTop} from "../Navigation/ScrollToTop";
import React, {useCallback, useEffect, useState} from "react";
import {createAction} from "../../store/actions";
import {
    PLACEHOLDER_PROPERTY_IMAGE,
    REDUCER_CONTEXT,
    REDUCER_USER,
    RESULT_SORT_VALUES,
    STATUS_MAP
} from "../../utils/constant";
import {
    formatAddress,
    formatDate,
    formatOfferItemDesc,
    formatPrice, formatToRequestBody,
    isNotNilOrEmpty, onChangeStringHandler,
    sortImagesByPriority
} from "../../utils/helpers";
import {
    Box, Divider, FormControl,
    Grid,
    Hidden, MenuItem,
    Paper, Select,
} from "@material-ui/core";
import {Alert, Pagination} from "@material-ui/lab";
import Loading from "../Loading";
import t from "../../texts";
import {IImage, IPagination, ISearchResultContent} from "../../utils/types";
import {find, pipe, propEq, range} from "ramda";
import Filter from "../MyAccount/Filter";
import {useSelector} from "react-redux";
import {CLEAR_MY_ADVERT_FILTER} from "../../store/reducers/context";
import {
    CLEAR_COMPANY_PAGINATION,
    SET_COMPANY_PAGINATION
} from "../../store/reducers/realEstate";

const AgencyAdvertList = ({companyId}: { companyId: string }) => {
    const {
        result,
        pagination,
        isLoading,
        fetchCompanyResult,
    } = useCompanySearchAdmin();

    const filter = useSelector((state: any) => state[REDUCER_CONTEXT].myAdvertFilter);
    const currentUser = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser);

    const setPagination = createAction(SET_COMPANY_PAGINATION);
    const clearFilter = createAction(CLEAR_MY_ADVERT_FILTER);
    const clearPagination = createAction(CLEAR_COMPANY_PAGINATION);


    const [sortBy, setSortBy] = useState<any>(find(propEq('value', pagination?.sort), RESULT_SORT_VALUES) || RESULT_SORT_VALUES[0]);
    const [pageNumber, setPageNumber] = useState<number>(pagination?.page || 0);
    // @ts-ignore
    const prevPagination: IPagination = usePrevious(pagination);

    useScrollToTop([pagination?.page, pagination?.size, pagination?.sort]);

    const fetchItems = useCallback(() => {
        if (isNotNilOrEmpty(currentUser?.extId) && !isLoading) {
            const request = isNotNilOrEmpty(filter) ? formatToRequestBody(filter) : {};

            fetchCompanyResult(companyId, request);
        }
    }, [companyId, fetchCompanyResult, currentUser, filter, isLoading]);

    useEffect(() => {
        if (!isLoading) {
            fetchItems();
        }
        return () => {
            clearPagination();
            clearFilter();
        }
    }, []);

    useEffect(() => {
        setPagination({...pagination, sort: sortBy?.value});
    }, [sortBy]);

    useEffect(() => {
        setPagination({...pagination, page: pageNumber});
    }, [pageNumber]);

    useEffect(() => {
        const isPaginationChange = isNotNilOrEmpty(prevPagination) && (prevPagination?.size !== pagination?.size || prevPagination?.page !== pagination?.page || prevPagination?.sort !== pagination?.sort);
        if (isPaginationChange) {
            fetchItems();
        }
    }, [pagination]);

    // @ts-ignore
    const getImages = item => pipe<IImage[], IImage[], IImage[]>(sortImagesByPriority, (array) => range(0, 3).map(index => array[index] || PLACEHOLDER_PROPERTY_IMAGE))(item?.images);

    // const linkToDetail= baseUrl + 'kancelar/' + companyId + '/id/' + offerItemId;
    const getImageGrid = (img: IImage, counter: number) => {
        return (
            <Grid key={`${counter}-${img.extId}`} item xs={12} sm={4}>
                <img src={img.url} alt="Flat1"/>
            </Grid>
        );
    };

    const renderImage = (img: IImage, counter: number) => {
        if (counter < 2) {
            return getImageGrid(img, counter);
        } else {
            return (
                <Hidden key={`${counter}-${img.extId}`} only="xs">
                    {getImageGrid(img, counter)}
                </Hidden>
            );
        }
    };

    return (
        <>
            <Filter onFilter={fetchItems}/>
            <Loading isLoading={isLoading}/>
            {!isLoading && result?.length === 0
                && (
                    <Alert className="alert" severity="info">
                        Vášmu hľadaniu nevyhovujú žiadne Vami vytvorené inzeráty.
                    </Alert>
                )}

            {!isLoading && result?.length > 0 && (
                <>
                    <Divider style={{margin: '10px 0'}}/>

                    <Box justifyContent="flex-end" style={{marginBottom: '10px', display: 'flex'}}>
                        <FormControl variant="outlined" size="small">
                            <Select
                                className="left"
                                value={sortBy}
                                onChange={onChangeStringHandler(setSortBy)}
                            >
                                {RESULT_SORT_VALUES.map(
                                    (item: any) => (
                                        <MenuItem
                                            key={item.order}
                                            value={item}
                                        >
                                            {item.label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </>
            )}
            {result?.map((item: ISearchResultContent) => (
                <Box key={item.extId} className="section">
                    <Paper className="paper" elevation={0}>
                        <Grid container spacing={1}>
                            {getImages(item).map((image, index) => renderImage(image, index))}
                        </Grid>

                        <Grid className="mtb" container>
                            <Grid id="h6" className="left" item sm={5}>
                                <h6>{formatOfferItemDesc(item.offerType, item.realEstate)}</h6>
                            </Grid>

                            {/*<Grid className="right" item sm={7}>
                                {renderBadges()}
                            </Grid>*/}
                        </Grid>

                        <Grid container>
                            <Grid id="price" className="left" item xs={12} sm={4}>
                                <p>{formatPrice(item.price)}</p>
                            </Grid>

                            <Grid id="address" className="right" item xs={12} sm={8}>
                                <p>{formatAddress(item.address)}</p>
                            </Grid>
                        </Grid>

                        <Grid className="left adStateTable" container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <Grid id="detailLeft" container spacing={2}>
                                    <Grid className="blueText" item xs={5}>{t.STATE}</Grid>
                                    <Grid item xs={7}>{STATUS_MAP[item.status]}</Grid>

                                    <Grid className="blueText" item xs={5}>{t.CREATE_DATE}</Grid>
                                    <Grid item xs={7}>
                                        {item.publishedAt ? formatDate(item.publishedAt) : ''}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid id="detailRight" container spacing={2}>
                                    <Grid className="blueText" item xs={5}>{t.COST_NOTE}</Grid>
                                    <Grid item xs={7}>{item.priceInfo}</Grid>

                                    <Grid className="blueText" item xs={5}>{t.ACTIVE_TILL}</Grid>
                                    <Grid item xs={7}>
                                        {item.publishedTo ? formatDate(item.publishedTo) : ''}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/*<Grid container spacing={1}>
                            {showActions && (
                                <Grid item xs={6} sm={3}>
                                    <Button
                                        color="primary"
                                        onClick={updateAdvert}
                                        variant="outlined"
                                    >
                                        {t.EDIT_ADVERT}
                                    </Button>
                                </Grid>
                            )}
                            {item.status !== OfferItemStatus.EDIT && (
                                <Grid item xs={6} sm={3}>
                                    <Button
                                        color="primary"
                                        onClick={showAdvert}
                                        variant="outlined"
                                    >
                                        {t.SHOW_ADVERT}
                                    </Button>
                                </Grid>
                            )}
                            {showActions && (
                                <>
                                    <Grid item xs={6} sm={3}>
                                        <ConfirmDialog
                                            name={t.DELETE_ADVERT}
                                            body={(
                                                <p>
                                                    {t.DELETE_ADVERT_CONFIRM}
                                                    {formatOfferItemDesc(item.offerType, item.realEstate)}
                                                </p>
                                            )}
                                            title={t.DELETE_ADVERT}
                                            closeButtonName={t.BACK}
                                            confirmButtonName={t.DELETE_ADVERT}
                                            confirmButtonFn={deleteAdvert}
                                            maxWidth="xs"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        {item.status === OfferItemStatus.UNACTIVE && (
                                            <Button
                                                color="primary"
                                                onClick={reactivateOfferItem}
                                                variant="outlined"
                                            >
                                                {t.ACTIVATE_ADVERT}
                                            </Button>
                                        )}

                                        {item.status === OfferItemStatus.PUBLISHED && (
                                            <Button
                                                color="primary"
                                                onClick={deactivateOfferItem}
                                                variant="outlined"
                                            >
                                                {t.DEACTIVATE_ADVERT}
                                            </Button>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>*/}

                        {/*{showStatistics && item.status !== OfferItemStatus.EDIT &&
                            <Accordion expanded={isExpanded}
                                       className="accordion"
                                       onChange={(e, value) => setIsExpanded(value)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                >
                                    <Typography className="bold" color="primary">{t.STATISTICS}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ViewsCharts offerItemId={item.extId} isExpanded={isExpanded}/>
                                </AccordionDetails>
                            </Accordion>
                        }*/}
                    </Paper>
                </Box>
            ))}

            <Box className="pagination">
                <Pagination
                    count={pagination?.totalPages || 1}
                    page={pageNumber + 1 || 1}
                    onChange={(event, pageNumber) => setPageNumber(pageNumber - 1)}
                />
            </Box>
        </>
    );
}

export default AgencyAdvertList;
