import { useApiRequest } from './api';
import { REDUCER_CODEBOOK } from '../utils/constant';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

const fetchCodebook = (codebookName) => {
    return useApiRequest(REDUCER_CODEBOOK, `codebook/${codebookName}`, codebookName, { method: 'get' });
};

const fetchCodebookParent = (codebookName, requestName) => {
    return useApiRequest(REDUCER_CODEBOOK, `codebook/${codebookName}/parent/:qp1`, requestName, { method: 'get' });
};

const createAction = (actionName) => {
    const dispatch = useDispatch();
    return useCallback(async (payload = {}) => {
        dispatch({
            type: actionName,
            payload
        });
    });
};

export { fetchCodebook, fetchCodebookParent, createAction };
