import {isNilOrEmpty, isNotNilOrEmpty, sortImagesByPriority} from "../../utils/helpers";
import {Grid} from "@material-ui/core";
import ReactImageGallery from "react-image-gallery";
import React, {useEffect, useState} from "react";
import {pipe} from "ramda";
import {IImage} from "../../utils/types";
import {useApiRequest} from "../../store/api";
import {REDUCER_SEARCH} from "../../utils/constant";
import {useSelector} from "react-redux";
import {IMAGES_BY_ID} from "../../store/reducers/search";

const Gallery = ({offerItemId}: { offerItemId: string }) => {
    const detail = useSelector((state: any) => state[REDUCER_SEARCH].realEstates?.[offerItemId]);
    const fetchImages = useApiRequest(REDUCER_SEARCH, 'offer-item/:offerItemId/real-estate/image', IMAGES_BY_ID, {method: 'get'});
    const [images, setImages] = useState([] as any[]);

    useEffect(() => {
        if (isNotNilOrEmpty(offerItemId) && isNotNilOrEmpty(detail) && isNilOrEmpty(detail?.images)) {
            fetchImages({params: {offerItemId}});
        }
    }, [detail.extId]);


    useEffect(() => {
        if (isNotNilOrEmpty(detail?.images)) {
            let images = pipe<IImage[], any[]>(
                sortImagesByPriority
                // take(isCollapsedView ? 2 : 3)
            )(detail.images);
            images = images.map((item: IImage) => ({original: item.url, fullscreen: item.url, thumbnail: item.url}));
            setImages(images);
        }
    }, [detail?.images?.length]);
    return (
        <>
            {isNotNilOrEmpty(images) && (
                <Grid container spacing={1}>
                    <Grid item xs={12} className="carousel">
                        <ReactImageGallery showIndex items={images} />
                    </Grid>
                </Grid>
            )}
        </>
    )
}

export default Gallery;
