import {useHistory} from 'react-router-dom';
import {useEffect} from 'react';
import {isNotNilOrEmpty} from '../../utils/helpers';
import {useApiRequest} from '../../store/api';
import {ERouteParameters, ERoutePaths} from '../../enumerators';
import {ALERT_SEVERITY, REDUCER_USER} from '../../utils/constant';
import {CONFIRM_EMAIL} from '../../store/reducers/user';
import {createAction} from '../../store/actions';
import {SET_ALERT} from '../../store/reducers/context';
import {useParamName, useQuery} from '../../hooks/hooks';
import {includes} from 'ramda';
import t from '../../texts';
import {SET_ACCESS_TOKEN} from '../../store/reducers/auth';

const Confirmation = () => {
    const {push, location} = useHistory();
    const queryParams = useQuery();
    const confirmationUuid = useParamName(ERouteParameters.CONFIRMATION_UUID);

    const setAccessToken = createAction(SET_ACCESS_TOKEN);
    const sendConfirmEmail = useApiRequest(REDUCER_USER, 'confirmation/:confirmationUuid', CONFIRM_EMAIL, {method: 'put'});
    const setAlert = createAction(SET_ALERT);

    useEffect(() => {
        if (isNotNilOrEmpty(confirmationUuid)) {
            sendConfirmEmail({params: {confirmationUuid}}).then(() => {
                const type: string = String(queryParams.get('type'));
                if (isNotNilOrEmpty(type)) {
                    let message;
                    if (type === 'VERIFY_EMAIL') {
                        message = t.CONFIRMATION_VERIFY_EMAIL;
                    } else if (type === 'ADD_TO_REALITY_COMPANY') {
                        message = t.CONFIRMATION_ADD_TO_REALITY_COMPANY;
                    } else {
                        message = t.CONFIRMATION_SUCCESS;
                    }
                    setAlert({severity: ALERT_SEVERITY.SUCCESS, message});
                }

                push(ERoutePaths.HOME);
            }).catch(() => {
                push(ERoutePaths.LOGIN);
            });
        }
        // testing path http://localhost:8081/oauth2/redirect?error=Looks%20bad
        const error: string | null = queryParams.get('error');
        const token: string | null = queryParams.get('token');
        if (includes(ERoutePaths.AUTH0, location.pathname)) {
            if (isNotNilOrEmpty(error)) {
                setAlert({severity: ALERT_SEVERITY.ERROR, message: `${t.REDIRECT_ERROR}. ${error}`});
                push(ERoutePaths.LOGIN);
            } else if (isNotNilOrEmpty(token)) {
                setAccessToken(token);
                setAlert({severity: ALERT_SEVERITY.SUCCESS, message: t.REDIRECT_SUCCESS});
                push(ERoutePaths.ACCOUNT);
            }
        }
    }, []);

    return null;
};

export default Confirmation;
