import {Button, Grid, InputBase} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {REDUCER_USER} from '../../utils/constant';
import {useApiRequest} from '../../store/api';
import {editUserValidation, validateForm} from '../Form/validation';
import {UPDATE_USER} from '../../store/reducers/user';
import {IUser} from '../../utils/types';
import {isNilOrEmpty, isNotNilOrEmpty} from "../../utils/helpers";
import t from '../../texts';
import InputErrorText from "../Form/InputErrorText";

const initUser = {
    credit: '',
    degree: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
};

const UserForm = ({handleSubmit}: { handleSubmit: any }) => {
    const updateUser = useApiRequest(REDUCER_USER, 'user/me', UPDATE_USER, {method: 'put'});
    const {data: currentUser, isLoading} = useSelector((state:any) => state[REDUCER_USER].currentUser);
    const [userForm, setUserForm] = useState<IUser>({} as IUser);
    const [formErrors, setFormErrors] = useState({} as any);


    useEffect(() => {
        if (isNilOrEmpty(userForm) && isNotNilOrEmpty(currentUser)) {
            setUserForm(currentUser || initUser);
        }
    }, [currentUser]);

    const handleChange = useCallback((event: any) => {
        setUserForm({
            ...userForm,
            [event.target.name]: event.target.value
        });
    }, [setUserForm, userForm]);

    const setErrors = useCallback((error: any) => {
        setFormErrors((prevState: any) => ({
            ...prevState,
            ...error
        }));
    }, [setFormErrors]);

    const onSubmit = useCallback(async (event) => {
        event?.preventDefault();
        try {
            await validateForm(editUserValidation, userForm, setErrors);
            await updateUser({body: {...userForm}});
            handleSubmit();
            setUserForm(initUser as IUser);
        } catch (e) {
            console.error(e);
        }
    }, [updateUser, userForm, setErrors, validateForm]);

    return (
        <form onSubmit={onSubmit} noValidate>
            <Grid id="myAccountTable" className="left" container spacing={2}>
                <Grid className="blueText" item xs={12} sm={5}>{t.NAME}</Grid>
                <Grid item xs={12} sm={7}>
                    <InputBase
                        className="p-0"
                        required
                        value={userForm.firstName}
                        name="firstName"
                        onChange={handleChange}
                        error={isNotNilOrEmpty(formErrors?.firstName)}
                    />
                    <InputErrorText error={formErrors?.firstName} />
                </Grid>

                <Grid className="blueText" item xs={12} sm={5}>{t.SURNAME}</Grid>
                <Grid item xs={12} sm={7}>
                    <InputBase
                        className="p-0"
                        required
                        value={userForm.lastName}
                        name="lastName"
                        onChange={handleChange}
                        error={isNotNilOrEmpty(formErrors?.lastName)}
                    />
                    <InputErrorText error={formErrors?.lastName} />
                </Grid>

                <Grid className="blueText" item xs={12} sm={5}>{t.PHONE}</Grid>
                <Grid item xs={12} sm={7}>
                    <InputBase
                        className="p-0"
                        required
                        value={userForm.phone}
                        name="phone"
                        type="tel"
                        onChange={handleChange}
                        error={isNotNilOrEmpty(formErrors?.phone)}
                    />
                    <InputErrorText error={formErrors?.phone} />
                </Grid>

                <Grid className="blueText" item xs={12} sm={5}>{t.EMAIL}</Grid>
                <Grid item xs={12} sm={7}>
                    <InputBase
                        type="email"
                        className="p-0"
                        required
                        value={userForm.email}
                        name="email"
                        onChange={handleChange}
                        error={isNotNilOrEmpty(formErrors?.email)}
                    />
                    <InputErrorText error={formErrors?.email} />
                </Grid>

                <Grid item xs={12} className="right">
                    <Button className="saveButton" disabled={isLoading} type="submit">{t.SAVE}</Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default UserForm;
