import {isNil} from "ramda";

export default {
    LOGIN_BUTTON: 'Prihlásiť',
    LOGOUT_BUTTON: 'Odhlásiť',
    MY_ACCOUNT_BUTTON: 'Moj profil',
    HOME: 'Domovská stránka',

    FLATS: 'Byty',
    HOUSES: 'Domy',
    PROJECTS: 'Projekty',
    LANDS: 'Pozemky',
    COMMERCIAL: 'Komerčné',
    OTHER_ESTATE_TYPE: 'Ostatné',
    ADD_NEW_OFFER: 'Vložiť nový inzerát',
    ADD_OFFER: 'Vložiť inzerát',
    FAVORITE_OFFERS: 'Obľúbené inzeráty',
    FAVORITE_OFFERS_SHORT: 'Obľúbené',
    AGENCIES_LIST: 'Zoznam realitných kancelárií',
    SHOW_AGENCIES: 'Zobraziť realitné kancelárie',
    AGENCIES_LIST_SHORT: 'Zoznam RK',
    AGENT_LIST: 'Zoznam predajcov',
    AGENCY_PROFILE: 'Správa realitnej kancelárie',
    BACK: 'Späť',
    CONTINUE: 'Pokračovať',
    SAVE_AND_CONTINUE: 'Uložiť a pokračovať',
    AGENCIES_ADMINISTRATION: 'Správa kancelárie',
    AGENCIES_ADVERTS: 'Inzeráty realitnej kancelárie',
    ADD_AGENT: 'Pridať predajcu',
    PUBLISH: 'Publikovať inzerát',

    // Homepage
    HOMEPAGE_TITLE: 'Skreality.sk',
    HOMEPAGE_SUBTITLE: 'Slovenský trh realít',
    OTHERS: 'Ostatné',
    SHOW: (count: number) => isNil(count) ? 'Zobraziť' : `Zobraziť (${count})`,
    SAVE_SEARCH: 'Uložiť hľadanie',

    // Search
    REAL_ESTATE_TYPE: 'Typ nehnuteľnosti',
    OFFER_TYPE: 'Typ inzerátu',
    BUILDING_MATERIAL_TYPES: 'Stavba',
    OWNERSHIP: 'Vlastníctvo',
    HOUSE_TYPE: 'Druh domu',
    REAL_ESTATE_STATE: 'Stav nehnuteľnosti',
    LOT_TYPES: 'Vlastnosti pozemku',
    COMMERCIAL_TYPE: 'Typ objektu',
    OBJECT_PARAMS: 'Vlastnosti objektu',
    ADDITIONS: 'Ďalšie vlastnosti',
    SELECT_LOCATION: 'Výber oblasti',
    LOCATION: 'Lokalita',
    COUNTRY: 'Štát',
    SEARCH_AROUND: 'Hľadať v okolí',
    SURFACE_AREA: 'Užitná plocha',
    LOT_AREA: 'Plocha pozemku',
    FLOORS: 'Poschodie',
    BUNGALOW: 'Bungalov',
    LOW_ENERGY: 'Nízkoenergetický',
    MADE_OF_WOOD: 'Drevostavba',
    MORE_BUILDINGS: 'Radový dom',
    SEPARATE_BUILDINGS: 'Samostatný',
    POOL: 'Bazén',
    BALCONY: 'Balkón',
    LODGY: 'Lodžia',
    TERRACE: 'Terasa',
    CELLAR: 'Pivnica',
    ELEVATER: 'Výťah',
    BARRIERLESS: 'Bezbarierový',
    GARAGE: 'Garáž',
    PARKING: 'Parkovanie',

    // Result
    SEARCH_RESULT: 'Výsledok hľadania',
    EDIT_QUERY: 'Upraviť hľadanie',
    SAVE_QUERY: 'Uložit hľadanie',
    FAVORITE_QUERY: 'Obľúbené hľadania',
    ORDER_NEWEST: 'Najnovšie',
    ORDER_OLDEST: 'Najstaršie',
    ORDER_CHEAPEST: 'Najlacnejšie',
    ORDER_EXPENSIVE: 'Najdrahšie',
    OFFER_AGE: 'Vek inzerátu',
    SAVE_SEARCH_SUCCESS: 'Pridané medzi automatické hľadania',
    OFFERS_COUNT: (count: number) => `${count} inzerátov`,

    // Result detail
    BACK_TO_SEARCH: 'Späť na výsledky vyhľadávania',
    BACK_TO_MY_ACCOUNT: 'Späť do Môj profil',
    PREVIOUS_ADD: 'Predchádzajúci inzerát',
    NEXT_ADD: 'Ďalší inzerát',
    PRICE: 'Celková cena',
    PRICE_COMMENT: 'Poznámka k cene',
    UPGRADE_DATE: 'Aktualizácia',
    BUILDING_MATERIAL_TYPE: 'Stavba',
    RESERVED: 'Rezervované',
    BUILD_AREA: 'Zastavaná plocha',
    GAS: 'Plyn',
    WATER: 'Voda',
    ELECTRICITY: 'Elektrina',
    YES: 'Áno',
    NO: 'Nie',
    HOUSE_SITUATION: 'Poloha domu',
    MESSAGE: 'Správa',
    NERABY_PLACES: 'V okolí',
    CONTACT_SELLER: 'Kontaktovať predávajúceho',
    SEND: 'Odoslať',

    // new advert
    STEP_INFO: 'Informácie',
    STEP_PROPERTIES: 'Vlastnosti',
    STEP_PHOTOS: 'Fotografie',
    STEP_ADDRESS: 'Adresa',
    STEP_OVERVIEW: 'Súrhn',
    ADVERT_TYPE: 'Typ inzerátu',
    PROPERTY_PRICE: 'Cena za nehnuteľnosť',
    PRICE_NEGOTIABLE: 'Cena k jednaniu',
    DESCRIPTION: 'Popis',
    DESCRIPTION_EN: 'Popis pre anglickú verziu',
    NUMBER_OF_LEVELS: 'Počet poschodí',
    NETWORKS: 'Siete',
    TYPE: 'Typ',
    OVERVIEW_IMAGES: 'Obrázky na prehľad inzerátov',
    DETAIL_IMAGES: 'Obrázky na detail inzerátu',
    UPLOAD_IMAGES_DESC: 'Pretiahnite alebo skopírujte obrázky',
    UPLOAD_IMAGES_INFO: 'Akceptujeme len .jpg, .jpeg, .png (nepodporujeme fotky priamo z iphone (.heic))',
    UPLOAD_MAX_FILES: (maxFiles: number) => `(Maximálne ${maxFiles} súbory)`,
    UPLOAD_IMAGE_SUCCESS: 'Fotografie úspešne nahraté.',
    UPLOAD_IMAGE_FAILED: 'Vyskytla sa chyba pri nahrávaní súboru.',
    MANUALLY: 'Zadať adresu manuálne',
    SEARCH_ADDRESS: 'Vyhľadať ulicu a číslo popisné',
    ADDRESS: 'Adresa',
    STREET: 'Ulica',
    REGISTRATION_NUMBER: 'ČP',
    BUILDING_NUMBER: 'ČO',
    CITY: 'Mesto',
    REGION: 'Región',
    DISTRICT: 'Okres',
    STATE: 'Stav',
    CONTACT_INFO: 'Kontaktné údaje',
    NAME: 'Meno',
    SURNAME: 'Priezvisko',
    PHONE: 'Telefón',
    EMAIL: 'E-mail',
    TODAY: 'Dnes',
    YESTERDAY: 'Včera',
    TOMORROW: 'Zajtra',
    REQUIRED: 'Položka je povinná',
    SAVE: 'Uložiť',
    PHOTOS_UPLOAD: 'Vloženie fotografií',
    TRANSACTION: 'Platba',
    COST: 'Cena',
    FREE: 'Zdarma',
    DURATION: 'Trvanie',
    DURATION_ONE_MONTH: '1 mesiac',
    SAVE_ADVERT: 'Ulozit inzerat do oblubenych',


    // Login
    LOGIN: 'Prihlásiť sa',
    USERNAME: 'Email',
    PASSWORD: 'Heslo',
    REMEMBER_LOGIN: 'Zapamätať prihlásenie',
    SIGN_IN: 'Prihlásiť',
    SIGN_IN_GOOGLE: 'Prihlásiť sa cez Google',
    SIGN_IN_FB: 'Prihlásiť sa cez Facebook',
    REGISTER: 'Vytvoriť účet',
    LOST_PASSWORD: 'Zabudnuté heslo',
    SIGN_IN_COMPANY: 'Vstup pre realitné kancelárie',

    // Utilities

    GO_BACK: 'Späť',

    // Password
    OLD_PASSWORD: 'Staré heslo',
    NEW_PASSWORD: 'Nové heslo',
    RESET_PASSWORD: 'Zmeniť heslo',
    BACK_TO_ACCOUNT: 'Späť do Môj profil',
    CHANGE_PASSWORD: 'Zmena hesla',
    FORGOTTEN_PASSWORD_SUCCESS: 'E-mail pre reset hesla bol odoslaný na Vašu e-mailovú adresu',
    FORGOTTEN_PASSWORD_FAILED: 'Nepodarilo sa odoslať e-mail pre reset hesla',

    EDIT_ADVERT: 'Upraviť inzerát',
    NEW_ADVERT: 'Nový inzerát',
    EDIT_SEARCH: 'Upraviť',
    SHOW_SEARCH: 'Zobraziť',
    SHOW_ADVERT: 'Zobraziť inzerát',
    DELETE_ADVERT: 'Zmazať inzerát',
    ACTIVATE_ADVERT: 'Aktivovať inzerát',
    DELETE_SEARCH: 'Zmazať',
    DEACTIVAT_SEARCH: 'Deaktivovať',
    ACTIVAT_SEARCH: 'Aktivovať',
    DEACTIVATE_ADVERT: 'Deaktivovať inzerát',
    CREATE_DATE: 'Vytvorené',
    COST_NOTE: 'Poznámka k cene',
    ACTIVE_TILL: 'Aktívny do',
    MY_ADVERT: 'Moje inzeráty',
    MY_SAVED_SEARCH: 'Moje uložené hľadania',
    EDIT_FORM: 'Upraviť',
    MY_ACCOUNT: 'Môj účet',
    EDIT_SAVED_SEARCH: 'Upraviť hľadanie',
    ACTIVATE_SAVED_SEARCH: 'Aktivovať hľadanie',
    DEACTIVATE_SAVED_SEARCH: 'Deaktivovať hľadanie',
    SHOW_SAVED_SEARCH_RESULT: 'Zobraziť výsledky hľadania',
    DELETE_SAVED_SEARCH: 'Zmazať hľadanie',
    CREATE_SAVED_SEARCH: 'Vytvoriť nové hľadanie',
    FAVORITE_ADVERT: 'Obľúbené inzeráty',
    NO_RESULT_MY_WATCHDOGS: 'Zatiaľ ste si neuložili žiadne automatické hľadanie.',
    NO_RESULT_MY_FAVORITES: 'Zatiaľ ste si neuložili žiadny oblúbený inzerát.',
    NO_RESULT_MY_AGENTS: 'Zatiaľ nemáte žiadneho predajcu.',

    EMAIL_TO_SELLER_SUCCESS: 'Správu sme odoslali realitnému maklérovi.',
    EMAIL_TO_SELLER_FAILED: 'E-mail se nepodarilo odoslať.',
    DELETE_ADVERT_CONFIRM: 'Naozaj si prajete zmazať tento inzerát: ',
    RESEND_VERIFICATION_EMAIL_SUCCESS: 'Potvrdzovací e-mail bol úspešne odoslaný.',
    RESEND_VERIFICATION_EMAIL_FAILED: 'Odoslanie potvrdzovacieho e-mailu zlyhalo, opakujte prosím akciu znovu.',
    SIGN_IN_FAILED: 'Prihlasovacie meno alebo heslo nie sú správne.',
    DEFAUL_ERROR: 'Došlo k neočakávanej chybe.',
    SERVER_ERROR: 'Server je nedostupný',
    REGISTER_SUCCESS: 'Užívateľský účet bol vytvorený. Prosím prihláste sa.',
    REGISTER_FAILED: 'Nastala chyba pri registrácií.',
    CONFIRM_EMAIL_FAILED: 'Potvrdenie neexistuje, alebo sa nepodarilo.',
    PASSWORD_SAME_OLD_ERROR: 'Zadané nové heslá sa zhodujú so starým heslom.',
    PASSWORD_SAME_ERROR: 'Zadané nové heslá sa nezhodujú.',
    PASSWORD_FORMAT_ERROR: 'Heslo musí mať dĺžku medzi 4 až 15 znakov a musí obsahovať aspoň jedno malé písmeno, aspoň jedno veľké písmeno a aspoň jedno číslo.',
    RESET_PASSWORD_SUCCESS: 'Heslo úspešne zmenené.',
    RESET_PASSWORD_FAILED: 'Heslo sa nepodarilo zmeniť.',
    CHANGE_PASSWORD_SUCCESS: 'Heslo úspešne zmenené.',
    CHANGE_PASSWORD_FAILED: 'Heslo sa nepodarilo zmeniť. Skontrolujte či ste správne zadali staré heslo.',
    LOG_OUT_SUCCESS: 'Užívateľ bol odhlásený',
    AUTH_SUCCESS: 'Boli ste úspešne prihlášený',
    DELETE_OFFER_ITEM_SUCCESS: 'Položka bola zmazaná',
    INVALID_EMAIL: 'E-mail nemá správný formát',
    GET_CURRENT_USER_FAILED: 'Uživatelský účet je neprístupný.',
    REGISTRATION_SUCCESS: 'Uživatelský účet bol úspešne zaregistrovaný',
    REGISTRATION_FAILED: 'Nastala chyba pri registrácií.',
    REGISTRATION_FAILED_EXISTED: 'Užívateľ s týmto e-mailom je už registrovaný.',
    PUBLISH_OFFER_ITEM_SUCCESS: 'Inzerát bol publikovaný',
    IS_NOT_AUTHENTICATE: 'Nie ste prihlásený k svojmu účtu.',
    DELETE_SEARCH_FAILED: 'Nepodarilo sa zmazať uložené vyhľadávánie',

    SEND_AGAIN: 'Znovu odoslať',
    NON_VERIFIED_EMAIL: 'Neverifikovaný e-mail',
    NON_VERIFIED_EMAIL_USER: 'Máte neverifikovaný e-mail, bez verifikácie nebudete môcť plnohodnotne používať aplikáciu',
    NOTIFICATION_EDIT_OFFERS: (x:number) => `Mate ${x} rozpracovanych inzeratov.`,

    NON_VERIFIED_EMAIL_TEXT: 'K plnohodnotnému používaniu aplikácie je nutné potvrdiť svoju e-mailovú adresu cez potvrdzovací e-mail, ktorý Vám bol poslaný pri registrácii.',
    EMPTY_SEARCH: 'Vášmu hľadaniu nevyhovuje žiadny inzerát.',
    EMPTY_SEARCH_AGENCIES: 'Vášmu hľadaniu nevyhovuje žiadny kancelárie.',

    ADVERT_STEP_NO_GO: 'Nie je možné prejsť na tento krok, najskôr musíte uložiť prvý krok.',
    HOW_ADVERTISE: 'Ako inzerovať',
    PERSONAL_DATA_PROTECTION: 'Ochrana osobných údajov',
    MARKETING_AGREEMENT: 'Marketingový súhlas',
    DOCUMENTS: 'Dokumenty',
    BUSINESS_CONDITIONS: 'Obchodné podmienky',
    TECHNICAL_SUPPORT: 'Technická podpora',
    CONATACTS: 'Kontakty',
    SEARCH_NOTIFICATION: 'Automatické hľadania',
    PUBLISHED: 'Publikované',
    EDIT: 'Rozpracované',
    EDIT_USER_INFO: 'Upraviť údaje',
    UNACTIVE: 'Deaktivované',
    UNACTIVE_ITEM: 'Deaktivovaný',


    // agency admin
    SELLER_DETAIL: 'Detail predajcu',
    SELLER_ADVERTS: 'Inzeráty predajcu',
    SELLER_EDIT: 'Editovať predajcu',
    SELLER_DELETE: 'Zmazať predajcu',
    EDIT_AGENCY: 'Upraviť realitnú kanceláriu',
    ADMIN_AGENCY: 'Správa realitnej kancelárie',
    SELLER: 'Predajca',
    CLOSE: 'Zatvoriť',
    TITLE: 'Názov',
    ADVERT_IMPORT:'Konfigurace Importu',
    IMPORT_TYPE:'Typ importu',
    REALSOFT: 'Realsoft',
    ZOZNAM_REALIT: 'Zoznam realit',
    IMPORT: 'Importovat',
    IMPORT_STATUS: 'Import',
    ACTIVE: 'Aktivní',
    DEACTIVATED: 'Neaktivny',
    RUN_IMPORT: 'Vyžádat okamžitý import',

    API_ADDRESS: 'apiAddress',
    AUTH_KEY: 'authKey',
    AUTH_SECRET: 'authSecret',

    // confirm
    REDIRECT_ERROR: 'Prihlásenie nebolo úspešné',
    REDIRECT_SUCCESS: 'Prihlásenie bolo úspešné',
    CONFIRMATION_VERIFY_EMAIL: 'Potvrdenie emailovej adresy bolo úspešne dokončené.',
    CONFIRMATION_ADD_TO_REALITY_COMPANY: 'Pridanie do realitnej kancelárie bolo úsešne potvrdené.',
    CONFIRMATION_SUCCESS: 'Akcia bola úspešne potvrdená.',

    // agency register
    REGISTER_ESTATE_AGENCY: 'Registrácia realitnej kancelárie',
    WEBSITE: 'WWW',
    COMPANY_INFO: 'Info o RK',
    COMPANY_NAME: 'Názov RK',
    CREATE_REAL_ESTATE_COMPANY_SUCCESS: 'Registrácia realitnej kancelárie bola úspešná',
    CREATE_REAL_ESTATE_COMPANY_FAILED: 'Registrácia realitnej kancelárie nebola úspešná',
    REGISTER_ESTATE_AGENCY_BUTTON: 'Požiadať o registráciu',
    SHOW_SELLER_EMAIL: 'Zobraziť email',
    SHOW_SELLER_PHONE: "Zobraziť telefón",


    // Zoznam realitnych kancelarii
    STATISTICS: 'Štatistika inzerátu',
    NO_STATISTICS: 'Štatistika neni dostupná',
    VIEWS_PER_DAY: 'Počet unikátnych užívateľov',
    TOTAL_VIEWS: 'Počet zobrazení celkom',
    SELLERS_ITEMS: 'Zobraziť inzeráty predávajúceho',
    COMPANY_ITEMS: 'Zobraziť inzeráty realitnej kancelárie',
    ADD_TO_FAVORITE: 'Pridať do obľúbených',
    REMOVE_FROM_FAVORITE: 'Odobrať z obľúbených',
    SCROLL_TO_TOP: 'Ísť hore',
    REPORT_ADVERT: 'Nahlásiť inzerat',
    REPORT_ADVERT_TITLE: 'Nahlásenie nevhodného inzerátu',
    REPORT_ISSUE: 'Čo si myslíte že je na tomto inzeráte nevhodné?',
    REPORT: 'Nahlásiť',
    SAVE_FAVORITE_SUCCESS: 'Inzerát bol pridaný do obľúbených',
    REMOVE_FAVORITE_SUCCESS: 'Inzerát bol odstránený z obľúbených',
    NEED_LOG_IN: 'Pre pridanie/odstránenie inzerátu do obľúbených sa musíte prihlásiť.',

    COOKIES_TITLE: 'Na našich stránkach používame súbory cookies, vďaka ktorým Vám poskytujeme relevantnejší obsah, funkcie sociálnych sietí a meriame výkonnosť stránky. Súhlas s používaním cookies môžete kedykoľvek odvolať.',
    COOKIES_MODAL_TITLE: 'Pri prezeraní našich stránok ukladáme súbory cookies do Vášho internetového prehliadača. Tieto súbory sú dôležitou súčasťou funkčnosti našich stránok. Súbory cookies použité na skreality.sk sú kategorizované na nevyhnutné, štatisické a marketingové. Vaše súkromie je pre nás dôležité a preto môžete v každej kategórií vidieť zoznam používaných cookies a prijať alebo odmietnuť uchovávanie súborov cookie z tejto kategórie.',
    AGREE_WITH_ALL: 'Prijať všetky',
    SAVE_AND_CLOSE: 'Uložiť a Zatvoriť',
    COOKIES_SETTINGS: 'Nastavenia',
    COOKIES_SETTINGS_MODAL: 'Nastavenia Cookies',
    USER_COOKIES_FUNCTIONAL: 'Nevyhnutné cookies',
    USER_COOKIES_MARKETING: 'Marketingové cookies',
    USER_COOKIES_STATISTICS: 'Analytické cookies',
    USER_COOKIES_FUNCTIONAL_DESC: 'Súbory cookies ktoré sú nevyhnutné pre správne fungovanie stránky.',
    USER_COOKIES_MARKETING_DESC: 'Marketingové cookies umožňujúce zobraziť užívateľom relevantný obsah.',
    USER_COOKIES_STATISTICS_DESC: 'Štatistické cookies nám pomáhajú merať a vylepšovať výkonnosť stránky.',
    SHOW_MAP: 'Zobraziť mapu',
    HIDE_MAP: 'Skryť mapu',
    FILTERING: 'Filtrovať',
    ALL: 'Všetky',
    DOWNLOAD: 'Stiahnuť',
    CONTACT_SUPPORT_POST_FAILED: 'Nepodarilo sa odoslať formulár.',
    CONTACT_SUPPORT_POST_SUCCESS: 'Úspešne odoslané',
    COOKIE_FOOTER: 'Nastavenie súkromia',
    ADD_REALITY_COMPANY_USER_FAILED: 'Operácia sa nepodarila, kontaktujte prosím support. Email: support@skreality.sk',
    REALITY_COMPANY_ACCOUNT: 'Účet RK',
    MIN_NUMBER_VALUE: (x: number) => `Číslo musí byť väčšie než ${x}`,
    MAX_NUMBER_VALUE: (x: number) => `Číslo musí byt menšie než ${x}`,

    MY_ACCOUNT_PICTURE: 'Nahrajte svoju fotografiu',
    UPLOAD_USER_PHOTO_SUCCESS: 'Fotografia úspešne nahratá',
    UPLOAD_USER_PHOTO_FAILED: 'Fotografiu se nepodarilo nahrať',
    UPLOAD_ADVICE: 'Kliknutím na fotografiu môžete nahrať novú fotografiu.',
    UPLOAD_REALITY_COMPANY_PHOTO_SUCCESS: 'Logo úspešne nahraté',
    UPLOAD_REALITY_COMPANY_PHOTO_FAILED: 'Logo sa nepodarilo nahrať',

    BEFORE_LOGIN_ERROR:'Pre túto funkciu sa musíte najprv prihlásiť',
    HIDE_IMPORTED: 'skryt importovane',
};
