import axios from 'axios';
import {concat, split, pipe, last} from 'ramda';
import {useCallback} from 'react';
import {isNotNilOrEmpty, replacePathParams, addQueryParams, isNilOrEmpty, isBetween} from '../utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {blobToBase64, getImageType} from '../utils/imageUtils';
import {REDUCER_AUTH, AUTH_TOKEN, REDUCER_CONTEXT, RECAPTCHA_HEADER} from '../utils/constant';
import {useHistory} from 'react-router-dom';
import {ERoutePaths, ERouteParameters} from '../enumerators';
import t from '../texts';
import {GET_CURRENT_USER} from './reducers/user';
import {useApiDataSelector} from '../hooks/hooks';
import {getConfig} from '../App';

const setDefaultHeader = () => {
    let accessToken = useApiDataSelector(state => state[REDUCER_AUTH].accessToken);
    if (isNilOrEmpty(accessToken)) {
        accessToken = localStorage.getItem(AUTH_TOKEN);
    }
    axios.defaults.headers.common = {};
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
};
export const fileHeader = {'Content-Type': 'multipart/form-data'};

const requestType = (eventName) => `${eventName}_REQUEST`;
const successType = (eventName) => `${eventName}_SUCCESS`;
const failedType = (eventName) => `${eventName}_FAILED`;

export const request = eventName => ({
    type: requestType(eventName),
    payload: {
        error: null,
        isLoading: true
    }
});
export const success = (eventName, response, params) => ({
    type: successType(eventName),
    payload: {
        data: response?.data,
        error: null,
        isLoading: false
    },
    meta: params
});

export const error = (eventName, error) => {
    const status = error?.response?.status;
    const data = error?.response?.data;
    let message = null;

    if (eventName === GET_CURRENT_USER && status === 401 && isNotNilOrEmpty(data.errors)) {
        message = data.errors[0]?.message;
    }
    if (isBetween(status, 500, 600)) {
        message = t.SERVER_ERROR;
    }
    return {
        type: failedType(eventName),
        payload: {
            error: {
                data,
                message,
                status
            },
            isLoading: false
        }
    };
};

export const useApiRequest = (context, endpoint, eventName, {
    method = 'get',
    params = {},
    headers = {}
} = {}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const recaptchaToken = useSelector((state) => state[REDUCER_CONTEXT].recaptchaToken);

    setDefaultHeader();

    return useCallback(async ({
        params: pathParams,
        body,
        queryParams
    } = {}) => {
        return new Promise(async (resolve, reject) => {
            dispatch(request(eventName));
            try {
                let path = endpoint;
                if (isNotNilOrEmpty(pathParams)) {
                    path = replacePathParams(path, pathParams);
                }
                if (isNotNilOrEmpty(queryParams)) {
                    const params = addQueryParams(queryParams);
                    path = concat(path, params);
                }
                const response = await axios[method](`${getConfig().apiBaseUrl}/api/${path}`, { ...params, ...body }, {
                    headers: {
                        ...headers,
                        [RECAPTCHA_HEADER]: recaptchaToken
                    }
                });
                dispatch(success(eventName, response, pathParams));
                resolve();
            } catch (e) {
                reject();
                dispatch(error(eventName, e));
                if (eventName === GET_CURRENT_USER && (e.response.status === 401 || e.response.status === 403)) {
                    history.push(ERoutePaths.LOGIN, {[ERouteParameters.ORIGINAL_PATH]: history.location});
                }
            }
        });
    }, [endpoint, method, recaptchaToken]);
};

export const authRequest = (endpoint, eventName, {
    method = 'get',
    headers = {}
} = {}) => {
    setDefaultHeader();
    const dispatch = useDispatch();
    const recaptchaToken = useSelector((state) => state[REDUCER_CONTEXT].recaptchaToken);

    return useCallback(async ({
                                  body,
                                  params,
                                  queryParams
                              } = {}) => new Promise(async (resolve, reject) => {
        dispatch(request(eventName));
        let path = endpoint;
        if (isNotNilOrEmpty(params)) {
            path = replacePathParams(path, params);
        }
        if (isNotNilOrEmpty(queryParams)) {
            const params = addQueryParams(queryParams);
            path = concat(path, params);
        }
        try {
            const response = await axios[method](`${getConfig().apiBaseUrl}/auth/${path}`, {...params, ...body}, {
                headers: {
                    ...headers,
                    [RECAPTCHA_HEADER]: recaptchaToken
                }
            });
            dispatch(success(eventName, response));
            resolve();
        } catch (e) {
            dispatch(error(eventName, e));
            reject();
        }
    }), [headers, endpoint, method, recaptchaToken]);
};

export const uploadFile = (context, endpoint, eventName, {method = 'post'} = {}) => {
    setDefaultHeader();
    const dispatch = useDispatch();

    return useCallback(async ({
                                  params: pathParams,
                                  body,
                                  queryParams
                              } = {}) => {
        dispatch(request(eventName));
        try {
            let path = endpoint;
            if (isNotNilOrEmpty(pathParams)) {
                path = replacePathParams(path, pathParams);
            }
            if (isNotNilOrEmpty(queryParams)) {
                const params = addQueryParams(queryParams);
                path = concat(path, params);
            }
            const response = await axios[method](`${getConfig().apiBaseUrl}/api/${path}`, body, {headers: fileHeader});
            dispatch(success(eventName, response, pathParams));
        } catch (e) {
            dispatch(error(eventName, e));
        }
    }, [endpoint, method]);
};

export const getFile = async (image) => {
    const type = pipe(split('.'), last)(image.url);
    const fileName = pipe(split('/'), last)(image.url);
    axios.defaults.headers.common = null;
    const response = await axios.get(image.url, {
        responseType: 'blob',
        timeout: 30000
    });
    const file = new File([response.data], fileName, {type: getImageType(type)});
    const data = await blobToBase64(response.data);
    return {
        file,
        info: image,
        data
    };
};
