import {isNilOrEmpty} from '../../utils/helpers';
import t from '../../texts';
import {equals, has, test} from 'ramda';
import {REAL_ESTATE_TYPES} from '../../utils/constant';

export const REQUIRED = 'required';
export const EQUALS = 'equals';
export const NOT_EQUALS = 'notEquals';
export const FORMATTING = 'formatting';

export const validateEmail = (email: string) => test(/^\S+@\S+\.\S+$/, email);
export const validatePassword = (password: string) => test(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,15}$/, password);

export const validateForm = (rules: any, entity: any, setFormError: any, clearFormError?: () => {}) => {
    let error = false;
    clearFormError?.();
    rules.forEach((rule: any) => {
        const value = entity[rule.name];
        const ruleName = rule.name;
        const ruleMessage = rule.message;
        if (has(REQUIRED, rule) && rule[REQUIRED] && isNilOrEmpty(value)) {
            setFormError({[ruleName]: t.REQUIRED});
            error = true;
        }
        // @ts-ignore
        if (has(EQUALS, rule) && rule[EQUALS] && !equals(value, entity[rule[EQUALS]])) {
            setFormError({[ruleName]: ruleMessage});
            error = true;
        }
        // @ts-ignore
        if (has(NOT_EQUALS, rule) && rule[NOT_EQUALS] && equals(value, entity[rule[NOT_EQUALS]])) {
            setFormError({[ruleName]: ruleMessage});
            error = true;
        }
        // @ts-ignore
        if (has(FORMATTING, rule) && rule[FORMATTING] && !rule.formatting(value)) {
            setFormError({[ruleName]: ruleMessage});
            error = true;
        }
    });

    if (error) {
        throw TypeError('ValidationError');
    }
};


export const offerItemValidation = [
    {name: 'price', required: true},
    {name: 'status', required: true},
    {name: 'offerType', required: true}
];

export const addressValidation = [
    {name: 'city', required: true},
    {name: 'region', required: true},
    {name: 'district', required: true}
];

export const realEstateValidation = (realEstateType: any) => [
    {name: 'realEstateType', required: true},
    {
        name: 'realEstateOwnership',
        required: realEstateType.code === REAL_ESTATE_TYPES.APARTMENT || realEstateType.code === REAL_ESTATE_TYPES.HOUSE
    },
    {name: 'buildingMaterialType', required: realEstateType.code === REAL_ESTATE_TYPES.APARTMENT},
    {
        name: 'realEstateDisposition',
        required: realEstateType.code === REAL_ESTATE_TYPES.HOUSE || realEstateType.code === REAL_ESTATE_TYPES.APARTMENT
    }, // (for APARTMENT)
    {name: 'surfaceArea', required: realEstateType.code !== REAL_ESTATE_TYPES.LOT}
];

export const userValidation = [
    {name: 'firstName', required: true},
    {name: 'lastName', required: true},
    {name: 'phone', required: true},
    {name: 'email', formatting: validateEmail, message: t.INVALID_EMAIL},
    {name: 'email', required: true},
    {name: 'agreementConditions', required: true}
];
export const editUserValidation = [
    {name: 'firstName', required: true},
    {name: 'lastName', required: true},
    {name: 'phone', required: true},
    {name: 'email', formatting: validateEmail, message: t.INVALID_EMAIL},
    {name: 'email', required: true},
];

export const contactFormValidation = [
    {name: 'name', required: true},
    {name: 'phone', required: true},
    {name: 'email', formatting: validateEmail, message: t.INVALID_EMAIL},
    {name: 'email', required: true}
];

export const contactFormSupportValidation = [
    {name: 'name', required: true},
    {name: 'phone', required: true},
    {name: 'email', formatting: validateEmail, message: t.INVALID_EMAIL},
    {name: 'email', required: true},
    {name: 'message', required: true}
];

export const agencyFormValidation = [
    {name: 'companyName', required: true},
    {name: 'email', formatting: validateEmail, message: t.INVALID_EMAIL},
    {name: 'email', required: true}
];

export const importFormValidation = [
    {name: 'authKey', required: true},
    {name: 'authSecret', required: true},
    {name: 'status', required: true},
    {name: 'type', required: true}
];

export const resetPasswordValidation = [
    {name: 'newPassword', required: true},
    {name: 'newPassword2', required: true},
    {name: 'newPassword', equals: 'newPassword2', message: t.PASSWORD_SAME_ERROR},
    {name: 'newPassword', formatting: validatePassword, message: t.PASSWORD_FORMAT_ERROR}
];

export const changePasswordValidation = [
    {name: 'oldPassword', required: true},
    {name: 'newPassword', required: true},
    {name: 'newPassword2', required: true},
    {name: 'newPassword', notEquals: 'oldPassword', message: t.PASSWORD_SAME_OLD_ERROR},
    {name: 'newPassword', equals: 'newPassword2', message: t.PASSWORD_SAME_ERROR},
    {name: 'newPassword', formatting: validatePassword, message: t.PASSWORD_FORMAT_ERROR}
];

export const passwordnValidation = [
    {name: 'oldPassword', required: true},
    {name: 'newPassword', required: true},
    {name: 'newPassword2', required: true},
    {name: 'newPassword', notEquals: 'oldPassword', message: t.PASSWORD_SAME_OLD_ERROR},
    {name: 'newPassword', equals: 'newPassword2', message: t.PASSWORD_SAME_ERROR},
    {name: 'newPassword', formatting: validatePassword, message: t.PASSWORD_FORMAT_ERROR}
];
