import {useSelector} from 'react-redux';
import {REDUCER_SEARCH} from '../utils/constant';
import {useCallback, useEffect} from 'react';
import {useApiRequest} from '../store/api';
import {SEARCH, SEARCH_LOCATIONS, SET_PAGINATION} from '../store/reducers/search';
import {SearchRequest} from '../utils/types';
import {formatToRequestBody} from '../utils/helpers';
import usePreviousFilter from './usePreviousFilter';
import {createAction} from "../store/actions";

const useSearch = () => {
    const {setPreviousFilter} = usePreviousFilter();
    const {
        data: searchResult,
        isLoading
    } = useSelector((state: any) => state[REDUCER_SEARCH].searchResult);

    const {
        data: locationsResult,
        isLoading: isLoadingLocation
    } = useSelector((state: any) => state[REDUCER_SEARCH].locations);

    const filter = useSelector((state: any) => state[REDUCER_SEARCH].filter);

    const searchRequest = useApiRequest(REDUCER_SEARCH, 'offer-item/search', SEARCH, {method: 'post'});

    const searchLocations = useApiRequest(REDUCER_SEARCH, 'offer-item/search/locations', SEARCH_LOCATIONS, {method: 'post'});

    const pagination = useSelector((state: any) => state[REDUCER_SEARCH].pagination);

    const setPagination = createAction(SET_PAGINATION);

    const fetchSearchResult = useCallback((isPaginationChange = false) => {
        const {size, page, sort} = pagination;
        if (!isLoading) {
            const request = new SearchRequest(formatToRequestBody(filter));
            searchRequest({body: request, queryParams: {size, page, sort}});
            if (!isLoadingLocation && !isPaginationChange) {
                searchLocations({body: request});
            }
            setPreviousFilter(filter);
        }
    }, [searchRequest, searchLocations, setPreviousFilter, isLoadingLocation, filter, isLoading, pagination]);

    useEffect(() => {
        fetchSearchResult(true);
    }, [pagination?.page, pagination?.size, pagination?.sort]);

    return {
        searchResult,
        locationsResult,
        pagination,
        isLoadingSearch: isLoading || isLoadingLocation,
        fetchSearchResult,
        setPagination
    };
};

export default useSearch;
