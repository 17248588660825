import {ALERT_SEVERITY, initialPayload} from '../utils/constant';
import {
    SIGN_IN_REQUEST,
    SIGN_IN_SUCCESS,
    SET_ACCESS_TOKEN,
    LOG_OUT_SUCCESS,
    SET_AUTHENTICATE,
    FORGOTTEN_PASSWORD_SUCCESS,
    REGISTRATION_SUCCESS,
    RESET_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_SUCCESS,
    REGISTRATION_FAILED, REGISTRATION_FAILED_EXISTED
} from './reducers/auth';
import {
    SET_CURRENT_USER,
    GET_CURRENT_USER_FAILED,
    RESEND_VERIFICATION_EMAIL_SUCCESS,
    EMAIL_TO_SELLER_SUCCESS, UPLOAD_USER_PHOTO_SUCCESS
} from './reducers/user';
import {isNotNilOrEmpty} from '../utils/helpers';
import {test, any, equals, none} from 'ramda';
import {SET_ALERT, CONTACT_SUPPORT_POST_SUCCESS} from './reducers/context';
import t from '../texts';
import {DELETE_OFFER_ITEM_SUCCESS, UPLOAD_REALITY_COMPANY_PHOTO_SUCCESS} from './reducers/realEstate';
import {setStoredAccessToken, removeStoredAccessToken} from '../utils/storage';
import {
    GET_ADDRESS_FAILED,
    GET_REAL_ESTATE_FAILED,
    PUBLISH_OFFER_ITEM_SUCCESS,
    REAL_ESTATE_DETAIL_BY_ID_FAILED
} from './reducers/advert';
import {
    CREATE_REAL_ESTATE_COMPANY_SUCCESS,
    GET_COMPANY_FAILED,
    GET_REALITY_COMPANY_USER_FAILED
} from './reducers/realityCompany';
import {SAVE_SEARCH_SUCCESS} from './reducers/offerItem';
import {ADDRESS_BY_ID_FAILED} from "./reducers/search";

const successAlertActions = [FORGOTTEN_PASSWORD_SUCCESS, CHANGE_PASSWORD_SUCCESS, REGISTRATION_SUCCESS, LOG_OUT_SUCCESS, RESET_PASSWORD_SUCCESS, DELETE_OFFER_ITEM_SUCCESS,
    REGISTRATION_SUCCESS, RESEND_VERIFICATION_EMAIL_SUCCESS, PUBLISH_OFFER_ITEM_SUCCESS, EMAIL_TO_SELLER_SUCCESS, CREATE_REAL_ESTATE_COMPANY_SUCCESS, SAVE_SEARCH_SUCCESS,
    UPLOAD_REALITY_COMPANY_PHOTO_SUCCESS, UPLOAD_USER_PHOTO_SUCCESS,
    CONTACT_SUPPORT_POST_SUCCESS];

const ignoredErrors = [GET_CURRENT_USER_FAILED, GET_COMPANY_FAILED, GET_REALITY_COMPANY_USER_FAILED, GET_ADDRESS_FAILED, GET_REAL_ESTATE_FAILED];

const middleware = store => next => action => {
    const error = action.payload?.error?.data?.errors?.[0] || action.payload?.error;

    if (action.type === SIGN_IN_REQUEST || action.type === GET_CURRENT_USER_FAILED) {
        removeStoredAccessToken();
        store.dispatch({
            type: SET_AUTHENTICATE,
            payload: false
        });
    }
    if (action.type === SIGN_IN_SUCCESS && isNotNilOrEmpty(action.payload?.data?.accessToken)) {
        setStoredAccessToken(action.payload?.data?.accessToken);
    }
    if (action.type === SET_ACCESS_TOKEN && isNotNilOrEmpty(action.payload)) {
        setStoredAccessToken(action.payload);
    }
    if (action.type === LOG_OUT_SUCCESS || action.type === SIGN_IN_REQUEST) {
        removeStoredAccessToken();
        store.dispatch({
            type: SET_AUTHENTICATE,
            payload: false
        });
        store.dispatch({
            type: SET_CURRENT_USER,
            payload: initialPayload(null)
        });
    }

    if (action.type === REGISTRATION_FAILED && error?.message === 'Email address already in use.') {
        store.dispatch({
            type: SET_ALERT,
            payload: {
                severity: ALERT_SEVERITY.ERROR,
                message: t[REGISTRATION_FAILED_EXISTED],
            }
        });
        next(action);
    }

    if (test(/FAILED$/, action.type) && none(equals(action.type), ignoredErrors)) {
        store.dispatch({
            type: SET_ALERT,
            payload: {
                severity: ALERT_SEVERITY.ERROR,
                message: error?.message || t[action.type]
            }
        });
    }

    if (any(equals(action.type), successAlertActions)) {
        store.dispatch({
            type: SET_ALERT,
            payload: {
                severity: ALERT_SEVERITY.SUCCESS,
                message: t[action.type]
            }
        });
    }

    return next(action);
};

export default middleware;
