import React, { useEffect, useState } from 'react';
import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import PageableUserChips from '../EstateAgencyAdministration/PageableUserChips';
import { useApiRequest } from '../../store/api';
import { REDUCER_USER } from '../../utils/constant';
import { GET_NO_AGENCY_USERS, USER_CHIP_CLICKED } from '../../store/reducers/user';
import { ChipUser } from '../../utils/types';
import { createAction } from '../../store/actions';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface AddUserDialogBodyProps {
    potentialUsers : Array<ChipUser>;
    realityCompanyId: string;
}

const AddUserDialogBody = ({ potentialUsers, realityCompanyId } : AddUserDialogBodyProps) => {

    const [filterVal, setFilterVal] = useState<string>('');
    const [sellers, setSellers] = useState<Array<ChipUser>>([]);

    const getUsersWithoutAgency = useApiRequest(REDUCER_USER, 'user/no-reality-company', GET_NO_AGENCY_USERS, { method: 'get' });
    const selectUserChip = createAction(USER_CHIP_CLICKED);

    const applyFiltering = (value: string) => {
        if (potentialUsers && potentialUsers.length > 0) {
            if (value && value.length > 2) {
                // removes empty strings from split
                const keyWords = value.toLowerCase().split(' ').filter(key => key);
                const filteredUsers: Array<ChipUser> = [];
                potentialUsers.forEach(seller => {
                    let matchCount = 0;
                    keyWords.forEach(key => {
                        if (seller.user?.email?.toLowerCase().includes(key) || seller.user?.firstName?.toLowerCase().includes(key) || seller.user?.lastName?.toLowerCase().includes(key)) {
                            matchCount += 1;
                        }
                        if (matchCount === keyWords.length) {
                            filteredUsers.push(seller);
                        }
                    });
                });

                setSellers(filteredUsers);
            } else {
                setSellers(potentialUsers);
            }
        }
    };

    useEffect(() => {
        getUsersWithoutAgency();
    }, []);

    useEffect(() => {
        applyFiltering(filterVal);
    }, [potentialUsers]);

    const handleFilterChange = (value: string) => {
        setFilterVal(value);
        applyFiltering(value);
    };

    const handleRemoveFilter = () => {
        handleFilterChange('');
    };

    return (
        // TODO zarovnat, přidat placeholder - rušící tlačítko?
        <div className="sk-chip">
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12} className="search-input">
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Hľadať"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleRemoveFilter}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        value={filterVal}
                        onChange={(evt => handleFilterChange(evt.target.value))}
                    />
                </Grid>
            </Grid>
            <PageableUserChips
                realityCompanyId={realityCompanyId}
                data={sellers}
                pageItemCount={3}
                rowItemSize={12}
                onChipClick={selectUserChip}
                visible={sellers && sellers.length > 0}
            />
        </div>
    );
};

export default AddUserDialogBody;
