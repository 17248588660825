// @ts-nocheck
import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox, Collapse, FormControl,
    FormControlLabel,
    Grid, InputLabel,
    MenuItem, Paper,
    Select,
    TextField
} from '@material-ui/core';
import {ICodebookItem, InputGroupParams, InputParams, InputParamsMulti} from '../../utils/types';
import {
    isNilOrEmpty, isNotNilOrEmpty,
    isSelectedCodebookItem,
    onChangeCodebookHandler,
    onChangeNumberHandler,
    onChangeStringHandler
} from '../../utils/helpers';
import InputErrorText from '../Form/InputErrorText';
import NumberFormat from 'react-number-format';
import t from "../../texts";
import {ChevronDown, ChevronUp} from "react-feather";

export const CollapseWithLabel = ({label, isSelected = false, children}) => {
    const [isOpen, setIsOpen] = useState(isSelected);
    return (
        <Box justifyContent="start" mb={1}>
            {!!label &&
                <Box className="collapseLabel">
                    <Button variant="text" size="small" onClick={() => setIsOpen(!isOpen)}>
                        <h3>{label}</h3>
                        {isOpen ? <ChevronUp/> : <ChevronDown/>}
                    </Button>
                </Box>
            }
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </Box>
    );
}

const CheckboxCodebookComp = ({onChange, values, currentValue, label}: InputGroupParams) => {
    if (isNilOrEmpty(values) || isNilOrEmpty(onChange)) {
        return null;
    }
    return (
        <CollapseWithLabel label={label} isSelected={isNotNilOrEmpty(currentValue)}>
            <Paper className="lp-16" elevation={0}>
                <Grid container>
                    {values.map((item: ICodebookItem) => (
                        <Grid className="left" item xs={12} sm={4} key={item.order}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        checked={isSelectedCodebookItem(item, currentValue) || false}
                                        color="primary"
                                        onClick={() => onChange(item)}
                                    />
                                )}
                                label={item.value}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </CollapseWithLabel>
    );
};

const CheckboxInputComp = ({onChange, currentValue, label}: InputParams) => (
    <FormControlLabel
        control={(
            <Checkbox
                checked={currentValue || false}
                color="primary"
                onClick={() => onChange(!currentValue)}
            />
        )}
        label={label}
    />
);

// @ts-ignore
const CurrencyInputComp = ({
                               onChange,
                               onBlur,
                               currentValue,
                               label,
                               required,
                               error,
                               disabled = false,
                               fullWidth = false
                           }: InputParams) => (
    <FormControl fullWidth={fullWidth}>
        <NumberFormat
            prefix="€"
            thousandSeparator={' '}
            allowNegative={false}
            customInput={TextField}
            label={label}
            variant="outlined"
            value={currentValue || ''}
            onValueChange={(vals) => onChange(vals.value)}
            onBlur={onBlur}
            InputProps={{
                size: 'small',
                required,
                error,
                disabled
            }}
        />
        <InputErrorText error={error}/>
    </FormControl>
);
const NumberInputComp = ({
                             onChange,
                             onBlur,
                             currentValue,
                             label,
                             required,
                             error,
                             disabled = false,
                             fullWidth = false,
                             min,
                             max,
                         }: InputParams) => {
    const [err, setErr] = useState('');

    const minMaxValidation = () => {
        if (isNotNilOrEmpty(min) && currentValue < min) {
            setErr(t.MIN_NUMBER_VALUE(min));
        } else if (isNotNilOrEmpty(max) && currentValue > max) {
            setErr(t.MAX_NUMBER_VALUE(max));
        } else {
            setErr('');
        }

    }

    useEffect(() => {
        minMaxValidation();
    }, []);

    useEffect(() => {
        minMaxValidation();
    }, [currentValue]);

    return (
        <FormControl fullWidth={fullWidth}>
            <TextField
                type="number"
                label={label}
                variant="outlined"
                size="small"
                value={currentValue || ''}
                onChange={onChangeNumberHandler(onChange)}
                onBlur={onBlur}
                required={required}
                error={!!error || isNotNilOrEmpty(err)}
                disabled={disabled}
            />
            <InputErrorText error={error || err}/>
        </FormControl>
    );
}
// eslint-disable-next-line react/require-default-props
const TextInputComp = ({
                           onChange,
                           currentValue,
                           label,
                           multiline = false,
                           rows,
                           required,
                           error,
                           disabled = false,
                           fullWidth = false
                       }: InputParamsMulti
) => (
    <FormControl fullWidth={fullWidth}>
        <TextField
            label={label}
            variant="outlined"
            size="small"
            value={currentValue || ''}
            onChange={onChangeStringHandler(onChange)}
            multiline={multiline}
            rows={rows}
            required={required}
            error={!!error}
            disabled={disabled}
        />
        <InputErrorText error={error}/>
    </FormControl>
);

const ButtonGroupComp = ({onChange, values, currentValue, fullWidth = false}: InputGroupParams) => (
    <ButtonGroup fullWidth={fullWidth}>
        {values.map((item: ICodebookItem) => (
            <Button
                key={item.code}
                variant={currentValue?.code === item.code ? 'contained' : 'outlined'}
                onClick={() => onChange(item)}
            >
                {item.value}
            </Button>
        ))}
    </ButtonGroup>
);

const SelectCodebookComp = ({
                                label,
                                onChange,
                                values,
                                currentValue,
                                required,
                                error,
                                disabled,
                                fullWidth = false,
                                emptyValue = false
                            }: InputGroupParams) => (
    <FormControl fullWidth={fullWidth}>
        {label && <InputLabel id={`select-codebook-label-${label}`}>{label}</InputLabel>}
        <Select
            labelId={`select-codebook-label-${label}`}
            label={label}
            className="left"
            value={currentValue?.code ?? ''}
            onChange={onChangeCodebookHandler(onChange, values)}
            required={required}
            error={error}
            disabled={disabled}
        >
            {emptyValue && (
                <MenuItem value=""><em>{t.ALL}</em></MenuItem>
            )}
            {values.map((item: ICodebookItem) => (
                <MenuItem value={item.code} key={item.code}>{item.value}</MenuItem>
            ))}
        </Select>
        <InputErrorText error={error}/>
    </FormControl>
);

const SelectInputComp = ({onChange, values, currentValue, required, error, fullWidth}: InputGroupParams) => (
    <>
        <Select
            fullWidth={fullWidth}
            value={currentValue?.code}
            onChange={onChangeStringHandler(onChange)}
            required={required}
            error={error}
        >
            {values.map((item: string) => (
                <MenuItem value={item.code} key={item.code}>{item.value}</MenuItem>
            ))}
        </Select>
        <InputErrorText error={error}/>
    </>
);

export {
    CheckboxCodebookComp,
    NumberInputComp,
    CheckboxInputComp,
    SelectCodebookComp,
    ButtonGroupComp,
    SelectInputComp,
    TextInputComp,
    CurrencyInputComp
};
