import {Box} from '@material-ui/core';
import Advertisement from '../../components/Advertisement';
import FavoriteSearch from '../../components/FavoriteSearch';
import Header from '../../components/Header';
import Logo from '../../components/Logo';
import BottomMenu from '../../components/Navigation/BottomMenu';
import UpperMenu from '../../components/Navigation/UpperMenu';
import RealEstateType from '../../components/RealEstateType';
import Layout from '../../layouts/Centered';
import React, {useEffect} from 'react';
import Banner from "../../components/Banner/Banner";
import {useApiDataSelector} from "../../hooks/hooks";
import {ALERT_SEVERITY, REDUCER_USER} from "../../utils/constant";
import {createAction} from "../../store/actions";
import {SET_ALERT} from "../../store/reducers/context";
import {isNotNilOrEmpty} from "../../utils/helpers";
import t from '../../texts';
import {useHistory} from "react-router-dom";

const Home = () => {
    const currentUser = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser);
    const setAlert = createAction(SET_ALERT);

    useEffect(() => {
        if (isNotNilOrEmpty(currentUser) && !currentUser?.emailVerified) {
            setAlert({severity: ALERT_SEVERITY.WARNING, message: t.NON_VERIFIED_EMAIL_USER});
        }
    }, []);

    const history = useHistory();

    return (
        <Layout>
            <Header />
            <Advertisement />

            <Box mx="0.5rem">
                <UpperMenu />
                <Logo />
                <RealEstateType />
                <FavoriteSearch />
                <Banner title="Ponuka bytov v Bratislave" subtitle="Aktuálna ponuka bytov Bratislava" buttonTitle="Pozrieť!" onPress={() => {
                    history.push("/vysledky-vyhledavani/byt/predaj/kraj-bratislavsky")
                }} image={{src: process.env.PUBLIC_URL + '/images/APARTMENT.png', width: 50, height: 50}} />
                <BottomMenu />
            </Box>
        </Layout>
    );
}

export default Home;
