import {useParamName} from './hooks';
import {ERouteParameters} from '../enumerators';
import {useSelector} from 'react-redux';
import {REDUCER_REAL_ESTATE, REDUCER_SEARCH} from '../utils/constant';
import {useApiRequest} from '../store/api';
import {
    FETCH_COMPANY_LOCATIONS,
    FETCH_COMPANY_OVERVIEW, SET_COMPANY_PAGINATION
} from '../store/reducers/realEstate';
import {useCallback, useEffect} from 'react';
import {isNotNilOrEmpty} from '../utils/helpers';
import {createAction} from "../store/actions";

const useCompanySearch = () => {
    const companyId = useParamName(ERouteParameters.COMPANY_ID);

    const {
        data,
        isLoading
    } = useSelector((state: any) => state[REDUCER_REAL_ESTATE].companyItems);

    const {
        data: locations,
        isLoading: isLoadingLocations
    } = useSelector((state: any) => state[REDUCER_REAL_ESTATE].companyLocations);
    const pagination = useSelector((state: any) => state[REDUCER_REAL_ESTATE].companyPagination);
    const setPagination = createAction(SET_COMPANY_PAGINATION);

    const fetchCompanyOfferItems = useApiRequest(REDUCER_REAL_ESTATE, 'offer-item/reality-company/:realityCompanyId', FETCH_COMPANY_OVERVIEW, {method: 'get'});
    const fetchCompanyLocations = useApiRequest(REDUCER_REAL_ESTATE, 'offer-item/reality-company/:realityCompanyId/locations', FETCH_COMPANY_LOCATIONS, {method: 'get'});

    const fetchCompanyResult = useCallback((isPaginationChange= false) => {
        if (isNotNilOrEmpty(companyId) && !isLoading) {
            fetchCompanyOfferItems({params: {realityCompanyId: companyId}, queryParams: {...pagination}});
            if (!isLoadingLocations && !isPaginationChange) {
                fetchCompanyLocations({params: {realityCompanyId: companyId}});
            }
        }
    }, [fetchCompanyOfferItems, fetchCompanyLocations, companyId, isLoading, isLoadingLocations, pagination]);

    useEffect(() => {
        fetchCompanyResult(true);
    }, [pagination?.page, pagination?.size, pagination?.sort]);

    return {
        companyId,
        fetchCompanyResult,
        locations,
        result: data,
        isLoading: isLoading || isLoadingLocations,
        pagination,
        setPagination,
    };
};

export default useCompanySearch;
