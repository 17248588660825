import Advertisement from '../../components/Advertisement';
import HowToAdvertise from '../../components/HowToAdvertise';
import Header from '../../components/Header';
import Layout from '../../layouts/AdministrationTemporary';
import React from 'react';
import { Box } from '@material-ui/core';
import './style.scss';
import { AdministrationMenuItems } from '../../utils/constant';


export default () => (
    <Box data-component="How_To_Advertise">
        <Layout menuItems={AdministrationMenuItems}>
            <Header />
            <Advertisement />
            <HowToAdvertise />
        </Layout>
    </Box>
);
