import {useEffect, useMemo, useRef, useState} from 'react';
import {deepEqual, defaultType, isFilterEmpty, isNilOrEmpty, isNotNilOrEmpty, removeDiacritics} from '../utils/helpers';
import {REDUCER_SEARCH} from '../utils/constant';
import {
    initPagination,
    SET_FILTER,
    SET_FILTER_PARAM,
    SET_PAGINATION
} from '../store/reducers/search';
import {useSelector} from 'react-redux';
import {createAction} from '../store/actions';
import {usePrevious} from './hooks';
import {ERoutePaths} from '../enumerators';
import useSavedSearch from './useSavedSearch';
import usePreviousFilter from './usePreviousFilter';
import useSellerSearch from './useSellerSearch';
import useSearch from './useSearch';
import {useFilterBuilder} from "./useFilterBuilder";
import {ISearchResultContent} from "../utils/types";
import useUrlPageNumber from "./useUrlPageNumber";
import useCodebooks from "./useCodebooks";
import {useScrollToTop} from "../components/Navigation/ScrollToTop";
import useCompanySearch from "./useCompanySearch";

const useFilter = () => {
    useCodebooks();

    const {
        sellerId,
        fetchSeller,
        locationsSeller,
        sellerResult,
        pagination: paginationSeller,
        setPagination: setPaginationSeller,
    } = useSellerSearch();

    const {
        searchResult,
        locationsResult,
        isLoadingSearch,
        fetchSearchResult,
        pagination: paginationSearch,
        setPagination: setPaginationSearch,
    } = useSearch();

    const {
        companyId,
        fetchCompanyResult,
        result: companyResult,
        locations: companyLocations,
        isLoading: isLoadingCompany,
        pagination: paginationCompany,
        setPagination: setPaginationCompany,
    } = useCompanySearch();

    const {savedSearch, savedSearchId} = useSavedSearch();

    const previousCompanyId = usePrevious(companyId);
    const previousSellerId = usePrevious(sellerId);
    const {previousFilter} = usePreviousFilter();

    const {filterFromUrl} = useFilterBuilder();
    const urlPageNumber = useUrlPageNumber();

    const filter = useSelector((state: any) => state[REDUCER_SEARCH].filter);
    const [prevFilter, setPrevFilter] = useState(null);

    const setFilterParam = createAction(SET_FILTER_PARAM);
    const setFilter = createAction(SET_FILTER);

    const [result, locations, isLoading, pagination, setPagination]: any[] = useMemo(() => {
        if (isNotNilOrEmpty(sellerId)) {
            return [sellerResult, locationsSeller, isLoadingSearch, paginationSeller, setPaginationSeller];
        } else if (isNotNilOrEmpty(companyId)) {
            return [companyResult, companyLocations, isLoadingCompany, paginationCompany, setPaginationCompany];
        } else {
            return [searchResult?.content, locationsResult, isLoadingSearch, paginationSearch, setPaginationSearch];
        }
    }, [sellerId, companyId, sellerResult, locationsSeller, isLoadingSearch, paginationSeller, searchResult, locationsResult, isLoadingSearch, paginationSearch, companyResult, companyLocations, isLoadingCompany, paginationCompany, setPaginationSeller, setPaginationSearch, setPaginationCompany]);

    useScrollToTop([pagination?.page, pagination?.size, pagination?.sort]);

    useEffect(() => {
        const urlFilter = filterFromUrl();
        if (!isFilterEmpty(urlFilter) && !deepEqual(urlFilter, filter) && isNilOrEmpty(savedSearchId)) {
            setFilter(urlFilter);
        }
    }, [filterFromUrl, filter]);

    useEffect(() => {
        if (isNilOrEmpty(filter) && isNotNilOrEmpty(previousFilter) && isFilterEmpty(prevFilter) && isNilOrEmpty(savedSearchId)) {
            setFilter(previousFilter);
        }
        if (isNilOrEmpty(filter.realEstateType) && isNotNilOrEmpty(filter)) {
            setFilterParam({realEstateType: defaultType});
        }
    }, [previousFilter]);

    useEffect(() => {
        if (isNotNilOrEmpty(savedSearchId) && isNotNilOrEmpty(savedSearch) && !isFilterEmpty(savedSearch)) {
            setFilter(savedSearch);
        }
    }, [savedSearchId, savedSearch]);

    useEffect(() => {
        if (urlPageNumber && urlPageNumber !== pagination.page) {
            const urlPagination = {
                page: urlPageNumber,
                size: pagination?.size,
                sort: pagination?.sort
            };
            setPagination(urlPagination);
        }
    }, [pagination?.page]);

    useEffect(() => {
        // @ts-ignore
        if (!isLoading && (filter !== prevFilter || sellerId !== previousSellerId || companyId !== previousCompanyId || isPaginationChange)) {
            if (isNotNilOrEmpty(sellerId)) {
                fetchSeller();
            } else if (isNotNilOrEmpty(companyId)) {
                fetchCompanyResult();
            } else if (isNotNilOrEmpty(filter)) {
                fetchSearchResult();
            }
            setPrevFilter(filter);
        }
    }, [filter, prevFilter, sellerId, companyId]);

    const linkToDetail = (offerItem: ISearchResultContent) => {
        const offerItemId = offerItem.extId;
        const offerType = offerItem.offerType?.urlFriendlyValue;
        const realEstateType = offerItem.realEstate.realEstateType?.urlFriendlyValue;
        const disposition = offerItem.realEstate.realEstateDisposition?.urlFriendlyValue;
        const address = removeDiacritics((offerItem.address.city + (offerItem.address.street ? ' ' + offerItem.address.street : '')).toLowerCase().replaceAll(' ', '-'));
        const baseUrl = `${ERoutePaths.DETAIL_BASE}${offerType}/${realEstateType}/${disposition}/${address}/`;
        if (isNotNilOrEmpty(companyId)) {
            return baseUrl + 'kancelar/' + companyId + '/id/' + offerItemId;
        } else if (isNotNilOrEmpty(sellerId)) {
            return baseUrl + 'predajca/' + sellerId + '/id/' + offerItemId;
        } else {
            return baseUrl + 'id/' + offerItemId;
        }
    };

    return {
        result,
        isLoading,
        locations,
        pagination,
        setPagination,
        linkToDetail
    };
};

export default useFilter;
