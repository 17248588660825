import {Box, Button, Grid, Paper, TextField} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import './style.scss';
import t from '../../texts';
import {isNilOrEmpty, onChangeStringHandler} from '../../utils/helpers';
import {SIGN_IN} from '../../store/reducers/auth';
import {authRequest} from '../../store/api';
import {
    RECAPTCHA_HEADER, REDUCER_CONTEXT
} from '../../utils/constant';
import {ERoutePaths} from '../../enumerators';
import {useHistory} from 'react-router-dom';
import SocialLogin from '../Login/SocialLogin';
import {useSelector} from "react-redux";
import Recaptcha from "../Recaptcha";

const Login = () => {
        const history = useHistory();
        const [username, setUsername] = useState('');
        const [password, setPassword] = useState('');
        const recaptchaToken = useSelector((state: any) => state[REDUCER_CONTEXT].recaptchaToken)

        const signIn = authRequest('login', SIGN_IN, {
            method: 'post', headers: {[RECAPTCHA_HEADER]: recaptchaToken}
        });

        const onSignIn = useCallback((event) => {
            event.preventDefault();
            if (isNilOrEmpty(username) && isNilOrEmpty(password)) {
                return;
            }
            signIn({body: {email: username, password}});
        }, [signIn, username, password]);

        return (
            <Box data-component="Login">
                <Paper elevation={1}>
                    <Box pt="2.3rem" pb="2rem" className="container">
                        <Box className="section">
                            <Grid container justify="center">
                                <Grid className="logo" item>
                                    <img src={process.env.PUBLIC_URL + '/images/logos/black-on-transparent.png'} width="128" height="32"
                                         alt="Slovenské reality" />
                                </Grid>
                                <Grid item>
                                    <h2>{t.HOMEPAGE_TITLE}</h2>
                                </Grid>
                            </Grid>

                            <Grid container justify="center">
                                <Grid item>
                                    <h3>{t.LOGIN}</h3>
                                </Grid>
                            </Grid>

                            <form onSubmit={onSignIn}>
                                <Box my="1.5rem">
                                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                                        <Grid className="left blueText" item xs={12} sm={2}>{t.USERNAME}</Grid>
                                        <Grid item xs={12} sm={10}>
                                            <TextField
                                                name="username"
                                                variant="outlined"
                                                size="small"
                                                value={username}
                                                onChange={onChangeStringHandler(setUsername)}
                                            />
                                        </Grid>
                                        <Grid className="left blueText" item xs={12} sm={2}>{t.PASSWORD}</Grid>
                                        <Grid item xs={12} sm={10}>
                                            <TextField
                                                type="password"
                                                name="password"
                                                variant="outlined"
                                                size="small"
                                                value={password}
                                                onChange={onChangeStringHandler(setPassword)}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className="loginButton"
                                            disabled={isNilOrEmpty(username) || isNilOrEmpty(password) || isNilOrEmpty(recaptchaToken)}
                                        >
                                            {t.SIGN_IN}
                                        </Button>
                                    </Grid>

                                    <SocialLogin />
                                    <Recaptcha actionName="login" />
                                </Grid>
                            </form>

                            <Box mt="3rem">
                                <Grid container justify="space-between">
                                    <Grid item>
                                        <Button
                                            className="textButton"
                                            onClick={() => history.push(ERoutePaths.REGISTER)}
                                        >
                                            {t.REGISTER}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            className="textButton"
                                            onClick={() => history.push(ERoutePaths.FORGOTTEN_PASSWORD)}
                                        >
                                            {t.LOST_PASSWORD}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        )
            ;
    }
;

export default Login;
