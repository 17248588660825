import Header from '../../components/Header';
import Layout from '../../layouts/Login';
import React from 'react';
import { Box } from '@material-ui/core';
import './style.scss';
import ForgottenPassword from '../../components/ForgottenPassword';

export default () => (
    <Box data-component="Login_Page">
        <Layout>
            <Header />
            <ForgottenPassword />
        </Layout>
    </Box>
);
