import React, {useEffect} from 'react';
import cx from 'classnames';
import {Grid} from '@material-ui/core';
import ResultsList from '../../components/ResultsList';
import ResultMap from '../../components/ResultsMap';
import Layout from '../../layouts/Stretched';
import {useSelector} from 'react-redux';
import {REDUCER_CONTEXT} from '../../utils/constant';
import {ERouteParameters, EViews} from '../../enumerators';
import useFilter from '../../hooks/useFilter';
import {initPagination} from "../../store/reducers/search";
import {useLocation} from "react-router-dom";

export default () => {
    const location = useLocation();

    const {isCollapsedView} = useSelector((state: any) => state[REDUCER_CONTEXT]);
    const {result, locations, isLoading, pagination, setPagination, linkToDetail} = useFilter();

    useEffect(() => {
        // @ts-ignore
        setPagination(location.state?.[ERouteParameters.ORIGINAL_PAGINATION] || initPagination);
    }, []);

    return (
        <Layout>
            <Grid
                id="results"
                className={cx('map', {collapsedSection: isCollapsedView})}
                item
                md={12}
                lg={isCollapsedView ? 4 : 7}
            >
                <ResultMap visibleFor={EViews.MOBILE} locations={locations} linkToDetail={linkToDetail}/>

                <ResultsList result={result} isLoading={isLoading} linkToDetail={linkToDetail}
                             pagination={pagination} setPagination={setPagination}/>
            </Grid>

            <ResultMap sizeOfMapContainer={8} visibleFor={EViews.DESKTOP} locations={locations}
                       linkToDetail={linkToDetail}/>
        </Layout>
    );
};
