import React from 'react';
import {Route} from 'react-router-dom';
import Recaptcha from "../Recaptcha";
import {useSelector} from "react-redux";
import {REDUCER_CONTEXT} from "../../utils/constant";

const MyRoute = ({component: Component, name, ...rest}: { component: any, name: string, path: string | string[] }) => {
    const recaptchaToken = useSelector((state: any) => state[REDUCER_CONTEXT].recaptchaToken);

    return (
        <>
            <Recaptcha actionName={name} />
            <Route
                {...rest}
                render={props => (!!recaptchaToken ? <Component {...props} /> : null)}
            />
        </>
    )
}

export default MyRoute;
