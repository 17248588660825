import Advertisement from '../../components/Advertisement';
import Header from '../../components/Header';
import Layout from '../../layouts/AdministrationTemporary';
import React from 'react';
import { Box } from '@material-ui/core';
import './style.scss';
import Contacts from '../../components/Contacts';
import { AdministrationMenuItems } from '../../utils/constant';


export default () => (
    <Box data-component="Contacts_Page">
        <Layout menuItems={AdministrationMenuItems}>
            <Header />
            <Advertisement />
            <Contacts />
        </Layout>
    </Box>
);
