import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}
export const scrollTo = (ref:any, offset?: number) => {
    window.scrollTo({
            top: ref.offsetTop + offset,
            left: 0,
            behavior: "smooth",
    });
}

const ScrollToTop = () => {
    const history = useHistory();

    useEffect(() => {
        const event = history.listen(() => {
            scrollToTop();
        });
        return () => event();
    }, []);

    return null;
};

export const useScrollToTop = (dependencies: any[]) => useEffect(() => {
    scrollToTop();
}, dependencies);


export default ScrollToTop;
