import React from 'react';
import { Box, FormControl, Grid, Paper } from '@material-ui/core';
import Advertisement from '../../components/Advertisement';
import Header from '../../components/Header';
import Layout from '../../layouts/NewAdvert';
import { length } from 'ramda';
import './style.scss';
import t from '../../texts';
import { CurrencyInputComp, SelectCodebookComp, TextInputComp } from '../../components/Form';
import { withCodebook, withState } from '../../components/Form/InputHOC';
import { OFFER_TYPE, REDUCER_ADVERT } from '../../utils/constant';
import { SET_OFFER_ITEM_PROPERTY } from '../../store/reducers/advert';
import { useApiDataSelector } from '../../hooks/hooks';
import { isNotNilOrEmpty } from '../../utils/helpers';

const SelectCodebook = withCodebook(SelectCodebookComp, 'offerItem', SET_OFFER_ITEM_PROPERTY);
const TextInput = withState(TextInputComp, 'offerItem', SET_OFFER_ITEM_PROPERTY);
const CurrencyInput = withState(CurrencyInputComp, 'offerItem', SET_OFFER_ITEM_PROPERTY);

const AdvertInformation = () => {
    const offerItem = useApiDataSelector((state: any) => state[REDUCER_ADVERT].offerItem);

    return (
        <Layout>
            <Header />
            <Advertisement />

            <Box data-component="AdvertInformation">
                <h3>{t.STEP_INFO}</h3>
                <Paper elevation={0}>
                    <Grid container spacing={1} justify="flex-end" alignItems="center">
                        <Grid className="left blueText" item xs={12} sm={5}>{t.ADVERT_TYPE}</Grid>
                        <Grid item xs={12} sm={7}>
                            <FormControl variant="outlined" size="small">
                                <SelectCodebook
                                    codebookName={OFFER_TYPE}
                                    property="offerType"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} justify="flex-end" alignItems="center">
                        <Grid className="left blueText" item xs={12} sm={5}>{t.PRICE}</Grid>
                        <Grid item xs={12} sm={7}>
                            <CurrencyInput label={t.PROPERTY_PRICE} property="price" required />
                        </Grid>
                        <Grid className="left blueText" item xs={12} sm={5}>{t.PRICE_COMMENT}</Grid>
                        <Grid item xs={12} sm={7}>
                            <TextInput label={t.PRICE_COMMENT} property="priceInfo" />
                        </Grid>
                    </Grid>
                </Paper>

                <h3>Popis</h3>
                <Paper elevation={0}>
                    <Grid id="description" container spacing={1} alignItems="center">
                        <Grid
                            className="right blueText charRemains"
                            item
                            xs={12}
                        >
                            Ostáva
                            {' '}
                            {1000 - (isNotNilOrEmpty(offerItem?.description) ? length(offerItem.description) : 0)}
                            {' '}
                            znakov
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput label={t.DESCRIPTION} property="description" multiline rows={6} />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </Layout>
    );
};

export default AdvertInformation;
