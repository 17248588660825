import {Avatar, Box, Button, Grid} from '@material-ui/core';
import React, {useCallback, useEffect} from 'react';
import './style.scss';
import {uploadFile, useApiRequest} from '../../store/api';
import {
    GET_MY_AGENCY_USERS,
    UPDATE_MY_AGENCY,
    UPLOAD_REALITY_COMPANY_PHOTO
} from '../../store/reducers/realEstate';
import {AGENCY_SECTIONS, REDUCER_REAL_ESTATE} from '../../utils/constant';
import {RealEstateCompanyUser} from '../../utils/types';
import PageableUserChips from '../EstateAgencyAdministration/PageableUserChips';
import {useApiDataSelector, usePrevious} from '../../hooks/hooks';
import t from '../../texts';
import AddUserDialog from '../EstateAgencyAdministration/AddUserDialog';
import EstateAgencyForm from '../EstateAgencyAdministration/EstateAgencyForm';
import {isNotNilOrEmpty} from '../../utils/helpers';
import useAgency from '../../hooks/useAgency';
import {Alert} from '@material-ui/lab';
import Dropzone from "../Dropzone/Dropzone";
import {fileToFormData} from "../../utils/imageUtils";
import {head} from "ramda";
import AgencyAdvertList from "./AgencyAdvertList";
import ImportAdvertForm from "./ImportAdvertForm";

interface IProps {
    activeSection: string,
}

const EstateAgencyAdministration = ({activeSection = AGENCY_SECTIONS.AGENCIES_ADMINISTRATION}: IProps) => {
    const {myAgency} = useAgency();

    const updateMyAgency = useApiRequest(REDUCER_REAL_ESTATE, 'reality-company/:realityCompanyId', UPDATE_MY_AGENCY, {method: 'put'});
    const getMyAgencyUsers = useApiRequest(REDUCER_REAL_ESTATE, 'reality-company/:realityCompanyId/users', GET_MY_AGENCY_USERS, {method: 'get'});
    const uploadAgencyPicture = uploadFile(REDUCER_REAL_ESTATE, 'reality-company/:realityCompanyId/logo', UPLOAD_REALITY_COMPANY_PHOTO, {method: 'put'});
    // const getUsersWithoutAgency = useApiRequest(REDUCER_USER, 'user/no-reality-company', GET_NO_AGENCY_USERS, { method: 'get' });

    const agencyUsers: Array<RealEstateCompanyUser> = useApiDataSelector((state: any) => state[REDUCER_REAL_ESTATE].myRealEstateAgencyUsers);
    const prevAgency = usePrevious(myAgency);

    useEffect(() => {
        // @ts-ignore
        if (isNotNilOrEmpty(myAgency?.extId) && myAgency.extId !== prevAgency?.extId) {
            getMyAgencyUsers({params: {realityCompanyId: myAgency.extId}});
        }
    }, [myAgency, prevAgency]);

    const updateAgency = useCallback((form) => {
        updateMyAgency({
            params: {realityCompanyId: myAgency?.extId},
            body: {...myAgency, ...form}
        });
    }, [updateMyAgency, myAgency]);

    const upload = async (images: any) => {
        if (isNotNilOrEmpty(images)) {
            const formData = await fileToFormData(head(images));
            uploadAgencyPicture({
                params: {realityCompanyId: myAgency?.extId},
                body: formData
            });
        }
    }

    return (
        <Box data-component="EstateAgencyAdministration">
            <Box className="container">
                {activeSection === AGENCY_SECTIONS.AGENCIES_ADMINISTRATION && (
                    <Box className="section">
                        <h2>{t.ADMIN_AGENCY}</h2>
                        <Box className="right" style={{marginBottom: 25}}>
                            <EstateAgencyForm updateAgency={updateAgency}/>
                        </Box>

                        <Grid container spacing={3} justify="center">
                            <Grid item sm={12} md={7}>
                                <Grid id="myAccountTable" className="left" container spacing={2}>
                                    <Grid className="blueText" item xs={12} sm={5}>{t.TITLE}</Grid>
                                    <Grid item xs={12} sm={7}>{myAgency?.companyName}</Grid>

                                    <Grid className="blueText" item xs={12} sm={5}>{t.DESCRIPTION}</Grid>
                                    <Grid item xs={12} sm={7}>{myAgency?.companyInfo}</Grid>

                                    <Grid className="blueText" item xs={12} sm={5}>{t.EMAIL}</Grid>
                                    <Grid item xs={12} sm={7}>{myAgency?.email}</Grid>

                                    <Grid className="blueText" item xs={12} sm={5}>{t.PHONE}</Grid>
                                    <Grid item xs={12} sm={7}>{myAgency?.phone}</Grid>

                                    <Grid className="blueText" item xs={12} sm={5}>{t.WEBSITE}</Grid>
                                    <Grid item xs={12} sm={7}>{myAgency?.website}</Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12} md={5}>
                                <Box justifyContent="space-around" display="flex" style={{marginBottom: 10}}>
                                    <Dropzone maxFiles={1} onUpload={upload}>
                                        <Box display="flex" flexDirection="column" alignItems="center"
                                             justifyContent="space-between">
                                            <Avatar src={myAgency?.companyLogo?.url} alt="agency-logo"
                                                    style={{width: '150px', height: '150px'}}/>
                                            <Button variant="text" color="primary"
                                                    style={{width: 250, fontSize: 12}}>{t.UPLOAD_ADVICE}</Button>
                                        </Box>
                                    </Dropzone>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {activeSection === AGENCY_SECTIONS.AGENT_LIST && (
                    <>
                        <Box className="right">
                            <AddUserDialog/>
                        </Box>
                        <h2>{t.AGENT_LIST}</h2>
                        {agencyUsers && agencyUsers.length === 0 &&
                            <Alert className="alert" severity="info">{t.NO_RESULT_MY_AGENTS}</Alert>}
                        <PageableUserChips
                            realityCompanyId={myAgency?.extId}
                            data={agencyUsers}
                            pageItemCount={6}
                            rowItemSize={6}
                            visible={agencyUsers && agencyUsers.length > 0}
                        />
                    </>
                )}
                {activeSection === AGENCY_SECTIONS.AGENCIES_ADVERTS && (
                    <Box className="section">
                        <h2>{t.AGENCIES_ADVERTS}</h2>
                        <AgencyAdvertList companyId={myAgency.extId}/>
                    </Box>
                )}
                {activeSection === AGENCY_SECTIONS.ADVERT_IMPORT && (
                    <Box className="section">
                        <h2>{t.ADVERT_IMPORT}</h2>
                        <ImportAdvertForm/>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default EstateAgencyAdministration;
