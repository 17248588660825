import React, {useCallback, useEffect, useState} from 'react';
import {Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@material-ui/core";
import t from "../../texts";
import {withState, withValues} from "../Form/InputHOC";
import {SelectInputComp, TextInputComp} from "../Form";
import {
    CLEAR_IMPORT_FORM_ERROR,
    CREATE_IMPORT_CONFIGURATION,
    GET_IMPORT_CONFIGURATION,
    initImportForm,
    RUN_IMPORT_CONFIGURATION,
    SET_IMPORT_FORM_ERROR,
    SET_IMPORT_PROPERTY,
    UPDATE_IMPORT_CONFIGURATION
} from "../../store/reducers/realityCompany";
import {
    IMPORT_STATUS,
    ImportTypeCodebook,
    REDUCER_REAL_ESTATE,
    REDUCER_REALITY_COMPANY
} from "../../utils/constant";
import {useApiRequest} from "../../store/api";
import useAgency from "../../hooks/useAgency";
import {createAction} from "../../store/actions";
import {useApiDataSelector, usePrevious} from "../../hooks/hooks";
import {editUserValidation, importFormValidation, validateForm} from "../Form/validation";
import {IUser} from "../../utils/types";
import {SET_FORM_ERROR} from "../../store/reducers/advert";
import {isNilOrEmpty, isNotNilOrEmpty} from "../../utils/helpers";
import {equals} from "ramda";
import {useSelector} from "react-redux";

const TextInput = withState(TextInputComp, 'importForm', SET_IMPORT_PROPERTY, REDUCER_REALITY_COMPANY);
const SelectInput = withValues(SelectInputComp, 'importForm', SET_IMPORT_PROPERTY, REDUCER_REALITY_COMPANY);

const ImportAdvertForm = () => {
    const {myAgency} = useAgency();
    const [isChanged, setIsChanged] = useState(false);

    const setImportProperty = createAction(SET_IMPORT_PROPERTY);
    const setFormErrors = createAction(SET_IMPORT_FORM_ERROR);
    const clearImportFormErrors = createAction(CLEAR_IMPORT_FORM_ERROR);

    const importForm = useApiDataSelector((state: any) => state[REDUCER_REALITY_COMPANY].importForm);

    const createImport = useApiRequest(REDUCER_REAL_ESTATE, 'reality-company/:realityCompanyId/import-configuration', CREATE_IMPORT_CONFIGURATION, {method: 'post'})
    const updateImport = useApiRequest(REDUCER_REAL_ESTATE, 'reality-company/:realityCompanyId/import-configuration/:importConfigurationId', UPDATE_IMPORT_CONFIGURATION, {method: 'put'})
    const getImport = useApiRequest(REDUCER_REAL_ESTATE, 'reality-company/:realityCompanyId/import-configuration', GET_IMPORT_CONFIGURATION, {method: 'get'})
    const runImport = useApiRequest(REDUCER_REAL_ESTATE, 'reality-company/:realityCompanyId/import-configuration/:importConfigurationId/run', RUN_IMPORT_CONFIGURATION, {method: 'post'})

    const prevImportForm = usePrevious(importForm);

    useEffect(() => {
        if (!importForm?.extId) {
            getImport({params: {realityCompanyId: myAgency.extId}});
        }
    }, []);

    useEffect(() => {
        if (prevImportForm !== undefined && !equals(importForm, prevImportForm) && !equals(initImportForm?.data, prevImportForm)) {
            try {
                validateForm(importFormValidation, importForm, setErrors, clearImportFormErrors)
            } catch (e) {
                console.error(e);
            }
            setIsChanged(true);
        }
    }, [importForm]);

    const onImportAdvert = useCallback(() => {
        if (!!importForm?.extId) {
            updateImport({
                params: {realityCompanyId: myAgency.extId, importConfigurationId: importForm.extId},
                body: {...importForm, type: importForm.type?.code}
            });
        } else {
            createImport({
                params: {realityCompanyId: myAgency.extId},
                body: {...importForm, type: importForm.type?.code}
            });
        }
    }, [createImport, importForm, myAgency]);

    const setErrors = useCallback((error: any) => {
        setFormErrors(error);
    }, [setFormErrors]);

    const onSubmit = useCallback(async (event, method) => {
        event?.preventDefault();
        try {
            await validateForm(importFormValidation, importForm, setErrors, clearImportFormErrors);
            method();
        } catch (e) {
            console.error(e);
        }
    }, [importForm, setErrors, validateForm, clearImportFormErrors]);


    const onRunImport = useCallback(() => {
        runImport({params: {realityCompanyId: myAgency.extId, importConfigurationId: importForm.extId}});
    }, [runImport]);

    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12} sm={6}>
                <Grid className="noMargin" container spacing={1} justify="center" alignItems="center">
                    <Grid item xs={12}>
                        {/*<SelectInput fullWidth values={ImportStatusCodebook} currentValue={importForm.status} required
                                     label={t.IMPORT_STATUS} property="status"/>*/}

                        <FormControl component="fieldset">
                            <FormLabel component="legend" required>{t.IMPORT_STATUS}</FormLabel>
                            <RadioGroup aria-label="status" name="status" value={importForm?.status}
                                        row onChange={({target: {value}}) => setImportProperty({status: value})}>
                                <FormControlLabel checked={importForm?.status === IMPORT_STATUS.ACTIVE}
                                                  value={IMPORT_STATUS.ACTIVE}
                                                  control={<Radio/>} label={t.ACTIVE}/>
                                <FormControlLabel checked={importForm?.status === IMPORT_STATUS.DEACTIVATED}
                                                  value={IMPORT_STATUS.DEACTIVATED}
                                                  control={<Radio/>} label={t.DEACTIVATED}/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <SelectInput fullWidth values={ImportTypeCodebook}
                                     required
                                     label={t.IMPORT_TYPE}
                                     property="type"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput fullWidth label={t.AUTH_KEY} property="authKey" required/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput fullWidth label={t.AUTH_SECRET} property="authSecret" required/>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: 20, textAlign: "right"}}>
                        <Grid container justify="space-between">
                            <Grid>

                                <Button variant="contained" color="primary"
                                        disabled={!isChanged}
                                        type="submit" onClick={(e) => onSubmit(e, onImportAdvert)}>{t.SAVE}</Button>
                            </Grid>
                            <Grid>
                                <Button variant="contained" color="secondary"
                                        disabled={isNilOrEmpty(importForm?.extId)}
                                        type="submit" onClick={e => onSubmit(e, onRunImport)}>{t.RUN_IMPORT}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ImportAdvertForm;
