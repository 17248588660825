import { combineReducers } from 'redux';
import { reducer as authReducers, initialState as authInitialState } from './reducers/auth';
import { reducer as contextReducer, initialState as contextInitialState } from './reducers/context';
import { reducer as codebookReducer, initialState as codebookInitialState } from './reducers/codebook';
import { reducer as imagesReducer, initialState as imagesInitialState } from './reducers/images';
import { reducer as searchReducer, initialState as searchInitialState } from './reducers/search';
import { reducer as advertReducer, initialState as advertInitialState } from './reducers/advert';
import { reducer as userReducer, initialState as userInitialState } from './reducers/user';
import { reducer as realEstateReducer, initialState as realEstateInitialState } from './reducers/realEstate';
import { reducer as offerItemReducer, initialState as offerItemInitialState } from './reducers/offerItem';
import {
    reducer as realityCompanyReducer, initialState as realityCompanyInitialState
} from './reducers/realityCompany';

const rootReducer = combineReducers({
    advert: advertReducer,
    auth: authReducers,
    codebook: codebookReducer,
    context: contextReducer,
    images: imagesReducer,
    search: searchReducer,
    realEstate: realEstateReducer,
    realityCompany: realityCompanyReducer,
    offerItem: offerItemReducer,
    user: userReducer
});
const initialStateCombined = {
    advert: advertInitialState,
    auth: authInitialState,
    codebook: codebookInitialState,
    context: contextInitialState,
    images: imagesInitialState,
    search: searchInitialState,
    realEstate: realEstateInitialState,
    realityCompany: realityCompanyInitialState,
    offerItem: offerItemInitialState,
    user: userInitialState
};

export { rootReducer, initialStateCombined };
