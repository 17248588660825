import {AppBar, Badge, Box, Button, Toolbar} from '@material-ui/core';
import t from '../../texts';
import {ERouteParameters, ERoutePaths, OfferItemStatus, USER_PRIVILEGES} from '../../enumerators';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import './style.scss';
import {authRequest, useApiRequest} from '../../store/api';
import {LOG_OUT} from '../../store/reducers/auth';
import {useSelector} from 'react-redux';
import {
    ADMIN_SECTIONS,
    REDUCER_AUTH,
    REDUCER_REAL_ESTATE,
    REDUCER_REALITY_COMPANY,
    REDUCER_USER
} from '../../utils/constant';
import {Bell} from "react-feather";
import NotificationsDialog from "./NotificationsDialog";
import {useApiDataSelector} from "../../hooks/hooks";
import {FETCH_MY_OFFER_ITEMS_ELEMENTS} from "../../store/reducers/realEstate";
import {Notification} from "../../utils/types";
import {equals, none} from "ramda";

const Header = () => {
    const history = useHistory();

    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

    const notificationButtonRef = useRef();

    const currentUser = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser);
    const isAuthenticated = useSelector((state: any) => state[REDUCER_AUTH].isAuthenticated);
    const logoutUser = authRequest('logout', LOG_OUT, {method: 'post'});
    const {
        isLoading: isLoadingMyItems
    } = useSelector((state: any) => state[REDUCER_REAL_ESTATE].myOfferItems);
    const myOfferItemsCounter = useSelector((state: any) => state[REDUCER_REAL_ESTATE].myOfferItemsCounter);

    const fetchMyOfferItemsCounter = useApiRequest(REDUCER_REAL_ESTATE, 'offer-item/search/user/:userId', FETCH_MY_OFFER_ITEMS_ELEMENTS, {method: 'post'});

    const {
        data: realityCompanyUser,
    } = useSelector((state: any) => state[REDUCER_REALITY_COMPANY].user);

    const addNotification = (notification: Notification) => {
        if (none(n => equals(n.text, notification.text), notifications)) {
            setNotifications([...notifications, notification]);
        }
    }

    useEffect(() => {
        if (!!currentUser && !currentUser?.emailVerified) {
            addNotification({
                text: t.NON_VERIFIED_EMAIL_USER,
                onPress: () => history.push(ERoutePaths.ACCOUNT, {
                    state: {[ERouteParameters.ADMIN_ACTIVE_SECTION]: ADMIN_SECTIONS.MY_ACCOUNT}
                })
            });
        }
    }, []);

    useEffect(() => {
        if (!isLoadingMyItems && !!currentUser?.extId) {
            fetchMyOfferItemsCounter({
                params: {userId: currentUser?.extId},
                body: {status: OfferItemStatus.EDIT},
                queryParams: {
                    page: 0,
                    size: 10,
                    sort: "publishedAt,DESC"
                }
            });
        }
    }, [currentUser]);

    useEffect(() => {
        if (myOfferItemsCounter > 0) {
            addNotification({
                text: t.NOTIFICATION_EDIT_OFFERS(myOfferItemsCounter),
                onPress: () => history.push(ERoutePaths.ACCOUNT, {
                    state: {[ERouteParameters.ADMIN_ACTIVE_SECTION]: ADMIN_SECTIONS.MY_ADVERT}
                })
            });
        }
    }, [myOfferItemsCounter]);

    const logout = useCallback(() => {
        if (isAuthenticated) {
            logoutUser().then(() => history.push(ERoutePaths.HOME));
        }
    }, [logoutUser]);

    return (
        <AppBar data-component="Header" className="App">
            <Toolbar>
                <Box>
                    <Link to={ERoutePaths.HOME}>
                        <img src={process.env.PUBLIC_URL + '/images/logos/original-on-transparent.png'} width="128"
                             height="32"
                             alt="skreality.sk logo"/>
                    </Link>
                </Box>

                {isAuthenticated
                    ? (
                        <>
                            <Button innerRef={notificationButtonRef} color="inherit"
                                    onClick={() => notifications?.length > 0 && setIsNotificationsOpen(!isNotificationsOpen)}>
                                <Badge badgeContent={notifications?.length} color="secondary" anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}>
                                    <Bell/>
                                </Badge>
                            </Button>

                            <Link to={ERoutePaths.ACCOUNT}>
                                <Button color="inherit">
                                    {t.MY_ACCOUNT}
                                </Button>
                            </Link>

                            {USER_PRIVILEGES.COMPANY_ADMIN === realityCompanyUser?.role &&
                                <Link to={ERoutePaths.ESTATE_AGENCY_ADMINISTRATION}>
                                    <Button color="inherit">
                                        {t.REALITY_COMPANY_ACCOUNT}
                                    </Button>
                                </Link>
                            }
                            <Button color="inherit" onClick={logout}>
                                {t.LOGOUT_BUTTON}
                            </Button>
                        </>
                    ) : (
                        <Link to={ERoutePaths.ACCOUNT}>
                            {t.LOGIN_BUTTON}
                        </Link>
                    )}
            </Toolbar>
            {!!notificationButtonRef?.current && (
                <NotificationsDialog
                    reference={notificationButtonRef?.current}
                    notifications={notifications}
                    isOpen={isNotificationsOpen}
                    onClose={() => setIsNotificationsOpen(false)}
                />
            )}
        </AppBar>
    );
};

export default Header;
