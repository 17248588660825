import React, {useCallback, useEffect, useState} from 'react';
import {createAction, fetchCodebook} from '../../store/actions';
import {ComponentFilterParams, ICodebookItem} from '../../utils/types';
import {checkboxCodebookHandler, isNilOrEmpty, isNotArray, isNotNilOrEmpty} from '../../utils/helpers';
import {SET_FILTER_PARAM} from '../../store/reducers/search';
import {useSelector} from 'react-redux';
import {REDUCER_CODEBOOK, REDUCER_SEARCH} from '../../utils/constant';
import {isFunction} from "lodash";

export const withFilter = (Component: any) => ({
                                                   label,
                                                   filter,
                                                   ...rest
                                               }: { label: string, filter: string, fullWidth?: boolean, min?: number, max?: number }) => {
    const {[filter]: value} = useSelector((state: any) => state[REDUCER_SEARCH].filter);
    const [localValue, setLocalValue] = useState();
    const setFilterParam = createAction(SET_FILTER_PARAM);

    useEffect(() => {
        if (isNotNilOrEmpty(value) && value !== localValue) {
            setLocalValue(value);
        }
    }, []);

    const onSave = useCallback(() => {
        if (isNotNilOrEmpty(localValue) && localValue !== value) {
            setFilterParam({[filter]: localValue});
        }
    }, [setFilterParam, localValue, value]);

    return (
        <Component
            onChange={setLocalValue}
            onBlur={onSave}
            label={label}
            currentValue={localValue}
            {...rest}
        />
    );
};

export const withFilterOnChange = (Component: any) => ({label, filter, ...rest}: { label: string, filter: string }) => {
    const {[filter]: value} = useSelector((state: any) => state[REDUCER_SEARCH].filter);
    const setFilterParam = createAction(SET_FILTER_PARAM);

    useEffect(() => {
        if (isNilOrEmpty(value)) {
            return;
        }
        const payload = {[filter]: value};
        setFilterParam(payload);
    }, [value]);

    return (
        <Component
            onChange={(newValue: any) => setFilterParam({[filter]: newValue})}
            label={label}
            currentValue={value}
            {...rest}
        />
    );
};

export const withCodebookFilter = (Component: any) => ({
                                                           codebookName,
                                                           filter,
                                                           selector = codebookName,
                                                           onChange,
                                                           ...rest
                                                       }: ComponentFilterParams) => {
    const {[filter]: value} = useSelector((state: any) => state[REDUCER_SEARCH].filter);
    const setFilterParam = createAction(SET_FILTER_PARAM);
    const fetch = fetchCodebook(codebookName);
    const values = useSelector((state: any) => state[REDUCER_CODEBOOK][selector]);

    useEffect(() => {
        if (isNilOrEmpty(values)) {
            fetch();
        }
    }, []);

    if (isNilOrEmpty(values) || isNotArray(values)) {
        return null;
    }
    return (
        <Component
            onChange={(newValue: any) => {
                isFunction(onChange)
                    ? onChange(newValue)
                    : setFilterParam({[filter]: newValue});
            }}
            values={values}
            currentValue={value}
            {...rest}
        />
    );
};

export const withCodebookValuesFilter = (Component: any) => ({
                                                                 codebookName,
                                                                 filter,
                                                                 selector = codebookName,
                                                                 ...rest
                                                             }: ComponentFilterParams) => {
    const {[filter]: value} = useSelector((state: any) => state[REDUCER_SEARCH].filter);
    const setFilterParam = createAction(SET_FILTER_PARAM);
    const fetch = fetchCodebook(codebookName);
    const values = useSelector((state: any) => state[REDUCER_CODEBOOK][selector]);

    useEffect(() => {
        if (isNilOrEmpty(values)) {
            fetch();
        }
    }, []);

    const onChange = (item: ICodebookItem) => {
        checkboxCodebookHandler(item, (newValue: any[]) => setFilterParam({[filter]: newValue}), value);
    };

    if (isNilOrEmpty(values) || isNotArray(values)) {
        return null;
    }

    return <Component onChange={onChange} values={values} currentValue={value} {...rest} />;
};
