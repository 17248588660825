import { Box, Card, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import { ERoutePaths } from '../../enumerators';
import React, { useEffect } from 'react';
import { REAL_ESTATE_TYPE, REDUCER_CODEBOOK, REDUCER_SEARCH } from '../../utils/constant';
import { ICodebookItem } from '../../utils/types';
import './style.scss';
import { createAction, fetchCodebook } from '../../store/actions';
import { isArray, isNilOrEmpty, isNotNilOrEmpty } from '../../utils/helpers';
import { filterInitialState, SET_FILTER } from '../../store/reducers/search';
import t from '../../texts';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usePreviousFilter from '../../hooks/usePreviousFilter';

const RealEstateType = () => {
    const history = useHistory();
    const { setPreviousFilter } = usePreviousFilter();

    const filter = useSelector((state: any) => state[REDUCER_SEARCH].filter);
    const setFilter = createAction(SET_FILTER);

    const fetchRealEstateTypes = fetchCodebook(REAL_ESTATE_TYPE);
    // @ts-ignore
    const realEstateTypes = useSelector(state => state[REDUCER_CODEBOOK][REAL_ESTATE_TYPE]);

    useEffect(() => {
        if (isNilOrEmpty(realEstateTypes)) {
            fetchRealEstateTypes();
        }
    }, []);

    const selectType = (item: ICodebookItem) => {
        setFilter({ ...filterInitialState, realEstateType: item });
        setPreviousFilter(filter);
        history.push(ERoutePaths.SEARCH + `/${item?.urlFriendlyValue}`);
    };

    return (
        <Box data-component="RealEstateType">
            <h3>{t.REAL_ESTATE_TYPE}</h3>

            <Grid container justify="center" spacing={2}>
                {isNotNilOrEmpty(realEstateTypes) && isArray(realEstateTypes) && realEstateTypes.map((item: ICodebookItem) => {
                    const { code, value } = item;
                    return (
                        <Grid key={code} item xs={12} sm={6}>
                            <Card onClick={() => selectType(item)}>
                                <CardMedia
                                    component="img"
                                    image={process.env.PUBLIC_URL + `/images/${code}.png`}
                                    title={value}
                                    alt={value}
                                />

                                <CardContent>
                                    <Typography component="h4">{value}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default RealEstateType;
