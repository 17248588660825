import {Avatar, Box, Button, Grid} from "@material-ui/core";
import t from "../../texts";
import {Alert, AlertTitle} from "@material-ui/lab";
import UserForm from "./UserForm";
import Dropzone from "../Dropzone/Dropzone";
import React, {useState} from "react";
import {ERoutePaths} from "../../enumerators";
import {createAction} from "../../store/actions";
import {COOKIE_DIALOG, SET_SECTION} from "../../store/reducers/context";
import {uploadFile, useApiRequest} from "../../store/api";
import {ADMIN_SECTIONS, REDUCER_USER} from "../../utils/constant";
import {RESEND_VERIFICATION_EMAIL, UPLOAD_USER_PHOTO} from "../../store/reducers/user";
import {useHistory} from "react-router-dom";
import {fileToFormData} from "../../utils/imageUtils";
import {useApiDataSelector} from "../../hooks/hooks";
import {isNotNilOrEmpty} from "../../utils/helpers";
import {head} from "ramda";

const UserInfo = () => {
    const {push} = useHistory();

    const currentUser = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser);

    const resendVerificationEmail = useApiRequest(REDUCER_USER, 'user/resend-confirmation', RESEND_VERIFICATION_EMAIL, {method: 'post'});
    const uploadUserPhoto = uploadFile(REDUCER_USER, 'user/me/picture', UPLOAD_USER_PHOTO, {method: 'put'});

    const openCloseCookieDialog = createAction(COOKIE_DIALOG);
    const setSection = createAction(SET_SECTION);
    const [edit, setEdit] = useState(false);

    const upload = async (images: any) => {
        if (isNotNilOrEmpty(images)) {
            const formData = await fileToFormData(head(images));
            setSection(ADMIN_SECTIONS.MY_ACCOUNT);
            uploadUserPhoto({
                body: formData
            });
        }
    }

    const handleEditButtonClick = () => {
        setEdit(!edit);
    };

    const handleChangePassword = () => {
        push(ERoutePaths.CHANGE_PASSWORD);
    };

    return (
        <Box className="section">
            <h2>{t.MY_ACCOUNT}</h2>
            <Box className="myAccountButtons">
                <Button
                    className="editButton"
                    variant="outlined"
                    color="primary"
                    onClick={handleEditButtonClick}
                >
                    {t.EDIT_USER_INFO}
                </Button>
                <Button
                    className="editButton"
                    variant="outlined"
                    color="primary"
                    onClick={handleChangePassword}
                    disabled={currentUser?.provider !== 'LOCAL'}
                >
                    {t.CHANGE_PASSWORD}
                </Button>
                <Button
                    className="editButton"
                    variant="outlined"
                    color="primary"
                    onClick={() => openCloseCookieDialog(true)}
                >
                    {t.COOKIES_SETTINGS_MODAL}
                </Button>
            </Box>
            {!currentUser?.emailVerified && <Box margin="20px 0">
                <Alert
                    className="text-left"
                    action={(
                        <Button
                            variant="outlined"
                            color="inherit"
                            size="medium"
                            onClick={resendVerificationEmail}
                        >
                            {t.SEND_AGAIN}
                        </Button>
                    )}
                    severity="error"
                >
                    <AlertTitle>{t.NON_VERIFIED_EMAIL}</AlertTitle>
                    {t.NON_VERIFIED_EMAIL_TEXT}
                </Alert>
            </Box>
            }
            <Grid container spacing={3} justify="center">
                <Grid item sm={12} md={7}>
                    {!edit && (
                        <Grid id="myAccountTable" className="left" container spacing={2}>

                            <Grid className="blueText" item xs={12} sm={5}>{t.NAME}</Grid>
                            <Grid item xs={12} sm={7}>{currentUser?.firstName}</Grid>

                            <Grid className="blueText" item xs={12} sm={5}>{t.SURNAME}</Grid>
                            <Grid item xs={12} sm={7}>{currentUser?.lastName}</Grid>

                            <Grid className="blueText" item xs={12} sm={5}>{t.PHONE}</Grid>
                            <Grid item xs={12} sm={7}>{currentUser?.phone}</Grid>

                            <Grid className="blueText" item xs={12} sm={5}>{t.EMAIL}</Grid>
                            <Grid item xs={12} sm={7}>{currentUser?.email}</Grid>
                        </Grid>
                    )}
                    {edit && <UserForm handleSubmit={handleEditButtonClick} />}
                </Grid>
                <Grid item sm={12} md={5}>
                    <Box justifyContent="space-around" display="flex" style={{marginBottom: 10}}>
                        <Dropzone maxFiles={1} onUpload={upload}>
                            <Box display="flex" flexDirection="column" alignItems="center"
                                 justifyContent="space-between">
                                <Avatar src={currentUser?.picture?.url} alt="user-picture"
                                        style={{width: '150px', height: '150px'}} />
                                <Button variant="text" color="primary"
                                        style={{width: 250, fontSize: 12}}>{t.UPLOAD_ADVICE}</Button>
                            </Box>
                        </Dropzone>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default UserInfo;
