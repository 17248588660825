import {useParamName, usePrevious} from './hooks';
import {ERouteParameters} from '../enumerators';
import {useSelector} from 'react-redux';
import {REDUCER_REAL_ESTATE} from '../utils/constant';
import {useApiRequest} from '../store/api';
import {
    FETCH_REAL_ESTATE_LOCATIONS,
    FETCH_REAL_ESTATE_OVERVIEW,
    SET_SELLER_PAGINATION
} from '../store/reducers/realEstate';
import {useCallback, useEffect} from 'react';
import {isNotNilOrEmpty} from '../utils/helpers';
import {createAction} from "../store/actions";

const useSellerSearch = () => {
    const sellerId = useParamName(ERouteParameters.SELLER_ID);
    const previousSellerId = usePrevious(sellerId);

    const {
        data: sellerResult = [],
        isLoading: isLoadingSeller
    } = useSelector((state: any) => state[REDUCER_REAL_ESTATE].userOfferItems);

    const {
        data: locationsSeller,
        isLoading: isLoadingLocations
    } = useSelector((state: any) => state[REDUCER_REAL_ESTATE].userOfferItemsLocations);
    const pagination = useSelector((state: any) => state[REDUCER_REAL_ESTATE].userOfferItemsPagination);
    const setPagination = createAction(SET_SELLER_PAGINATION);

    const fetchMyOverviewOfferItems = useApiRequest(REDUCER_REAL_ESTATE, 'offer-item/user/:userId', FETCH_REAL_ESTATE_OVERVIEW, {method: 'get'});
    const fetchRealEstatesLocations = useApiRequest(REDUCER_REAL_ESTATE, 'offer-item/user/:userId/locations', FETCH_REAL_ESTATE_LOCATIONS, {method: 'get'});

    const fetchSeller = useCallback((isPaginationChange= false) => {
        if (isNotNilOrEmpty(sellerId) && !isLoadingSeller) {
            fetchMyOverviewOfferItems({params: {userId: sellerId}, queryParams: {...pagination}});
            if (!isLoadingLocations && !isPaginationChange) {
                fetchRealEstatesLocations({params: {userId: sellerId}});
            }
        }
    }, [fetchMyOverviewOfferItems, fetchRealEstatesLocations, sellerId, isLoadingSeller, pagination]);

    useEffect(() => {
        fetchSeller(true);
    }, [pagination?.page, pagination?.size, pagination?.sort]);

    return {
        sellerId,
        previousSellerId,
        fetchSeller,
        locationsSeller,
        sellerResult,
        isLoadingSeller: isLoadingSeller || isLoadingLocations,
        pagination,
        setPagination,
    };
};

export default useSellerSearch;
