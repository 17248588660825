import { Box, Button, Grid, MobileStepper, Paper, Step, StepButton, Stepper } from '@material-ui/core';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './style.scss';
import { IProps } from '../../utils/types';
import { ALERT_SEVERITY, NEW_ADVERT_STEPS, REDUCER_ADVERT } from '../../utils/constant';
import { isNilOrEmpty, isNotNilOrEmpty, replaceOfferItemIdPath } from '../../utils/helpers';
import { head, split } from 'ramda';
import t from '../../texts';
import { useApiRequest } from '../../store/api';
import { ERouteParameters, ERoutePaths, OfferItemStatus } from '../../enumerators';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createAction } from '../../store/actions';
import {
    CLEAR_ADVERT_FORM,
    CREATE_OFFER_ITEM,
    CREATE_REAL_ESTATE,
    CREATE_REAL_ESTATE_ADDRESS,
    GET_ADDRESS,
    GET_OFFER_ITEM,
    GET_REAL_ESTATE,
    PUBLISH_OFFER_ITEM,
    SET_FORM_ERROR
} from '../../store/reducers/advert';
import { addressValidation, offerItemValidation, realEstateValidation, validateForm } from '../../components/Form/validation';
import Footer from '../../components/Footer';
import { useApiDataSelector, useParamName, usePrevious } from '../../hooks/hooks';
import { SET_ALERT } from '../../store/reducers/context';

const NewAdvert = ({ children }: IProps) => {
    const history = useHistory();
    const location = useLocation();
    const offerItemId = useParamName(ERouteParameters.OFFER_ITEM_ID);
    const [currentStep, setCurrentStep] = useState<any|null>(null);

   // const currentStep = useSelector((state: any) => state[REDUCER_ADVERT].currentStep);
    const error = useSelector((state: any) => state[REDUCER_ADVERT].error);
  //  const setCurrentStep = createAction(SET_CURRENT_STEP);
    const setFormError = createAction(SET_FORM_ERROR);
    const setAlert = createAction(SET_ALERT);
    const clearAdvert = createAction(CLEAR_ADVERT_FORM);

    const fetchOfferItem = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId', GET_OFFER_ITEM, { method: 'get' });
    const fetchRealEstate = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/real-estate', GET_REAL_ESTATE, { method: 'get' });
    const fetchAddress = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/address/real-estate', GET_ADDRESS, { method: 'get' });

    const createOfferItem = useApiRequest(REDUCER_ADVERT, 'offer-item', CREATE_OFFER_ITEM, { method: 'post' });
    const updateOfferItem = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId', CREATE_OFFER_ITEM, { method: 'put' });
    const createRealEstate = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/real-estate', CREATE_REAL_ESTATE, { method: 'post' });
    const updateRealEstate = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/real-estate', CREATE_REAL_ESTATE, { method: 'put' });
    const createAddress = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/address/real-estate', CREATE_REAL_ESTATE_ADDRESS, { method: 'post' });
    const updateAddress = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/address/:addressId', CREATE_REAL_ESTATE_ADDRESS, { method: 'put' });
    const publishAdvert = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/publish', PUBLISH_OFFER_ITEM, { method: 'post' });

    const offerItem = useApiDataSelector((state: any) => state[REDUCER_ADVERT].offerItem);
    const advert = useApiDataSelector((state: any) => state[REDUCER_ADVERT].advert);
    const address = useApiDataSelector((state: any) => state[REDUCER_ADVERT].address);

    const prevOfferItem = usePrevious(offerItem);

    const isNewOffer = useMemo(() => isNilOrEmpty(offerItemId) || offerItemId === ':OFFER_ITEM_ID', [offerItemId]);

    // init method
    useEffect(() => {
        const path = split('/', location.pathname);
        const fromStep = path[1];

        if (isNotNilOrEmpty(offerItemId) && offerItemId !== ':OFFER_ITEM_ID') {
            fetchOfferItem({ params: { offerItemId } });
            fetchRealEstate({ params: { offerItemId } });
            fetchAddress({ params: { offerItemId } });
        } else {
            clearAdvert();
        }

        if (isNilOrEmpty(currentStep)) {
            if (ERoutePaths.ADVERT_PROPERTIES.includes(fromStep)) {
                setCurrentStep(NEW_ADVERT_STEPS[1]);
            } else if (ERoutePaths.ADVERT_PHOTO.includes(fromStep)) {
                setCurrentStep(NEW_ADVERT_STEPS[2]);
            } else if (ERoutePaths.ADVERT_ADDRESS.includes(fromStep)) {
                setCurrentStep(NEW_ADVERT_STEPS[3]);
            } else if (ERoutePaths.ADVERT_OVERVIEW.includes(fromStep)) {
                setCurrentStep(NEW_ADVERT_STEPS[4]);
            } else {
                setCurrentStep(head(NEW_ADVERT_STEPS));
            }
        }
    }, []);

    const gotoStep = useCallback((step: any) => {
        if (step?.value === 0) {
            history.push(replaceOfferItemIdPath(ERoutePaths.ADVERT_INFORMATION_ID, offerItem?.extId));
            setCurrentStep(step);
        } else if (isNotNilOrEmpty(offerItem?.extId)) {
            switch (step?.value) {
                case 1:
                    history.push(replaceOfferItemIdPath(ERoutePaths.ADVERT_PROPERTIES, offerItem?.extId));
                    break;
                case 2:
                    isNotNilOrEmpty(offerItem?.extId) && history.push(replaceOfferItemIdPath(ERoutePaths.ADVERT_PHOTO, offerItem?.extId));
                    break;
                case 3:
                    isNotNilOrEmpty(offerItem?.extId) && history.push(replaceOfferItemIdPath(ERoutePaths.ADVERT_ADDRESS, offerItem?.extId));
                    break;
                case 4:
                    isNotNilOrEmpty(offerItem?.extId) && history.push(replaceOfferItemIdPath(ERoutePaths.ADVERT_OVERVIEW, offerItem?.extId));
                    break;
                default:
                    history.push(ERoutePaths.ADVERT_INFORMATION);
            }
            setCurrentStep(step);
        } else {
            setAlert({ severity: ALERT_SEVERITY.WARNING, message: t.ADVERT_STEP_NO_GO });
        }
    }, [history, offerItem, setCurrentStep]);

    const onPreviousStep = useCallback(() => {
        const previous = currentStep.value - 1;
        gotoStep(NEW_ADVERT_STEPS[previous]);
    }, [gotoStep, currentStep]);

    const setNextStep = useCallback((step?: any) => {
        if (isNilOrEmpty(step)) {
            gotoStep(NEW_ADVERT_STEPS[currentStep.value + 1]);
        } else {
            gotoStep(step);
        }
    }, [gotoStep, currentStep]);

    useEffect(() => {
        // @ts-ignore
        if (isNewOffer && isNotNilOrEmpty(offerItem?.extId) && isNilOrEmpty(prevOfferItem?.extId) && currentStep === NEW_ADVERT_STEPS[0]) {
            setNextStep();
        }
    }, [offerItem]);

    const createUpdateOfferItem = useCallback(async (step: any) => {
        await validateForm(offerItemValidation, offerItem, setFormError);
        if (isNilOrEmpty(offerItem.extId)) {
            await createOfferItem({ body: offerItem });
        } else {
            await updateOfferItem({ params: { offerItemId: offerItem.extId }, body: offerItem });
            setNextStep(step);
        }
    }, [offerItem, setFormError, createOfferItem, updateOfferItem, setNextStep]);

    const createUpdateRealEstate = useCallback(async (step: any) => {
        await validateForm(realEstateValidation(advert.realEstateType), advert, setFormError);
        if (isNilOrEmpty(advert.extId)) {
            await createRealEstate({ params: { offerItemId: offerItem.extId }, body: advert });
        } else {
            await updateRealEstate({ params: { offerItemId: offerItem.extId }, body: advert });

        }
        if (isNilOrEmpty(error)) {
            setNextStep(step);
        }
    }, [offerItem, advert, setFormError, createRealEstate, updateRealEstate, setNextStep]);

    const createUpdateAddress = useCallback(async (step: any) => {
        await validateForm(addressValidation, address, setFormError);
        if (isNilOrEmpty(address.extId)) {
            await createAddress({ params: { offerItemId: offerItem.extId }, body: address });
        } else {
            await updateAddress({ params: { offerItemId: offerItem.extId, addressId: address.extId }, body: address });
        }
        if (isNilOrEmpty(error)) {
            setNextStep(step);
        }
    }, [address, setFormError, address, createAddress, updateAddress, setNextStep]);

    const publishOfferItem = useCallback(async () => {
        if (offerItem?.status !== OfferItemStatus.PUBLISHED) {
            await publishAdvert({ params: { offerItemId: offerItem.extId } });
        }
        history.push(replaceOfferItemIdPath(ERoutePaths.DETAIL_ID, offerItem?.extId), {
            [ERouteParameters.ORIGINAL_PATH]: ERoutePaths.ACCOUNT
        });
    }, [publishAdvert, offerItem, replaceOfferItemIdPath, history]);

    const onSubmit = useCallback((event: any, step: any) => {
        event?.preventDefault();
        try {
            switch (currentStep.value) {
                case 0:
                    createUpdateOfferItem(step);
                    break;
                case 1:
                    createUpdateRealEstate(step);
                    break;
                case 2:
                    setNextStep(step);
                    break;
                case 3:
                    createUpdateAddress(step);
                    break;
                case 4:
                    publishOfferItem();
            }
        } catch (error) {
            console.error(error);
        }
    }, [currentStep, createUpdateAddress, publishAdvert, createUpdateOfferItem, createUpdateRealEstate, setNextStep]);

    return (
        <Grid container data-component="Layout_NewAdvert" className="App">
            <Paper id="content-paper" elevation={1}>
                <h2>{isNotNilOrEmpty(offerItemId) ? t.EDIT_ADVERT : t.NEW_ADVERT}</h2>

                <Box id="stepper">
                    <Stepper nonLinear activeStep={0} alternativeLabel>
                        {NEW_ADVERT_STEPS.map((item) => (
                            <Step active={currentStep?.value === item.value} key={item.value}>
                                <StepButton onClick={() => gotoStep(item)}>
                                    {item.label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

                <Box id="mobileStepper">
                    <MobileStepper
                        variant="dots"
                        steps={NEW_ADVERT_STEPS.length}
                        position="static"
                        activeStep={currentStep?.value}
                        nextButton={null}
                        backButton={null}
                    />
                    {currentStep?.label}
                </Box>


                <form onSubmit={event => onSubmit(event, null)} noValidate>
                    <Box id="content-wrapper">
                        {children}
                    </Box>

                    <Box id="buttons">
                        <Grid container>
                            <Grid className="left" item xs={6}>
                                {currentStep?.value !== 0 && (
                                    <Button className="backButton" variant="outlined" onClick={onPreviousStep}>
                                        {t.BACK}
                                    </Button>
                                )}
                            </Grid>
                            <Grid className="right" item xs={6}>
                                <Button
                                    className="showButon"
                                    type="submit"
                                >
                                    {currentStep?.value === 4 ? t.PUBLISH : t.SAVE_AND_CONTINUE}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                </form>
                <Footer />
            </Paper>


        </Grid>
    );
};

export default NewAdvert;
