import { Box, Grid } from '@material-ui/core';
import React from 'react';
import './style.scss';

interface IProps {
    children: React.ReactNode;
}

export default ({ children }: IProps) => (
    <Grid container data-component="Layout_Login" className="App">
        <Box id="content-wrapper">
            {children}
        </Box>
    </Grid>
);
