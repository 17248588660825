import {useSelector} from 'react-redux';
import {DISTRICT_BY_REGION, REDUCER_ADVERT, REDUCER_CODEBOOK} from '../../utils/constant';
import {createAction, fetchCodebook, fetchCodebookParent} from '../../store/actions';
import React, {useEffect, useState} from 'react';
import {isNilOrEmpty, isNotArray, isNotNilOrEmpty} from '../../utils/helpers';
import {find, head} from 'ramda';
import {ComponentCodebookParams, ComponentGroupParams, ComponentParamsMulti} from '../../utils/types';
import {useApiDataSelector} from '../../hooks/hooks';

// eslint-disable-next-line react/require-default-props
export const withState = (Component: any, selectorName: string, action: string, reducerName = REDUCER_ADVERT) => ({
                                                                                                                      label,
                                                                                                                      property,
                                                                                                                      multiline = false,
                                                                                                                      rows,
                                                                                                                      required = false,
                                                                                                                      disabled = false,
                                                                                                                      min,
                                                                                                                      max,
                                                                                                                      ...rest
                                                                                                                  }: ComponentParamsMulti) => {
    const data = useApiDataSelector((state: any) => state[reducerName][selectorName]);
    const value = isNotNilOrEmpty(data) ? data[property] : null;
    const {[property]: error} = useSelector((state: any) => state[reducerName].formErrors);
    const setProperty = createAction(action);

    return (
        <Component
            onChange={(newValue: any) => setProperty({[property]: newValue})}
            currentValue={value}
            label={label}
            multiline={multiline}
            rows={rows}
            required={required}
            error={error}
            disabled={disabled}
            min={min}
            max={max}
            {...rest}
        />
    );
};

export const withValues = (Component: any, selectorName: string, action: string, reducerName = REDUCER_ADVERT) =>
    ({values = [], property, required = false, ...rest}: ComponentGroupParams) => {
        const data = useApiDataSelector((state: any) => {
                return state[reducerName][selectorName];
            }
        );
        const value = isNotNilOrEmpty(data) && isNotNilOrEmpty(values) ? find(item => item.code === data[property], values) : null;

        const {[property]: error} = useSelector((state: any) => state[reducerName].formErrors);
        const setProperty = createAction(action);

        useEffect(() => {
            if (isNilOrEmpty(value) && isNotNilOrEmpty(values)) {
                setProperty({[property]: head(values)});
            }
        }, [values]);

        if (isNilOrEmpty(values) || isNotArray(values)) {
            return null;
        }

        return (
            <Component
                onChange={(newValue: any) => setProperty({[property]: newValue})}
                currentValue={value || head(values)}
                values={values}
                required={required}
                error={error}
                {...rest}
            />
        );
    };

export const withCodebook = (Component: any, selectorName: string, action: string, reducerName = REDUCER_ADVERT) => ({
                                                                                                                         codebookName,
                                                                                                                         property,
                                                                                                                         required = false,
                                                                                                                         disabled = false
                                                                                                                     }: ComponentCodebookParams) => {
    const data = useApiDataSelector((state: any) => state[reducerName][selectorName]);
    const value = isNotNilOrEmpty(data) ? data[property] : null;
    const {[property]: error} = useSelector((state: any) => state[reducerName].formErrors);
    const setProperty = createAction(action);
    const fetch = fetchCodebook(codebookName);
    const values = useSelector((state: any) => state[REDUCER_CODEBOOK][codebookName]);

    useEffect(() => {
        if (isNilOrEmpty(values)) {
            fetch();
        }
    }, []);

    useEffect(() => {
        if (isNilOrEmpty(value) && isNotNilOrEmpty(values)) {
            setProperty({[property]: head(values)});
        }
    }, [values]);

    if (isNilOrEmpty(values) || isNotArray(values)) {
        return null;
    }
    return (
        <Component
            onChange={(newValue: any) => setProperty({[property]: newValue})}
            values={values}
            currentValue={value}
            required={required}
            error={error}
            disabled={disabled}
        />
    );
};

export const withFilteredDistrict = (Component: any, selectorName: string, action: string, reducerName = REDUCER_ADVERT) => ({
                                                                                                                                 codebookName,
                                                                                                                                 property,
                                                                                                                                 filter,
                                                                                                                                 required = false,
                                                                                                                                 disabled = false,
                                                                                                                             }: { codebookName: string, property: string, filter: string, required?: boolean, disabled?: boolean }) => {
    const {
        [property]: value,
        [filter]: filterValue
    } = useApiDataSelector((state: any) => state[reducerName][selectorName]);
    const setProperty = createAction(action);
    const fetch = fetchCodebookParent(codebookName, 'DISTRICT_BY_REGION');
    const codebookValues = useSelector((state: any) => state[REDUCER_CODEBOOK][DISTRICT_BY_REGION]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        if (isNotNilOrEmpty(filterValue)) {
            fetch({params: {'qp1': filterValue?.code}});
        }
    }, [filterValue]);

    useEffect(() => {
        if (isNotNilOrEmpty(codebookValues) && isNotNilOrEmpty(filterValue)) {
            setValues(codebookValues[filterValue?.code]);
        }
    }, [codebookValues]);

    useEffect(() => {
        if (isNilOrEmpty(value) && isNotNilOrEmpty(values)) {
            setProperty({[property]: head(values)});
        }
    }, [values]);

    if (isNilOrEmpty(values) || isNotArray(values)) {
        return null;
    }

    return (
        <Component
            onChange={(newValue: any) => setProperty({[property]: newValue})}
            values={values}
            currentValue={value}
            required={required}
            disabled={disabled}
        />
    );
};
