import {Box, Button, Grid, TextareaAutosize, TextField} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import './style.scss';
import {useApiRequest} from "../../store/api";
import {REDUCER_CONTEXT} from "../../utils/constant";
import {CONTACT_SUPPORT_POST} from "../../store/reducers/context";
import t from "../../texts";
import {isNotNilOrEmpty} from "../../utils/helpers";
import InputErrorText from "../Form/InputErrorText";
import {contactFormSupportValidation, validateForm} from "../Form/validation";
import {Link} from "react-router-dom";
import {ERoutePaths} from "../../enumerators";
import Recaptcha from "../Recaptcha";

const initForm = {
    name: '',
    email: '',
    phone: '',
    message: '',
};

export default () => {
    const [contactForm, setContactForm] = useState(initForm);
    const [formErrors, setFormErrors] = useState({} as any)

    const contactSupport = useApiRequest(REDUCER_CONTEXT, 'message/support', CONTACT_SUPPORT_POST, {
        method: 'post',
    });

    const handleChange = useCallback((event: any) => {
        setContactForm({
            ...contactForm,
            [event.target.name]: event.target.value
        });
    }, [setContactForm, contactForm]);

    const setErrors = useCallback((error: any) => {
        setFormErrors((prevState: any) => ({
            ...prevState,
            ...error
        }));
    }, [setFormErrors]);

    const onSend = useCallback(async (event) => {
        try {
            event.preventDefault();
            await validateForm(contactFormSupportValidation, contactForm, setErrors);
            await contactSupport({
                body: contactForm,
            });
            setContactForm(initForm);
        } catch (e) {
            console.error(e);
        }
    }, [contactSupport, contactForm, setErrors, setContactForm]);

    return (
        <Box data-component="Contacts">
            <Box className="container">
                <Box className="section">
                    <h2>Kontakty</h2>

                    <Grid className="pl" container style={{textAlign: 'left'}} justify="flex-start">
                        <p>
                            Nie sme realitná kancelária. Sme internetový portál poskytujúci realitnú inzerciu.
                        </p>

                        <div style={{listStyle: "decimal outside", textAlign: 'left'}}>
                            <h3>Identifikačné údaje a kontaktné údaje prevádzkovateľa:</h3>
                            <h4> Prevádzkovateľ:</h4>
                            <Grid container direction="row">
                                <Grid item xs={6} sm={3}>Obchodné meno:</Grid>
                                <Grid item xs={6} sm={9}>Algora s.r.o.</Grid>
                                <Grid item xs={6} sm={3}>Sídlo:</Grid>
                                <Grid item xs={6} sm={9}>Marka Čulena 14169/20, 974 01 Banská Bystrica</Grid>
                                <Grid item xs={6} sm={3}>IČO:</Grid>
                                <Grid item xs={6} sm={9}>54 273 927</Grid>
                                <Grid item xs={6} sm={3}>Zapísaný:</Grid>
                                <Grid item xs={6} sm={9}>Obchodný register Okresného súdu Banská Bystrica, oddiel: Sro,
                                    vložka
                                    č.: 42742/S</Grid>
                            </Grid>
                            <h4>Kontaktné údaje:</h4>
                            <Grid container direction="row">
                                <Grid item xs={6} sm={3}>Telefón:</Grid>
                                <Grid item xs={6} sm={9}>+420 776 280 354</Grid>
                                <Grid item xs={6} sm={3}>Email:</Grid>
                                <Grid item xs={6} sm={9}>support@skreality.sk</Grid>
                            </Grid>
                            <h4>Bankové spojenie:</h4>
                            <Grid container direction="row">
                                <Grid item xs={6} sm={3}>Banka:</Grid>
                                <Grid item xs={6} sm={9}>Všeobecná úverová banka, a.s.</Grid>
                                <Grid item xs={6} sm={3}>IBAN:</Grid>
                                <Grid item xs={6} sm={9}>SK21 0200 0000 0045 4903 1558</Grid>
                                <Grid item xs={6} sm={3}>SWIFT:</Grid>
                                <Grid item xs={6} sm={9}>SUBASKBX</Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <h4>Pošlite nám svoje otázky a pripomienky:</h4>
                    <Grid className="pl" container alignItems="center" justify="center">
                        <Grid item sm={8} xs={12}>
                            <form onSubmit={onSend} noValidate>
                                <Grid container spacing={2} alignItems="center" justify="flex-end">
                                    <Grid className="blueText" item xs={12} sm={2}>{t.NAME}</Grid>
                                    <Grid item xs={12} sm={10}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            required
                                            value={contactForm.name}
                                            name="name"
                                            onChange={handleChange}
                                            error={isNotNilOrEmpty(formErrors?.name)}
                                        />
                                        <InputErrorText error={formErrors?.name} />
                                    </Grid>

                                    <Grid className="blueText" item xs={12} sm={2}>{t.EMAIL}</Grid>
                                    <Grid item xs={12} sm={10}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            required
                                            type="email"
                                            value={contactForm.email}
                                            name="email"
                                            onChange={handleChange}
                                            error={isNotNilOrEmpty(formErrors?.email)}
                                        />
                                        <InputErrorText error={formErrors?.email} />
                                    </Grid>

                                    <Grid className="blueText" item xs={12} sm={2}>{t.PHONE}</Grid>
                                    <Grid item xs={12} sm={10}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            required
                                            value={contactForm.phone}
                                            name="phone"
                                            type="tel"
                                            onChange={handleChange}
                                            error={isNotNilOrEmpty(formErrors?.phone)}
                                        />
                                        <InputErrorText error={formErrors?.phone} />
                                    </Grid>

                                    <Grid className="blueText" item xs={12} sm={2}>{t.MESSAGE}</Grid>
                                    <Grid item xs={12} sm={10}>
                                        <TextareaAutosize
                                            style={{width: '100%'}}
                                            rowsMin={6}
                                            className="p-0"
                                            required
                                            value={contactForm.message}
                                            name="message"
                                            onChange={handleChange}
                                        />
                                        <InputErrorText error={formErrors?.message} />
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <span>Odoslaním formulára súhlasím so spracovávaním <Link className="link"
                                                                                                target="_blank"
                                                                                                to={ERoutePaths.GDPR}>osobných údajov.</Link></span>
                                    </Grid>

                                    <Grid item xs={12} className="right">
                                        <Button variant="contained" color="primary"
                                                type="submit">{t.SEND}</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Recaptcha actionName="contacts" />
        </Box>
    );
};
