import Header from '../../components/Header';
import Layout from '../../layouts/Centered';
import React from 'react';
import { Box } from '@material-ui/core';
import './style.scss';
import RegisterEstateAgency from '../../components/RegisterEstateAgency';
import Advertisement from '../../components/Advertisement';

export default () => (
    <Box data-component="RegisterEstateAgency_Page">
        <Layout>
            <Header />
            <Advertisement />
            <RegisterEstateAgency />
        </Layout>
    </Box>
);
