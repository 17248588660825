import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { ERouteParameters } from '../enumerators';
import { isNotNilOrEmpty, isNilOrEmpty } from '../utils/helpers';

export function usePrevious(value) {
// The ref object is a generic container whose current property is mutable ...
// ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export function useApiDataSelector(selector) {
    const result = useSelector(selector);
    return result?.data;
}

export function useParamName(paramName) {
    const params = useParams();
    return params[paramName] !== paramName && params[paramName] !== 'undefined' ? params[paramName] : undefined;
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function useResultMode() {
    const sellerId = useParamName(ERouteParameters.SELLER_ID);
    const companyId = useParamName(ERouteParameters.COMPANY_ID);

    return {
        isSeller: isNotNilOrEmpty(sellerId),
        isCompany: isNotNilOrEmpty(companyId),
        isSearch: isNilOrEmpty(sellerId) && isNilOrEmpty(companyId)
    };
}
