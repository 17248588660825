/* This page can be merged with Administration/PrivateRoute.tsx */

import {Box, Grid, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Paper} from '@material-ui/core';
import React, {useState} from 'react';
import {Menu as MenuIcon} from 'react-feather';
import {Link, useHistory, useLocation} from 'react-router-dom';
import Footer from '../../components/Footer';
import './style.scss';
import {MenuItemType} from '../../utils/types';
import {ArrowBack} from "@material-ui/icons";
import t from '../../texts';
import {ERoutePaths} from "../../enumerators";

interface IProps {
    children: React.ReactNode;
    menuItems: MenuItemType[];
}

const AdministrationTemporary = ({menuItems, children}: IProps) => {
    const [anchorEl, setanchorEl] = useState(null);
    const {pathname} = useLocation();
    const {push} = useHistory();

    const goHome = () => push(ERoutePaths.HOME);
    const handleClick = (event: any) => setanchorEl(event.currentTarget);

    const handleClose = () => setanchorEl(null);

    return (
        <>
            <Box id="background" />
            <Grid container data-component="Layout_Administration_Temporary" className="App">
                <Paper id="content-paper" elevation={1}>
                    <Box id="navigationTop">
                        <List component="nav">
                            <ListItem button onClick={goHome}>
                                <ArrowBack style={{marginRight: '1rem'}} />
                                <ListItemText
                                    primary={t.HOME}
                                />
                            </ListItem>
                            {menuItems.map((item: MenuItemType) => (
                                /* @ts-ignore */
                                <ListItem component={Link} to={item.link} key={`top-${item.label}`}
                                          selected={item.link === pathname}>
                                    <ListItemText
                                        primary={item.label}
                                        secondary={item.secondary}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    <Box id="collapsedMenu">
                        <IconButton onClick={handleClick}>
                            <MenuIcon size="2.2rem" />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={goHome} className="link">
                                <ArrowBack style={{marginRight: '1rem'}} />{t.HOME}
                            </MenuItem>
                            {menuItems.map((item: MenuItemType) => (
                                <MenuItem
                                    key={`collapsed-${item.label}`}
                                    onClick={() => {
                                        item.link && push(item.link);
                                        handleClose();
                                    }}
                                    selected={item.link === pathname}>{item.label}</MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box id="navigationLeft">
                        <List component="nav">
                            <ListItem button onClick={goHome}>
                                <ArrowBack style={{marginRight: '1rem'}} />
                                <ListItemText
                                    primary={t.HOME}
                                />
                            </ListItem>
                            {menuItems.map((item: MenuItemType) => (
                                /* @ts-ignore */
                                <ListItem key={`nav-${item.label}`} button component={Link} to={item.link}
                                          selected={item.link === pathname}>
                                    <ListItemText
                                        primary={item.label}
                                        secondary={item.secondary}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box id="content-wrapper">
                        {children}
                    </Box>
                    <Footer />
                </Paper>
            </Grid>
        </>
    );
};

export default AdministrationTemporary;
