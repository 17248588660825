import React, {useEffect, useState} from "react";
import {useApiRequest} from "../../store/api";
import {REDUCER_OFFER_ITEM} from "../../utils/constant";
import t from '../../texts';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer
} from "recharts";
import {GET_STATISTICS} from "../../store/reducers/offerItem";
import {useSelector} from "react-redux";
import {isNilOrEmpty, isNotNilOrEmpty} from "../../utils/helpers";
import {Statistic} from "../../utils/types";
import {Alert} from "@material-ui/lab";
import {CircularProgress} from "@material-ui/core";

type IProps = {
    offerItemId: string,
    isExpanded: boolean,
}

const ViewsCharts = ({offerItemId, isExpanded}: IProps) => {
    const [data, setData] = useState<Statistic[]>([]);
    const statistics = useSelector((state: any) => state[REDUCER_OFFER_ITEM].statistics[offerItemId]);
    const isLoading = useSelector((state: any) => state[REDUCER_OFFER_ITEM].statisticsIsLoading);
    const getStatistics = useApiRequest(REDUCER_OFFER_ITEM, 'report/:offerItemId', GET_STATISTICS, {method: 'get'});

    useEffect(() => {
        if (offerItemId && isExpanded) {
            getStatistics({params: {offerItemId}});
        }
    }, [isExpanded]);

    useEffect(() => {
        if (isNotNilOrEmpty(statistics)) {
            setData(statistics.map((s: Statistic) => ({
                name: s.name,
                uv: s.views,
                pv: s.totalUsers,
                amt: s.totalUsers,
            })));
        }
    }, [statistics]);

    if (isLoading) {
        return <CircularProgress style={{width: '50px', height: '50px'}} />;
    }
    if (isNilOrEmpty(data)) {
        return <Alert className="alert" severity="info">{t.NO_STATISTICS}</Alert>;
    }

    return (
        <ResponsiveContainer width="95%" height={400}>
            <LineChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                    type="monotone"
                    dataKey="pv"
                    stroke="#8884d8"
                    activeDot={{r: 8}}
                    name={t.VIEWS_PER_DAY}
                />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" name={t.TOTAL_VIEWS} />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default ViewsCharts;
