import { Box } from '@material-ui/core';
import React from 'react';
import './style.scss';

export default () => {
    return (
        <Box data-component="Advertisement">
            <img src={process.env.PUBLIC_URL + '/images/Banner.jpg'} alt="banner"/>
        </Box>
    )
};
