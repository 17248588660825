import { initialPayload } from '../../utils/constant';
import { handleAction } from '../../utils/helpers';

export const GET_SAVED_ADVERT = 'GET_SAVED_ADVERT';
export const GET_SAVED_ADVERT_REQUEST = `${GET_SAVED_ADVERT}_REQUSET`;
export const GET_SAVED_SEARCH = 'GET_SAVED_SEARCH';
export const GET_SAVED_SEARCH_REQUEST = `${GET_SAVED_SEARCH}_REQUSET`;
export const SAVE_SEARCH = 'SAVE_SEARCH';
export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS';
export const DELETE_SEARCH = 'DELETE_SEARCH';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const DELETE_SEARCH_FAILED = 'DELETE_SEARCH_FAILED';
export const GET_USERS_OFFER_ITEMS = 'GET_USERS_OFFER_ITEMS';
export const GET_STATISTICS = 'GET_STATISTICS';

export const initialState = {
    favorites: initialPayload({}),
    searches: initialPayload([]),
    statistics: {},
    statisticsIsLoading: false
};

export function reducer(state = initialState, action) {

    const handlers = {
        [GET_SAVED_ADVERT]: {
            ...state,
            favorites: action.payload
        },
        [GET_SAVED_SEARCH]: {
            ...state,
            searches: action.payload
        },
        [GET_STATISTICS]: {
            ...state,
            statistics: {
                ...state.statistics,
                [action.meta?.offerItemId]: action.payload?.data
            },
            statisticsIsLoading: action.payload?.isLoading
        },
        [GET_SAVED_SEARCH_REQUEST]: {
            ...state,
            searches: {
                ...action.payload,
                data: []
            }
        }
    };
    return handleAction(action.type, handlers) || state;
}
