import React, { useCallback } from 'react';
import { Button, Grid } from '@material-ui/core';
import t from '../../texts';
import { useLocation } from 'react-router-dom';
import { getConfig } from '../../App';

const SocialLogin = () => {

    const redirectTo = useCallback((url: any) => {
        window.location.href = url;
        return null;
    }, []);

    const googleLogo = process.env.PUBLIC_URL+ '/images/logos/GoogleLogo.png';
    const fbLogo = process.env.PUBLIC_URL + '/images/logos/FbLogo.png';

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const state = `&state=${query.get('state')}`;
    return (
        <>
            <Grid item xs={12}>
                <Button
                    className="loginButton socialLogin"
                    onClick={() => redirectTo(`${getConfig().apiBaseUrl}/oauth2/authorize/google?redirect_uri=${getConfig().oauth2RedirectUri}${state}`)}
                    startIcon={(
                        <img
                            src={googleLogo}
                            width="18"
                            height="18"
                            alt="Google logo"
                        />
                    )}
                >
                    {t.SIGN_IN_GOOGLE}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Button
                    className="loginButton socialLogin"
                    onClick={() => redirectTo(`${getConfig().apiBaseUrl}/oauth2/authorize/facebook?redirect_uri=${getConfig().oauth2RedirectUri}${state}`)}
                    startIcon={(
                        <img
                            src={fbLogo}
                            width="18"
                            height="18"
                            alt="FB logo"
                        />
                    )}
                >
                    {t.SIGN_IN_FB}
                </Button>
            </Grid>
        </>
    );
};

export default SocialLogin;
