import {Box, Button, Checkbox, FormControlLabel, Grid, Paper, TextField} from '@material-ui/core';
import cx from 'classnames';
import React, {useCallback, useState} from 'react';
import './style.scss';
import t from '../../texts';
import {isNilOrEmpty, isNotNilOrEmpty, onChangeStringHandler} from '../../utils/helpers';
import {authRequest} from '../../store/api';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {IUser} from "../../utils/types";
import {userValidation, validateForm} from "../Form/validation";
import {useHistory} from "react-router-dom";
import {ERoutePaths} from "../../enumerators";
import {test} from "ramda";
import {REGISTRATION} from "../../store/reducers/auth";
import SocialLogin from "../Login/SocialLogin";
import InputErrorText from "../Form/InputErrorText";
import {Link} from 'react-router-dom';
import Recaptcha from "../Recaptcha";

export const validatePassword = (password: string, setFormError: any) => {
    const PASSWD = 'password';
    if (isNilOrEmpty(password)) {
        setFormError({[PASSWD]: t.REQUIRED});
        throw TypeError('ValidationError');
    }
    if (!test(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,15}$/, password)) {
        setFormError({[PASSWD]: t.PASSWORD_FORMAT_ERROR});
        throw TypeError('ValidationError');
    }
};

const Register = () => {
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [userForm, setUserForm] = useState<IUser>({} as IUser);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [formErrors, setFormErrors] = useState({} as any);

    const register = authRequest('signup', REGISTRATION, {method: 'post'});

    const setErrors = useCallback((error: any) => {
        setFormErrors((prevState: any) => ({
            ...prevState,
            ...error
        }));
    }, [setFormErrors]);

    const onRegister = useCallback(async (event) => {
        event?.preventDefault();
        try {
            await validateForm(userValidation, userForm, setErrors);
            await validatePassword(password, setErrors);

            register({body: {password, ...userForm}})
                .then(() => {
                    history.push(ERoutePaths.LOGIN)
                    setUserForm({} as IUser);
                    setErrors({});
                });
        } catch (e) {
            console.error(e);
        }
    }, [register, userForm, setErrors, password, validateForm, validatePassword]);

    const handleChange = useCallback((event: any, value?: any) => {
        setUserForm({
            ...userForm,
            [event.target.name]: event.target.value || value
        });
    }, [setUserForm, userForm]);

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const gdpr = <span>Oboznámil som sa so spracúvaním <Link className="link" target="_blank" to={ERoutePaths.GDPR}>osobných údajov.</Link></span>;
    const marketing = <span>Súhlasím so zasielaním marketingových správ podľa
         <Link
             to={process.env.PUBLIC_URL + '/files/Informovany-suhlas-na-marketingove-ucely.docx'}
             target="_blank" className="link"> týchto pravidiel.</Link>
    </span>;


    return (
        <Box data-component="Register">

            <Paper elevation={1}>
                <Box pt="2.3rem" pb="2rem" className={cx('container')}>
                    <Box className="section">
                        <Grid container justify="center">
                            <Grid className="logo" item>
                                <img src={process.env.PUBLIC_URL + '/images/logos/black-on-transparent.png'} width="128" height="37"
                                     alt="skreality.sk logo" />
                            </Grid>
                            <Grid item>
                                <h2>{t.HOMEPAGE_TITLE}</h2>
                            </Grid>
                        </Grid>

                        <Grid container justify="center">
                            <Grid item>
                                <h3>{t.REGISTER}</h3>
                            </Grid>
                        </Grid>
                        <Box my="1.5rem">
                            <Grid container spacing={2} alignItems="center" justify="flex-end">
                                <Grid className="left blueText" item xs={12} sm={3}>{t.USERNAME}</Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        name="email"
                                        variant="outlined"
                                        size="small"
                                        type="email"
                                        value={userForm.email || ''}
                                        onChange={handleChange}
                                        required
                                        error={isNotNilOrEmpty(formErrors?.email)}
                                    />
                                    <InputErrorText error={formErrors?.email} />
                                </Grid>
                                <Grid className="left blueText" item xs={12} sm={3}>{t.PASSWORD}</Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        variant="outlined"
                                        size="small"
                                        required
                                        value={password || ''}
                                        inputProps={{
                                            minLength: 6,
                                        }}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                        onChange={onChangeStringHandler(setPassword)}
                                        error={isNotNilOrEmpty(formErrors?.password)}
                                    />
                                    <InputErrorText error={formErrors?.password} />
                                </Grid>

                                <Grid className="left blueText" item xs={12} sm={3}>{t.NAME}</Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        name="firstName"
                                        variant="outlined"
                                        size="small"
                                        value={userForm.firstName || ''}
                                        onChange={handleChange}
                                        required
                                        error={isNotNilOrEmpty(formErrors?.firstName)}
                                    />
                                    <InputErrorText error={formErrors?.firstName} />
                                </Grid>
                                <Grid className="left blueText" item xs={12} sm={3}>{t.SURNAME}</Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        name="lastName"
                                        variant="outlined"
                                        size="small"
                                        value={userForm.lastName || ''}
                                        onChange={handleChange}
                                        required
                                        error={isNotNilOrEmpty(formErrors?.lastName)}
                                    />
                                    <InputErrorText error={formErrors?.lastName} />
                                </Grid>
                                <Grid className="left blueText" item xs={12} sm={3}>{t.PHONE}</Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        name="phone"
                                        variant="outlined"
                                        size="small"
                                        value={userForm.phone || ''}
                                        onChange={handleChange}
                                        type="tel"
                                        required
                                        error={isNotNilOrEmpty(formErrors?.phone)}
                                    />
                                    <InputErrorText error={formErrors?.phone} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                name="agreementMarketing"
                                                checked={userForm.agreementMarketing || false}
                                                color="primary"
                                                onClick={(event) => handleChange(event, !userForm.agreementMarketing)}
                                            />
                                        )}
                                        label={marketing}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                name="agreementConditions"
                                                checked={userForm.agreementConditions || false}
                                                color="primary"
                                                onClick={(event) => handleChange(event, !userForm.agreementConditions)}
                                            />
                                        )}
                                        label={gdpr}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="loginButton"
                                    disabled={!userForm.agreementConditions}
                                    onClick={onRegister}>{t.REGISTER}</Button>
                            </Grid>

                            <SocialLogin />

                            <Grid item xs={12}>
                                <Button className="textButton" onClick={() => history.goBack()}>{t.GO_BACK}</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Paper>
            <Recaptcha actionName="registration" />
        </Box>
    );
};

export default Register;
