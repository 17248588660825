import React from "react";
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Popover, Typography} from "@material-ui/core";
import {Notification} from "../../utils/types";
import {Bell} from "react-feather";

type Props = { notifications: Notification[], onClose: () => void, isOpen: boolean, reference: any }

const NotificationsDialog = ({
                                 notifications,
                                 onClose,
                                 isOpen,
                                 reference
                             }: Props) => (
    <Popover
        anchorEl={reference}
        open={isOpen}
        onClose={onClose}
        anchorReference="anchorEl"
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
    >
        <Box p={2}>
            <Typography>Notifikace</Typography>
            <List>
                {notifications.map((notification: Notification, index: number) => (
                    <ListItem style={{cursor: 'pointer'}} key={`notification-${index}`} onClick={notification.onPress}>
                        <ListItemAvatar>
                            <Avatar className="avatar">
                                <Bell/>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={notification.text}/>
                    </ListItem>
                ))}
            </List>
        </Box>
    </Popover>
);

export default NotificationsDialog;
