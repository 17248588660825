import {handleAction} from '../../utils/helpers';
import {initialPayload} from '../../utils/constant';
import {initPagination} from './search';

export const FETCH_MY_OFFER_ITEMS_OVERVIEW = 'FETCH_MY_OFFER_ITEMS_OVERVIEW';
export const FETCH_MY_OFFER_ITEMS_ELEMENTS = 'FETCH_MY_OFFER_ITEMS_ELEMENTS';

export const SET_SELLER_PAGINATION = 'SET_SELLER_PAGINATION';

export const SET_COMPANY_PAGINATION = 'SET_COMPANY_PAGINATION';
export const CLEAR_COMPANY_PAGINATION = 'CLEAR_COMPANY_PAGINATION';

export const FETCH_REAL_ESTATE_OVERVIEW = 'FETCH_REAL_ESTATE_OVERVIEW';
export const FETCH_REAL_ESTATE_LOCATIONS = 'FETCH_REAL_ESTATE_LOCATIONS';

export const FETCH_COMPANY_OVERVIEW = 'FETCH_COMPANY_OVERVIEW';
export const FETCH_COMPANY_LOCATIONS = 'FETCH_COMPANY_LOCATIONS';

export const GET_MY_AGENCY = 'GET_MY_AGENCY';
export const UPDATE_MY_AGENCY = 'UPDATE_MY_AGENCY';
export const UPDATE_MY_AGENCY_REQUEST = 'UPDATE_MY_AGENCY_REQUEST';
export const GET_MY_AGENCY_USERS = 'GET_MY_AGENCY_USERS';
export const CLEAR_REAL_ESTATE_PAGINATION = 'CLEAR_REAL_ESTATE_PAGINATION';

export const UPLOAD_REALITY_COMPANY_PHOTO = 'UPLOAD_REALITY_COMPANY_PHOTO';
export const UPLOAD_REALITY_COMPANY_PHOTO_SUCCESS = `${UPLOAD_REALITY_COMPANY_PHOTO}_SUCCESS`;

export const DELETE_OFFER_ITEM = 'DELETE_OFFER_ITEM';
export const DELETE_OFFER_ITEM_SUCCESS = `${DELETE_OFFER_ITEM}_SUCCESS`;

export const initialState = {
    myOfferItems: initialPayload([]),
    pagination: initPagination,
    myRealEstateAgency: initialPayload(null),
    myRealEstateAgencyUsers: initialPayload([]),
    companyItems: initialPayload([]),
    companyLocations: initialPayload([]),
    companyPagination: initPagination,
    userOfferItems: initialPayload([]),
    userOfferItemsLocations: initialPayload([]),
    userOfferItemsPagination: initPagination,
    myOfferItemsCounter: 0,
};

export function reducer(state = initialState, action) {
    const handlers = {
        [FETCH_MY_OFFER_ITEMS_OVERVIEW]: {
            ...state,
            myOfferItems: {
                ...action.payload,
                data: action.payload?.data?.content
            },
            pagination: {
                ...state.pagination,
                page: action.payload?.data?.number,
                totalElements: action.payload?.data?.totalElements,
                totalPages: action.payload?.data?.totalPages
            }
        },
        [FETCH_MY_OFFER_ITEMS_ELEMENTS]: {
            ...state,
            myOfferItemsCounter: action.payload?.data?.totalElements,
        },
        [FETCH_REAL_ESTATE_OVERVIEW]: {
            ...state,
            userOfferItems: {
                ...action.payload,
                data: action.payload?.data?.content
            },
            userOfferItemsPagination: {
                ...state.userOfferItemsPagination,
                totalElements: action.payload?.data?.totalElements,
                totalPages: action.payload?.data?.totalPages
            }
        },
        [SET_SELLER_PAGINATION]: {
            ...state,
            userOfferItemsPagination: action.payload,
        },
        [FETCH_REAL_ESTATE_LOCATIONS]: {
            ...state,
            userOfferItemsLocations: action.payload
        },
        [GET_MY_AGENCY]: {
            ...state,
            myRealEstateAgency: action.payload
        },
        [UPLOAD_REALITY_COMPANY_PHOTO]: {
            ...state,
            myRealEstateAgency: action.payload
        },
        [UPLOAD_REALITY_COMPANY_PHOTO_SUCCESS]: {
            ...state,
            myRealEstateAgency: action.payload
        },
        [UPDATE_MY_AGENCY]: {
            ...state,
            myRealEstateAgency: action.payload
        },
        [GET_MY_AGENCY_USERS]: {
            ...state,
            myRealEstateAgencyUsers: action.payload
        },
        [FETCH_COMPANY_OVERVIEW]: {
            ...state,
            companyItems: {
                ...action.payload,
                data: action.payload?.data?.content
            },
            companyPagination: {
                ...state.companyPagination,
                totalElements: action.payload?.data?.totalElements,
                totalPages: action.payload?.data?.totalPages
            }
        },
        [SET_COMPANY_PAGINATION]: {
            ...state,
            companyPagination: action.payload,
        },
        [CLEAR_COMPANY_PAGINATION]: {
            ...state,
            companyPagination: initPagination,
        },
        [FETCH_COMPANY_LOCATIONS]: {
            ...state,
            companyLocations: action.payload
        },
        [CLEAR_REAL_ESTATE_PAGINATION]: {
            ...state,
            pagination: initPagination,
        }
    };
    return handleAction(action.type, handlers) || state;

}
