import React, {useEffect, useMemo, useState} from 'react';
import {ChipUser} from '../../utils/types';
import {
    Box,
    Grid
} from '@material-ui/core';
import UserChip from '../EstateAgencyAdministration/UserChip';
import {Pagination} from '@material-ui/lab';
import {chunk} from '../../utils/ArrayUtils';
import './style.scss';

interface PageableUserChipsProps {
    data: Array<ChipUser>;
    pageItemCount: number;
    rowItemSize: 6 | 12;
    onChipClick?: Function;
    visible: boolean;
    realityCompanyId: string;
}

const PageableUserChips = ({
                               data,
                               pageItemCount,
                               rowItemSize,
                               onChipClick,
                               visible,
                               realityCompanyId
                           }: PageableUserChipsProps) => {
        const [pageNumber, setPageNumber] = useState(1);
        const [pages, setPages] = useState([]);

        // resolve pages by props count of items on one page
        useEffect(() => {
            if (data) {
                const chunks = chunk(data, pageItemCount);
                setPages(chunks);
                if (chunks.length < pageNumber) {
                    setPageNumber(1);
                }
            }
        }, [data]);

        const getPageCount = () => {
            if (data) {
                return Math.ceil(data.length / pageItemCount);
            }
            return 0;
        };

        const getCurrentPageData = useMemo(() => {
            if (pages.length >= 1 && pageNumber <= pages.length) {
                return pages[pageNumber - 1] as ChipUser[];
            }
            return [];
        }, [pages, pageNumber]);

        const handlePageChange = (event: React.ChangeEvent<any>, value: number) => {
            setPageNumber(value);
        };

        return (
            <Box data-component="PageableUserChips">
                <Grid container spacing={2} justify="space-between">
                    {getCurrentPageData.map(user => (
                        <UserChip realityCompanyId={realityCompanyId} key={user.user?.extId} reUser={user}
                                  rowItemSize={rowItemSize} onClick={onChipClick} />))}
                </Grid>
                {visible && (
                    <Box mt="1rem" justifyContent="center">
                        <Pagination
                            count={getPageCount()}
                            page={pageNumber}
                            onChange={handlePageChange}
                        />
                    </Box>
                )}
            </Box>
        );
    }
;

export default PageableUserChips;
