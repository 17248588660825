export function chunk(arr: any, chunkSize: number) {
    if (!arr || !Array.isArray(arr) || arr.length <= 0) {
        return [];
    } else if (chunkSize <= 0) {
        return [arr];
    }
    const chunks: any = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
}
