import { handleAction } from '../../utils/helpers';
import { initialPayload } from '../../utils/constant';

export const SET_FILTER = 'SET_FILTER';
export const SET_FILTER_PARAM = 'SET_FILTER_PARAM';
export const CLEAR_FILTER = 'CLEAR_FILTER';

export const OFFER_ITEM_BY_ID = 'OFFER_ITEM_BY_ID';
const OFFER_ITEM_BY_ID_SUCCESS = 'OFFER_ITEM_BY_ID_SUCCESS';

export const REAL_ESTATE_BY_ID = 'REAL_ESTATE_BY_ID';
const REAL_ESTATE_BY_ID_SUCCESS = 'REAL_ESTATE_BY_ID_SUCCESS';

export const IMAGES_BY_ID = 'IMAGES_BY_ID';
const IMAGES_BY_ID_SUCCESS = 'IMAGES_BY_ID_SUCCESS';

export const ADDRESS_BY_ID = 'ADDRESS_BY_ID';
export const ADDRESS_BY_ID_SUCCESS = 'ADDRESS_BY_ID_SUCCESS';
export const ADDRESS_BY_ID_FAILED = 'ADDRESS_BY_ID_FAILED';

export const NEARBY_BY_ID = 'NEARBY_BY_ID';
const NEARBY_BY_ID_SUCCESS = 'NEARBY_BY_ID_SUCCESS';

export const REAL_ESTATE_DETAIL_BY_ID = 'REAL_ESTATE_DETAIL_BY_ID';
const REAL_ESTATE_DETAIL_BY_ID_SUCCESS = 'REAL_ESTATE_DETAIL_BY_ID_SUCCESS';

export const SEARCH = 'SEARCH';
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const SET_PAGINATION = 'SET_PAGINATION';

export const SEARCH_LOCATIONS = 'SEARCH_LOCATIONS';

export const GET_FILTER_COUNT = 'GET_FILTER_COUNT';

export const initPagination = {
    page: 0,
    size: 10,
    sort: 'publishedAt,DESC'
};

export const filterInitialState = {
    publishedAtFrom: 0,
    offerType: {
        code: 'SELL',
        value: 'Predaj',
        order: 1,
        urlFriendlyValue: 'predaj',
        userFriendlyValue: 'na predaj'
    },
    realEstateType: {
        order: 0,
        code: 'APARTMENT',
        value: 'Byt',
        urlFriendlyValue: 'byt',
        userFriendlyValue: 'Byty'
    }
};

export const initialState = {
    filter: {}, // filterInitialState,
    searchResult: initialPayload({}),
    realEstates: {},
    locations: initialPayload([]),
    pagination: initPagination,
    filterCount: 0,
};

export function reducer(state = initialState, action) {

    const handlers = {
        [SET_FILTER]: {
            ...state,
            filter: action.payload
        },
        [SET_FILTER_PARAM]: {
            ...state,
            filter: { ...state.filter, ...action.payload }
        },
        [CLEAR_FILTER]: {
            ...state,
            filter: {},
            filterCount: 0
        },
        [GET_FILTER_COUNT]: {
            ...state,
            filterCount: action.payload
        },
        [SEARCH]: {
            ...state,
            searchResult: action.payload
        },
        [SEARCH_REQUEST]: {
            ...state,
            searchResult: {
                data: state.searchResult.data,
                ...action.payload
            }
        },
        [SEARCH_SUCCESS]: {
            ...state,
            searchResult: action.payload,
            pagination: {
                ...state.pagination,
                totalElements: action.payload?.data?.totalElements,
                totalPages: action.payload?.data?.totalPages
            }
        },
        [CLEAR_SEARCH]: {
            ...state,
            filter: {},
            searchResult: initialPayload({})
        },
        [SEARCH_LOCATIONS]: {
            ...state,
            locations: action.payload
        },
        [OFFER_ITEM_BY_ID]: {
            ...state
        },
        [OFFER_ITEM_BY_ID_SUCCESS]: {
            ...state,
            realEstates: {
                ...state.realEstates,
                [action.meta?.offerItemId]: { ...state.realEstates[action.meta?.offerItemId], ...action.payload?.data }
            }
        },
        [REAL_ESTATE_BY_ID]: {
            ...state
        },
        [REAL_ESTATE_DETAIL_BY_ID]: {
            ...state
        },
        [REAL_ESTATE_BY_ID_SUCCESS]: {
            ...state,
            realEstates: {
                ...state.realEstates,
                [action.meta?.offerItemId]: { ...state.realEstates[action.meta?.offerItemId], ...action.payload?.data }
            }
        },
        [REAL_ESTATE_DETAIL_BY_ID_SUCCESS]: {
            ...state,
            realEstates: {
                ...state.realEstates,
                [action.meta?.offerItemId]: { ...state.realEstates[action.meta?.offerItemId], realEstate: action.payload?.data }
            }
        },
        [IMAGES_BY_ID_SUCCESS]: {
            ...state,
            realEstates: {
                ...state.realEstates,
                [action.meta?.offerItemId]:
                    {
                        ...state.realEstates[action.meta?.offerItemId],
                        images: action.payload?.data
                    }
            }
        },
        [ADDRESS_BY_ID_SUCCESS]: {
            ...state,
            realEstates: {
                ...state.realEstates,
                [action.meta?.offerItemId]: {
                    ...state.realEstates[action.meta?.offerItemId],
                    address: action.payload?.data
                }
            }
        },
        [NEARBY_BY_ID_SUCCESS]: {
            ...state,
            realEstates: {
                ...state.realEstates,
                [action.meta?.offerItemId]: {
                    ...state.realEstates[action.meta?.offerItemId],
                    nearbyPlaces: action.payload?.data
                }
            }
        },
        [SET_PAGINATION]: {
            ...state,
            pagination: action.payload
        }
    };
    return handleAction(action.type, handlers) || state;
}
