import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, Paper, Tooltip} from '@material-ui/core';
import cx from 'classnames';
import t from '../../texts';
import {ChevronLeft, ChevronRight, List, Star} from 'react-feather';
import './style.scss';
import {useSelector} from 'react-redux';
import {createAction} from '../../store/actions';
import {SET_ALERT} from '../../store/reducers/context';
import {ERouteParameters, ERoutePaths, OfferItemStatus} from '../../enumerators';
import {useHistory} from 'react-router-dom';
import {formatAddress, formatOfferItemDesc, formatPrice, isNilOrEmpty, isNotNilOrEmpty,} from '../../utils/helpers';
import {useApiRequest} from '../../store/api';

import DetailTable from '../ResultDetailItem/DetailTable';
import NearbyPlacesTable from '../ResultDetailItem/NearbyPlacesTable';
import {useParamName, usePrevious} from '../../hooks/hooks';
import {findIndex, isNil, propEq} from 'ramda';
import Footer from '../Footer';
import SellerCompanyDetail from '../ResultDetailItem/SellerCompanyDetail';
import Gallery from '../ResultDetailItem/Gallery';
import {isFavoriteAdvert, saveFavoriteAdvert} from '../../utils/storage';
import {ALERT_SEVERITY, META_APP_NAME, REDUCER_AUTH, REDUCER_CONTEXT, REDUCER_SEARCH} from '../../utils/constant';
import {ADDRESS_BY_ID, NEARBY_BY_ID, REAL_ESTATE_BY_ID, REAL_ESTATE_DETAIL_BY_ID} from '../../store/reducers/search';
import ActionButtons from "./ActionButtons";
import {ISearchResultContent} from "../../utils/types";
import {Helmet} from "react-helmet";
import {useFilterBuilder} from "../../hooks/useFilterBuilder";
import MapExpander from "../Map/MapExpander";

interface IProps {
    result: any[],
    linkToDetail: (offer: ISearchResultContent) => string,
}

const ResultDetailItem = ({result, linkToDetail}: IProps) => {
    const {push, location} = useHistory();
    const offerItemId = useParamName(ERouteParameters.OFFER_ITEM_ID);

    const {offerItemDetailTitleText, offerItemDetailDescriptionText} = useFilterBuilder();

    // @ts-ignore
    const resultListPath = location?.state?.[ERouteParameters.ORIGINAL_PATH] || ERoutePaths.SEARCH_RESULT;
    const {isCollapsedView} = useSelector((state: any) => state[REDUCER_CONTEXT]);
    const [isFavorite, setIsFavorite] = useState(false);
    const isAuthenticated = useSelector((state: any) => state[REDUCER_AUTH].isAuthenticated);

    const fetchEstate = useApiRequest(REDUCER_SEARCH, 'offer-item/:offerItemId', REAL_ESTATE_BY_ID, {method: 'get'});
    const fetchDetail = useApiRequest(REDUCER_SEARCH, 'offer-item/:offerItemId/real-estate', REAL_ESTATE_DETAIL_BY_ID, {method: 'get'});
    const fetchAddress = useApiRequest(REDUCER_SEARCH, 'offer-item/:offerItemId/address/real-estate', ADDRESS_BY_ID, {method: 'get'});
    const fetchNearbyPlaces = useApiRequest(REDUCER_SEARCH, 'offer-item/:offerItemId/address/nearby-places', NEARBY_BY_ID, {method: 'get'});

    const prevOfferItemId = usePrevious(offerItemId);
    const detail = useSelector((state: any) => state[REDUCER_SEARCH].realEstates?.[offerItemId]);

    useEffect(() => {
        if (isNotNilOrEmpty(offerItemId) && (isNilOrEmpty(detail) || offerItemId !== prevOfferItemId)) {
            fetchEstate({params: {offerItemId}});
            fetchDetail({params: {offerItemId}});
            fetchAddress({params: {offerItemId}});
            fetchNearbyPlaces({params: {offerItemId}});
            setIsFavorite(!!isFavoriteAdvert(offerItemId));
        }
    }, [offerItemId]);

    const setAlert = createAction(SET_ALERT);
    const [previousItem, setPrevious] = useState<any>(null);
    const [nextItem, setNext] = useState<any>(null);
    const [searchTextTitle, setSearchTextTitle] = useState<string>('');
    const [searchTextDescription, setSearchTextDescription] = useState<string>('');

    useEffect(() => {
        if (isNotNilOrEmpty(result)) {
            const index = findIndex(propEq('extId', offerItemId), result);
            if (!isNil(index)) {
                setPrevious(result[index - 1]);
                setNext(result[index + 1]);
            }
        }
    }, [result, offerItemId]);

    const saveFavorite = useCallback(() => {
        if (isAuthenticated) {
            saveFavoriteAdvert(offerItemId);
            setIsFavorite(!!isFavoriteAdvert(offerItemId));
            if (isFavorite) {
                setAlert({severity: ALERT_SEVERITY.SUCCESS, message: t.REMOVE_FAVORITE_SUCCESS});
            } else {
                setAlert({severity: ALERT_SEVERITY.SUCCESS, message: t.SAVE_FAVORITE_SUCCESS});
            }
        } else {
            setAlert({severity: ALERT_SEVERITY.WARNING, message: t.NEED_LOG_IN});
        }
    }, [offerItemId, isAuthenticated, isFavorite, setAlert, setIsFavorite]);

    useEffect(() => {
        if (detail && detail.realEstate && detail.address) {
            setSearchTextTitle(offerItemDetailTitleText(detail));
            setSearchTextDescription(offerItemDetailDescriptionText(detail))
        }
    }, [detail, offerItemDetailTitleText, offerItemDetailDescriptionText]);

    if (isNilOrEmpty(detail?.realEstate)) {
        return null;
    }

    return (
        <Box data-component="ResultDetail" px="0.5rem">
            <Helmet>
                <title>{`${searchTextTitle} ${META_APP_NAME}`}</title>
                <meta name="description" content={searchTextDescription}/>
                <meta property="og:title" content={`${searchTextTitle} ${META_APP_NAME}`}/>
                <meta property="og:description" content={searchTextDescription}/>
            </Helmet>
            <MapExpander/>
            <Box className={cx('container', {collapsed: isCollapsedView})}>
                <Grid container>
                    <Grid item sm={isCollapsedView ? 12 : (previousItem && nextItem ? 5 : 6)}>
                        <Box className="searchButton pl">
                            <Button
                                onClick={() => push(resultListPath, {
                                    // @ts-ignore
                                    [ERouteParameters.ORIGINAL_PAGINATION]: location?.state?.[ERouteParameters.ORIGINAL_PAGINATION],
                                })}
                                startIcon={<List width={24} height={24}/>}
                            >
                                {resultListPath === ERoutePaths.ACCOUNT ? t.BACK_TO_MY_ACCOUNT : t.BACK_TO_SEARCH}
                            </Button>
                        </Box>
                    </Grid>

                    {previousItem && (
                        <Grid item sm={isCollapsedView ? 6 : 3}>
                            <Box className="searchButton pl">
                                <Button
                                    onClick={() => push(linkToDetail(previousItem))}
                                >
                                    <ChevronLeft/>
                                    {t.PREVIOUS_ADD}
                                </Button>
                            </Box>
                        </Grid>
                    )}
                    {nextItem && (
                        <Grid
                            className="right"
                            item
                            sm={isCollapsedView ? (isNilOrEmpty(previousItem) ? 12 : 6) : isNilOrEmpty(previousItem) ? 6 : 3}
                        >
                            <Box className="next pl">
                                <Button
                                    onClick={() => push(linkToDetail(nextItem))}
                                >
                                    {t.NEXT_ADD}
                                    <ChevronRight/>
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>

                <Box className="section">
                    <Paper elevation={0}>
                        <Gallery offerItemId={offerItemId}/>

                        <Grid className="mtb" container>
                            <Grid className="left" item sm={6}>
                                <h2>
                                    {formatOfferItemDesc(detail.offerType, detail?.realEstate)}
                                    <Button onClick={saveFavorite}>
                                        <Tooltip title={t.SAVE_ADVERT} placement="top">
                                            <Star
                                                className={cx('star', isFavorite ? 'active' : '')}
                                            />
                                        </Tooltip>
                                    </Button>
                                </h2>
                            </Grid>

                            <Grid className="right" item sm={6}>
                                {OfferItemStatus.UNACTIVE === detail.status &&
                                    <h2 className="unactive">{t.UNACTIVE_ITEM}</h2>}
                                {/* {badges.map(badge => (
                                    <Chip
                                        key={badge.code}
                                        label={badge.value}
                                        size="small"
                                        variant="outlined"
                                    />
                                ))} */}
                            </Grid>

                        </Grid>

                        <Grid container>
                            <Grid id="address" item xs={12}>
                                <p>{formatAddress(detail?.address)}</p>
                            </Grid>

                            <Grid id="price" className="left" item xs={12}>
                                <p>{formatPrice(detail?.price)}</p>
                            </Grid>

                        </Grid>
                    </Paper>
                </Box>

                <Box className="section bodyText">
                    <p>{detail.description}</p>
                </Box>

                <Box className="section">
                    <h3>Detail</h3>
                    <Paper className="paperDetail" elevation={0}>
                        <DetailTable detail={detail}/>
                    </Paper>
                </Box>

                {isNotNilOrEmpty(detail.nearbyPlaces) && (
                    <Box className="section">
                        <h3>{t.NERABY_PLACES}</h3>
                        <Paper className="paperDetail" elevation={0}>
                            <NearbyPlacesTable detail={detail}/>
                        </Paper>
                    </Box>
                )}

                <SellerCompanyDetail offerItemId={offerItemId}/>

                <ActionButtons offerItemId={offerItemId} isFavorite={isFavorite} saveFavorite={saveFavorite}/>
            </Box>
            <Footer/>
        </Box>
    );
};

export default ResultDetailItem;
