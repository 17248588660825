import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import './style.scss';
import cx from 'classnames';

const Loading = ({ isLoading = false, style, fullScreen }: { isLoading: boolean, style?: any, fullScreen?: boolean }) => {
    if (!isLoading) {
        return null;
    }

    return (
        <Box data-component="Loading" style={style}>
            <Box className={cx({ fullscreen: fullScreen, inbox: !fullScreen })}>
                <Box className="loader-container">
                    <CircularProgress style={{ width: '50px', height: '50px' }} />
                </Box>
            </Box>
        </Box>
    );
};

Loading.defaultProps = {
    style: {},
    fullScreen: false
};

export default Loading;
