import { OfferItemStatus } from '../../enumerators';
import { handleAction } from '../../utils/helpers';
import { initialPayload, NEW_ADVERT_STEPS } from '../../utils/constant';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_ADVERT_PROPERTY = 'SET_ADVERT_PROPERTY';
export const SET_ADDRESS_PROPERTY = 'SET_ADDRESS_PROPERTY';
export const SET_ADDRESS_REGION = 'SET_ADDRESS_REGION';

export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_OFFER_ITEM_PROPERTY = 'SET_OFFER_ITEM_PROPERTY';

export const CREATE_OFFER_ITEM = 'CREATE_OFFER_ITEM';
export const CREATE_OFFER_ITEM_SUCCESS = `${CREATE_OFFER_ITEM}_SUCCESS`;
export const CREATE_OFFER_ITEM_REQUEST = `${CREATE_OFFER_ITEM}_REQUEST`;

export const GET_OFFER_ITEM = 'GET_OFFER_ITEM';
export const GET_OFFER_ITEM_REQUEST = `${GET_OFFER_ITEM}_REQUEST`;

export const CLEAR_REAL_ESTATE = 'CLEAR_REAL_ESTATE';
export const GET_REAL_ESTATE = 'GET_REAL_ESTATE';
export const GET_REAL_ESTATE_REQUEST = `${GET_REAL_ESTATE}_REQUEST`;
export const GET_REAL_ESTATE_SUCCESS = `${GET_REAL_ESTATE}_SUCCESS`;
export const GET_REAL_ESTATE_FAILED = `${GET_REAL_ESTATE}_FAILED`;

export const REAL_ESTATE_DETAIL_BY_ID ='REAL_ESTATE_DETAIL_BY_ID';
export const REAL_ESTATE_DETAIL_BY_ID_REQUEST = `${REAL_ESTATE_DETAIL_BY_ID}_REQUEST`;
export const REAL_ESTATE_DETAIL_BY_ID_SUCCESS = `${REAL_ESTATE_DETAIL_BY_ID}_SUCCESS`;
export const REAL_ESTATE_DETAIL_BY_ID_FAILED = `${REAL_ESTATE_DETAIL_BY_ID}_FAILED`;

export const GET_ADDRESS = 'GET_ADDRESS';
export const GET_ADDRESS_REQUEST = `${GET_ADDRESS}_REQUEST`;
export const GET_ADDRESS_SUCCESS = `${GET_ADDRESS}_SUCCESS`;
export const GET_ADDRESS_FAILED = `${GET_ADDRESS}_FAILED`;

export const CREATE_REAL_ESTATE = 'CREATE_REAL_ESTATE';
export const CREATE_REAL_ESTATE_REQUEST = `${CREATE_REAL_ESTATE}_REQUEST`;

export const CREATE_REAL_ESTATE_ADDRESS = 'CREATE_REAL_ESTATE_ADDRESS';
export const CREATE_REAL_ESTATE_ADDRESS_REQUEST = `${CREATE_REAL_ESTATE_ADDRESS}_REQUEST`;

export const PUBLISH_OFFER_ITEM = 'PUBLISH_OFFER_ITEM';
export const PUBLISH_OFFER_ITEM_SUCCESS = `${PUBLISH_OFFER_ITEM}_SUCCESS`;

export const DEACTIVATE_OFFER_ITEM = 'DEACTIVATE_OFFER_ITEM';
export const DEACTIVATE_OFFER_ITEM_SUCCESS = `${DEACTIVATE_OFFER_ITEM}_SUCCESS`;

export const REACTIVATE_OFFER_ITEM = 'REACTIVATE_OFFER_ITEM';
export const REACTIVATE_OFFER_ITEM_SUCCESS = `${REACTIVATE_OFFER_ITEM}_SUCCESS`;

export const GET_ADVERT_IMAGES = 'GET_ADVERT_IMAGES';
export const GET_ADVERT_IMAGES_REQUEST = `${GET_ADVERT_IMAGES}_REQUEST`;
export const GET_ADVERT_IMAGES_SUCCESS = `${GET_ADVERT_IMAGES}_SUCCESS`;

export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export const CLEAR_ADVERT_FORM = 'CLEAR_ADVERT_FORM';

const offerItemInit = {
    status: OfferItemStatus.EDIT,
    offerType: {
        code: 'SELL',
        value: 'Predaj',
        order: 1,
        urlFriendlyValue: 'predaj',
        userFriendlyValue: 'na predaj'
    }
};

export const initialState = {
    currentStep: {},
    advert: initialPayload({}),
    images: initialPayload([]),
    offerItem: initialPayload(offerItemInit),
    address: initialPayload({}),
    publish: initialPayload({}),
    reactivate: initialPayload({}),
    deactivate: initialPayload({}),
    formErrors: {}
};

export function reducer(state = initialState, action) {

    const handlers = {
        [CLEAR_ADVERT_FORM]: {
            ...state,
            ...initialState,
            currentStep: NEW_ADVERT_STEPS[0]
        },
        [SET_CURRENT_STEP]: {
            ...state,
            currentStep: action.payload
        },
        [SET_OFFER_ITEM_PROPERTY]: {
            ...state,
            offerItem: {
                ...state.offerItem,
                data: { ...state.offerItem.data, ...action.payload }
            }
        },
        [CREATE_OFFER_ITEM]: {
            ...state,
            offerItem: action.payload
        },
        [CREATE_OFFER_ITEM_REQUEST]: {
            ...state,
            offerItem: {
                ...action.payload,
                data: state.offerItem.data
            }
        },
        [CREATE_OFFER_ITEM_SUCCESS]: {
            ...state,
            offerItem: action.payload
        },
        [GET_OFFER_ITEM]: {
            ...state,
            offerItem: action.payload
        },
        [GET_OFFER_ITEM_REQUEST]: {
            ...state,
            offerItem: {
                ...action.payload,
                data: state.offerItem.data
            }
        },
        [CREATE_REAL_ESTATE]: {
            ...state,
            advert: action.payload
        },
        [CREATE_REAL_ESTATE_REQUEST]: {
            ...state,
            advert: {
                ...action.payload,
                data: state.advert.data
            }
        },
        [GET_REAL_ESTATE_REQUEST]: {
            ...state,
            advert: {
                ...action.payload,
                data: state.advert.data
            }
        },
        [GET_REAL_ESTATE_SUCCESS]: {
            ...state,
            advert: action.payload
        },
        [GET_REAL_ESTATE_FAILED]: {
            ...state,
            advert: {
                ...action.payload,
                data: state.advert.data
            }
        },
        [SET_ADVERT_PROPERTY]: {
            ...state,
            advert: {
                ...state.advert,
                data: { ...state.advert.data, ...action.payload }
            }
        },
        [CLEAR_REAL_ESTATE]: {
            ...state,
            advert: initialPayload({
                realEstateType: state.advert?.data?.realEstateType,
                extId: state.advert?.data?.extId
            })
        },
        [SET_ADDRESS]: {
            ...state,
            address: {
                ...state.address,
                data: action.payload,
                error: null
            }
        },
        [GET_ADDRESS_REQUEST]: {
            ...state,
            address: {
                ...action.payload,
                data: state.address.data
            }
        },
        [GET_ADDRESS_SUCCESS]: {
            ...state,
            address: action.payload
        },
        [GET_ADDRESS_FAILED]: {
            ...state,
            address: {
                ...action.payload,
                data: state.address.data
            }
        },
        [SET_ADDRESS_PROPERTY]: {
            ...state,
            address: {
                ...state.address,
                data: { ...state.address.data, ...action.payload }
            }
        },
        [SET_ADDRESS_REGION]: {
            ...state,
            address: {
                ...state.address,
                data: {
                    ...state.address.data,
                    ...action.payload,
                    district: null
                }
            }
        },
        [CREATE_REAL_ESTATE_ADDRESS]: {
            ...state,
            address: action.payload
        },
        [CREATE_REAL_ESTATE_ADDRESS_REQUEST]: {
            ...state,
            address: {
                ...action.payload,
                data: state.address.data
            }
        },
        [PUBLISH_OFFER_ITEM]: {
            ...state,
            publish: action.payload
        },
        [PUBLISH_OFFER_ITEM_SUCCESS]: {
            ...state,
            ...initialState
        },
        [DEACTIVATE_OFFER_ITEM]: {
            ...state,
            deactivate: action.payload
        },
        [DEACTIVATE_OFFER_ITEM_SUCCESS]: {
            ...state,
            ...initialState
        },
        [REACTIVATE_OFFER_ITEM]: {
            ...state,
            reactivate: action.payload
        },
        [REACTIVATE_OFFER_ITEM_SUCCESS]: {
            ...state,
            ...initialState
        },
        [SET_FORM_ERROR]: {
            ...state,
            formErrors: { ...state.formErrors, ...action.payload }
        },
        [GET_ADVERT_IMAGES]: {
            ...state,
            images: action.payload
        },
        [GET_ADVERT_IMAGES_REQUEST]: {
            ...state,
            images: {
                ...action.payload,
                data: []
            }
        },
        [GET_ADVERT_IMAGES_SUCCESS]: {
            ...state,
            images: {
                data: action.payload?.data
            }
        }
    };
    return handleAction(action.type, handlers) || state;
}
