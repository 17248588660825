import {Box, Button} from "@material-ui/core";
import React from "react";
import './style.scss';

interface IProps {
    style?: any,
    image?: { src?: string, width?: string | number, height?: string | number },
    title?: string,
    subtitle?: string,
    buttonTitle?: string,
    onPress?: () => void,
}

const Banner = ({buttonTitle, onPress, style, image, title, subtitle}: IProps) => {
    return (
        <Box data-component="Banner">
            <Box className="container" style={style}>
                <Box>
                    <h3>{title}</h3>
                    <p>{subtitle}</p>
                </Box>
                <Box alignItems="center" display="flex">
                    <img src={image?.src} width={image?.width} height={image?.height} />

                    {onPress && buttonTitle &&
                        <Button variant="contained" onClick={onPress}>{buttonTitle}</Button>}
                </Box>
            </Box>
        </Box>
    );
}

export default Banner;
