import {Box, Grid, Paper} from '@material-ui/core';
import Advertisement from '../../components/Advertisement';
import Header from '../../components/Header';
import Layout from '../../layouts/NewAdvert';
import React, {useEffect, useMemo} from 'react';
import './style.scss';
import t from '../../texts';
import {
    PLACEHOLDER_PROPERTY_IMAGE,
    REDUCER_ADVERT,
    REDUCER_REALITY_COMPANY,
} from '../../utils/constant';
import {
    formatAddress,
    formatOfferItemDesc,
    formatPrice,
    isNotNilOrEmpty, sortImagesByPriority
} from '../../utils/helpers';
import {useApiRequest} from '../../store/api';
import {GET_SELLER} from '../../store/reducers/realityCompany';
import {ERouteParameters} from '../../enumerators';
import {useApiDataSelector, useParamName} from '../../hooks/hooks';
import {pipe, range} from "ramda";
import {IImage} from "../../utils/types";
import {renderBadges} from "../../utils/componentUtils";
import {GET_ADVERT_IMAGES} from "../../store/reducers/advert";


const AdvertOverview = () => {
    const offerItemId = useParamName(ERouteParameters.OFFER_ITEM_ID);

    const offerItem = useApiDataSelector((state: any) => state[REDUCER_ADVERT].offerItem);
    const advert = useApiDataSelector((state: any) => state[REDUCER_ADVERT].advert);
    const images = useApiDataSelector((state: any) => state[REDUCER_ADVERT].images);
    const address = useApiDataSelector((state: any) => state[REDUCER_ADVERT].address);
    const seller = useApiDataSelector((state: any) => state[REDUCER_REALITY_COMPANY].seller);

    const getSellerForAdvert = useApiRequest(REDUCER_REALITY_COMPANY, 'offer-item/:offerItemId/person-seller', GET_SELLER, {method: 'get'});
    const fetchImages = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/real-estate/image', GET_ADVERT_IMAGES, {method: 'get'});

    const imagesSort = useMemo(() => pipe<IImage[], IImage[], IImage[]>(sortImagesByPriority, (array) => range(0, 2).map(index => array[index] || PLACEHOLDER_PROPERTY_IMAGE))(images), [images]);

    useEffect(() => {
        if (isNotNilOrEmpty(offerItemId)) {
            getSellerForAdvert({params: {offerItemId}});
            fetchImages({params: {offerItemId}});
        }
    }, [offerItemId]);

    return (
        <Layout>
            <Header />
            <Advertisement />

            <Box data-component="AdvertOverview">

                <h3>{t.STEP_OVERVIEW}</h3>
                <Paper elevation={0}>
                    <Grid container spacing={1}>
                        {imagesSort.map((image, index) =>
                            <Grid key={`${image.extId}-image-${index}`} item xs={12} sm={6}>
                                <img src={image.url} alt="Flat1" />
                            </Grid>
                        )}
                    </Grid>

                    <Grid className="mtb" container>
                        <Grid id="h6" className="left" item sm={12}>
                            <h6>{formatOfferItemDesc(offerItem.offerType, advert)}</h6>
                        </Grid>

                        <Grid className="right" item sm={12}>
                            {renderBadges(advert.badges)}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid id="price" className="left" item xs={12} sm={4}>
                            <p>{formatPrice(offerItem.price)}</p>
                        </Grid>

                        <Grid id="address" className="right" item xs={12} sm={8}>
                            <p>{formatAddress(address)}</p>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} className="left">
                            <p>{offerItem.description}</p>
                        </Grid>
                    </Grid>
                </Paper>

                <h3>{t.CONTACT_INFO}</h3>
                <Paper elevation={0}>
                    <Grid className="table left" container spacing={2}>
                        <Grid className="blueText" item xs={12} sm={5}>{t.NAME}</Grid>
                        <Grid item xs={12} sm={7}>{seller?.firstName}</Grid>

                        <Grid className="blueText" item xs={12} sm={5}>{t.SURNAME}</Grid>
                        <Grid item xs={12} sm={7}>{seller?.lastName}</Grid>

                        <Grid className="blueText" item xs={12} sm={5}>{t.PHONE}</Grid>
                        <Grid item xs={12} sm={7}>{seller?.phone}</Grid>

                        <Grid className="blueText" item xs={12} sm={5}>{t.EMAIL}</Grid>
                        <Grid item xs={12} sm={7}>{seller?.email}</Grid>
                    </Grid>
                </Paper>

                <h3>{t.TRANSACTION}</h3>
                <Paper elevation={0}>
                    <Grid className="table left" container spacing={2}>
                        <Grid className="blueText" item xs={12} sm={5}>{t.COST}</Grid>
                        <Grid item xs={12} sm={7}>{t.FREE}</Grid>
                        <Grid className="blueText" item xs={12} sm={5}>{t.DURATION}</Grid>
                        <Grid item xs={12} sm={7}>{t.DURATION_ONE_MONTH}</Grid>
                    </Grid>
                </Paper>

                {/*<Paper id="statusInfo" elevation={0}>*/}
                {/*    <Grid className="noMargin" container spacing={2}>*/}
                {/*        <Grid item xs={12}>{formatOfferItemStatus(offerItem)}</Grid>*/}
                {/*    </Grid>*/}
                {/*</Paper>*/}
            </Box>
        </Layout>
    );
};

export default AdvertOverview;
