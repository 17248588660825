import { removeImage, removeFile } from '../../utils/imageUtils';
import { handleAction } from '../../utils/helpers';
import { initialPayload } from '../../utils/constant';
import { CLEAR_ADVERT_FORM } from './advert';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const SET_IMAGE_FILES = 'SET_IMAGE_FILES';

export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGES_REQUEST = 'FETCH_IMAGES_REQUEST';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const DELETE_IMAGE_SUCCESS = `${DELETE_IMAGE}_SUCCESS`;
export const DELETE_IMAGE_FAILED = `${DELETE_IMAGE}_FAILED`;
export const DELETE_IMAGE_REQUEST = `${DELETE_IMAGE}_REQUEST`;

export const initialState = {
    allImages: initialPayload([]),
    imagesAsFiles: []
};


export function reducer(state = initialState, action) {

    const handlers = {
        [FETCH_IMAGES]: {
            ...state,
            allImages: action.payload
        },
        [FETCH_IMAGES_REQUEST]: {
            ...state,
            allImages: action.payload,
            imagesAsFiles: []
        },
        [DELETE_IMAGE_REQUEST]: {
            ...state,
            allImages: {
                ...action.payload,
                data: state.allImages.data
            }
        },
        [DELETE_IMAGE_SUCCESS]: {
            ...state,
            allImages: {
                isLoading: false,
                error: null,
                data: removeImage(action.meta?.imageId, state.allImages.data)
            },
            imagesAsFiles: removeFile(action.meta?.imageId, state.imagesAsFiles)
        },
        [DELETE_IMAGE_FAILED]: {
            ...state,
            allImages: {
                ...action.payload,
                data: state.allImages.data
            }
        },
        [SET_IMAGE_FILES]: {
            ...state,
            imagesAsFiles: action.payload
        },
        [CLEAR_ADVERT_FORM]: {
            ...state,
            ...initialState
        }
    };

    return handleAction(action.type, handlers) || state;
}
