import {handleAction} from '../../utils/helpers';
import {UPDATE_USER_SUCCESS, UPDATE_USER_FAILED, UPDATE_USER_REQUEST} from './user';
import {ADMIN_SECTIONS} from '../../utils/constant';

export const SET_IS_COLLAPSED_VIEW = 'SET_IS_COLLAPSED_VIEW';
export const SET_IS_MAP_EXPANDED = 'SET_IS_MAP_EXPANDED';
export const SET_ALERT = 'SET_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';
export const SET_MY_ADVERT_FILTER = 'SET_MY_ADVERT_FILTER';
export const CLEAR_MY_ADVERT_FILTER = 'CLEAR_MY_ADVERT_FILTER';
export const CONTACT_SUPPORT_POST = 'CONTACT_SUPPORT_POST';
export const CONTACT_SUPPORT_POST_SUCCESS = 'CONTACT_SUPPORT_POST_SUCCESS';
export const REPORT_OFFER_ITEM = 'REPORT_OFFER_ITEM';
export const REPORT_OFFER_ITEM_SUCCESS = 'REPORT_OFFER_ITEM_SUCCESS';
export const COOKIE_DIALOG = 'COOKIE_DIALOG';
export const SET_RECAPTCHA = 'SET_RECAPTCHA';
export const SET_SECTION = 'SET_SECTION';

const initFilter = {hideImported: false};

export const initialState = {
    isCollapsedView: false,
    isMapExpanded: false,
    isCookieDialogOpen: false,
    alert: null,
    myAdvertFilter: initFilter,
    recaptchaToken: '',
    section: null
};

export function reducer(state = initialState, action) {

    const handlers = {
        [COOKIE_DIALOG]: {
            ...state,
            isCookieDialogOpen: action.payload
        },
        [SET_IS_COLLAPSED_VIEW]: {
            ...state,
            isCollapsedView: action.payload
        },
        [SET_IS_MAP_EXPANDED]: {
            ...state,
            isMapExpanded: action.payload
        },
        [SET_ALERT]: {
            ...state,
            alert: action.payload
        },
        [CLEAR_ALERT]: {
            ...state,
            alert: null
        },
        [SET_MY_ADVERT_FILTER]: {
            ...state,
            myAdvertFilter: {
                ...state.myAdvertFilter,
                ...action.payload
            }
        },
        [CLEAR_MY_ADVERT_FILTER]: {
            ...state,
            myAdvertFilter: initFilter,
        },
        [SET_RECAPTCHA]: {
            ...state,
            recaptchaToken: action.payload
        },
        [UPDATE_USER_REQUEST]: {
            ...state,
            section: null,
        },
        [UPDATE_USER_SUCCESS]: {
            ...state,
            section: ADMIN_SECTIONS.MY_ACCOUNT
        },
        [UPDATE_USER_FAILED]: {
            ...state,
            section: ADMIN_SECTIONS.MY_ACCOUNT
        },
        [SET_SECTION]: {
            ...state,
            section: action.payload,
        }
    };
    return handleAction(action.type, handlers) || state;
}
