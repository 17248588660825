import React from 'react';
import {Box, Button, Divider, List, ListItem} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {ERoutePaths} from '../../enumerators';
import t from '../../texts';
import './style.scss';
import {createAction} from "../../store/actions";
import {COOKIE_DIALOG} from "../../store/reducers/context";

export default () => {
    const openCloseCookieDialog = createAction(COOKIE_DIALOG);

    return (
        <Box data-component="Footer">
            <Divider />
            <List>
                <ListItem>
                    <Link to={ERoutePaths.GDPR}>Ochrana&nbsp;osobných&nbsp;údajov</Link>
                    {' '}
                    |
                </ListItem>
                <ListItem>
                    <Link to={ERoutePaths.DOCUMENTS}>Dokumenty</Link>
                    {' '}
                    |
                </ListItem>
                <ListItem>
                    <Link to={ERoutePaths.BUSINESS_CONDITIONS}>Obchodné&nbsp;podmienky</Link>
                    {' '}
                    |
                </ListItem>
                <ListItem>
                    <Button variant="text" color="primary"
                            onClick={() => openCloseCookieDialog(true)}>{t.COOKIE_FOOTER}</Button>
                    {' '}
                    |
                </ListItem>
                <ListItem>
                    <Link to={ERoutePaths.CONTACTS}>Kontakty</Link>
                </ListItem>
            </List>
            <Box>&copy; Skreality.sk {new Date().getFullYear()}</Box>
        </Box>
    );
}
