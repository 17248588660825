import {Box, Button, Grid , TextField} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import './style.scss';
import t from '../../texts';
import {isNotNilOrEmpty,} from '../../utils/helpers';
import {useApiRequest} from '../../store/api';
import {IEstateAgency} from '../../utils/types';
import {useHistory} from 'react-router-dom';
import InputErrorText from '../Form/InputErrorText';
import {REDUCER_REALITY_COMPANY} from "../../utils/constant";
import {CREATE_REAL_ESTATE_COMPANY, GET_REALITY_COMPANY_USER} from "../../store/reducers/realityCompany";
import {ERoutePaths} from "../../enumerators";
import {agencyFormValidation, validateForm} from "../Form/validation";

const RegisterEstateAgency = () => {
    const {push, goBack} = useHistory();
    const [agencyForm, setAgencyForm] = useState<IEstateAgency>({} as IEstateAgency);
    const [formErrors, setFormErrors] = useState({} as any);

    const save = useApiRequest(REDUCER_REALITY_COMPANY, 'reality-company', CREATE_REAL_ESTATE_COMPANY, {method: 'post'});
    const getRealityCompanyForUser = useApiRequest(REDUCER_REALITY_COMPANY, 'reality-company', GET_REALITY_COMPANY_USER, {method: 'get'});

    const handleChange = useCallback((event: any) => {
        setAgencyForm({
            ...agencyForm,
            [event.target.name]: event.target.value
        });
    }, [setAgencyForm, agencyForm]);

    const setErrors = useCallback((error: any) => {
        setFormErrors((prevState: any) => ({
            ...prevState,
            ...error
        }));
    }, [setFormErrors]);


    const onRegister = useCallback(async () => {
        try {
            await validateForm(agencyFormValidation, agencyForm, setErrors);
            await save({body: agencyForm});
            await getRealityCompanyForUser();
            push(ERoutePaths.ESTATE_AGENCY_ADMINISTRATION);
        } catch (e) {
            console.error(e);
        }
    }, [agencyForm, save]);

    return (
        <Box data-component="RegisterEstateAgency">
            <Box className="container">
                <Box className="section">
                    <Grid container justify="center">
                        <Grid item>
                            <h2>{t.REGISTER_ESTATE_AGENCY}</h2>
                        </Grid>
                    </Grid>
                    <Box my="1.5rem">
                        <Grid container spacing={2} alignItems="center" justify="flex-end">
                            <Grid className="left blueText" item xs={12} sm={3}>{t.COMPANY_NAME}</Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    name="companyName"
                                    variant="outlined"
                                    size="small"
                                    type="companyName"
                                    value={agencyForm.companyName || ''}
                                    onChange={handleChange}
                                    required
                                    error={isNotNilOrEmpty(formErrors?.companyName)}
                                />
                                <InputErrorText error={formErrors?.companyName} />
                            </Grid>
                            <Grid className="left blueText" item xs={12} sm={3}>{t.COMPANY_INFO}</Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    name="companyInfo"
                                    variant="outlined"
                                    size="small"
                                    value={agencyForm.companyInfo || ''}
                                    onChange={handleChange}
                                    required
                                    multiline
                                    rows={3}
                                    error={isNotNilOrEmpty(formErrors?.companyInfo)}
                                />
                                <InputErrorText error={formErrors?.companyInfo} />
                            </Grid>
                            <Grid className="left blueText" item xs={12} sm={3}>{t.PHONE}</Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    name="phone"
                                    variant="outlined"
                                    size="small"
                                    value={agencyForm.phone || ''}
                                    onChange={handleChange}
                                    required
                                    error={isNotNilOrEmpty(formErrors?.phone)}
                                />
                                <InputErrorText error={formErrors?.phone} />
                            </Grid>
                            <Grid className="left blueText" item xs={12} sm={3}>{t.EMAIL}</Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    name="email"
                                    variant="outlined"
                                    size="small"
                                    value={agencyForm.email || ''}
                                    onChange={handleChange}
                                    type="email"
                                    required
                                    error={isNotNilOrEmpty(formErrors?.email)}
                                />
                                <InputErrorText error={formErrors?.email} />
                            </Grid>
                            <Grid className="left blueText" item xs={12} sm={3}>{t.WEBSITE}</Grid>
                            <Grid item xs={12} sm={9}>
                                <TextField
                                    name="website"
                                    variant="outlined"
                                    size="small"
                                    value={agencyForm.website || ''}
                                    onChange={handleChange}
                                    type="url"
                                    required
                                    error={isNotNilOrEmpty(formErrors?.website)}
                                />
                                <InputErrorText error={formErrors?.website} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={2} alignItems="center">
                        <p>Po odoslaní formuláru budete kontaktovaný pracovníkom Skreality.sk pre dokončenie registrácie realitnej kancelárie.</p>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                className="loginButton"
                                onClick={onRegister}
                            >
                                {t.REGISTER_ESTATE_AGENCY_BUTTON}
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button className="textButton" onClick={() => goBack()}>{t.GO_BACK}</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default RegisterEstateAgency;
