import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { isArray, isNilOrEmpty } from '../../utils/helpers';

const InputErrorText = ({ error }: {error: any}) => {
    if (isNilOrEmpty(error)) {
        return null;
    }
    if (isArray(error)) {
        return error.map((e: any) => <FormHelperText error>{e}</FormHelperText>);
    }
    return <FormHelperText error>{error}</FormHelperText>;
};

export default InputErrorText;
