import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { formatDistance, isNilOrEmpty } from '../../utils/helpers';
import {NEARBY_PLACES_MAP} from "../../utils/constant";

const DetailTable = ({ detail }: { detail: any }) => {
    const { nearbyPlaces } = detail;

    if (isNilOrEmpty(nearbyPlaces)) {
        return null;
    }

    return (
        <Grid className="left detailTableFontSize" container spacing={8}>
            <Grid item xs={12}>
                <Grid id="detailLeft" container spacing={2}>
                    {Object.keys(nearbyPlaces).map((key) => {
                        if (isNilOrEmpty(nearbyPlaces[key])) {
                            return null;
                        }
                        const { distance, name, type } = nearbyPlaces[key];

                        // @ts-ignore
                        const val = NEARBY_PLACES_MAP[type];
                        return (
                            <Fragment key={type}>
                                <Grid className="blueText" item xs={3}>{val}</Grid>
                                <Grid item xs={7}>{name}</Grid>
                                <Grid className="blueText" item xs={2}>{formatDistance(distance)}</Grid>
                            </Fragment>
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};
export default DetailTable;
