import React from 'react';
import {ListItem} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {MenuItemType} from '../../utils/types';


interface IProps {
    children: React.ReactNode;
    item: MenuItemType;
    selected?: boolean;
    disabled?: boolean;
}

const MenuListItem = ({item, children, selected = false, disabled = false}: IProps) => item.callback ? (
    <ListItem
        disabled={disabled}
        button
        onClick={item.callback}
        selected={selected}
    >
        {children}
    </ListItem>
) : (
    /* @ts-ignore */
    <ListItem button component={Link} to={item.link} selected={selected} disabled={disabled}>
        {children}
    </ListItem>
);

export default MenuListItem;
