import {
    AUTH_TOKEN, AUTH_TOKEN_EXPIRE, SEARCH_DATA_KEY, USER_FAVORITE, LAST_SEARCH_DATA_KEY,
} from './constant';
import {isNotNilOrEmpty, isNilOrEmpty} from './helpers';
import {last, takeLast, gte, length, includes, concat, without, equals, any} from 'ramda';

const moment = require('moment');

export const removeStoredAccessToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_TOKEN_EXPIRE);
};

export const setStoredAccessToken = (accessToken) => {
    localStorage.setItem(AUTH_TOKEN, accessToken);
    localStorage.setItem(AUTH_TOKEN_EXPIRE, moment());
};

export const getSearchHistory = () => {
    return JSON.parse(localStorage.getItem(SEARCH_DATA_KEY) || '[]') || [];
};

export const setSearchHistory = (filter) => {
    if (isNilOrEmpty(filter)) {
        return;
    }
    const history = getSearchHistory();
    localStorage.setItem(LAST_SEARCH_DATA_KEY, JSON.stringify(filter));
    if (any(equals(filter), history)) {
        return;
    }
    history.push(filter);
    localStorage.setItem(SEARCH_DATA_KEY, JSON.stringify(gte(length(history), 4) ? takeLast(4, history) : history));
};

export const getLastSearch = () => {
    const history = getSearchHistory();
    return JSON.parse(localStorage.getItem(LAST_SEARCH_DATA_KEY)) || (isNotNilOrEmpty(history) && last(history));
};

export const getFavoritesAdvert = () => {
    return JSON.parse(localStorage.getItem(USER_FAVORITE) || '[]') || [];
};

export const isFavoriteAdvert = offerItemId => {
    const items = getFavoritesAdvert();
    return includes(offerItemId, items);
};

export const saveFavoriteAdvert = offerItemId => {
    const items = getFavoritesAdvert();
    const result = includes(offerItemId, items) ? without([offerItemId], items) : concat(items, [offerItemId]);
    localStorage.setItem(USER_FAVORITE, JSON.stringify(result));
};

export const setStorageItem = (key, value) => {
    localStorage.setItem(key, value.toString());
};
export const getBooleanItem = (key) => {
    return localStorage.getItem(key) === 'true';
};
