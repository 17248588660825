import Header from '../../components/Header';
import Layout from '../../layouts/Login';
import React from 'react';
import { Box } from '@material-ui/core';
import './style.scss';
import Register from '../../components/Register';

export default () => (
    <Box data-component="Register_Page">
        <Layout>
            <Header />
            <Register />
        </Layout>
    </Box>
);
