import React, {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import t from '../../texts';
import {Box, IconButton} from '@material-ui/core';
import {Upload, Trash} from 'react-feather';
import './style.scss';

interface Props {
    maxFiles: number,
    onUpload: (file: any) => void,
    onDelete?: (file: any) => void,
    uploadedFiles?: any[],
    children?: React.ReactNode,
}

const Dropzone = ({maxFiles, onUpload, onDelete, uploadedFiles = [], children}: Props) => {

    const {
        acceptedFiles,
        getRootProps,
        getInputProps
    } = useDropzone({
        maxFiles
    });

    useEffect(() => {
        onUpload(acceptedFiles);
    }, [acceptedFiles]);

    return (
        <Box mb={1} data-component="Dropzone">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                {!children &&
                    <>
                        <p>{t.UPLOAD_IMAGES_DESC}</p>
                        <Box><Upload /></Box>
                        <em>{t.UPLOAD_MAX_FILES(maxFiles)}</em>
                    </>
                }
                {children}
            </div>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-evenly"
                alignItems="center"
                flexWrap="wrap"
                p={1}
                m={1}
            >
                {uploadedFiles?.map((file: any) => (
                    <Box m={1} className="dropzone-image-container" key={file.extId}>
                        {onDelete &&
                            <IconButton aria-label="delete" className="dropzone-image-trash"
                                        onClick={() => onDelete(file)}>
                                <Trash fontSize="small" />
                            </IconButton>
                        }
                        <img src={file.url} alt={`image-${file.extId}`} className="dropzone-image" />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
export default Dropzone;
