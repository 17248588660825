import React, {useCallback, useMemo, useState} from 'react';
import {ChipUser} from '../../utils/types';
import {
    Avatar, Box,
    Card,
    CardContent,
    CardHeader, Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem, Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {User, Trash, Edit} from 'react-feather';
import t from '../../texts';
import {useApiRequest} from '../../store/api';
import {REDUCER_REAL_ESTATE, REDUCER_USER} from '../../utils/constant';
import {REMOVE_USER_FROM_COMPANY} from '../../store/reducers/user';
import {GET_MY_AGENCY_USERS} from '../../store/reducers/realEstate';
import {useHistory} from 'react-router-dom';
import {ERouteParameters, ERoutePaths, USER_PRIVILEGES} from '../../enumerators';
import {formatAcronym, formatFullName} from "../../utils/helpers";

require('./chip.scss');


interface UserChipProps {
    reUser: ChipUser;
    realityCompanyId: string;
    rowItemSize: 6 | 12;
    onClick?: Function;
}

const UserChip = ({reUser, rowItemSize, onClick, realityCompanyId}: UserChipProps) => {
    const {push} = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState<boolean>(false);

    const user = useMemo(() => reUser?.user, [reUser]);

    const getMyAgencyUsers = useApiRequest(REDUCER_REAL_ESTATE, 'reality-company/:realityCompanyId/users', GET_MY_AGENCY_USERS, {method: 'get'});
    const removeUserFromCompany = useApiRequest(REDUCER_USER, 'reality-company/:realityCompanyId/user/:userId', REMOVE_USER_FROM_COMPANY, {method: 'delete'});

    const openActions = (evt: any) => {
        setAnchorEl(evt.currentTarget);
        setOpen(true);
    };

    const closeActions = () => {
        setOpen(false);
    };

    const handleChipClick = (evt: React.ChangeEvent<any>) => {
        if (onClick && !evt.target.id.includes('menu') && !open) {
            onClick(reUser);
        }

    };

    const show = useCallback(() => {
        push(ERoutePaths.SEARCH_RESULT_SELLER.replace(`:${ERouteParameters.SELLER_ID}`, user.extId));
    }, []);

    const edit = () => {

    };

    const remove = useCallback(async () => {
        await removeUserFromCompany({params: {realityCompanyId, userId: user.extId}});
        getMyAgencyUsers({params: {realityCompanyId}});
    }, [removeUserFromCompany, user]);

    const resolveCardClasses = () => {
        let classes = '';
        if (onClick) {
            classes += 'clickable ';
        }
        if (reUser.selected) {
            classes += 'chipSelected ';
        }
        return classes;
    };

    return (
        <Grid id="card" key={user.extId} item xs={12} sm={rowItemSize}>
            <Box borderColor={reUser.selected ? '#3775ca' : '#f4f4f4'} border={2} borderRadius="2%">
                <Card className={resolveCardClasses()}>
                    <span onClick={handleChipClick}>
                        <CardHeader
                            avatar={
                                reUser.user.picture ?
                                    <Avatar src={reUser.user.picture.url} alt={`"user-avatar-${reUser.user.extId}`} /> :
                                    <Avatar alt="">{formatAcronym(formatFullName(user))}</Avatar>
                            }
                            action={(
                                <div>
                                    <IconButton aria-label="settings" onClick={openActions}>
                                        <MoreVertIcon id={`menu-${user.extId}`} />
                                    </IconButton>
                                    <Menu
                                        open={open}
                                        keepMounted
                                        anchorEl={anchorEl}
                                        getContentAnchorEl={null}
                                        onClose={closeActions}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }}
                                        transformOrigin={{
                                            vertical: 35,
                                            horizontal: 'right'
                                        }}
                                    >
                                        <MenuItem onClick={show}>
                                            <ListItemIcon>
                                                <User />
                                            </ListItemIcon>
                                            <ListItemText primary={t.SELLER_ADVERTS} />
                                        </MenuItem>
                                        <MenuItem onClick={edit}>
                                            <ListItemIcon>
                                                <Edit />
                                            </ListItemIcon>
                                            <ListItemText primary={t.SELLER_EDIT} />
                                        </MenuItem>
                                        <MenuItem onClick={remove}>
                                            <ListItemIcon>
                                                <Trash />
                                            </ListItemIcon>
                                            <ListItemText primary={t.SELLER_DELETE} />
                                        </MenuItem>
                                    </Menu>
                                </div>
                            )}
                        />
                        <CardContent>
                            <Typography variant="body2" component="p">
                                {`${user.firstName} ${user.lastName}`} -
                                {reUser.role === USER_PRIVILEGES.COMPANY_SELLER && <strong> Predajca</strong>}
                                {reUser.role === USER_PRIVILEGES.COMPANY_ADMIN && <strong> Správca RK</strong>}
                            </Typography>
                            <Typography variant="body2" component="p">
                                {user.email ? user.email : ''}
                            </Typography>
                            <Typography variant="body2" component="p">
                                {user.phone ? user.phone : <span>&nbsp;</span>}
                            </Typography>
                        </CardContent>
                    </span>
                </Card>
            </Box>
        </Grid>
    );
};

export default UserChip;
