import { Grid } from '@material-ui/core';
import t from '../../texts';
import React from 'react';
import './style.scss';

export default () => (
    <Grid container direction="column" data-component="Logo">
        <Grid item>
            <img src={process.env.PUBLIC_URL + '/images/logos/black-on-transparent-no-text.png'} width="75" height="75" alt={t.HOMEPAGE_TITLE} />
            {' '}
            {/* To-do: Přepsat width a height do CSS. */}
            <h1>{t.HOMEPAGE_TITLE}</h1>
            <p>{t.HOMEPAGE_SUBTITLE}</p>
        </Grid>
    </Grid>
);
