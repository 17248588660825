import React, {useCallback, useEffect, useState} from 'react';
import {Grid, TextField} from '@material-ui/core';
import t from "../../texts";
import {isNilOrEmpty, isNotNilOrEmpty} from "../../utils/helpers";
import InputErrorText from "../Form/InputErrorText";
import ConfirmDialog from "../ConfirmDialog";
import useAgency from "../../hooks/useAgency";
import {agencyFormValidation, validateForm} from "../Form/validation";

interface IProps {
    updateAgency: (value: any) => void;
}

const EstateAgencyForm = ({updateAgency}: IProps) => {
    const {myAgency} = useAgency();
    const [formErrors, setFormErrors] = useState({} as any);
    const [agencyForm, setAgencyForm] = useState({} as any);

    useEffect(() => {
        if (isNilOrEmpty(agencyForm) && isNotNilOrEmpty(myAgency)) {
            setAgencyForm(myAgency);
        }
    }, []);

    const handleChange = useCallback((event: any) => {
        setAgencyForm({
            ...agencyForm,
            [event.target.name]: event.target.value
        });
    }, [setAgencyForm, agencyForm]);


    const setErrors = useCallback((error: any) => {
        setFormErrors((prevState: any) => ({
            ...prevState,
            ...error
        }));
    }, [setFormErrors]);

    const update = useCallback(async () => {
        await validateForm(agencyFormValidation, agencyForm, setErrors);
        updateAgency(agencyForm);
    }, [agencyForm, setErrors, updateAgency])

    return (
        <ConfirmDialog
            name={t.EDIT_FORM}
            body={(
                <Grid container spacing={2} alignItems="center" justify="flex-end">
                    <Grid className="left blueText" item xs={12} sm={3}>{t.COMPANY_NAME}</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            name="companyName"
                            variant="outlined"
                            size="small"
                            type="companyName"
                            value={agencyForm.companyName || ''}
                            onChange={handleChange}
                            required
                            error={isNotNilOrEmpty(formErrors?.companyName)}
                        />
                        <InputErrorText error={formErrors?.companyName} />
                    </Grid>
                    <Grid className="left blueText" item xs={12} sm={3}>{t.COMPANY_INFO}</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            name="companyInfo"
                            variant="outlined"
                            size="small"
                            value={agencyForm.companyInfo || ''}
                            onChange={handleChange}
                            required
                            multiline
                            rows={3}
                            error={isNotNilOrEmpty(formErrors?.companyInfo)}
                        />
                        <InputErrorText error={formErrors?.companyInfo} />
                    </Grid>
                    <Grid className="left blueText" item xs={12} sm={3}>{t.PHONE}</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            name="phone"
                            variant="outlined"
                            size="small"
                            value={agencyForm.phone || ''}
                            onChange={handleChange}
                            required
                            error={isNotNilOrEmpty(formErrors?.phone)}
                        />
                        <InputErrorText error={formErrors?.phone} />
                    </Grid>
                    <Grid className="left blueText" item xs={12} sm={3}>{t.EMAIL}</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            name="email"
                            variant="outlined"
                            size="small"
                            value={agencyForm.email || ''}
                            onChange={handleChange}
                            type="email"
                            required
                            error={isNotNilOrEmpty(formErrors?.email)}
                        />
                        <InputErrorText error={formErrors?.email} />
                    </Grid>
                    <Grid className="left blueText" item xs={12} sm={3}>{t.WEBSITE}</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            name="website"
                            variant="outlined"
                            size="small"
                            value={agencyForm.website || ''}
                            onChange={handleChange}
                            type="url"
                            required
                            error={isNotNilOrEmpty(formErrors?.website)}
                        />
                        <InputErrorText error={formErrors?.website} />
                    </Grid>
                </Grid>
            )}
            title={t.EDIT_AGENCY}
            closeButtonName={t.CLOSE}
            confirmButtonName={t.SAVE}
            confirmButtonFn={update}
            maxWidth="sm"
        />
    );
};

export default EstateAgencyForm;
