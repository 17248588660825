import {getConfig} from "../../App";
import {ReCaptcha} from "react-recaptcha-v3";
import React from "react";
import {createAction} from "../../store/actions";
import {SET_RECAPTCHA} from "../../store/reducers/context";

const Recaptcha = ({actionName}: { actionName: string }) => {
    const setRecaptchaToken = createAction(SET_RECAPTCHA);

    return (
        <ReCaptcha
            sitekey={getConfig().recaptchaSiteKey}
            action={actionName}
            verifyCallback={setRecaptchaToken}
        />
    );
}

export default Recaptcha;
