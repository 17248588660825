import {
    Avatar,
    Box,
    Card, CardContent,
    CardHeader, CardMedia,
    Grid, Typography,
} from '@material-ui/core';
import t from '../../texts';
import React, {useCallback, useMemo, Fragment} from 'react';
import './style.scss';
import {getSearchHistory} from '../../utils/storage';
import {formatFavoriteText, isArray, isNilOrEmpty, isNotNilOrEmpty} from '../../utils/helpers';
import {createAction} from '../../store/actions';
import {SET_FILTER} from '../../store/reducers/search';
import {useHistory} from 'react-router-dom';
import {useFilterBuilder} from "../../hooks/useFilterBuilder";
import {drop, head, join} from "ramda";

const FavoriteSearch = () => {
    const history = useHistory();
    const favorites = useMemo(() => getSearchHistory(), []) as any[];
    const {urlFromFilter} = useFilterBuilder();
    const setFilter = createAction(SET_FILTER);

    const onClick = useCallback((filter) => {
        setFilter(filter);
        history.push(urlFromFilter(filter));
    }, [setFilter]);

    if (isNilOrEmpty(favorites)) {
        return null;
    }
    return (
        <Box data-component="FavoriteSearch">
            <h3>{t.FAVORITE_QUERY}</h3>

            <Grid container justify="center" spacing={2}>
                {isNotNilOrEmpty(favorites) && isArray(favorites) && favorites.map((item: any, index: number) => {
                    const text = formatFavoriteText(item);
                    return (
                        <Fragment key={index}>
                            <Grid item xs={12} sm={6}>
                                <Card onClick={() => onClick(item)} className="card">
                                    <CardHeader
                                        avatar={
                                            <Avatar>
                                                <img
                                                    src={process.env.PUBLIC_URL + `/images/${item?.realEstateType?.code}.png`}
                                                    width={20} />
                                            </Avatar>
                                        }
                                        title={head(text)}
                                        subheader={join(', ', drop(1, text))}
                                    />
                                </Card>
                            </Grid>
                        </Fragment>
                    )
                })}
            </Grid>
        </Box>
    );
};

export default FavoriteSearch;
