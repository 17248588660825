import { handleAction } from '../../utils/helpers';
import { initialPayload } from '../../utils/constant';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_AUTHENTICATE = 'SET_AUTHENTICATE';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_REQUEST = `${SIGN_IN}_REQUEST`;
export const SIGN_IN_SUCCESS = `${SIGN_IN}_SUCCESS`;
export const SIGN_IN_FAILED = `${SIGN_IN}_FAILED`;

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = `${RESET_PASSWORD}_SUCCESS`;

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = `${CHANGE_PASSWORD}_SUCCESS`;
export const CHANGE_PASSWORD_FAILED = `${CHANGE_PASSWORD}_FAILED`;

export const LOG_OUT = 'LOG_OUT';
export const LOG_OUT_SUCCESS = `${LOG_OUT}_SUCCESS`;

export const FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD';
export const FORGOTTEN_PASSWORD_SUCCESS = `${FORGOTTEN_PASSWORD}_SUCCESS`;

export const REGISTRATION = 'REGISTRATION';
export const REGISTRATION_SUCCESS = `${REGISTRATION}_SUCCESS`;
export const REGISTRATION_FAILED = `${REGISTRATION}_FAILED`;
export const REGISTRATION_FAILED_EXISTED = 'REGISTRATION_FAILED_EXISTED';

export const initialState = {
    isAuthenticated: null,
    accessToken: initialPayload(null),
    forgottenPassword: initialPayload(null),
    registration: initialPayload(null),
};

export function reducer(state = initialState, action) {

    const handlers = {
        [SIGN_IN]: {
            ...state,
            accessToken: {
                data: action.payload?.data?.accessToken
            }
        },
        [SET_ACCESS_TOKEN]: {
            ...state,
            accessToken: {
                ...state.accessToken,
                data: action.payload
            }
        },
        [SET_AUTHENTICATE]: {
            ...state,
            isAuthenticated: action.payload
        },
        [FORGOTTEN_PASSWORD]: {
            ...state,
            forgottenPassword: action.payload
        },
        [REGISTRATION]: {
            ...state,
            registration: action.payload
        },
    };
    return handleAction(action.type, handlers) || state;
}
