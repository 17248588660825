import { Box, FormControl, Grid, Paper } from '@material-ui/core';
import Advertisement from '../../components/Advertisement';
import Header from '../../components/Header';
import Layout from '../../layouts/NewAdvert';
import React, { useEffect, useMemo } from 'react';
import './style.scss';
import t from '../../texts';
import { withCodebook, withState } from '../../components/Form/InputHOC';
import { CheckboxInputComp, NumberInputComp, SelectCodebookComp } from '../../components/Form';
import {
    BUILDING_MATERIAL_TYPE,
    COMMERCIAL_TYPE,
    HOUSE_TYPE,
    LOT_TYPE,
    REAL_ESTATE_DISPOSITION,
    REAL_ESTATE_OWNERSHIP,
    REAL_ESTATE_STATE,
    REAL_ESTATE_TYPE,
    REAL_ESTATE_TYPES,
    REDUCER_ADVERT
} from '../../utils/constant';
import { CLEAR_REAL_ESTATE, SET_ADVERT_PROPERTY } from '../../store/reducers/advert';
import { useApiDataSelector, usePrevious } from '../../hooks/hooks';
import { isNotNilOrEmpty } from '../../utils/helpers';
import { createAction } from '../../store/actions';


const SelectCodebook = withCodebook(SelectCodebookComp, 'advert', SET_ADVERT_PROPERTY);
const CheckboxInput = withState(CheckboxInputComp, 'advert', SET_ADVERT_PROPERTY);
const NumberInput = withState(NumberInputComp, 'advert', SET_ADVERT_PROPERTY);

const AdvertProperties = () => {
    const { realEstateType } = useApiDataSelector((state: any) => state[REDUCER_ADVERT].advert);
    const prevRealEstateType = usePrevious(realEstateType);
    const clearRealEstate = createAction(CLEAR_REAL_ESTATE);

    const isApartment = useMemo(() => realEstateType?.code === REAL_ESTATE_TYPES.APARTMENT, [realEstateType]);
    const isHouse = useMemo(() => realEstateType?.code === REAL_ESTATE_TYPES.HOUSE, [realEstateType]);
    const isCommercial = useMemo(() => realEstateType?.code === REAL_ESTATE_TYPES.COMMERCIAL, [realEstateType]);
    const isLot = useMemo(() => realEstateType?.code === REAL_ESTATE_TYPES.LOT, [realEstateType]);

    useEffect(() => {
        // @ts-ignore
        if (isNotNilOrEmpty(prevRealEstateType) && isNotNilOrEmpty(realEstateType) && prevRealEstateType?.code !== realEstateType?.code) {
            clearRealEstate();
        }
    }, [realEstateType]);

    return (
        <Layout>
            <Header />
            <Advertisement />

            <Box data-component="AdvertProperties">
                <h3>{t.STEP_PROPERTIES}</h3>

                <Paper elevation={0}>
                    <Grid className="noMargin" container spacing={1} justify="flex-end" alignItems="center">
                        <Grid className="left blueText" item xs={12} sm={5}>{t.REAL_ESTATE_TYPE}</Grid>
                        <Grid item xs={12} sm={7}>
                            <FormControl variant="outlined" size="small">
                                <SelectCodebook
                                    codebookName={REAL_ESTATE_TYPE}
                                    property="realEstateType"
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
                <h3>{realEstateType?.value}</h3>

                <Paper elevation={0}>
                    {isHouse && (
                        <Grid container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12} sm={5}>{t.HOUSE_TYPE}</Grid>
                            <Grid item xs={12} sm={7}>
                                <FormControl variant="outlined" size="small">
                                    <SelectCodebook
                                        codebookName={HOUSE_TYPE}
                                        property="houseType"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {(isHouse || isApartment) && (
                        <Grid container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12} sm={5}>{t.TYPE}</Grid>
                            <Grid item xs={12} sm={7}>
                                <FormControl variant="outlined" size="small">
                                    <SelectCodebook
                                        codebookName={REAL_ESTATE_DISPOSITION}
                                        property="realEstateDisposition"
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {isApartment && (
                        <Grid container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12} sm={5}>{t.BUILDING_MATERIAL_TYPES}</Grid>
                            <Grid item xs={12} sm={7}>
                                <FormControl variant="outlined" size="small">
                                    <SelectCodebook
                                        codebookName={BUILDING_MATERIAL_TYPE}
                                        property="buildingMaterialType"
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {(isHouse || isApartment) && (
                        <Grid container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12} sm={5}>{t.OWNERSHIP}</Grid>
                            <Grid item xs={12} sm={7}>
                                <FormControl variant="outlined" size="small">
                                    <SelectCodebook
                                        codebookName={REAL_ESTATE_OWNERSHIP}
                                        property="realEstateOwnership"
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {!isLot && (
                        <Grid container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12} sm={5}>{t.REAL_ESTATE_STATE}</Grid>
                            <Grid item xs={12} sm={7}>
                                <FormControl variant="outlined" size="small">
                                    <SelectCodebook
                                        codebookName={REAL_ESTATE_STATE}
                                        property="realEstateState"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {isLot && (
                        <Grid container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12} sm={5}>{t.LOT_TYPES}</Grid>
                            <Grid item xs={12} sm={7}>
                                <FormControl variant="outlined" size="small">
                                    <SelectCodebook
                                        codebookName={LOT_TYPE}
                                        property="lotType"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {isCommercial && (
                        <Grid container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12} sm={5}>{t.COMMERCIAL_TYPE}</Grid>
                            <Grid item xs={12} sm={7}>
                                <FormControl variant="outlined" size="small">
                                    <SelectCodebook
                                        codebookName={COMMERCIAL_TYPE}
                                        property="commercialType"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    {(isApartment || isCommercial) && (
                        <Grid container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12} sm={5}>{t.FLOORS}</Grid>
                            <Grid item xs={12} sm={7}>
                                <NumberInput label={t.FLOORS} property="floor" min={-5} />
                            </Grid>
                        </Grid>
                    )}
                    {!isLot && (
                        <>
                            <Grid container spacing={1} justify="flex-end" alignItems="center">
                                <Grid className="left blueText" item xs={12} sm={5}>{t.NUMBER_OF_LEVELS}</Grid>
                                <Grid item xs={12} sm={7}>
                                    <NumberInput label={t.NUMBER_OF_LEVELS} property="floors" min={0} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justify="flex-end" alignItems="center">
                                <Grid className="left blueText" item xs={12} sm={5}>{t.BUILD_AREA}</Grid>
                                <Grid item xs={12} sm={7}>
                                    <NumberInput label={t.BUILD_AREA} property="builtArea" min={0} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} justify="flex-end" alignItems="center">
                                <Grid className="left blueText" item xs={12} sm={5}>{t.SURFACE_AREA}</Grid>
                                <Grid item xs={12} sm={7}>
                                    <NumberInput label={t.SURFACE_AREA} property="surfaceArea" min={0} required />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {(isLot || isHouse) && (
                        <Grid container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12} sm={5}>{t.LOT_AREA}</Grid>
                            <Grid item xs={12} sm={7}>
                                <NumberInput label={t.LOT_AREA} property="lotArea" min={0} />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={1} justify="flex-end" alignItems="center">
                        <Grid className="left blueText" item xs={12}>{t.NETWORKS}</Grid>
                        <Grid item xs={12} sm={4}>
                            <CheckboxInput label={t.GAS} property="gasPipe" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CheckboxInput label={t.WATER} property="waterPipe" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CheckboxInput label={t.ELECTRICITY} property="electricity" />
                        </Grid>
                    </Grid>
                    {!isLot && (
                        <Grid className="noMargin" container spacing={1} justify="flex-end" alignItems="center">
                            <Grid className="left blueText" item xs={12}>{t.ADDITIONS}</Grid>
                            {isHouse && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="bungalow" label={t.BUNGALOW} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="lowEnergy" label={t.LOW_ENERGY} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="madeOfWood" label={t.MADE_OF_WOOD} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="moreBuildings" label={t.MORE_BUILDINGS} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="separateBuilding" label={t.SEPARATE_BUILDINGS} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="pool" label={t.POOL} />
                                    </Grid>
                                </>
                            )}
                            {isApartment && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="barrierless" label={t.BARRIERLESS} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="elevator" label={t.ELEVATER} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="balcony" label={t.BALCONY} />
                                    </Grid>
                                </>
                            )}
                            {(isApartment || isHouse) && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="lodgy" label={t.LODGY} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="terrace" label={t.TERRACE} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="cellar" label={t.CELLAR} />
                                    </Grid>
                                </>
                            )}

                            {(isApartment || isCommercial || isHouse) && (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="parking" label={t.PARKING} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CheckboxInput property="garage" label={t.GARAGE} />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}
                </Paper>
            </Box>
        </Layout>
    );
};

export default AdvertProperties;
