import {Avatar, Box, Button, Grid, Paper, Typography} from '@material-ui/core';
import {formatAcronym, formatFullName, isNilOrEmpty, isNotNilOrEmpty} from '../../utils/helpers';
import {Link, useHistory} from 'react-router-dom';
import {ERouteParameters, ERoutePaths} from '../../enumerators';
import React, {useEffect, useState} from 'react';
import {useApiRequest} from '../../store/api';
import {REDUCER_REALITY_COMPANY} from '../../utils/constant';
import {GET_COMPANY, GET_SELLER} from '../../store/reducers/realityCompany';
import {useSelector} from 'react-redux';
import {useParamName} from '../../hooks/hooks';
import t from "../../texts";

const SellerDetail = () => {
    const sellerId = useParamName(ERouteParameters.SELLER_ID);
    const {push} = useHistory();
    const {
        seller: {data: seller},
        company: {data: company}
    } = useSelector((state: any) => state[REDUCER_REALITY_COMPANY]);

    const [showEmail, setShowEmail] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const [showCompanyEmail, setShowCompanyEmail] = useState(false);

    const getUser = useApiRequest(REDUCER_REALITY_COMPANY, 'user/:userId', GET_SELLER, {method: 'get'});
    const getRealityCompany = useApiRequest(REDUCER_REALITY_COMPANY, 'reality-company/user/:userId', GET_COMPANY, {method: 'get'});

    const offerItemsForSeller = () => push(ERoutePaths.SEARCH_RESULT_SELLER.replace(`:${ERouteParameters.SELLER_ID}`, sellerId));

    useEffect(() => {
        if (isNilOrEmpty(seller) || sellerId !== seller.extId) {
            getUser({params: {userId: sellerId}});
            getRealityCompany({params: {userId: sellerId}});
        }
    }, [sellerId]);

    const offerItemsForCompany = () => push(ERoutePaths.SEARCH_RESULT_COMPANY.replace(`:${ERouteParameters.COMPANY_ID}`, company.extId));

    return (
        <Box className="container">
            <Paper className="broker" elevation={0}>
                <Grid container spacing={1}>
                    <Grid className="avatar" item xs={12} sm={2}>
                        <Button onClick={offerItemsForSeller}>
                            {!seller?.picture?.url && <Avatar alt="">{formatAcronym(formatFullName(seller))}</Avatar>}
                            {seller?.picture?.url && <Avatar src={seller?.picture?.url} alt="seller-picture" />}
                        </Button>
                    </Grid>
                    {isNotNilOrEmpty(seller) && (
                        <Grid item xs={12} sm={4}>
                            <Grid container alignItems="flex-start" direction="column">
                                <Typography variant="body2" component="p">
                                    <Link
                                        to={ERoutePaths.SEARCH_RESULT_SELLER.replace(`:${ERouteParameters.SELLER_ID}`, sellerId)}
                                    >
                                        {formatFullName(seller)}
                                    </Link>
                                </Typography>
                                {showEmail ?
                                    <Typography variant="body2" component="p">
                                        {seller?.email}
                                    </Typography>
                                    : <Button color="primary" variant="text" style={{padding: 0}}
                                              onClick={() => setShowEmail(true)}>{t.SHOW_SELLER_EMAIL}</Button>
                                }
                                {showPhone ?
                                    <Typography variant="body2" component="p">
                                        {seller?.phone}
                                    </Typography>
                                    : <Button color="primary" variant="text" style={{padding: 0}}
                                              onClick={() => setShowPhone(true)}>{t.SHOW_SELLER_PHONE}</Button>
                                }
                            </Grid>
                        </Grid>
                    )}
                    {isNotNilOrEmpty(company) && (
                        <>
                            <Grid className="avatar" item xs={12} sm={2}>
                                <Button onClick={offerItemsForCompany}>
                                    {!seller?.companyLogo?.url && <Avatar alt="">{formatAcronym(company?.companyName)}</Avatar>}
                                    {seller?.companyLogo?.url && <Avatar src={company?.companyLogo?.url} alt="company-picture" />}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container alignItems="flex-start" direction="column">
                                    <Typography variant="body2" component="p">
                                        {company?.companyName}
                                    </Typography>
                                    {showCompanyEmail ?
                                        <Typography variant="body2" component="p">
                                            {company?.email}
                                        </Typography>
                                        : <Button color="primary" variant="text" style={{padding: 0}}
                                                  onClick={() => setShowCompanyEmail(true)}>{t.SHOW_SELLER_EMAIL}</Button>
                                    }
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>

                    </Grid>
                    {isNotNilOrEmpty(company) && (
                        <Grid item xs={12} sm={6}>
                            <Button color="primary" variant="text"
                                    onClick={offerItemsForCompany}>{t.COMPANY_ITEMS}</Button>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Box>
    );
};

export default SellerDetail;
