
import { useSelector } from 'react-redux';
import { REDUCER_REAL_ESTATE } from '../utils/constant';
import { useApiRequest } from '../store/api';
import {
    FETCH_COMPANY_OVERVIEW
} from '../store/reducers/realEstate';
import { useCallback } from 'react';
import { isNotNilOrEmpty } from '../utils/helpers';

const useCompanySearchAdmin = () => {
    const {
        data,
        isLoading
    } = useSelector((state: any) => state[REDUCER_REAL_ESTATE].companyItems);

    const pagination = useSelector((state: any) => state[REDUCER_REAL_ESTATE].companyPagination);

    const fetchCompanyOfferItems = useApiRequest(REDUCER_REAL_ESTATE, 'offer-item/search/reality-company/:realityCompanyId', FETCH_COMPANY_OVERVIEW, { method: 'post' });

    const fetchCompanyResult = useCallback((companyId, body = {}) => {
        if (isNotNilOrEmpty(companyId) && !isLoading) {
            fetchCompanyOfferItems({ params: { realityCompanyId: companyId }, queryParams: { ...pagination }, body });
        }
    }, [fetchCompanyOfferItems, pagination, isLoading]);

    return {
        fetchCompanyResult,
        result: data,
        isLoading,
        pagination,
    };
};

export default useCompanySearchAdmin;
