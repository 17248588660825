import { Box, Grid, Paper } from '@material-ui/core';
import React, {CSSProperties} from 'react';
import Footer from '../../components/Footer';
import './style.scss';

interface IProps {
    children: React.ReactNode;
    containerStyle?: CSSProperties,
}

export default ({ children, containerStyle }: IProps) => (
    <Grid container data-component="Layout_Centered" className="App" style={containerStyle}>
        <Paper id="content-paper" elevation={1}>
            <Box id="content-wrapper">
                {children}
            </Box>
            <Footer />
        </Paper>
    </Grid>
);
