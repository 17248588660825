import React, {Fragment, useMemo} from 'react';
import {Box, Chip, Grid, Hidden, Paper} from '@material-ui/core';
import {
    formatAddress,
    formatOfferItemDesc,
    formatPrice,
    isNotNilOrEmpty,
    sortImagesByPriority
} from '../../utils/helpers';
import {IImage, IPagination, ISearchResultContent} from '../../utils/types';
import {Link, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {pipe, range} from 'ramda';
import {PLACEHOLDER_PROPERTY_IMAGE, REDUCER_CONTEXT} from '../../utils/constant';
import './style.scss';
import {ERouteParameters} from '../../enumerators';
import useFilter from "../../hooks/useFilter";


const ResultItem = ({
                        item,
                        linkToDetail,
                        pagination,
                    }: {
    item: ISearchResultContent,
    linkToDetail: (item: ISearchResultContent) => string,
    pagination?: IPagination
}) => {
    const location = useLocation();
    const {isCollapsedView} = useSelector((state: any) => state[REDUCER_CONTEXT]);
    // @ts-ignore
    const images = useMemo(() => pipe<IImage[], IImage[], IImage[]>(sortImagesByPriority, (array) => range(0, isCollapsedView ? 2 : 3).map(index => array[index] || PLACEHOLDER_PROPERTY_IMAGE))(item?.images), [item?.images, isCollapsedView]);
    const link = linkToDetail(item);

    return (
        <Link to={{
            pathname: link,
            state: {
                [ERouteParameters.ORIGINAL_PATH]: location.pathname,
                [ERouteParameters.ORIGINAL_PAGINATION]: pagination,
            }
        }}
        >
            <Box key={item?.extId} className="section">
                <Paper className="paper" elevation={0}>
                    {/* change to list/map when item.overviewImage is array */}
                    <Grid container spacing={1}>
                        {isNotNilOrEmpty(images) && images.map((image: any, index: number) => (
                            <Fragment key={image.extId ? image.extId : `image-key-${index}`}>
                                {index === 0 && (
                                    <Grid id="pic" item xs={12} sm={4}>
                                        <img src={image.url} alt="Flat1"/>
                                    </Grid>
                                )}
                                {index > 0 && (
                                    <Hidden only="xs">
                                        <Grid id="pic" item xs={12} sm={4}>
                                            <img src={image.url} alt="Flat2"/>
                                        </Grid>
                                    </Hidden>
                                )}
                            </Fragment>
                        ))}
                    </Grid>


                    <Grid className="mtb" container justify="space-between">
                        <Grid id="h6" className="left" item xs={12} sm={5}>
                            <h6>{formatOfferItemDesc(item?.offerType, item?.realEstate)}</h6>
                        </Grid>

                        <Hidden only="xs">
                            {!isCollapsedView && (
                                <Grid className="right" item sm={7}>
                                    {item?.badges?.map(badge => (
                                        <Chip
                                            key={badge.code}
                                            label={badge.value}
                                            size="small"
                                            variant="outlined"
                                        />
                                    ))}
                                </Grid>
                            )}
                        </Hidden>

                        <Grid id="price" className="left" item xs={12} sm={4}>
                            <p>{formatPrice(item.price)}</p>
                        </Grid>

                        <Grid id="address" className="right" item xs={12} sm={8}>
                            <p>{formatAddress(item.address)}</p>
                        </Grid>

                    </Grid>
                </Paper>
            </Box>
        </Link>
    );
};

export default ResultItem;
