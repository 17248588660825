import {useParamName} from './hooks';
import {ERouteParameters} from '../enumerators';
import {useSelector} from 'react-redux';
import {REDUCER_AUTH, REDUCER_OFFER_ITEM, REDUCER_SEARCH} from '../utils/constant';
import {useCallback, useEffect, useMemo} from 'react';
import {
    formatToRequestBody,
    isFavoriteSearch,
    isNilOrEmpty,
    isNotNilOrEmpty,
    normalizeSavedSearch,
    reConstructFilter
} from '../utils/helpers';
import {find, pluck, propEq} from 'ramda';
import {useApiRequest} from '../store/api';
import {DELETE_SEARCH, GET_SAVED_SEARCH, SAVE_SEARCH, UPDATE_SEARCH} from '../store/reducers/offerItem';
import {SavedSearch, SearchRequest} from '../utils/types';
import useCodebooks from './useCodebooks';

const useSavedSearch = () => {
    const savedSearchId = useParamName(ERouteParameters.SEARCH_ID);
    const {codebooks} = useCodebooks();

    const filter = useSelector((state: any) => state[REDUCER_SEARCH].filter);
    const {data: mySearches, isLoading} = useSelector((state: any) => state[REDUCER_OFFER_ITEM].searches);

    const savedSearch = useMemo<SavedSearch | null | undefined>(() => {
        if (isNotNilOrEmpty(mySearches) && isNotNilOrEmpty(savedSearchId)) {
            const filter = reConstructFilter(find<SavedSearch>(propEq('extId', savedSearchId), mySearches), codebooks);
            return normalizeSavedSearch(filter);
        } else {
            return null;
        }
    }, [mySearches, savedSearchId, codebooks]);

    const favorites = useMemo<SavedSearch[]>(() => isNotNilOrEmpty(mySearches) ? mySearches.map((item: SavedSearch) => normalizeSavedSearch(reConstructFilter(item, codebooks))) : [], [mySearches, codebooks]);

    const isSavedSearch = useMemo(() => isFavoriteSearch(filter, pluck('savedSearch', favorites)), [filter, favorites]);
    // console.log('useSavedSearch', isSavedSearch, filter, favorites);

    const fetchSavedSearchesForUser = useApiRequest(REDUCER_OFFER_ITEM, 'offer-item/saved-search', GET_SAVED_SEARCH, {method: 'get'});
    const save = useApiRequest(REDUCER_OFFER_ITEM, 'offer-item/saved-search', SAVE_SEARCH, {method: 'post'});
    const update = useApiRequest(REDUCER_OFFER_ITEM, 'offer-item/saved-search/:extId', UPDATE_SEARCH, {method: 'put'});
    const remove = useApiRequest(REDUCER_OFFER_ITEM, 'offer-item/saved-search/:savedSearchId', DELETE_SEARCH, {method: 'delete'});

    useEffect(() => {
        if (isNotNilOrEmpty(savedSearchId) && isNilOrEmpty(savedSearch) && isNilOrEmpty(mySearches) && !isLoading) {
            fetchSavedSearchesForUser();
        }
    }, [savedSearchId]);

    const saveSearch = useCallback(async (filter) => {
        const request = new SearchRequest(formatToRequestBody(filter));
        await save({body: request});
        fetchSavedSearchesForUser();
    }, [save]);

    const updateSavedSearch = useCallback(async (filter) => {
        const request = new SearchRequest(formatToRequestBody(filter));
        await update({params: {extId: savedSearchId}, body: {...savedSearch, savedSearch: request}});
        fetchSavedSearchesForUser();
    }, [fetchSavedSearchesForUser, update]);

    const deleteSavedSearch = useCallback(async (filter) => {
        const search = find(propEq('savedSearch', filter), favorites);
        !!search && await remove({params: {savedSearchId: search.extId}});
        fetchSavedSearchesForUser();
    }, [favorites, remove]);

    return {
        fetchSavedSearchesForUser,
        updateSavedSearch,
        savedSearch: savedSearch?.savedSearch,
        savedSearchList: mySearches,
        deleteSavedSearch,
        saveSearch,
        isSavedSearch,
        savedSearchId,
    };
};

export default useSavedSearch;
