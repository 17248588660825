import React from "react";
import {IEstateAgency, IPagination, ISearchResultContent} from "../../utils/types";

import {REDUCER_CONTEXT, } from "../../utils/constant";
import {Avatar, Box,Grid, Paper, Typography} from "@material-ui/core";
import Loading from "../Loading";
import cx from "classnames";
import {useHistory} from "react-router-dom";
import t from "../../texts";
import {
    formatAcronym,
    isNilOrEmpty,
} from "../../utils/helpers";
import {Alert} from "@material-ui/lab";
import Footer from "../Footer";
import {useSelector} from "react-redux";
import MapExpander from "../Map/MapExpander";

interface IProps {
    result: any[],
    isLoading: boolean,
    linkToDetail: (offerItem: ISearchResultContent) => string,
    pagination?: IPagination,
}

const EstateAgencyList = ({result, isLoading, linkToDetail}: IProps) => {
    const {push} = useHistory();
    const {isCollapsedView} = useSelector((state: any) => state[REDUCER_CONTEXT]);

    return (
        <Box data-component="ResultsList" mt={3}>
            <Loading isLoading={isLoading} />
            <MapExpander />

            <Box className={cx('container', {collapsed: isCollapsedView})}>

                <Grid className="pl" container alignItems="center">
                    <Grid id="h2" className="left" item sm={5}>
                        <h2>{t.AGENCIES_LIST}</h2>
                    </Grid>
                </Grid>

                {result?.map((item: IEstateAgency) => (
                    <Box key={item.extId} className="container clickable"
                         onClick={() => push(linkToDetail(item as any))}>
                        <Paper className="broker" elevation={0}>
                            <Grid container spacing={1}>
                                <Grid className="avatar" item xs={12} sm={2}>
                                    {!item?.companyLogo?.url && <Avatar alt="">{formatAcronym(item?.companyName)}</Avatar>}
                                    {item?.companyLogo?.url && <Avatar src={item?.companyLogo?.url} alt="company-picture" />}
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Typography variant="body2" component="p">
                                        {item?.companyName}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {item?.companyInfo}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Typography variant="body2" component="p">
                                        {item?.website}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {item?.email}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {item?.phone}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                ))}
                {isNilOrEmpty(result) && !isLoading
                    && <Alert className="alert" severity="warning">{t.EMPTY_SEARCH_AGENCIES}</Alert>}
            </Box>

            <Footer />
        </Box>
    );
}

export default EstateAgencyList;
