import React, {useCallback, useEffect, useState} from 'react';
import {Avatar, Box, Button, Grid, Paper, TextField, Typography} from '@material-ui/core';
import {ContactFormInitState, formatAcronym, formatFullName, isNilOrEmpty, isNotNilOrEmpty} from '../../utils/helpers';
import {useApiRequest} from '../../store/api';
import {REDUCER_REALITY_COMPANY, REDUCER_USER} from '../../utils/constant';
import {GET_COMPANY, GET_SELLER} from '../../store/reducers/realityCompany';
import t from "../../texts";
import {IContactForm} from "../../utils/types";
import {EMAIL_TO_SELLER, RESET_EMAIL_FORM} from "../../store/reducers/user";
import {usePrevious} from "../../hooks/hooks";
import {createAction} from "../../store/actions";
import {contactFormValidation, validateForm} from "../Form/validation";
import InputErrorText from "../Form/InputErrorText";
import {useSelector} from "react-redux";
import {ERouteParameters, ERoutePaths} from "../../enumerators";
import {Link, useHistory} from "react-router-dom";

const SellerCompanyDetail = ({offerItemId}: { offerItemId: string }) => {
    const {push} = useHistory();

    const [showEmail, setShowEmail] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const [showCompanyEmail, setShowCompanyEmail] = useState(false);

    const prevOfferItemId = usePrevious(offerItemId);
    const getSellerForAdvert = useApiRequest(REDUCER_REALITY_COMPANY, 'offer-item/:offerItemId/person-seller', GET_SELLER, {method: 'get'});
    const getRealityCompany = useApiRequest(REDUCER_REALITY_COMPANY, 'reality-company/user/:userId', GET_COMPANY, {method: 'get'});
    const sendEmailToSeller = useApiRequest(REDUCER_USER, 'user/:userId/email', EMAIL_TO_SELLER, {method: 'post'});
    const resetForm = createAction(RESET_EMAIL_FORM);

    const {
        seller: {data: seller},
        company: {data: company}
    } = useSelector((state: any) => state[REDUCER_REALITY_COMPANY]);

    const [formErrors, setFormErrors] = useState({} as any);
    const [contactForm, setContactForm] = useState<IContactForm>(ContactFormInitState as IContactForm);

    useEffect(() => {
        if (isNotNilOrEmpty(offerItemId) && (isNilOrEmpty(seller) || prevOfferItemId !== offerItemId)) {
            resetForm();
            getSellerForAdvert({params: {offerItemId}});
        }
    }, [offerItemId]);

    useEffect(() => {
        if (isNotNilOrEmpty(seller)) {
            getRealityCompany({params: {userId: seller.extId}});
        }
    }, [seller]);

    const setErrors = useCallback((error: any) => {
        setFormErrors((prevState: any) => ({
            ...prevState,
            ...error
        }));
    }, [setFormErrors]);

    const sendContactForm = useCallback(async () => {
        if (isNilOrEmpty(seller)) {
            return;
        }
        try {
            await validateForm(contactFormValidation, contactForm, setErrors);
            await sendEmailToSeller({
                body: {...contactForm, offerItemUrl: window.location.href},
                params: {userId: seller.extId}
            });
            setContactForm(ContactFormInitState as IContactForm);
            setFormErrors({});
        } catch (e) {
            console.error(e);
        }

    }, [sendEmailToSeller, contactForm, seller]);

    const handleInputChange = (event: any) => {
        event.persist();
        setContactForm((inputs: IContactForm) => ({...inputs, [event.target.name]: event.target.value}));
    };

    const offerItemsForSeller = () => push(ERoutePaths.SEARCH_RESULT_SELLER.replace(`:${ERouteParameters.SELLER_ID}`, seller.extId));
    const offerItemsForCompany = () => push(ERoutePaths.SEARCH_RESULT_COMPANY.replace(`:${ERouteParameters.COMPANY_ID}`, company.extId));

    return (
        <Box>
            <h3>{t.CONTACT_SELLER}</h3>
            <Paper className="broker" elevation={0}>
                <Grid container spacing={1}>
                    <Grid className="avatar" item xs={12} sm={2}>
                        <Button onClick={offerItemsForSeller}>
                            {!seller?.picture?.url && <Avatar alt="">{formatAcronym(formatFullName(seller))}</Avatar>}
                            {seller?.picture?.url && <Avatar src={seller?.picture?.url} alt="seller-picture" />}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Grid container alignItems="flex-start" direction="column">
                            <Typography variant="body2" component="p">
                                <Link
                                    to={ERoutePaths.SEARCH_RESULT_SELLER.replace(`:${ERouteParameters.SELLER_ID}`, seller?.extId)}>
                                    {formatFullName(seller)}
                                </Link>
                            </Typography>
                            {showEmail ?
                                <Typography variant="body2" component="p">
                                    {seller?.email}
                                </Typography>
                                : <Button color="primary" variant="text" style={{padding: 0}}
                                          onClick={() => setShowEmail(true)}>{t.SHOW_SELLER_EMAIL}</Button>
                            }
                            {showPhone ?
                                <Typography variant="body2" component="p">
                                    {seller?.phone}
                                </Typography>
                                : <Button color="primary" variant="text" style={{padding: 0}}
                                          onClick={() => setShowPhone(true)}>{t.SHOW_SELLER_PHONE}</Button>
                            }
                        </Grid>
                    </Grid>
                    {isNotNilOrEmpty(company) && (
                        <>
                            <Grid className="avatar" item xs={12} sm={2}>
                                <Button onClick={offerItemsForCompany}>
                                    {!company?.companyLogo?.url && <Avatar alt="">{formatAcronym(company?.companyName)}</Avatar>}
                                    {company?.companyLogo?.url && <Avatar src={company?.companyLogo?.url} alt="company-picture" />}
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container alignItems="flex-start" direction="column">
                                    <Typography variant="body2" component="p">
                                        {company?.companyName}
                                    </Typography>
                                    {showCompanyEmail ?
                                        <Typography variant="body2" component="p">
                                            {company?.email}
                                        </Typography>
                                        : <Button color="primary" variant="text" style={{padding: 0}}
                                                  onClick={() => setShowCompanyEmail(true)}>{t.SHOW_SELLER_EMAIL}</Button>
                                    }
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Button color="primary" variant="text" onClick={offerItemsForSeller}>{t.SELLERS_ITEMS}</Button>
                    </Grid>
                    {isNotNilOrEmpty(company) && (
                        <Grid item xs={12} sm={6}>
                            <Button color="primary" variant="text"
                                    onClick={offerItemsForCompany}>{t.COMPANY_ITEMS}</Button>
                        </Grid>
                    )}
                </Grid>
            </Paper>
            <Paper className="paperContact" elevation={0}>
                <Grid container spacing={2} alignItems="center">
                    <Grid className="left blueText" item xs={12} sm={3}>Meno</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            name="name"
                            label="Napr. Ján Novák"
                            variant="outlined"
                            size="small"
                            value={contactForm.name}
                            onChange={handleInputChange}
                            error={isNotNilOrEmpty(formErrors?.name)}
                        />

                        <InputErrorText error={formErrors?.name} />
                    </Grid>

                    <Grid className="left blueText" item xs={12} sm={3}>E-mail</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            name="email"
                            label="Napr. novak@gmail.com"
                            variant="outlined"
                            size="small"
                            value={contactForm.email}
                            onChange={handleInputChange}
                            error={isNotNilOrEmpty(formErrors?.email)}
                        />
                        <InputErrorText error={formErrors?.email} />
                    </Grid>

                    <Grid className="left blueText" item xs={12} sm={3}>Telefón</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            label="Napr. 0905 123 456"
                            variant="outlined"
                            size="small"
                            name="phone"
                            value={contactForm.phone}
                            onChange={handleInputChange}
                            error={isNotNilOrEmpty(formErrors?.phone)}
                        />
                        <InputErrorText error={formErrors?.phone} />
                    </Grid>

                    <Grid className="blueText right charRemains" item
                          xs={12}>Ostáva {isNotNilOrEmpty(contactForm?.message) ? 1000 - contactForm?.message?.length : 1000} znakov</Grid>

                    <Grid className="left blueText" item xs={12} sm={3}>Text</Grid>
                    <Grid item xs={12} sm={9}>
                        <TextField
                            label={t.MESSAGE}
                            variant="outlined"
                            size="small"
                            name="message"
                            value={contactForm.message}
                            onChange={handleInputChange}
                            multiline
                            rows={8}
                            error={isNotNilOrEmpty(formErrors?.message)}
                        />
                    </Grid>
                    <Grid className="buttonAlign" item xs={12}>
                        <Button className="answerButton" onClick={sendContactForm}>{t.SEND}</Button>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default SellerCompanyDetail;
