// @ts-nocheck
import React, {useCallback, useMemo, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid, Hidden,
    Paper,
    Typography
} from '@material-ui/core';
import {
    formatAddress,
    formatDate,
    formatOfferItemDesc,
    formatPrice,
    replaceOfferItemIdPath,
    sortImagesByPriority
} from '../../utils/helpers';
import {PLACEHOLDER_PROPERTY_IMAGE, REDUCER_ADVERT, STATUS_MAP} from '../../utils/constant';
import {IImage, ISearchResultContent} from '../../utils/types';
import t from '../../texts';
import {ERouteParameters, ERoutePaths, OfferItemStatus} from '../../enumerators';
import {useHistory} from 'react-router-dom';
import {pipe, range} from 'ramda';
import {useApiRequest} from '../../store/api';
import {DELETE_OFFER_ITEM} from '../../store/reducers/realEstate';
import ConfirmDialog from '../ConfirmDialog';
import {DEACTIVATE_OFFER_ITEM, REACTIVATE_OFFER_ITEM} from '../../store/reducers/advert';
import {ExpandMore} from "@material-ui/icons";
import ViewsCharts from "./ViewsChart";
import {renderBadges} from "../../utils/componentUtils";

const MyAccountItem = ({
                           overviewItem,
                           fetchCallback,
                           showActions = false,
                           showStatistics = true,
                       }: { overviewItem: ISearchResultContent, fetchCallback?: any, showActions?: boolean, showStatistics?: boolean }) => {
    const history = useHistory();
    const deleteOfferItem = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId', DELETE_OFFER_ITEM, {method: 'delete'});
    const reactivateAdvert = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/reactivate', REACTIVATE_OFFER_ITEM, {method: 'post'});
    const deactivateAdvert = useApiRequest(REDUCER_ADVERT, 'offer-item/:offerItemId/deactivate', DEACTIVATE_OFFER_ITEM, {method: 'post'});

    const [isExpanded, setIsExpanded] = useState(false);

    // @ts-ignore
    const images = useMemo(() => pipe<IImage[], IImage[], IImage[]>(sortImagesByPriority, (array) => range(0, 3).map(index => array[index] || PLACEHOLDER_PROPERTY_IMAGE))(overviewItem?.images), [overviewItem.images]);

    const updateAdvert = useCallback(() => {
        history.push(replaceOfferItemIdPath(ERoutePaths.ADVERT_INFORMATION_ID, overviewItem.extId));
    }, []);

    const showAdvert = useCallback(async () => {
        history.push(ERoutePaths.DETAIL_ID.replace(`:${ERouteParameters.OFFER_ITEM_ID}`, overviewItem.extId), {
            [ERouteParameters.ORIGINAL_PATH]: history.location.pathname
        });
    }, []);

    const reactivateOfferItem = useCallback(async () => {
        await reactivateAdvert({params: {offerItemId: overviewItem.extId}});
        fetchCallback();
    }, []);

    const deactivateOfferItem = useCallback(async () => {
        await deactivateAdvert({params: {offerItemId: overviewItem.extId}});
        fetchCallback();
    }, []);

    const deleteAdvert = useCallback(() => {
        deleteOfferItem({params: {offerItemId: overviewItem?.extId}})
            .then(() => {
                fetchCallback();
            });
    }, [deleteOfferItem]);

    const getImageGrid = (img: IImage, counter: number) => {
        return (
            <Grid key={`${counter}-${img.extId}`} item xs={12} sm={4}>
                <img src={img.url} alt="Flat1" />
            </Grid>
        );
    };

    const renderImage = (img: IImage, counter: number) => {
        if (counter < 2) {
            return getImageGrid(img, counter);
        } else {
            return (
                <Hidden key={`${counter}-${img.extId}`} only="xs">
                    {getImageGrid(img, counter)}
                </Hidden>
            );
        }
    };

    return (
        <Box key={overviewItem.extId} className="section">
            <Paper className="paper" elevation={0}>
                <Grid container spacing={1}>
                    {images.map((image, index) => renderImage(image, index))}
                </Grid>

                <Grid className="mtb" container>
                    <Grid id="h6" className="left" item sm={5}>
                        <h6>{formatOfferItemDesc(overviewItem.offerType, overviewItem.realEstate)}</h6>
                    </Grid>

                    <Grid className="right" item sm={7}>
                        {renderBadges()}
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid id="price" className="left" item xs={12} sm={4}>
                        <p>{formatPrice(overviewItem.price)}</p>
                    </Grid>

                    <Grid id="address" className="right" item xs={12} sm={8}>
                        <p>{formatAddress(overviewItem.address)}</p>
                    </Grid>
                </Grid>

                <Grid className="left adStateTable" container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Grid id="detailLeft" container spacing={2}>
                            <Grid className="blueText" item xs={5}>{t.STATE}</Grid>
                            <Grid item xs={7}>{STATUS_MAP[overviewItem.status]}</Grid>

                            <Grid className="blueText" item xs={5}>{t.CREATE_DATE}</Grid>
                            <Grid
                                item
                                xs={7}
                            >
                                {overviewItem.publishedAt ? formatDate(overviewItem.publishedAt) : ''}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid id="detailRight" container spacing={2}>
                            <Grid className="blueText" item xs={5}>{t.COST_NOTE}</Grid>
                            <Grid item xs={7}>{overviewItem.priceInfo}</Grid>

                            <Grid className="blueText" item xs={5}>{t.ACTIVE_TILL}</Grid>
                            <Grid item xs={7}>
                                {overviewItem.publishedTo ? formatDate(overviewItem.publishedTo) : ''}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    {(showActions || !overviewItem.externalId) && (
                        <Grid item xs={6} sm={3}>
                            <Button
                                color="primary"
                                onClick={updateAdvert}
                                variant="outlined"
                            >
                                {t.EDIT_ADVERT}
                            </Button>
                        </Grid>
                    )}
                    {overviewItem.status !== OfferItemStatus.EDIT && (
                        <Grid item xs={6} sm={3}>
                            <Button
                                color="primary"
                                onClick={showAdvert}
                                variant="outlined"
                            >
                                {t.SHOW_ADVERT}
                            </Button>
                        </Grid>
                    )}
                    {showActions && (
                        <>
                            <Grid item xs={6} sm={3}>
                                <ConfirmDialog
                                    name={t.DELETE_ADVERT}
                                    body={(
                                        <p>
                                            {t.DELETE_ADVERT_CONFIRM}
                                            {formatOfferItemDesc(overviewItem.offerType, overviewItem.realEstate)}
                                        </p>
                                    )}
                                    title={t.DELETE_ADVERT}
                                    closeButtonName={t.BACK}
                                    confirmButtonName={t.DELETE_ADVERT}
                                    confirmButtonFn={deleteAdvert}
                                    maxWidth="xs"
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                {overviewItem.status === OfferItemStatus.UNACTIVE && (
                                    <Button
                                        color="primary"
                                        onClick={reactivateOfferItem}
                                        variant="outlined"
                                    >
                                        {t.ACTIVATE_ADVERT}
                                    </Button>
                                )}

                                {overviewItem.status === OfferItemStatus.PUBLISHED && (
                                    <Button
                                        color="primary"
                                        onClick={deactivateOfferItem}
                                        variant="outlined"
                                    >
                                        {t.DEACTIVATE_ADVERT}
                                    </Button>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>

                {showStatistics && overviewItem.status !== OfferItemStatus.EDIT &&
                    <Accordion expanded={isExpanded}
                               className="accordion"
                               onChange={(e, value) => setIsExpanded(value)}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            <Typography className="bold" color="primary">{t.STATISTICS}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ViewsCharts offerItemId={overviewItem.extId} isExpanded={isExpanded} />
                        </AccordionDetails>
                    </Accordion>
                }
            </Paper>
        </Box>
    );
};

export default MyAccountItem;
