export enum ERouteParameters {
    REAL_ESTATE_TYPE = 'REAL_ESTATE_TYPE',
    OFFER_TYPE = 'OFFER_TYPE',
    LOCATION = 'LOCATION',
    OFFER_ITEM_ID = 'OFFER_ITEM_ID',
    ADMIN_ACTIVE_SECTION = 'ADMIN_ACTIVE_SECTION',
    REAL_ESTATE_ID = 'REAL_ESTATE_ID',
    ORIGINAL_PATH = 'ORIGINAL_PATH',
    ORIGINAL_PAGINATION = 'ORIGINAL_PAGINATION',
    SEARCH_ID = 'SEARCH_ID',
    SELLER_ID = 'SELLER_ID',
    COMPANY_ID = 'COMPANY_ID',
    CONFIRMATION_UUID = 'CONFIRMATION_UUID',
    OWNER = 'OWNER',
}

export enum USER_PRIVILEGES {
    COMPANY_ADMIN = 'COMPANY_ADMIN',
    BASIC_SELLER_VERIFIED = 'BASIC_SELLER_VERIFIED',
    COMPANY_SELLER = 'COMPANY_SELLER'
}

export const ERoutePaths = {
    HOME: '/',
    SEARCH: '/vyhledavani',
    SEARCH_ID: `/ulozene-vyhledavani/:${ERouteParameters.SEARCH_ID}?`,
    SEARCH_RESULT_BASE: `/vysledky-vyhledavani`,
    SEARCH_RESULT: `/vysledky-vyhladavanie/:${ERouteParameters.REAL_ESTATE_TYPE}/:${ERouteParameters.OFFER_TYPE}/:${ERouteParameters.LOCATION}`,
    SEARCH_RESULT_ID: `/vysledky-ulozeneho-vyhladavanie/:${ERouteParameters.SEARCH_ID}?`,
    SEARCH_RESULT_SELLER: `/vysledky-vyhledavani-predajca/:${ERouteParameters.SELLER_ID}`,
    SEARCH_RESULT_COMPANY: `/vysledky-vyhledavani-agentura/:${ERouteParameters.COMPANY_ID}`,
    ESTATE_AGENCY_LIST: '/zoznam-agentur',
    DETAIL_BASE: `/detail/`,
    DETAIL: `/detail/*/id/:${ERouteParameters.OFFER_ITEM_ID}`,
    DETAIL_ID: `/detail/id/:${ERouteParameters.OFFER_ITEM_ID}`,
    DETAIL_SELLER: `/detail/*/predajca/:${ERouteParameters.SELLER_ID}/id/:${ERouteParameters.OFFER_ITEM_ID}`,
    DETAIL_COMPANY: `/detail/*/kancelar/:${ERouteParameters.COMPANY_ID}/id/:${ERouteParameters.OFFER_ITEM_ID}`,
    ACCOUNT: '/muj-ucet',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOTTEN_PASSWORD: '/forgotten-password',
    ADVERT_INFORMATION: '/novy-inzerat-informace',
    ADVERT_INFORMATION_ID: `/novy-inzerat-informace/:${ERouteParameters.OFFER_ITEM_ID}?`,
    ADVERT_PROPERTIES: `/novy-inzerat-vlastnosti/:${ERouteParameters.OFFER_ITEM_ID}`,
    ADVERT_PHOTO: `/novy-inzerat-galerie/:${ERouteParameters.OFFER_ITEM_ID}`,
    ADVERT_ADDRESS: `/novy-inzerat-adresa/:${ERouteParameters.OFFER_ITEM_ID}`,
    ADVERT_OVERVIEW: `/novy-inzerat-souhrn/:${ERouteParameters.OFFER_ITEM_ID}`,
    ESTATE_AGENCY_ADMINISTRATION: '/sprava-kancelare',
    ESTATE_AGENCY_REGISTRATION: '/registrace-sprava-kancelare',
    HOW_TO_ADVERTISE: '/ako-inzerovat',
    GDPR: '/ochrana-osobnych-udajov',
    DOCUMENTS: '/dokumenty',
    BUSINESS_CONDITIONS: '/obchodne-podmienky',
    CONTACTS: '/kontakty',
    CONFIRMATION: `/confirmation/:${ERouteParameters.CONFIRMATION_UUID}`,
    AUTH0: '/oauth2/redirect',
    RESET_PASSWORD: `/reset-password/:${ERouteParameters.CONFIRMATION_UUID}`,
    CHANGE_PASSWORD: `/change-password`
};

export const OtherRealEstateProps = {
    cellar: 'sklep',
    barrierless: 'bezbarierovy',
    parking: 'parkovanie',
    garage: 'garaz',
    lodgy: 'lodzia',
    terrace: 'terasa',
    balcony: 'balkon',
    elevator: 'vytah',
    lowEnergy: 'nizkoenergeticky',
    madeOfWood: 'drevostavba',
    bungalow: 'bungalov',
    separateBuilding: 'samostatna-budova',
    moreBuildings: 'vice-budov',
    pool: 'bazen'
}

export const OtherRealEstatePropsDescription = {
    cellar: 'sklep',
    barrierless: 'bezbariérový',
    parking: 'parkovánie',
    garage: 'garáž',
    lodgy: 'lodžia',
    terrace: 'terasa',
    balcony: 'balkón',
    elevator: 'výtah',
    lowEnergy: 'nízkoenergetický',
    madeOfWood: 'dřevostavba',
    bungalow: 'bungalov',
    separateBuilding: 'samostatná budova',
    moreBuildings: 'více budov',
    pool: 'bazén'
}

export const UrlSearchParamsPrefixes = {
    DISTRICTS: {
        PREFIX: '/okres-',
        SEPARATOR: '-'
    },
    REGIONS: {
        PREFIX: '/kraj-',
        SEPARATOR: '-'
    },
    DISPOSITION: {
        PREFIX: '&velikost=',
        SEPARATOR: '-'
    },
    MATERIAL: {
        PREFIX: '&material=',
        SEPARATOR: ','
    },
    OWNERSHIP: {
        PREFIX: '&vlastnictvo=',
        SEPARATOR: ','
    },
    STATE: {
        PREFIX: '&stav=',
        SEPARATOR: ','
    },
    HOUSE_TYPES: {
        PREFIX: '&druh-domu=',
        SEPARATOR: ','
    },
    LOT_TYPES: {
        PREFIX: '&vlastnosti-pozemku=',
        SEPARATOR: ','
    },
    COMMERCIAL_TYPES: {
        PREFIX: '&typ-objektu=',
        SEPARATOR: ','
    },
    LOT_AREA: {
        PREFIX: '&plocha-pozemku',
        LOWER: '-do=',
        UPPER: '-od=',
        BOTH: '-az-',
    },
    FLOOR: {
        PREFIX: '&poschodie',
        LOWER: '-do=',
        UPPER: '-od=',
        BOTH: '-az-',
    },
    SURFACE: {
        PREFIX: '&plocha',
        LOWER: '-do=',
        UPPER: '-od=',
        BOTH: '-az-',
    },
    PRICE: {
        PREFIX: '&cena',
        LOWER: '-do=',
        UPPER: '-od=',
        BOTH: '-az-',
    },

};

export enum ImageType {
    OVERVIEW = 'OVERVIEW',
    OTHER = 'OTHER',
    PLACEHOLDER = 'PLACEHOLDER'
}

export enum OfferItemStatus {
    EDIT = 'EDIT',
    PUBLISHED = 'PUBLISHED',
    UNACTIVE = 'UNACTIVE'
}

export enum EViews {
    DESKTOP = 'DESKTOP',
    MOBILE = 'MOBILE'
}
