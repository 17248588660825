import {Alert} from '@material-ui/lab';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {REDUCER_CONTEXT} from '../../utils/constant';
import {Snackbar} from '@material-ui/core';
import {isNilOrEmpty, isNotNilOrEmpty} from '../../utils/helpers';
import {createAction} from '../../store/actions';
import {CLEAR_ALERT} from '../../store/reducers/context';

const SnackBar = () => {
    const alert = useSelector((state: any) => state[REDUCER_CONTEXT].alert);
    const clearAlert = createAction(CLEAR_ALERT);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isNotNilOrEmpty(alert?.message)) {
            setIsOpen(true);
        } else if (isNilOrEmpty(alert) && isOpen) {
            setIsOpen(false);
        }
    }, [alert]);

    const onClose = useCallback(() => {
        setIsOpen(false);
        clearAlert();
    }, [clearAlert, setIsOpen]);

    if (isNilOrEmpty(alert?.message)) {
        return null;
    }
    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
            <Alert severity={alert?.severity}>{alert?.message}</Alert>
        </Snackbar>
    );
};

export default SnackBar;
