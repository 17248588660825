import Advertisement from '../../components/Advertisement';
import MyAccount from '../../components/MyAccount';
import Header from '../../components/Header';
import Layout from '../../layouts/Administration';
import React, {useState} from 'react';
import {Box} from '@material-ui/core';
import './style.scss';
import t from '../../texts';
import {ERouteParameters, ERoutePaths} from '../../enumerators';
import {File, User, Upload, Star, Bell, Home} from 'react-feather';
import {MenuItemType} from "../../utils/types";
import {ADMIN_SECTIONS, REDUCER_CONTEXT, REDUCER_REAL_ESTATE, REDUCER_USER} from "../../utils/constant";
import {useLocation} from "react-router-dom";
import {isNotNilOrEmpty} from "../../utils/helpers";
import {useApiDataSelector} from "../../hooks/hooks";
import {useSelector} from "react-redux";
import {createAction} from "../../store/actions";
import {SET_SECTION} from "../../store/reducers/context";


export default () => {
    const location = useLocation();
    // @ts-ignore
    const section = isNotNilOrEmpty(location?.state) && location.state[ERouteParameters.ADMIN_ACTIVE_SECTION];
    const sectionRedux = useSelector((state: any) => state[REDUCER_CONTEXT]?.section);
    const currentUser = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser);
    const [activeSection, setActiveSection] = useState(!currentUser?.emailVerified ? ADMIN_SECTIONS.MY_ACCOUNT : sectionRedux || section || ADMIN_SECTIONS.MY_ADVERT);
    const {data: myOverviewItems = []} = useSelector((state: any) => state[REDUCER_REAL_ESTATE].myOfferItems);

    const setSection = createAction(SET_SECTION);

    const setSectionCallback = (section: string) => {
        setActiveSection(section);
        setSection(null);
    }

    const menuItems = [
        {label: t.HOME, link: ERoutePaths.HOME, iconElement: <Home />},
        {
            label: t.MY_ACCOUNT,
            section: ADMIN_SECTIONS.MY_ACCOUNT,
            iconElement: <User />,
            secondary: currentUser?.firstName,
            callback: () => setSectionCallback(ADMIN_SECTIONS.MY_ACCOUNT)
        },
        {
            label: t.MY_ADVERT,
            section: ADMIN_SECTIONS.MY_ADVERT,
            iconElement: <File />,
            secondary: myOverviewItems?.totalElements,
            callback: () => setSectionCallback(ADMIN_SECTIONS.MY_ADVERT)
        },
        {
            label: t.ADD_NEW_OFFER,
            link: ERoutePaths.ADVERT_INFORMATION,
            iconElement: <Upload />,
            disabled: !currentUser?.emailVerified,
        },
        {
            label: t.SEARCH_NOTIFICATION,
            section: ADMIN_SECTIONS.SEARCH_NOTIFICATION,
            iconElement: <Bell />,
            callback: () => setSectionCallback(ADMIN_SECTIONS.SEARCH_NOTIFICATION)
        },
        {
            label: t.FAVORITE_ADVERT,
            section: ADMIN_SECTIONS.MY_FAVORITE,
            iconElement: <Star />,
            callback: () => setSectionCallback(ADMIN_SECTIONS.MY_FAVORITE)
        }
    ] as MenuItemType[];

    return (
        <Box data-component="My_Account">
            <Layout menuItems={menuItems} activeSection={activeSection}>
                <Header />
                <Advertisement />
                <MyAccount activeSection={activeSection} />
            </Layout>
        </Box>
    );
}
