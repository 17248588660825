import React, {useEffect} from 'react';
import cx from 'classnames';
import {Grid} from '@material-ui/core';
import ResultMap from '../../components/ResultsMap';
import Layout from '../../layouts/Stretched';
import {useSelector} from 'react-redux';
import {REDUCER_CONTEXT, REDUCER_REALITY_COMPANY} from '../../utils/constant';
import {ERouteParameters, ERoutePaths, EViews} from '../../enumerators';
import EstateAgencyList from "../../components/EstateAgencyList";
import {useApiRequest} from "../../store/api";
import {isNilOrEmpty} from "../../utils/helpers";
import {ISearchResultContent} from "../../utils/types";

export default () => {
    const {isCollapsedView, isMapExpanded} = useSelector((state: any) => state[REDUCER_CONTEXT]);
    const fetchCompanies = useApiRequest(REDUCER_REALITY_COMPANY, 'reality-company/all', 'FETCH_COMPANY_ALL', {method: 'get'});

    const {data, isLoading} = useSelector((state: any) => state[REDUCER_REALITY_COMPANY].companies);
    const linkToDetail = (offerItem: ISearchResultContent) => ERoutePaths.SEARCH_RESULT_COMPANY.replace(`:${ERouteParameters.COMPANY_ID}`, offerItem.extId);

    useEffect(() => {
        if (!isLoading && isNilOrEmpty(data)) {
            fetchCompanies();
        }
    }, []);

    return (
        <Layout>
            <Grid
                id="results"
                className={cx({collapsedSection: isCollapsedView})}
                item
                md={12}
                lg={isCollapsedView ? 4 : 7}
            >
                <ResultMap visibleFor={EViews.MOBILE} locations={data} linkToDetail={linkToDetail} />

                {!isMapExpanded && (
                    <EstateAgencyList result={data} isLoading={isLoading} linkToDetail={linkToDetail} />
                )}
            </Grid>

            <ResultMap sizeOfMapContainer={8} visibleFor={EViews.DESKTOP} locations={data} linkToDetail={linkToDetail} />
        </Layout>
    );
};
