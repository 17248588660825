import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import React from 'react';
import './style.scss';
import {Link} from 'react-router-dom';
import t from '../../texts';

export default () => {
    return (
        <Box data-component="GDPR">
            <Box className="container">
                <Box className="section">
                    <h2>Informačná povinnosť pri poskytnutí osobných údajov a spracúvanie cookies</h2>
                    <Link
                        to={process.env.PUBLIC_URL + '/files/Informacna-povinnost-pri-poskytnuti-osobnych-udajov-a-spracuvanie-cookies.docx'}
                        target="_blank" className="link">{t.DOWNLOAD}</Link>

                    <p>Spoločnosť Algora s.r.o., so sídlom Marka Čulena 14169/20, 974 01 Banská Bystrica, IČO: 54 273
                        927, zapísaný v Obchodnom registri Okresného súdu Banská Bystrica, oddiel: Sro, vložka č.
                        42742/S (ďalej tiež ako „prevádzkovateľ“) v súvislosti so
                        splnením informačných povinností podľa čl. 13 Nariadenia Európskeho parlamentu a Rady (EÚ)
                        2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom
                        pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (ďalej len „Nariadenie“) a § 19
                        zákona č. 18/2018 Z.z. o ochrane osobných údajov (ďalej tiež ako „zákon o OOÚ“) zdeľuje
                        dotknutej osobe nasledovné informácie.</p>

                    <p>Dotknutá osoba je v zmysle § 19 ods. 2 písm. e) zákona o OOÚ povinná poskytnúť osobné údaje na
                        nižšie uvedený účel, v opačnom prípade nie je možné uzavrieť zmluvu, o ktorú dotknutá osoba
                        prejavila záujem.</p>
                    <ul style={{listStyle: "decimal outside", textAlign: 'left'}}>
                        <li>
                            <h3>Identifikačné údaje a kontaktné údaje prevádzkovateľa:</h3>
                            <h4> Prevádzkovateľ:</h4>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                style={{textAlign: "left"}}
                            >
                                <Grid item xs={6} sm={3}>Obchodné meno:</Grid>
                                <Grid item xs={6} sm={9}>Algora s.r.o.</Grid>
                                <Grid item xs={6} sm={3}>Sídlo:</Grid>
                                <Grid item xs={6} sm={9}>Marka Čulena 14169/20, 974 01 Banská Bystrica</Grid>
                                <Grid item xs={6} sm={3}>IČO:</Grid>
                                <Grid item xs={6} sm={9}>54 273 927</Grid>
                                <Grid item xs={6} sm={3}>Zapísaný:</Grid>
                                <Grid item xs={6} sm={9}>Obchodný register Okresného súdu Banská Bystrica, oddiel: Sro,
                                    vložka
                                    č.: 42742/S</Grid>
                            </Grid>
                            <h4>Kontaktné údaje:</h4>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                style={{textAlign: "left"}}
                            >
                                <Grid item xs={6} sm={3}>Telefón:</Grid>
                                <Grid item xs={6} sm={9}>+420 776 280 354</Grid>
                                <Grid item xs={6} sm={3}>Email:</Grid>
                                <Grid item xs={6} sm={9}>support@skreality.sk</Grid>
                            </Grid>
                        </li>

                        <li>
                            <h3>Účel spracúvania osobných údajov, na ktorý sú osobné údaje určené, ako aj právny základ
                                spracúvania
                                osobných údajov:
                            </h3>


                            <p><strong>Účel:</strong> uzatvorenie zmluvy a predzmluvné vzťahy</p>

                            <strong>Právny základ:</strong>

                            <p>čl. 6 ods.1 písm. b) Nariadenia a § 13 ods. 1 písm. b) zákona o ochrane osobných údajov:
                                spracúvanie
                                osobných údajov je nevyhnutné na plnenie zmluvy, ktorej zmluvnou stranou je dotknutá
                                osoba,
                                alebo na
                                vykonanie opatrenia pred uzatvorením zmluvy na základe žiadosti dotknutej osoby,</p>

                            <p>čl. 6 ods.1 písm. c) Nariadenia a § 13 ods. 1 písm. c) zákona o ochrane osobných údajov:
                                spracúvanie
                                osobných údajov je nevyhnutné podľa osobitného predpisu – prevádzkovateľ je v zmysle
                                osobitných
                                predpisov povinný poskytnúť osobné údaje dotknutej osoby štátnym inštitúciám</p>

                            <p>Prevádzkovateľ týmto oznamuje dotknutej osobe, že bude v procese uzatvorenia zmluvy
                                spracúvať
                                osobné
                                údaje bez súhlasu dotknutej osoby, keďže spracúvanie osobných údajov bude vykonávané
                                prevádzkovateľom v rámci predzmluvných vzťahov a spracúvanie osobných údajov je
                                nevyhnutné na
                                uzatvorenie zmluvy a prevádzkovateľ je taktiež v zmysle osobitných predpisov povinný
                                poskytnúť
                                osobné údaje dotknutej osoby štátnym inštitúciám.
                            </p>
                            <p>
                                Prevádzkovateľ vyhlasuje, že osobné údaje bude spracúvať v súlade s dobrými mravmi a
                                bude konať
                                spôsobom, ktorý neodporuje zákonu o OOÚ a Nariadeniu.</p>
                        </li>
                        <li>
                            <h3>Identifikáciu príjemcu alebo kategóriu príjemcu, ak existuje</h3>

                            <p>Príjemcovia resp. kategórie príjemcov, ktorí spracúvajú osobné údaje sú:</p>
                            <p>
                                Správca cloudu, štátne inštitúcie za účelom plnenia povinností prevádzkovateľa:</p>
                            <p>
                                daňový úrad, orgán štátnej správy a verejnej moci na výkon kontroly a dozoru (napr.
                                slovenská
                                obchodná inšpekcia, úrad na ochranu osobných údajov), exekútor, advokát, súd, orgán
                                činný v
                                trestnom
                                konaní atď.
                            </p>
                        </li>
                        <li>
                            <h3>Doba uchovávania osobných údajov</h3>

                            <p>
                                Doba uchovávania osobných údajov: doba určitá – doba určená zákonmi na uplatňovanie práv
                                a
                                povinností vyplývajúcich z uzavretej zmluvy (napr. podľa § 599 ods. 1 Občianskeho
                                zákonníka si
                                môže
                                kupujúci uplatniť práva zo zodpovednosti za vady na súde v lehote do 24 mesiacov od
                                prevzatia
                                tovaru
                                – uchovávanie osobných údajov je teda potrebné pre identifikáciu zmluvnej strany),
                                prípadne až
                                pokiaľ nie je možné uplatniť práva a povinnosti v premlčacej lehote stanovenej zákonom;
                                dlhšie
                                spracúvanie osobných údajov je možné len výlučne na účel archivácie, na vedecký účel, na
                                účel
                                historického výskumu alebo na štatistický účel na základe osobitného predpisu.
                            </p>
                        </li>
                        <li>
                            <h3>Cezhraničný prenos osobných údajov mimo EÚ</h3>

                            <p>Cezhraničný prenos osobných údajov do tretích krajín mimo Európskeho hospodárskeho
                                priestoru
                                alebo
                                medzinárodnej organizácie sa neuskutočňuje. K tomuto dochádza iba v prípade, ak udelíte
                                súhlas s
                                ukladaním analytických cookies súborov. V takom prípade budú Vaše osobné údaje prenášané
                                do USA,
                                do
                                spoločnosti Google LLC, ktorá je poskytovateľom služby Google Analytics, ktorú
                                prevádzkovateľ
                                využíva za účelom merania návštevnosti a aktivity na webovej stránke prevádzkovateľa.
                            </p>
                        </li>
                        <li>
                            <h3>Informácia o existencii automatizovaného rozhodovania (profilovanie)</h3>

                            <p>Prevádzkovateľ vykonáva profilovanie na základe dát z Google Analytics pri získaní
                                súhlasu od
                                návštevníka.
                            </p>
                        </li>
                        <li>
                            <h3>Práva dotknutej osoby</h3>
                            <p>Dotknutá osoba má právo:</p>
                            <ul style={{listStyle: "disc", textAlign: "left"}}>
                                <li>
                                    <b>požadovať prístup k osobným údajom</b> <p>týkajúcich sa dotknutej osoby,
                                    Ako dotknutá osoba máte právo na poskytnutie zoznamu osobných údajov, ktoré od Vás
                                    máme k
                                    dispozícii
                                    ako aj informácie o tom ako Vaše osobné údaje spracúvame.
                                </p>
                                </li>
                                <li>
                                    <b>na opravu osobných údajov,</b><p>
                                    Prijali sme opatrenia, aby sme uchovávali Vaše presné, úplné a aktuálne osobné
                                    údaje. Ak si
                                    myslíte,
                                    že Vaše osobné údaje, ktoré uchovávame nie sú presné, úplné a aktuálne, prosím
                                    informujte nás o
                                    tom.</p>
                                </li>
                                <li>
                                    <b>na vymazanie osobných údajov,</b>
                                    <p>Ako dotknutá osoba nás môžete požiadať aj o vymazanie Vašich osobných údajov, ak
                                        sú
                                        na to
                                        splnené
                                        zákonom uvedené dôvody, napr. ak už účel spracúvania skončil.</p>
                                </li>
                                <li>
                                    <b>na obmedzenie spracúvania osobných údajov,</b>
                                    <p>Ako dotknutá osoba nás môžete pri splnení zákonných podmienok požiadať, aby sme
                                        prestali
                                        používať
                                        Vaše osobné údaje, napr. za situácie ak si myslíte, že Vaše osobné údaje, ktoré
                                        uchovávame, sú
                                        nepresné a pod.</p>
                                </li>
                                <li>
                                    <b>namietať spracúvanie osobných údajov,</b>
                                    <p>Ako dotknutá osoba máte právo namietať spracúvaniu Vašich údajov, v prípade, ak
                                        ste
                                        nadobudli
                                        presvedčenie, že na spracúvanie osobných údajov nemáme právny dôvod; napr. ak
                                        naše
                                        oprávnené
                                        záujmy
                                        na spracúvanie osobných údajov neprevažujú nad právami alebo záujmami dotknutej
                                        osoby.
                                    </p>
                                </li>
                                <li>
                                    <b>na prenosnosť osobných údajov,</b>
                                    <p>Ako dotknutá osoba máte za určitých okolností právo požiadať nás o prenos
                                        osobných
                                        údajov, ktoré
                                        ste
                                        nám poskytli. Toto právo na prenosnosť sa však týka len tých osobných údajov,
                                        ktoré
                                        sme nám
                                        poskytli
                                        na základe Vášho súhlasu alebo na základe zmluvy, ktorej ste jednou zo zmluvných
                                        strán.</p>
                                </li>
                                <li>
                                    <b>podať návrh na začatie konania resp. sťažnosť</b> na dozorný orgán,

                                    <p>Ako dotknutá osoba máte právo podať návrh resp. sťažnosť na Úrad na ochranu
                                        osobných
                                        údajov
                                        Slovenskej republiky, https://dataprotection.gov.sk , Hraničná 12, 820 07
                                        Bratislava
                                        27; tel.
                                        číslo:
                                        +421 /2/ 3231 3214; E-mail: statny.dozor@pdp.gov.sk
                                    </p><p>
                                    Dotknutá osoba môže požadovať výkon vyššie uvedených práv kedykoľvek, a to
                                    prostredníctvom
                                    emailu
                                    support@skreality.sk, tel. č.: +420 776 280 354, alebo písomne na poštovú adresu
                                    sídla
                                    prevádzkovateľa. Prevádzkovateľ vybaví žiadosť dotknutej osoby v súvislosti s vyššie
                                    uvedenými
                                    právami v zákonných lehotách.</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h2>Spracovanie súborov cookies</h2>
                    <p>
                        V zmysle Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane
                        fyzických
                        osôb pri spracúvaní osobných údajov, ktorým sa zrušuje smernica 95/46/ES (ďalej len
                        „všeobecné
                        nariadenie o ochrane údajov") a zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene
                        a
                        doplnení niektorých zákonov (ďalej len „zákon o ochrane osobných údajov").</p>
                    <p>
                        Cookies predstavuje krátky text (alfanumerický reťazec), ktorý je uložený na koncovom
                        zariadení
                        návštevníka našej internetovej stránky. Cookies využívame najmä z prevádzkových dôvodov, pre
                        analýzu
                        užívateľského správania na našej stránke. Cookies nepoužívame na identifikáciu osoby
                        užívateľa. Ak
                        ako používateľ alebo návštevník našich stránok nesúhlasíte s touto politikou využívania
                        technológie
                        cookies, môžete kedykoľvek slobodne vymazať súbory cookies súvisiace s touto stránkou zo
                        svojho
                        počítača. Taktiež môžete zakázať ukladanie súborov cookies pre tieto stránky priamo v
                        nastaveniach
                        svojho prehliadača, alebo pristupovať na tieto stránky v režime prehliadača, ktorý nepovolí
                        ukladanie cookies. Spracúvanie nevyhnutých cookies sa uskutočňuje na základe nášho
                        oprávneného
                        záujmu a analytické a marketingové cookies výlučne na základe Vášho výslovného súhlasu,
                        ktorý
                        vyjadríte stlačením tlačidla ,,Súhlasím“ v spodnej časti webovej stránky.
                    </p>
                    <ul style={{listStyle: "decimal outside", textAlign: 'left'}}>

                        <li>
                            <h3>Účel spracúvania osobných údajov, na ktorý sú osobné údaje určené, ako aj právny základ
                                spracúvania
                                osobných údajov:</h3>
                            <p>
                                Účel: Prevádzkovateľ používa na tejto webovej stránke súbory cookies za účelom
                                prispôsobenia
                                zobrazenia webovej stránky, merania návštevnosti webovej stránky a cielenej reklamy.
                            </p><p>
                            Prevádzkovateľ vyhlasuje, že osobné údaje bude spracúvať v súlade s dobrými mravmi a
                            bude
                            konať
                            spôsobom, ktorý neodporuje zákonu o OOÚ a Nariadeniu.
                        </p>
                        </li>
                        <li>
                            <h3>Identifikáciu príjemcu alebo kategóriu príjemcu, ak existuje</h3>
                            <p>
                                Príjemcovia resp. kategórie príjemcov, ktorí spracúvajú osobné údaje sú:

                                ....................
                            </p>
                        </li>
                        <li style={{marginBottom: '20px'}}>
                            <h3>Druhy spracúvaných cookies</h3>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Typ</TableCell>
                                        <TableCell>Názov</TableCell>
                                        <TableCell>Účel</TableCell>
                                        <TableCell>Expirácia</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Nevyhnutné</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Analytické</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Marketingové</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </li>
                        <li>
                            <h3>Práva dotknutej osoby</h3>
                            <ul style={{listStyle: "disc outside", textAlign: 'left'}}>
                                <p style={{textDecoration: 'underline'}}>Dotknutá osoba má právo:</p>
                                <li><b>požadovať prístup k osobným údajom</b> týkajúcich sa dotknutej osoby,
                                    <p>Ako dotknutá osoba máte právo na poskytnutie zoznamu osobných údajov, ktoré od
                                        Vás
                                        máme
                                        k
                                        dispozícii
                                        ako aj informácie o tom ako Vaše osobné údaje spracúvame.</p>
                                </li>
                                <li><b>na opravu osobných údajov,</b>
                                    <p>Prijali sme opatrenia, aby sme uchovávali Vaše presné, úplné a aktuálne osobné
                                        údaje. Ak
                                        si
                                        myslíte,
                                        že Vaše osobné údaje, ktoré uchovávame nie sú presné, úplné a aktuálne, prosím
                                        informujte
                                        nás o tom.</p>
                                </li>
                                <li>
                                    <b>na vymazanie osobných údajov,</b>
                                    <p>Ako dotknutá osoba nás môžete požiadať aj o vymazanie Vašich osobných údajov, ak
                                        sú
                                        na
                                        to
                                        splnené
                                        zákonom uvedené dôvody, napr. ak už účel spracúvania skončil.</p>
                                </li>
                                <li>
                                    <b>na obmedzenie spracúvania osobných údajov,</b>
                                    <p>Ako dotknutá osoba nás môžete pri splnení zákonných podmienok požiadať, aby sme
                                        prestali
                                        používať
                                        Vaše osobné údaje, napr. za situácie ak si myslíte, že Vaše osobné údaje, ktoré
                                        uchovávame,
                                        sú
                                        nepresné a pod.</p>
                                </li>
                                <li>
                                    <b>odvolať súhlas,</b>
                                    <p>Ako dotknutá osoba máte právo kedykoľvek odvolať Váš súhlas v prípadoch, keď Vaše
                                        osobné
                                        údaje
                                        spracúvame na základe Vášho súhlasu. Odvolanie súhlasu môžete vykonať odoslaním žiadosti na email support@skreality.sk,
                                        alebo písomne na poštovú adresu sídla prevádzkovateľa.</p>
                                </li>
                                <li>
                                    <b>podať návrh na začatie konania resp. sťažnosť na dozorný orgán,</b>

                                    <p>Ako dotknutá osoba máte právo podať návrh resp. sťažnosť na Úrad na ochranu
                                        osobných
                                        údajov
                                        Slovenskej republiky, https://dataprotection.gov.sk , Hraničná 12, 820 07
                                        Bratislava
                                        27;
                                        tel. číslo:
                                        +421 /2/ 3231 3214; E-mail: statny.dozor@pdp.gov.sk
                                    </p>
                                    <p>
                                        Dotknutá osoba môže požadovať výkon vyššie uvedených práv kedykoľvek, a to
                                        prostredníctvom emailu support@skreality.sk, tel. č.: +420 776 280 354, alebo písomne na poštovú
                                        adresu sídla prevádzkovateľa. Prevádzkovateľ vybaví žiadosť dotknutej osoby v súvislosti s
                                        vyššie uvedenými právami v zákonných lehotách.
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Box>
            </Box>
        </Box>
    );
};
