import {Grid} from '@material-ui/core';
import cx from 'classnames';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import './style.scss';
import {useSelector} from 'react-redux';
import {createAction} from '../../store/actions';
import {SET_IS_MAP_EXPANDED} from '../../store/reducers/context';
import {REDUCER_CONTEXT, REDUCER_SEARCH} from '../../utils/constant';
import {ERouteParameters, EViews} from '../../enumerators';
import Map from '../Map';
import {isNotNilOrEmpty} from '../../utils/helpers';
import {debounce} from 'lodash';
import {useParamName} from '../../hooks/hooks';
import {ISearchResultContent} from "../../utils/types";

interface IProps {
    visibleFor: EViews;
    locations: any[],
    linkToDetail: (offerItem: ISearchResultContent) => string,
    sizeOfMapContainer?: boolean | 8 | 2 | 1 | "auto" | 3 | 4 | 5 | 6 | 7 | 9 | 10 | 11 | 12 | undefined,
}

const ResultsMap = ({visibleFor, locations, linkToDetail, sizeOfMapContainer = 8}: IProps) => {
    const mapContainerRef = useRef<any>(null);
    const offerItemId = useParamName(ERouteParameters.OFFER_ITEM_ID);

    const [mapWidth, setMapWidth] = useState(100);

    const setMapExpanded = createAction(SET_IS_MAP_EXPANDED);

    const {isCollapsedView, isMapExpanded} = useSelector((state: any) => state[REDUCER_CONTEXT]);
    const detail = useSelector((state: any) => state[REDUCER_SEARCH].realEstates?.[offerItemId]);

    const setMap = () => setMapWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', setMap);
        return () => {
            window.removeEventListener('resize', setMap);
        }
    }, []);

    useEffect(() => {
        if (isNotNilOrEmpty(mapContainerRef?.current?.offsetWidth) && mapContainerRef?.current?.offsetWidth > 0) {
            setMapWidth(mapContainerRef?.current?.offsetWidth);
        }
    }, [mapContainerRef?.current?.offsetWidth, isCollapsedView, isMapExpanded]);

    const handleViewToggle = useCallback(debounce(() => {
        if (visibleFor === EViews.MOBILE) {
            setMapExpanded(!isMapExpanded);
        }
    }, 500), [setMapExpanded, isMapExpanded, visibleFor]);


    return (
        <Grid
            item
            sm={12}
            md={isCollapsedView ? sizeOfMapContainer : 5}
            data-component="ResultsMap"
            className={cx({
                mobile: visibleFor === EViews.MOBILE,
                desktop: visibleFor === EViews.DESKTOP,
                collapsedMap: isCollapsedView,
                fullScreenMap: isMapExpanded
            })}
            ref={mapContainerRef}
        >
            <Map
                onClick={handleViewToggle}
                markers={locations?.filter((m: any) => isNotNilOrEmpty(m.address?.latitude) && isNotNilOrEmpty(m.address?.longitude))}
                centerMarker={detail}
                width={mapWidth}
                linkToDetail={linkToDetail}
            />
        </Grid>
    );
};

export default ResultsMap;
