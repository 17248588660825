import React from 'react';
import { Grid } from '@material-ui/core';
import t from '../../texts';
import {
    formatBoolean,
    formatDate,
    formatLevels,
    formatPrice,
    formatSpaceSize,
    isNotNilOrEmpty
} from '../../utils/helpers';
import { REAL_ESTATE_TYPES } from '../../utils/constant';
import { is } from 'ramda';

const DetailTable = ({ detail }: { detail: any }) => {
    const {
        price,
        priceInfo,
        modifiedAt,
        realEstate: {
            realEstateType,
            buildingMaterialType,
            realEstateState,
            realEstateOwnership,
            reserved,
            surfaceArea,
            floor,
            floors,
            builtArea,
            houseType,
            waterPipe,
            gasPipe,
            lotArea,
            commercialType,
            lotType,
            separateBuilding,
            moreBuildings,
            electricity,
            barrierless,
            elevator,
            balcony,
            lodgy,
            terrace,
            cellar,
            parking,
            garage,
            madeOfWood,
            bungalow,
            lowEnergy,
            pool
        }
    } = detail;

    const isApartment = realEstateType?.code === REAL_ESTATE_TYPES.APARTMENT;
    const isHouse = realEstateType?.code === REAL_ESTATE_TYPES.HOUSE;
    const isCommercial = realEstateType?.code === REAL_ESTATE_TYPES.COMMERCIAL;
    const isLot = realEstateType?.code === REAL_ESTATE_TYPES.LOT;


    const Row = ({
        field,
        label,
        value
    }: { field: any, label: string, value: string }) => isNotNilOrEmpty(field) && field !== 0 && (is(Boolean, value) ? value : true) ? (
        <>
            <Grid className="blueText" item xs={6}>{label}</Grid>
            <Grid item xs={6}>
                {is(Boolean, value) ? formatBoolean(value) : value}
            </Grid>
        </>
    ) : null;

    return (
        <Grid className="left detailTableFontSize" container spacing={8}>
            <Grid item xs={12} sm={6}>
                <Grid id="detailLeft" container spacing={2}>
                    <Row field={price} label={t.PRICE} value={formatPrice(price)} />
                    <Row field={priceInfo} label={t.PRICE_COMMENT} value={priceInfo} />
                    <Row field={modifiedAt} label={t.UPGRADE_DATE} value={formatDate(modifiedAt)} />
                    <Row
                        field={buildingMaterialType}
                        label={t.BUILDING_MATERIAL_TYPE}
                        value={buildingMaterialType?.value}
                    />
                    <Row field={realEstateState} label={t.REAL_ESTATE_STATE} value={realEstateState?.value} />
                    <Row field={realEstateOwnership} label={t.OWNERSHIP} value={realEstateOwnership?.value} />
                    <Row field={reserved} label={t.RESERVED} value={reserved} />
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid id="detailRight" container spacing={2}>
                    {isApartment && (
                        <>
                            <Row field={surfaceArea} label={t.SURFACE_AREA} value={formatSpaceSize(surfaceArea)} />
                            <Row field={floor} label={t.FLOORS} value={formatLevels(floor, floors)} />
                            <Row field={gasPipe} label={t.GAS} value={gasPipe} />
                            <Row field={waterPipe} label={t.WATER} value={waterPipe} />
                            <Row field={electricity} label={t.ELECTRICITY} value={electricity} />
                            <Row field={barrierless} label={t.BARRIERLESS} value={barrierless} />
                            <Row field={elevator} label={t.ELEVATER} value={elevator} />
                            <Row field={lodgy} label={t.LODGY} value={lodgy} />
                            <Row field={balcony} label={t.BALCONY} value={balcony} />
                            <Row field={terrace} label={t.TERRACE} value={terrace} />
                            <Row field={cellar} label={t.CELLAR} value={cellar} />
                            <Row field={parking} label={t.PARKING} value={parking} />
                            <Row field={garage} label={t.GARAGE} value={garage} />
                        </>
                    )}
                    {isHouse && (
                        <>
                            <Row field={houseType} label={t.HOUSE_TYPE} value={houseType?.value} />
                            {(separateBuilding || moreBuildings) && (
                                <>
                                    <Grid className="blueText" item xs={6}>{t.HOUSE_SITUATION}</Grid>
                                    <Grid item xs={6}>
                                        {separateBuilding ? t.SEPARATE_BUILDINGS : t.MORE_BUILDINGS}
                                    </Grid>
                                </>
                            )}
                            <Row field={surfaceArea} label={t.SURFACE_AREA} value={formatSpaceSize(surfaceArea)} />
                            <Row field={lotArea} label={t.LOT_AREA} value={formatSpaceSize(lotArea)} />
                            <Row field={builtArea} label={t.BUILD_AREA} value={formatSpaceSize(builtArea)} />
                            <Row field={floor} label={t.FLOORS} value={formatLevels(floor, floors)} />
                            <Row field={gasPipe} label={t.GAS} value={gasPipe} />
                            <Row field={waterPipe} label={t.WATER} value={waterPipe} />
                            <Row field={madeOfWood} label={t.MADE_OF_WOOD} value={madeOfWood} />
                            <Row field={bungalow} label={t.BUNGALOW} value={bungalow} />
                            <Row field={lowEnergy} label={t.LOW_ENERGY} value={lowEnergy} />
                            <Row field={moreBuildings} label={t.MORE_BUILDINGS} value={moreBuildings} />
                            <Row field={separateBuilding} label={t.SEPARATE_BUILDINGS} value={separateBuilding} />
                            <Row field={pool} label={t.POOL} value={pool} />
                            <Row field={terrace} label={t.TERRACE} value={terrace} />
                            <Row field={balcony} label={t.BALCONY} value={balcony} />
                            <Row field={cellar} label={t.CELLAR} value={cellar} />
                            <Row field={garage} label={t.GARAGE} value={garage} />
                            <Row field={parking} label={t.PARKING} value={parking} />
                        </>
                    )}
                    {isCommercial && (
                        <>
                            <Row field={commercialType} label={t.COMMERCIAL_TYPE} value={commercialType?.value} />
                            <Row field={surfaceArea} label={t.SURFACE_AREA} value={formatSpaceSize(surfaceArea)} />
                            <Row field={lotArea} label={t.LOT_AREA} value={formatSpaceSize(lotArea)} />
                            <Row field={builtArea} label={t.BUILD_AREA} value={formatSpaceSize(builtArea)} />
                            <Row field={floor} label={t.FLOORS} value={formatLevels(floor, floors)} />
                            <Row field={gasPipe} label={t.GAS} value={gasPipe} />
                            <Row field={waterPipe} label={t.WATER} value={waterPipe} />

                        </>
                    )}
                    {isLot && (
                        <>
                            <Row field={lotType} label={t.LOT_TYPES} value={lotType?.value} />
                            <Row field={lotArea} label={t.LOT_AREA} value={formatSpaceSize(lotArea)} />
                            <Row field={gasPipe} label={t.GAS} value={gasPipe} />
                            <Row field={waterPipe} label={t.WATER} value={waterPipe} />
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
export default DetailTable;
