import React, {useEffect, useMemo} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {REDUCER_AUTH, REDUCER_REALITY_COMPANY, REDUCER_USER} from '../../utils/constant';
import {hasUserValidRole, isArray, isNilOrEmpty, isNotNilOrEmpty} from '../../utils/helpers';
import {isNil} from 'ramda';
import {useApiRequest} from "../../store/api";
import {GET_REALITY_COMPANY_USER} from "../../store/reducers/realityCompany";
import {GET_CURRENT_USER} from "../../store/reducers/user";

export interface IPrivateRouteParams {
    component: any,
    role?: string | null | undefined,
    path: string | string[],
    alternativePath?: string,
    companyRole?: string
}

const PrivateRoute = ({
                          component: Component,
                          role,
                          companyRole,
                          alternativePath,
                          ...rest
                      }: IPrivateRouteParams) => {
    const isAuthenticated = useSelector((state: any) => state[REDUCER_AUTH].isAuthenticated);
    const {isLoading} = useSelector((state: any) => state[REDUCER_USER].currentUser);
    const {
        data: roles,
        isLoading: isRolesLoading
    } = useSelector((state: any) => state[REDUCER_USER].currentUserPriviledges);
    const {
        data: realityCompanyUser,
        isLoading: isLoadingUser
    } = useSelector((state: any) => state[REDUCER_REALITY_COMPANY].user);

    const getCurrentUser = useApiRequest(REDUCER_USER, 'user/me', GET_CURRENT_USER, {method: 'get'});
    const getRealityCompanyForUser = useApiRequest(REDUCER_REALITY_COMPANY, 'reality-company', GET_REALITY_COMPANY_USER, {method: 'get'});

    useEffect(() => {
        getCurrentUser();
    }, []);

    useEffect(() => {
        if (isNotNilOrEmpty(companyRole) && isNilOrEmpty(realityCompanyUser) && !isLoadingUser) {
            getRealityCompanyForUser();
        }
    }, [isAuthenticated]);

    const isRoleValid = useMemo(() => {
            if (isNotNilOrEmpty(companyRole)) {
                return isNotNilOrEmpty(realityCompanyUser) && companyRole === realityCompanyUser.role;
            } else if (isNotNilOrEmpty(role)) {
                return isNotNilOrEmpty(roles) && hasUserValidRole(roles, role)
            } else {
                return true;
            }
        },
        [roles, realityCompanyUser]);

    if (isLoading || isRolesLoading || isLoadingUser || isNil(isAuthenticated)) {
        return null;
    }

    const originPath: string = (isArray(rest.path) ? rest.path[0] : rest.path) as string;
    const loginWithOriginalLocation = `/login?state=${btoa(originPath)}`;
    const redirectPath = isAuthenticated && !isRoleValid && alternativePath ? alternativePath : loginWithOriginalLocation;

    return (
        <Route
            {...rest}
            render={props => (
                isAuthenticated && isRoleValid
                    ? <Component {...props} />
                    : <Redirect to={redirectPath as string}/>
            )}
        />
    );
};

export default PrivateRoute;
