import {useApiRequest} from "../../store/api";
import {
    REDUCER_AUTH,
    REDUCER_CONTEXT,
    REDUCER_REALITY_COMPANY,
    REDUCER_USER
} from "../../utils/constant";
import {GET_SELLER} from "../../store/reducers/realityCompany";
import React, {useEffect, useMemo, useState} from "react";
import {isNotNilOrEmpty, replaceOfferItemIdPath} from "../../utils/helpers";
import {useSelector} from "react-redux";
import {usePrevious} from "../../hooks/hooks";
import {Box, Button, Grid, TextareaAutosize} from "@material-ui/core";
import {ERoutePaths} from "../../enumerators";
import t from "../../texts";
import {useHistory} from "react-router-dom";
import {scrollToTop} from "../Navigation/ScrollToTop";
import ConfirmDialog from "../ConfirmDialog";
import {REPORT_OFFER_ITEM} from "../../store/reducers/context";
import Recaptcha from "../Recaptcha";

type IProps = {
    offerItemId: string,
    saveFavorite: () => void,
    isFavorite: boolean
}


const ActionButtons = ({offerItemId, saveFavorite, isFavorite}: IProps) => {
    const {push, location} = useHistory();
    const prevOfferItemId = usePrevious(offerItemId);
    const [issue, setIssue] = useState('');
    const isAuthenticated = useSelector((state: any) => state[REDUCER_AUTH].isAuthenticated);
    const {data: currentUser} = useSelector((state: any) => state[REDUCER_USER].currentUser);

    const report = useApiRequest(REDUCER_CONTEXT, 'message/report', REPORT_OFFER_ITEM, {method: 'post'});
    const getSellerForAdvert = useApiRequest(REDUCER_REALITY_COMPANY, 'offer-item/:offerItemId/person-seller', GET_SELLER, {method: 'get'});
    const {
        seller: {data: seller},
    } = useSelector((state: any) => state[REDUCER_REALITY_COMPANY]);

    const isOwner = useMemo(() => seller?.extId === currentUser?.extId, [seller, currentUser]);

    useEffect(() => {
        if (isNotNilOrEmpty(offerItemId) && prevOfferItemId !== offerItemId) {
            getSellerForAdvert({params: {offerItemId}});
        }
    }, [offerItemId]);

    const onReport = () => {
        try {
            report({
                body: {
                    "message": issue,
                    "offerItemUrl": location?.pathname,
                },
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Box data-component="ResultDetail" className="section" mt={3}>
            <Box m={1} display="flex" flexWrap="wrap" gridRowGap={5} justifyContent="space-evenly">
                {isOwner && (
                    <Button
                        color="primary"
                        onClick={() => push(replaceOfferItemIdPath(ERoutePaths.ADVERT_INFORMATION_ID, offerItemId))}
                        variant="outlined"
                    >
                        {t.EDIT_ADVERT}
                    </Button>
                )}
                {isAuthenticated &&
                    <Button
                        color="primary"
                        onClick={saveFavorite}
                        variant="outlined"
                    >
                        {isFavorite ? t.REMOVE_FROM_FAVORITE : t.ADD_TO_FAVORITE}
                    </Button>
                }
                <Button
                    color="primary"
                    onClick={scrollToTop}
                    variant="outlined"
                >
                    {t.SCROLL_TO_TOP}
                </Button>

                <ConfirmDialog
                    name={t.REPORT_ADVERT}
                    title={t.REPORT_ADVERT_TITLE}
                    body={(
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextareaAutosize style={{width: '100%'}} rowsMin={6} placeholder={t.REPORT_ISSUE}
                                                  value={issue}
                                                  onChange={(event: any) => setIssue(event.target.value)} />
                                <p>Ďakujeme že pomáhate vylepšiť portál skreality.sk</p>
                            </Grid>
                        </Grid>
                    )}
                    closeButtonName={t.CLOSE}
                    confirmButtonName={t.REPORT}
                    confirmButtonFn={onReport}
                    maxWidth="sm"
                />
            </Box>
            <Recaptcha actionName="reportAdvert" />
        </Box>
    );
}

export default ActionButtons;
