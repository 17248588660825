import { Box, Grid } from '@material-ui/core';
import Header from '../../components/Header';
import React from 'react';
import './style.scss';

interface IProps {
    children: React.ReactNode;
}

export default ({ children }: IProps) => (
    <React.Fragment>
        <Header />

        <Box data-component="Layout_Stretched">
            <Grid container className="App">
                {children}
            </Grid>
        </Box>
    </React.Fragment>
);
