import React, {useState} from "react";
import {
    Button,
    Grid,
    Snackbar,
    Typography
} from "@material-ui/core";
import t from "../../texts";
import './style.scss';
import {
    REDUCER_CONTEXT,
    USER_COOKIES_FUNCTIONAL,
    USER_COOKIES_MARKETING,
    USER_COOKIES_STATISTICS
} from "../../utils/constant";
import {Close} from "@material-ui/icons";
import {getBooleanItem, setStorageItem} from "../../utils/storage";
import usePageTracking from "../../usePageTracking";
import {createAction} from "../../store/actions";
import {COOKIE_DIALOG} from "../../store/reducers/context";
import {useSelector} from "react-redux";

const Cookies = () => {
    const {initiateGA} = usePageTracking() || {};
    const [isOpen, setIsOpen] = useState(!getBooleanItem(USER_COOKIES_FUNCTIONAL));
    const isDialogOpen = useSelector((state: any) => state[REDUCER_CONTEXT].isCookieDialogOpen);
    const openCloseCookieDialog = createAction(COOKIE_DIALOG);

    const onAgreeAll = () => {
        initiateGA();
        setStorageItem(USER_COOKIES_FUNCTIONAL, 'true');
        setStorageItem(USER_COOKIES_MARKETING, 'true');
        setStorageItem(USER_COOKIES_STATISTICS, 'true');
        setIsOpen(false);
    }

    const onClose = () => {
        setStorageItem(USER_COOKIES_FUNCTIONAL, 'true');
        if (!isDialogOpen) {
            setIsOpen(false);
        }
    }

    return (
        <Snackbar open={isOpen} onClose={onClose}
                  className="App cookiesBar"
                  ClickAwayListenerProps={{
                      onClickAway: () => {
                      },
                  }}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
            <Grid container justify="space-around" alignItems="center">
                <Grid item xs={12} sm={8}>
                    <Typography align="left" variant="body2">{t.COOKIES_TITLE}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button variant="contained" color="primary" className="buttons"
                            onClick={onAgreeAll}>{t.AGREE_WITH_ALL}</Button>
                    <Button variant="outlined" color="primary" className="buttons"
                            onClick={() => {
                                setIsOpen(false);
                                openCloseCookieDialog(true);
                            }}>{t.COOKIES_SETTINGS}</Button>
                    <Button color="primary" size="small" onClick={onClose}>
                        <Close />
                    </Button>
                </Grid>
            </Grid>
        </Snackbar>
    );
}

export default Cookies;
