import { useMemo } from 'react';
import { getLastSearch, setSearchHistory } from '../utils/storage';
import { normalizeFilter } from '../utils/helpers';

const usePreviousFilter = () => {
    const previousFilter = useMemo(() => getLastSearch(), []);

    const setPreviousFilter = (filter: any) => {
        const params: any = normalizeFilter(filter);
        setSearchHistory(params);
    };

    return {
        previousFilter,
        setPreviousFilter,
    };
};

export default usePreviousFilter;
