import React, {useEffect, useState} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTheme} from '@material-ui/core/styles';
import './style.scss';

interface DialogProps {
    name: string;
    body: any;
    title: string;
    confirmButtonName: string;
    confirmButtonFn: Function;
    confirmButtonDisabled?: boolean;
    closeButtonName: string;
    maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    classes?: any;
    onCloseFn?: Function;
    setIsOpen?: Function;
    fullScreen?: boolean,
}

const ConfirmDialog = ({
                           name,
                           body,
                           title,
                           confirmButtonName,
                           confirmButtonFn,
                           confirmButtonDisabled,
                           closeButtonName,
                           maxWidth,
                           classes,
                           onCloseFn,
                           setIsOpen,
                           fullScreen = false,
                       }: DialogProps) => {
    const [open, setOpen] = useState(false);
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setIsOpen && setIsOpen(open);
    }, [open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (onCloseFn) {
            onCloseFn();
        }
        setOpen(false);
    };

    const handleConfirm = async () => {
        await confirmButtonFn();
        handleClose();
    };

    return (
        <div data-component="ConfirmDialog">
            <Button className="button" variant="outlined" onClick={handleClickOpen}>
                {name}
            </Button>
            <Dialog
                fullScreen={fullScreen}
                fullWidth={fullScreen}
                maxWidth={maxWidth}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                classes={classes}
            >
                <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {body}
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="secondary" variant="outlined">
                        {closeButtonName}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" variant="contained" autoFocus
                            disabled={confirmButtonDisabled}>
                        {confirmButtonName}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfirmDialog;
