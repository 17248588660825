import {Box} from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import './style.scss';
import {Link} from "react-router-dom";

export default () => {
    return (
        <Box data-component="BusinessConditions">
            <Box className={cx('container')}>
                <Box className="section">
                    <h2>Obchodné podmienky</h2>
                    <p>
                        <Link
                            to={process.env.PUBLIC_URL + '/files/vseobecne-obchodne-podmienky-fo.docx'}
                            target="_blank" className="link">Všeobecné obchodné podmienky pre fyzické osoby</Link>
                    </p>
                    <p>
                        <Link
                            to={process.env.PUBLIC_URL + '/files/vseobecne-obchodne-podmienky-po.docx'}
                            target="_blank" className="link">Všeobecné obchodné podmienky pre právnické osoby a fyzické
                            osoby - podnikateľov</Link>
                    </p>

                </Box>
            </Box>
        </Box>
    );
};
