import React from "react";
import {STATUS_MAP} from "./constant";

export interface ICodebookItem {
    code: string;
    value: string;
    order: number;
    urlFriendlyValue: string;
    userFriendlyValue: string;
    shortcutCode?: string,
    shortcutValue?: string,
}

export interface IApartmentRequest {
    apartment: {
        balcony?: boolean,
        barrierless?: boolean,
        buildingMaterialTypes?: string[],
        cellar?: boolean,
        elevator?: boolean,
        garage?: boolean,
        lodgy?: boolean,
        floors?: number,
        maxFloor?: number,
        minFloor?: number,
        parking?: boolean,
        realEstateDispositions?: string[],
        realEstateOwnerships?: string[],
        realEstateStates?: string[],
        surfaceAreaMax?: number,
        surfaceAreaMin?: number,
        terrace?: boolean
    }
}

export interface ICommercialRequest {
    commercial: {
        commercialType?: string[],
        garage?: boolean,
        parking?: boolean,
        realEstateState?: string[],
        surfaceAreaMax?: number,
        surfaceAreaMin?: number
    }
}

export interface IHouseRequest {
    house: {
        bungalow?: boolean,
        garage?: boolean,
        houseTypes?: string[],
        lotAreaMax?: number,
        lotAreaMin?: number,
        lowEnergy?: boolean,
        madeOfWood?: boolean,
        moreBuildings?: boolean,
        parking?: boolean,
        pool?: boolean,
        realEstateDispositions?: string[],
        realEstateStates?: string[],
        separateBuilding?: boolean,
        surfaceAreaMax?: number,
        surfaceAreaMin?: number
    }
}

export interface ILotRequest {
    lot: {
        lotAreaMax?: number,
        lotAreaMin?: number,
        lotTypes?: string[],
    }
}

export interface IOfferItem {
    offerTypes: string[] | undefined;
    priceMax: number | undefined;
    priceMin: number | undefined;
    publishedAtFrom: string | undefined;
    districts: string[] | undefined;
    regions: string[] | undefined;
    locality: string | undefined;
    radius: number | undefined;

    apartment?: IApartmentRequest,
    house?: IHouseRequest;
    lot?: ILotRequest;
    commercial?: ICommercialRequest;
}

export class SearchRequest implements IOfferItem {

    offerTypes: string[] | undefined;
    priceMax: number | undefined;
    priceMin: number | undefined;
    publishedAtFrom: string | undefined;
    districts: string[] | undefined;
    regions: string[] | undefined;
    locality: string | undefined;
    radius: number | undefined;
    apartment?: IApartmentRequest | undefined;
    house?: IHouseRequest | undefined;
    lot?: ILotRequest | undefined;
    commercial?: ICommercialRequest | undefined;

    constructor(params: any) {
        const {
            realEstateType,
            offerType,
            publishedAtFrom,
            priceMin,
            priceMax,
            districts,
            regions,
            locality,
            radius,
            ...typeParams
        } = params;

        this.offerTypes = [offerType];
        this.publishedAtFrom = publishedAtFrom;
        this.priceMax = priceMax;
        this.priceMin = priceMin;
        this.districts = districts;
        this.regions = regions;
        this.radius = radius;
        this.locality = locality;

        switch (realEstateType) {
            case 'APARTMENT':
                this.apartment = {
                    ...typeParams
                };
                break;
            case 'HOUSE':
                this.house = {
                    ...typeParams
                };
                break;
            case 'LOT':
                this.lot = {
                    ...typeParams
                };
                break;
            case 'COMMERCIAL':
                this.commercial = {
                    ...typeParams
                };
                break;
        }
    }
}

export interface ISearchResultContent {
    description: string,
    extId: string,
    offerType: any,
    price: number,
    priceInfo: string,
    publishedAt: string,
    status: 'EDIT' | 'PUBLISHED' | 'UNACTIVE',
    address: any,
    badges: any[],
    images: any[],
    realEstate: any,
    publishedTo: string
}

export interface IImage {
    description: string,
    extId: string,
    type: string,
    url: string,
}

export interface IContactForm {
    name: string,
    email: string,
    phone: string,
    message: string,
    offerItemUrl: string,
    copyToMail: boolean,
}

export interface IUser {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    agreementMarketing?: boolean,
    agreementConditions?: boolean,
}

export interface IEstateAgency {
    companyInfo: string,
    companyName: string,
    companyLogo: IImage,
    email: string,
    extId: string,
    phone: string,
    website: string,
}

export interface IProps {
    children: React.ReactNode;
}

export interface ComponentParams {
    property: any;
    label: string;
    required?: boolean;
    error?: any;
}

export interface InputParams {
    onChange: any;
    onBlur: any;
    currentValue: any;
    label: string;
    required?: boolean;
    error?: any;
    disabled?: boolean;
    fullWidth?: boolean;
    min?: number;
    max?: number;
}

export interface ComponentCodebookParams {
    values?: any[],
    property: any;
    codebookName: string;
    required?: boolean;
    error?: any;
    disabled?: boolean;
    fullWidth?: boolean;
}

export interface ComponentGroupParams {
    values?: any[],
    property: any;
    required?: boolean;
    error?: any;
    disabled?: boolean;
    fullWidth?: boolean;
    currentValue?: any,
    label?: string,
}

export interface ComponentFilterParams {
    label?: string,
    selector?: string;
    filter: string;
    codebookName: string;
    fullWidth?: boolean;
    onChange?: any;
}

export interface InputGroupParams {
    label?: string,
    onChange: any;
    currentValue: any;
    values: any[];
    required?: boolean;
    error?: any;
    disabled?: boolean;
    fullWidth?: boolean;
    emptyValue?: boolean;
}

export interface ComponentParamsMulti {
    property: any;
    label: string;
    required?: boolean;
    error?: any;
    multiline?: boolean;
    disabled?: boolean;
    rows?: number;
    min?: number,
    max?: number,
    fullWidth?: boolean,
}

export interface InputParamsMulti {
    onChange: any;
    currentValue: any;
    property: any;
    label: string;
    required?: boolean;
    error?: any;
    multiline?: boolean;
    disabled?: boolean;
    rows?: number;
    fullWidth?: boolean,
}

export type RealEstateCompanyDto = {
    extId: string;
    companyName: string;
    companyInfo: string;
    website: string;
    email: string;
    phone: string;
}

export type User = {
    extId: string;
    firstName: string;
    lastName: string;
    degree: any; // idk
    phone: string;
    email: string;
    emailVerified: boolean;
    provider: string;
    credit: unknown; // idk
    picture?: IImage;
}

export type RealEstateCompanyUser = {
    user: User;
    role?: string;
    confirmed?: boolean;
}

// to jsou vymozenosti jak svina
export interface ChipUser extends RealEstateCompanyUser {
    selected?: boolean;
}

export type SavedSearch = {
    active: boolean,
    extId: string
    savedSearch: IOfferItem,
}

export type MenuItemType = {
    label: string,
    section: string,
    iconElement: any,
    link?: string,
    secondary?: any,
    callback?: () => void,
    disabled?: boolean,
}

export interface IPagination {
    size: number,
    page: number,
    totalElements: number,
    totalPages: number,
    sort: string,
}

export type Statistic = {
    averageEngagementTime: number
    date: Date,
    day: number,
    month: number,
    name: string,
    totalUsers: number,
    views: number,
}

export type Notification = {
    text: string,
    onPress: () => void,
}
