import qs from "qs";
import {useHistory} from "react-router-dom";
import {useMemo} from "react";

const useUrlPageNumber = () => {
    const {location} = useHistory();

    const obj = qs.parse(location.search, { ignoreQueryPrefix: true });
    const urlPageNumber = parseInt(obj.page as string || "1") - 1;

    return useMemo(() => {
        return urlPageNumber;
    }, [urlPageNumber]);
}

export default useUrlPageNumber;
