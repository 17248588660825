import {handleAction} from '../../utils/helpers';
import {
    IMPORT_STATUS,
    ImportTypeCodebook,
    initialPayload
} from '../../utils/constant';
import {initPagination} from './search';
import {head} from "ramda";

export const CREATE_REAL_ESTATE_COMPANY = 'CREATE_REAL_ESTATE_COMPANY';
export const CREATE_REAL_ESTATE_COMPANY_SUCCESS = `${CREATE_REAL_ESTATE_COMPANY}_SUCCESS`;
export const GET_SELLER = 'GET_SELLER';
export const GET_SELLER_REQUEST = `${GET_SELLER}_REQUEST`;
export const GET_SELLER_SUCCESS = `${GET_SELLER}_SUCCESS`;
export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_REQUEST = `${GET_COMPANY}_REQUEST`;
export const GET_COMPANY_SUCCESS = `${GET_COMPANY}_SUCCESS`;
export const GET_COMPANY_FAILED = `${GET_COMPANY}_FAILED`;
export const GET_REALITY_COMPANY_USER = 'GET_REALITY_COMPANY_USER';
export const GET_REALITY_COMPANY_USER_FAILED = `${GET_REALITY_COMPANY_USER}_FAILED`;
export const ADD_REALITY_COMPANY_USER = 'ADD_REALITY_COMPANY_USER';
export const ADD_REALITY_COMPANY_USER_FAILED = 'ADD_REALITY_COMPANY_USER_FAILED';

export const FETCH_COMPANY_ALL = 'FETCH_COMPANY_ALL';
export const SET_PAGINATION_COMPANIES = 'SET_PAGINATION_COMPANIES';

export const CREATE_IMPORT_CONFIGURATION = 'CREATE_IMPORT_CONFIGURATION';
export const UPDATE_IMPORT_CONFIGURATION = 'UPDATE_IMPORT_CONFIGURATION';
export const GET_IMPORT_CONFIGURATION = 'GET_IMPORT_CONFIGURATION';
export const RUN_IMPORT_CONFIGURATION = 'RUN_IMPORT_CONFIGURATION';

export const SET_IMPORT_PROPERTY = 'SET_IMPORT_PROPERTY';
export const GET_IMPORT_CONFIGURATION_REQUEST = 'GET_IMPORT_CONFIGURATION_REQUEST';
export const SET_IMPORT_FORM_ERROR = 'SET_IMPORT_FORM_ERROR';
export const CLEAR_IMPORT_FORM_ERROR = 'CLEAR_IMPORT_FORM_ERROR';

export const initImportForm = {
    data: {
        status: IMPORT_STATUS.ACTIVE,
        type: head(ImportTypeCodebook),
        authKey: '',
        authSecret: '',
        apiAddress: 'https://api.zoznamrealit.sk',
    }
};

export const initialState = {
    seller: initialPayload(null),
    company: initialPayload(null),
    user: initialPayload(null),
    companies: initialPayload([]),
    pagination: initPagination,
    importForm: initImportForm,
    formErrors: {}
};

export function reducer(state = initialState, action) {

    const handlers = {
        [GET_SELLER]: {
            ...state,
            seller: action.payload
        },
        [GET_COMPANY]: {
            ...state,
            company: action.payload
        },
        [GET_REALITY_COMPANY_USER]: {
            ...state,
            user: action.payload
        },
        [FETCH_COMPANY_ALL]: {
            ...state,
            companies: action.payload,
            pagination: {
                ...state.pagination,
                page: action.payload?.data?.number,
                totalElements: action.payload?.data?.totalElements,
                totalPages: action.payload?.data?.totalPages
            }
        },
        [SET_PAGINATION_COMPANIES]: {
            ...state,
            pagination: action.payload
        },
        [SET_IMPORT_PROPERTY]: {
            ...state,
            importForm: {
                data: {...state.importForm.data, ...action.payload}
            }
        },
        [GET_IMPORT_CONFIGURATION]: {
            ...state,
            importForm: action.payload,
        },
        [GET_IMPORT_CONFIGURATION_REQUEST]: {
            ...state,
            importForm: {
                data: state.importForm.data,
                isLoading: true,
            }
        },
        [SET_IMPORT_FORM_ERROR]: {
            ...state,
            formErrors: {...state.formErrors, ...action.payload}
        },
        [CLEAR_IMPORT_FORM_ERROR]: {
            ...state,
            formErrors: {},
        },
    };
    return handleAction(action.type, handlers) || state;
}
