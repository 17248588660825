import Advertisement from '../../components/Advertisement';
import EstateAgencyAdministration from '../../components/EstateAgencyAdministration';
import Header from '../../components/Header';
import {ERouteParameters, ERoutePaths} from '../../enumerators';
import Layout from '../../layouts/Administration';
import React, {useMemo, useState} from 'react';
import {Box} from '@material-ui/core';
import './style.scss';
import t from '../../texts';
import {File, Folder, Upload, Users} from 'react-feather';
import {MenuItemType} from "../../utils/types";
import {AGENCY_SECTIONS, REDUCER_USER} from "../../utils/constant";
import useAgency from "../../hooks/useAgency";
import {useApiDataSelector} from "../../hooks/hooks";

const EstateAgencyAdministrationPage = () => {
    const [activeSection, setActiveSection] = useState(AGENCY_SECTIONS.AGENCIES_ADMINISTRATION);
    const {myAgency, agencyUsers} = useAgency();
    const currentUser = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser);

    const menuItems = useMemo(() => [
        {
            label: t.AGENCIES_ADMINISTRATION,
            section: AGENCY_SECTIONS.AGENCIES_ADMINISTRATION,
            callback: () => setActiveSection(AGENCY_SECTIONS.AGENCIES_ADMINISTRATION),
            iconElement: <Folder />,
            secondary: currentUser?.firstName
        }, {
            label: t.AGENT_LIST,
            section: AGENCY_SECTIONS.AGENT_LIST,
            callback: () => setActiveSection(AGENCY_SECTIONS.AGENT_LIST),
            iconElement: <Users />,
            secondary: agencyUsers?.length,
        }, {
            label: t.AGENCIES_ADVERTS,
            callback: () => setActiveSection(AGENCY_SECTIONS.AGENCIES_ADVERTS),
            iconElement: <File />
        }, {
            label: t.ADVERT_IMPORT,
            callback: () => setActiveSection(AGENCY_SECTIONS.ADVERT_IMPORT),
            iconElement: <Upload />
        }
    ] as MenuItemType[], [setActiveSection, myAgency, agencyUsers]);

    return (
        <Box data-component="Estate_Agency_Administration">
            <Layout menuItems={menuItems} activeSection={activeSection}>
                <Header />
                <Advertisement />
                <EstateAgencyAdministration activeSection={activeSection} />
            </Layout>
        </Box>
    );
}

export default EstateAgencyAdministrationPage;
