import {useApiDataSelector, usePrevious} from './hooks';
import {REDUCER_REAL_ESTATE, REDUCER_USER} from '../utils/constant';
import {useApiRequest} from '../store/api';
import {GET_MY_AGENCY} from '../store/reducers/realEstate';
import {useEffect} from 'react';
import {RealEstateCompanyUser} from '../utils/types';
import {isNilOrEmpty} from '../utils/helpers';
import {useSelector} from "react-redux";
import {singletonHook} from "react-singleton-hook";

const useAgency = singletonHook({
    myAgency: {},
    agencyUsers: []
}, () => {
    const {extId: userId} = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser) || {};
    const prevUserId = usePrevious(userId);

    const {data: myAgency = {}, isLoading} = useSelector((state: any) => state[REDUCER_REAL_ESTATE].myRealEstateAgency);
    const agencyUsers: Array<RealEstateCompanyUser> = useApiDataSelector((state: any) => state[REDUCER_REAL_ESTATE].myRealEstateAgencyUsers);

    const getMyAgency = useApiRequest(REDUCER_REAL_ESTATE, 'reality-company/user/:userId', GET_MY_AGENCY, {method: 'get'});

    useEffect(() => {
        if (userId && !isLoading && (prevUserId !== userId || isNilOrEmpty(myAgency))) {
            getMyAgency({params: {userId}});
        }
    }, [userId]);


    return {
        myAgency,
        agencyUsers
    };
});

export default useAgency;
