import React from 'react';
import { Grid } from '@material-ui/core';
import cx from 'classnames';
import ResultDetailItem from '../../components/ResultDetailItem';
import ResultMap from '../../components/ResultsMap';
import Layout from '../../layouts/Stretched';
import { useSelector } from 'react-redux';
import { REDUCER_CONTEXT } from '../../utils/constant';
import { EViews } from '../../enumerators';
import useFilter from '../../hooks/useFilter';

const ResultDetail = () => {
    const { isCollapsedView, isMapExpanded } = useSelector((state: any) => state[REDUCER_CONTEXT]);
    const { result, locations, linkToDetail } = useFilter();

    return (
        <Layout>
            <Grid
                id="results"
                className={cx({ collapsedSection: isCollapsedView })}
                item
                sm={12}
                md={isCollapsedView ? 6 : 7}
            >
                <ResultMap locations={locations} linkToDetail={linkToDetail} visibleFor={EViews.MOBILE} />

                {!isMapExpanded && (
                    <ResultDetailItem result={result} linkToDetail={linkToDetail} />
                )}
            </Grid>

            <ResultMap sizeOfMapContainer={6} locations={locations} linkToDetail={linkToDetail} visibleFor={EViews.DESKTOP} />
        </Layout>
    );
};

export default ResultDetail;
