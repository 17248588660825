import {ERoutePaths, USER_PRIVILEGES} from './enumerators';
import Page_FilterSearch from './pages/FilterSearch';
import Page_Home from './pages/Home';
import Page_NotFound from './pages/NotFound';
import Page_SearchResult from './pages/SearchResults';
import Page_ResultDetail from './pages/ResultDetail';
import Page_MyAccount from './pages/MyAccount';
import Page_AdvertInformation from './pages/AdvertInformation';
import Page_AdvertProperties from './pages/AdvertProperties';
import Page_AdvertPhoto from './pages/AdvertPhoto';
import Page_AdvertAddress from './pages/AdvertAddress';
import Page_AdvertOverview from './pages/AdvertOverview';
import Page_EstateAgencyAdministration from './pages/EstateAgencyAdministration';
import Page_Login from './pages/Login';
import Page_Register from './pages/Register';
import Page_HowToAdvertise from './pages/HowToAdvertise';
import Page_GDPR from './pages/GDPR';
import Page_Documents from './pages/DocumentsPage';
import Page_BusinessConditions from './pages/BusinessConditions';
import Page_Contacts from './pages/Contacts';
import Page_ForgottenPassword from './pages/ForgottenPassword';
import Page_RegisterEstateAgency from './pages/RegisterEstateAgency';
import Confirmation from './pages/Confirmation';
import Page_EstateAgencyList from './pages/EstateAgencyList';
import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.scss';
import {Provider} from 'react-redux';
import {initialStateCombined, rootReducer} from './store/reducer';
import middleware from './store/middleware';
import {applyMiddleware, createStore} from 'redux';
import logger from 'redux-logger';
import PrivateRoute from './components/Auth/PrivateRoute';
import Auth from './components/Auth/Auth';
import ResetPassword from './pages/ResetPassword';
import ChangePassword from './pages/ChangePassword';
import {loadReCaptcha} from 'react-recaptcha-v3';
import SnackBar from './components/SnackBar';
import {Config} from './types/config';
import ScrollToTop from './components/Navigation/ScrollToTop';
import Cookies from "./components/Cookies/Cookies";
import {Helmet} from "react-helmet";
import {META_DESCRIPTION_FULL, META_TITLE_FULL} from "./utils/constant";
import CookiesDialog from "./components/Cookies/CookiesDialog";
import {SingletonHooksContainer} from "react-singleton-hook";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import MyRoute from "./components/Auth/MyRoute";

let config: Config;

export const getConfig = () => {
    return config;
};

export const setConfig = (inputConfig: Config) => {
    config = inputConfig;
};

// @ts-ignore
const store = createStore(rootReducer, initialStateCombined, applyMiddleware(logger, middleware));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#004577',
        },
        secondary: {
            main: '#1976d2',
            dark: '#3f51b5',
        },
    },
});

const App = () => {
    useEffect(() => {
        loadReCaptcha(getConfig().recaptchaSiteKey);
    });

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <Helmet>
                    <title>Skreality.sk • Slovenský trh realít</title>
                    <meta name="description" content={META_DESCRIPTION_FULL}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image" content="/images/logos/social-logo.png"/>
                    <meta property="og:title" content={META_TITLE_FULL}/>
                    <meta property="og:description" content={META_DESCRIPTION_FULL}/>
                    <meta property="og:url" content="https://www.skreality.sk"/>
                </Helmet>
                <Router basename="/">
                    <SingletonHooksContainer/>
                    <SnackBar/>
                    <Cookies/>
                    <CookiesDialog/>
                    <Auth>
                        <ScrollToTop/>
                        <Switch>
                            <Route component={Page_Home} path={ERoutePaths.HOME} exact/>
                            <Route component={Page_FilterSearch} path={[ERoutePaths.SEARCH_ID, ERoutePaths.SEARCH]}/>
                            <MyRoute
                                name="search"
                                component={Page_SearchResult}
                                path={[
                                    ERoutePaths.SEARCH_RESULT_ID,
                                    ERoutePaths.SEARCH_RESULT_SELLER,
                                    ERoutePaths.SEARCH_RESULT_COMPANY,
                                    ERoutePaths.SEARCH_RESULT,
                                    ERoutePaths.SEARCH_RESULT_BASE
                                ]}
                            />
                            <MyRoute name="search"
                                     component={Page_ResultDetail}
                                     path={[ERoutePaths.DETAIL_SELLER, ERoutePaths.DETAIL, ERoutePaths.DETAIL_ID]}/>

                            <PrivateRoute component={Page_MyAccount} path={ERoutePaths.ACCOUNT}/>

                            <PrivateRoute
                                role={USER_PRIVILEGES.BASIC_SELLER_VERIFIED}
                                component={Page_AdvertInformation}
                                path={[ERoutePaths.ADVERT_INFORMATION_ID, ERoutePaths.ADVERT_INFORMATION]}
                            />
                            <PrivateRoute
                                role={USER_PRIVILEGES.BASIC_SELLER_VERIFIED}
                                component={Page_AdvertProperties}
                                path={ERoutePaths.ADVERT_PROPERTIES}
                            />
                            <PrivateRoute
                                role={USER_PRIVILEGES.BASIC_SELLER_VERIFIED}
                                component={Page_AdvertPhoto}
                                path={ERoutePaths.ADVERT_PHOTO}
                            />
                            <PrivateRoute
                                role={USER_PRIVILEGES.BASIC_SELLER_VERIFIED}
                                component={Page_AdvertAddress}
                                path={ERoutePaths.ADVERT_ADDRESS}
                            />
                            <PrivateRoute
                                role={USER_PRIVILEGES.BASIC_SELLER_VERIFIED}
                                component={Page_AdvertOverview}
                                path={ERoutePaths.ADVERT_OVERVIEW}
                            />
                            <PrivateRoute
                                companyRole={USER_PRIVILEGES.COMPANY_ADMIN}
                                component={Page_EstateAgencyAdministration}
                                path={ERoutePaths.ESTATE_AGENCY_ADMINISTRATION}
                                alternativePath={ERoutePaths.ESTATE_AGENCY_REGISTRATION}
                            />
                            <Route component={Page_Login} path={ERoutePaths.LOGIN}/>
                            <Route component={Page_RegisterEstateAgency}
                                   path={ERoutePaths.ESTATE_AGENCY_REGISTRATION}/>
                            <Route component={Page_Register} path={ERoutePaths.REGISTER}/>
                            <Route component={Page_ForgottenPassword} path={ERoutePaths.FORGOTTEN_PASSWORD}/>
                            <Route component={Page_HowToAdvertise} path={ERoutePaths.HOW_TO_ADVERTISE}/>
                            <Route component={Page_GDPR} path={ERoutePaths.GDPR}/>
                            <Route component={Page_Documents} path={ERoutePaths.DOCUMENTS}/>
                            <Route component={Page_BusinessConditions} path={ERoutePaths.BUSINESS_CONDITIONS}/>
                            <Route component={Page_Contacts} path={ERoutePaths.CONTACTS}/>
                            <Route component={ResetPassword} path={ERoutePaths.RESET_PASSWORD}/>
                            <Route component={ChangePassword} path={ERoutePaths.CHANGE_PASSWORD}/>
                            <Route component={Page_EstateAgencyList} path={ERoutePaths.ESTATE_AGENCY_LIST}/>
                            <Route component={Confirmation} path={ERoutePaths.CONFIRMATION}/>
                            <Route component={Confirmation} path={ERoutePaths.AUTH0}/>
                            <Route component={Page_NotFound} exact/>
                        </Switch>
                    </Auth>
                </Router>
            </MuiThemeProvider>
        </Provider>
    );
};

export default App;
