import React, {useCallback, useEffect, useMemo} from 'react';
import {Home} from 'react-feather';
import {useHistory} from 'react-router-dom';
import {Box, Button, FormControl, Grid, MenuItem, Paper, Select} from '@material-ui/core';
import Advertisement from '../../components/Advertisement';
import Header from '../../components/Header';
import t from '../../texts';
import {ERouteParameters, ERoutePaths} from '../../enumerators';
import Layout from '../../layouts/Centered';

import './style.scss';
import {
    ADMIN_SECTIONS,
    BUILDING_MATERIAL_TYPE,
    COMMERCIAL_TYPE,
    HOUSE_TYPE,
    LOT_TYPE,
    OFFER_TYPE,
    REAL_ESTATE_DISPOSITION,
    REAL_ESTATE_OWNERSHIP,
    REAL_ESTATE_STATE,
    REAL_ESTATE_TYPE,
    REAL_ESTATE_TYPES,
    REDUCER_SEARCH,
    REGION
} from '../../utils/constant';
import {formatToRequestBody, isFilterEmpty, isNilOrEmpty, isNotNilOrEmpty} from '../../utils/helpers';
import {createAction} from '../../store/actions';
import {
    ButtonGroupComp,
    CheckboxCodebookComp,
    CheckboxInputComp, CollapseWithLabel,
    NumberInputComp,
    SelectCodebookComp
} from '../../components/Form';
import RegionsSKMap from '../../components/Filters/RegionsSKMap';
import {
    withCodebookFilter,
    withCodebookValuesFilter,
    withFilter,
    withFilterOnChange
} from '../../components/Filters/FilterHOC';
import {useSelector} from 'react-redux';
import {filterInitialState, GET_FILTER_COUNT, SET_FILTER, SET_FILTER_PARAM} from '../../store/reducers/search';
import DistrictFilter from '../../components/Filters/DistrictFilter';
import {useApiDataSelector, useParamName} from '../../hooks/hooks';
import useSavedSearch from '../../hooks/useSavedSearch';
import usePreviousFilter from '../../hooks/usePreviousFilter';
import {useFilterBuilder} from "../../hooks/useFilterBuilder";
import Recaptcha from "../../components/Recaptcha";
import {useApiRequest} from "../../store/api";
import {SearchRequest} from "../../utils/types";
import {debounce} from "lodash";

const ButtonGroupFilter = withCodebookFilter(ButtonGroupComp);
const SelectCodebookFilter = withCodebookFilter(SelectCodebookComp);
const CheckboxCodebookFilter = withCodebookValuesFilter(CheckboxCodebookComp);
const NumberFilter = withFilter(NumberInputComp);
const CheckboxFilter = withFilterOnChange(CheckboxInputComp);

const FilterSearch = () => {
    const history = useHistory();
    const savedSearchId = useParamName(ERouteParameters.SEARCH_ID);

    const {updateSavedSearch, savedSearch} = useSavedSearch();
    const {previousFilter, setPreviousFilter} = usePreviousFilter();

    const filter = useSelector((state: any) => state[REDUCER_SEARCH].filter);
    const filterCount = useApiDataSelector((state: any) => state[REDUCER_SEARCH].filterCount);

    const setFilter = createAction(SET_FILTER);
    const setFilterParam = createAction(SET_FILTER_PARAM);
    const {urlFromFilter} = useFilterBuilder();
    const getCount = useApiRequest(REDUCER_SEARCH, 'offer-item/search/count', GET_FILTER_COUNT, {method: 'post'});


    useEffect(() => {
        if (isNilOrEmpty(filter) && isNilOrEmpty(savedSearchId)) {
            if (isNotNilOrEmpty(previousFilter)) {
                setFilter(previousFilter);
            }
            setFilterParam(filterInitialState);
        }
    }, [previousFilter]);

    useEffect(() => {
        if (isNotNilOrEmpty(savedSearchId) && isNotNilOrEmpty(savedSearch)) {
            setFilter(savedSearch);
        }
    }, [savedSearchId, savedSearch]);

    useEffect(() => {
        if (!isFilterEmpty(filter)) {
            getCountByFilter(filter);
        }
    }, [filter]);

    const getCountByFilter = useCallback(debounce((filter) => {
        const request = new SearchRequest(formatToRequestBody(filter));
        getCount({body: request});
    }, 2000), [getCount]);

    const handleSubmit = useCallback(async () => {
        if (savedSearchId) {
            updateSavedSearch(filter);
            history.push(ERoutePaths.ACCOUNT, {[ERouteParameters.ADMIN_ACTIVE_SECTION]: ADMIN_SECTIONS.SEARCH_NOTIFICATION});
        } else {
            setPreviousFilter(filter);
            history.push(urlFromFilter(filter));
        }
    }, [filter, savedSearchId]);

    const onChangeRealEstateType = (value: any) => {
        setFilter({...filterInitialState, realEstateType: value})
    }

    const isApartment = useMemo(() => filter?.realEstateType?.code === REAL_ESTATE_TYPES.APARTMENT, [filter]);
    const isHouse = useMemo(() => filter?.realEstateType?.code === REAL_ESTATE_TYPES.HOUSE, [filter]);
    const isCommercial = useMemo(() => filter?.realEstateType?.code === REAL_ESTATE_TYPES.COMMERCIAL, [filter]);
    const isLot = useMemo(() => filter?.realEstateType?.code === REAL_ESTATE_TYPES.LOT, [filter]);

    return (
        <Layout>
            <Header/>
            <Advertisement/>

            <Box data-component="FilterSearch" mx="0.5rem">
                <h1>
                    <Grid container alignItems="center">
                        <Grid item xs={1} sm={1}>
                            <Home width={20} height={20}/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <SelectCodebookFilter
                                codebookName={REAL_ESTATE_TYPE}
                                filter="realEstateType"
                                onChange={onChangeRealEstateType}
                            />
                        </Grid>
                    </Grid>
                </h1>

                <Box mt={3} mb={5}>
                    <ButtonGroupFilter codebookName={OFFER_TYPE} filter="offerType" fullWidth/>
                </Box>

                {(isApartment || isHouse) && (
                    <CheckboxCodebookFilter
                        label={t.TYPE}
                        codebookName={REAL_ESTATE_DISPOSITION}
                        filter="realEstateDispositions"
                    />
                )}
                {isApartment && (
                    <>
                        <CheckboxCodebookFilter
                            label={t.BUILDING_MATERIAL_TYPES}
                            codebookName={BUILDING_MATERIAL_TYPE}
                            filter="buildingMaterialTypes"
                        />
                        <CheckboxCodebookFilter
                            label={t.OWNERSHIP}
                            codebookName={REAL_ESTATE_OWNERSHIP}
                            filter="realEstateOwnership"
                        />
                    </>
                )}
                {isHouse && (
                    <CheckboxCodebookFilter
                        label={t.HOUSE_TYPE}
                        codebookName={HOUSE_TYPE}
                        filter="houseTypes"
                    />
                )}
                {!isLot && (
                    <CheckboxCodebookFilter
                        label={t.REAL_ESTATE_STATE}
                        codebookName={REAL_ESTATE_STATE}
                        filter="realEstateStates"
                    />
                )}
                {isLot && (
                    <CheckboxCodebookFilter label={t.LOT_TYPES} codebookName={LOT_TYPE} filter="lotTypes"/>
                )}
                {isCommercial && (
                    <CheckboxCodebookFilter
                        label={t.COMMERCIAL_TYPE}
                        codebookName={COMMERCIAL_TYPE}
                        filter="commercialTypes"
                    />
                )}

                <CollapseWithLabel label={t.OBJECT_PARAMS}
                                   isSelected={isNotNilOrEmpty(filter.surfaceAreaMin)
                                       || isNotNilOrEmpty(filter.surfaceAreaMax)
                                       || isNotNilOrEmpty(filter.lotAreaMin)
                                       || isNotNilOrEmpty(filter.lotAreaMax)
                                       || isNotNilOrEmpty(filter.minFloor)
                                       || isNotNilOrEmpty(filter.maxFloor)
                                   }>
                    <Paper className="paperPadding" elevation={0}>
                        <Grid container spacing={2}>
                            {!isLot && (
                                <>
                                    <Grid className="left blueText" item xs={12} sm={4}>{t.SURFACE_AREA}</Grid>
                                    <Grid item xs={12} sm={4}>
                                        <NumberFilter
                                            label="Od"
                                            filter="surfaceAreaMin"
                                            min={0}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <NumberFilter
                                            label="Do"
                                            filter="surfaceAreaMax"
                                            min={0}
                                        />
                                    </Grid>
                                </>
                            )}

                            {(isLot || isHouse) && (
                                <>
                                    <Grid className="left blueText" item xs={12} sm={4}>{t.LOT_AREA}</Grid>
                                    <Grid item xs={12} sm={4}>
                                        <NumberFilter
                                            label="Od"
                                            filter="lotAreaMin"
                                            min={0}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <NumberFilter
                                            label="Do"
                                            filter="lotAreaMax"
                                            min={0}
                                        />
                                    </Grid>
                                </>
                            )}
                            {isApartment && (
                                <>
                                    <Grid className="left blueText" item xs={12} sm={4}>{t.FLOORS}</Grid>
                                    <Grid item xs={12} sm={4}>
                                        <NumberFilter
                                            label="Od"
                                            filter="minFloor"
                                            min={-5}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <NumberFilter
                                            label="Do"
                                            filter="maxFloor"
                                            min={0}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Paper>
                </CollapseWithLabel>

                {!isLot && (
                    <CollapseWithLabel
                        label={t.ADDITIONS}
                        isSelected={filter.bungalow || filter.lowEnergy || filter.madeOfWood || filter.moreBuildings || filter.separateBuilding || filter.pool
                            || filter.balcony || filter.elevator || filter.barrierless || filter.lodgy || filter.terrace || filter.cellar || filter.garage || filter.parking}
                    >
                        <Paper className="lp-16" elevation={0}>
                            <Grid container>
                                {isHouse && (
                                    <>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.BUNGALOW} filter="bungalow"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.LOW_ENERGY} filter="lowEnergy"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.MADE_OF_WOOD} filter="madeOfWood"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.MORE_BUILDINGS} filter="moreBuildings"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.SEPARATE_BUILDINGS} filter="separateBuilding"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.POOL} filter="pool"/>
                                        </Grid>
                                    </>
                                )}
                                {isApartment && (
                                    <>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.BALCONY} filter="balcony"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.ELEVATER} filter="elevator"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.BARRIERLESS} filter="barrierless"/>
                                        </Grid>
                                    </>
                                )}
                                {(isApartment || isHouse) && (
                                    <>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.LODGY} filter="lodgy"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.TERRACE} filter="terrace"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.CELLAR} filter="cellar"/>
                                        </Grid>
                                    </>
                                )}
                                {(isApartment || isCommercial || isHouse) && (
                                    <>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.GARAGE} filter="garage"/>
                                        </Grid>
                                        <Grid className="left" item xs={6} sm={4}>
                                            <CheckboxFilter label={t.PARKING} filter="parking"/>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Paper>
                    </CollapseWithLabel>
                )}

                <CollapseWithLabel label={t.SELECT_LOCATION} isSelected={isNotNilOrEmpty(filter.regions)}>
                    <Paper id="location" elevation={0}>
                        <Grid container justify="center">
                            {/* <Grid className="blueText" item xs={12} sm={1}>{t.COUNTRY}</Grid> */}

                            {/* <Grid item xs={12} sm={6}> */}
                            {/*    <FormControl variant="outlined" size="small"> */}
                            {/*        <Select className="left" defaultValue={1}> */}
                            {/*            <MenuItem value={1}>Slovenská Republika</MenuItem> */}
                            {/*        </Select> */}
                            {/*    </FormControl> */}
                            {/* </Grid> */}

                            <Grid className="map" item xs={12}>
                                <RegionsSKMap/>
                            </Grid>
                            <Grid className="checkboxes" container>
                                <CheckboxCodebookFilter filter="regions" codebookName={REGION}/>
                            </Grid>
                        </Grid>
                        <DistrictFilter/>
                    </Paper>
                </CollapseWithLabel>
                {/*   Lokalita - Hladajte v okoli -> nemame funkcionalitu na BE
                 <Box>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <h3>{t.LOCATION}</h3>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <h3>{t.SEARCH_AROUND}</h3>
                        </Grid>
                    </Grid>

                    <Paper className="paperPadding" elevation={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Zadejte hodnotu"
                                    variant="outlined"
                                    size="small"
                                    value={locality}
                                    onBlur={({ target: { value } }) => isNotNilOrEmpty(value) && setFilterParam({ locality: value })}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" size="small">
                                    <Select
                                        className="left"
                                        defaultValue={5}
                                        value={radius}
                                        onChange={({ target: { value } }) => isNotNilOrEmpty(value) && setFilterParam({ radius: value })}
                                    >
                                        <MenuItem value={0.5}>0,5 km</MenuItem>
                                        <MenuItem value={1}>1 km</MenuItem>
                                        <MenuItem value={2}>2 km</MenuItem>
                                        <MenuItem value={5}>5 km</MenuItem>
                                        <MenuItem value={10}>10 km</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                 </Box> */}

                <CollapseWithLabel label="Cena od - do"
                                   isSelected={isNotNilOrEmpty(filter.priceMin) || isNotNilOrEmpty(filter.priceMax)}>
                    <Paper className="paperPadding" elevation={0}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <NumberFilter
                                    label="Od"
                                    filter="priceMin"
                                    fullWidth
                                    min={0}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <NumberFilter
                                    label="Do"
                                    filter="priceMax"
                                    fullWidth
                                    min={0}
                                />
                            </Grid>

                        </Grid>
                    </Paper>
                </CollapseWithLabel>

                <CollapseWithLabel label={t.OFFER_AGE} isSelected={isNotNilOrEmpty(filter.publishedAtFrom)}>
                    <Paper className="paperPadding" elevation={0}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <Select
                                        className="left"
                                        value={filter?.publishedAtFrom || 0}
                                        onChange={({target: {value}}) => isNotNilOrEmpty(value) && setFilterParam({publishedAtFrom: value})}
                                    >
                                        <MenuItem value={1}>1 deň</MenuItem>
                                        <MenuItem value={7}>7 dní</MenuItem>
                                        <MenuItem value={30}>30 dní</MenuItem>
                                        <MenuItem value={0}>Neobmedzené</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                </CollapseWithLabel>

                <Box mt={5} id="buttons">
                    <Grid container>
                        <Grid className="left" item xs={6}>
                            <Button className="backButton" variant="outlined" onClick={history.goBack}>{t.BACK}</Button>
                        </Grid>

                        <Grid className="right" item xs={6}>
                            <Button className="showButon" onClick={handleSubmit}>
                                {savedSearchId ? t.SAVE_SEARCH : t.SHOW(filterCount)}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Recaptcha actionName="search"/>
        </Layout>
    );
};

export default FilterSearch;
