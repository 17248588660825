import {Box} from "@material-ui/core";
import {ChevronsLeft, ChevronsRight} from "react-feather";
import React from "react";
import {createAction} from "../../store/actions";
import {SET_IS_COLLAPSED_VIEW} from "../../store/reducers/context";
import {useSelector} from "react-redux";
import {REDUCER_CONTEXT} from "../../utils/constant";

const MapExpander = () => {
    const {isCollapsedView} = useSelector((state: any) => state[REDUCER_CONTEXT]);
    const setCollapsedView = createAction(SET_IS_COLLAPSED_VIEW);

    return (
        <Box className="mapExpander">
            <Box
                className="toggle"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => setCollapsedView(!isCollapsedView)}
            >
                {!isCollapsedView && <ChevronsLeft width={27} height={27}/>}
                {isCollapsedView && <ChevronsRight width={27} height={27}/>}
            </Box>
        </Box>
    );
}

export default MapExpander;
