import {Box, Divider, FormControl, Grid, MenuItem, Select} from '@material-ui/core';
import {Alert, Pagination} from '@material-ui/lab';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import './style.scss';
import {
    ADMIN_SECTIONS,
    REDUCER_CONTEXT,
    REDUCER_OFFER_ITEM,
    REDUCER_REAL_ESTATE,
    REDUCER_USER, RESULT_SORT_VALUES
} from '../../utils/constant';
import {useApiRequest} from '../../store/api';
import MyAccountItem from '../MyAccount/MyAccountItem';
import {
    CLEAR_REAL_ESTATE_PAGINATION,
    FETCH_MY_OFFER_ITEMS_OVERVIEW
} from '../../store/reducers/realEstate';
import {formatToRequestBody, isNotNilOrEmpty, onChangeStringHandler} from '../../utils/helpers';

import {ISearchResultContent} from '../../utils/types';
import t from '../../texts';
import {useApiDataSelector, usePrevious} from '../../hooks/hooks';
import Loading from '../Loading';
import {useSelector} from 'react-redux';
import {getFavoritesAdvert} from '../../utils/storage';
import {GET_SAVED_ADVERT} from '../../store/reducers/offerItem';
import MyWatchdog from '../MyAccount/MyWatchdog';
import Filter from '../MyAccount/Filter';
import {scrollTo, useScrollToTop} from '../Navigation/ScrollToTop';
import {find, propEq} from 'ramda';
import {createAction} from '../../store/actions';
import {CLEAR_MY_ADVERT_FILTER} from '../../store/reducers/context';
import UserInfo from "./UserInfo";

const MyAccount = ({activeSection}: { activeSection: string }) => {
    const fetchMyOfferItems = useApiRequest(REDUCER_REAL_ESTATE, 'offer-item/search/user/:userId', FETCH_MY_OFFER_ITEMS_OVERVIEW, {method: 'post'});
    const
        findItemsByIds = useApiRequest(REDUCER_OFFER_ITEM, 'offer-item/overview', GET_SAVED_ADVERT, {method: 'post'});

    const filter = useSelector((state: any) => state[REDUCER_CONTEXT].myAdvertFilter);
    const currentUser = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser);
    const {
        data: myOverviewItems = [],
        isLoading: isLoadingMyItems
    } = useSelector((state: any) => state[REDUCER_REAL_ESTATE].myOfferItems);
    const pagination = useSelector((state: any) => state[REDUCER_REAL_ESTATE].pagination);

    const clearFilter = createAction(CLEAR_MY_ADVERT_FILTER);
    const clearPagination = createAction(CLEAR_REAL_ESTATE_PAGINATION);

    const [myFavoritesIds, setMyFavoritesIds] = useState<string[]>([]);
    const [sortBy, setSortBy] = useState<any>(find(propEq('value', pagination?.sort), RESULT_SORT_VALUES) || RESULT_SORT_VALUES[0]);
    const prevSortBy = usePrevious(sortBy);
    const [pageNumber, setPageNumber] = useState<number>(pagination?.page || 0);

    const contentRef = useRef();

    useScrollToTop([pageNumber]);

    const {
        data: myFavorites,
        isLoading: isLoadingMyFavorites
    } = useSelector((state: any) => state[REDUCER_OFFER_ITEM].favorites);

    const fetchItems = useCallback((page = 0) => {
        if (isNotNilOrEmpty(currentUser?.extId) && !isLoadingMyItems) {
            const request = isNotNilOrEmpty(filter) ? formatToRequestBody(filter) : {};

            fetchMyOfferItems({
                params: {userId: currentUser?.extId},
                queryParams: {
                    page,
                    size: 10,
                    sort: sortBy?.value
                },
                body: request
            });
            setPageNumber(page);
        }
    }, [fetchMyOfferItems, currentUser, filter, sortBy, setPageNumber, isLoadingMyItems]);

    useEffect(() => {
        setMyFavoritesIds(getFavoritesAdvert());
    }, []);

    useEffect(() => {
        // @ts-ignore
        scrollTo(contentRef?.current, -80);
        clearPagination();
        clearFilter();
        if (activeSection === ADMIN_SECTIONS.MY_ADVERT) {
            fetchItems();
        }
    }, [activeSection]);

    useEffect(() => {
        if (isNotNilOrEmpty(prevSortBy)) {
            fetchItems();
        }
    }, [sortBy]);

    const onChangePageNumber = useCallback(async (event: any, pageNumber: number) => {
        setPageNumber(pageNumber - 1);
        fetchItems(pageNumber - 1);
    }, [setPageNumber, fetchItems]);

    useEffect(() => {
        if (myFavoritesIds?.length > 0 && myFavoritesIds?.length !== myFavorites?.length && !isLoadingMyFavorites) {
            findItemsByIds({body: {extIds: myFavoritesIds}});
        }
    }, [myFavoritesIds, isLoadingMyFavorites, myFavorites]);


    return (
        <Box data-component="MyAccount" {...{ref: contentRef} as any}>
            <Box className="container">
                {activeSection === ADMIN_SECTIONS.MY_ACCOUNT && (
                    <UserInfo/>
                )}

                {activeSection === ADMIN_SECTIONS.MY_ADVERT && (
                    <Box className="section">
                        <h2>{t.MY_ADVERT}</h2>
                        <Filter onFilter={fetchItems}/>
                        <Loading isLoading={isLoadingMyItems}/>

                        {!isLoadingMyItems && myOverviewItems?.length === 0
                            && (
                                <Alert className="alert" severity="info">
                                    Vášmu hľadaniu nevyhovujú žiadne Vami vytvorené inzeráty.
                                </Alert>
                            )}

                        {!isLoadingMyItems && myOverviewItems?.length > 0 && (
                            <>
                                <Divider style={{margin: '10px 0'}}/>
                                <Grid container justify="flex-end" style={{marginBottom: '10px'}}>
                                    <Grid item sm={4}>
                                        <FormControl variant="outlined" size="small">
                                            <Select
                                                className="left"
                                                value={sortBy}
                                                onChange={onChangeStringHandler(setSortBy)}
                                            >
                                                {RESULT_SORT_VALUES.map(
                                                    (item: any) => (
                                                        <MenuItem
                                                            key={item.order}
                                                            value={item}
                                                        >
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {myOverviewItems.map((item: ISearchResultContent, index: number) => (
                                    <MyAccountItem
                                        key={`${index}-${item.extId}`}
                                        overviewItem={item}
                                        fetchCallback={fetchItems}
                                        showActions
                                    />
                                ))}
                            </>
                        )}
                    </Box>
                )}

                {activeSection === ADMIN_SECTIONS.MY_FAVORITE && (
                    <Box className="section">
                        <h2>{t.FAVORITE_ADVERT}</h2>
                        <Loading isLoading={isLoadingMyFavorites}/>

                        {!isLoadingMyFavorites && myFavorites?.length === 0
                            && <Alert className="alert" severity="info">{t.NO_RESULT_MY_FAVORITES}</Alert>}

                        {!isLoadingMyFavorites && isNotNilOrEmpty(myFavorites) && myFavorites.map((item: ISearchResultContent, index: number) => (
                            <MyAccountItem
                                key={`${index}-${item.extId}`}
                                overviewItem={item}
                                showStatistics={false}
                            />
                        ))
                        }
                    </Box>
                )}
                {activeSection === ADMIN_SECTIONS.SEARCH_NOTIFICATION && (
                    <MyWatchdog/>
                )}
            </Box>
            {activeSection === ADMIN_SECTIONS.MY_ADVERT && !isLoadingMyItems && myOverviewItems?.length > 0 && (
                <Box className="pagination">
                    <Pagination
                        count={pagination?.totalPages || 1}
                        page={pageNumber + 1 || 1}
                        onChange={onChangePageNumber}
                    />
                </Box>
            )}
        </Box>
    );
};
export default MyAccount;
