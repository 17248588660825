import {Chip} from "@material-ui/core";
import React from "react";

export const renderBadges = (badges: any[]) => {
    if (badges) {
        return badges.map(badge => (
            <Chip
                key={badge.code}
                label={badge.value}
                size="small"
                variant="outlined"
            />
        ));
    }
    return null;
};
