import {Avatar, Box, Button, Grid, Paper, Typography} from '@material-ui/core';
import {formatAcronym, isNilOrEmpty, isNotNilOrEmpty} from '../../utils/helpers';
import {ERouteParameters} from '../../enumerators';
import React, {useEffect, useState} from 'react';
import {useApiRequest} from '../../store/api';
import {REDUCER_REALITY_COMPANY} from '../../utils/constant';
import {GET_COMPANY} from '../../store/reducers/realityCompany';
import {useSelector} from 'react-redux';
import {useParamName} from '../../hooks/hooks';
import t from "../../texts";

const CompanyDetail = () => {
    const companyId = useParamName(ERouteParameters.COMPANY_ID);

    const {
        company: {data: company}
    } = useSelector((state: any) => state[REDUCER_REALITY_COMPANY]);
    const [showCompanyEmail, setShowCompanyEmail] = useState(false);

    const getRealityCompany = useApiRequest(REDUCER_REALITY_COMPANY, 'reality-company/:realityCompanyId', GET_COMPANY, {method: 'get'});

    useEffect(() => {
        if (isNilOrEmpty(company) || companyId !== company.extId) {
            getRealityCompany({params: {realityCompanyId: companyId}});
        }
    }, [companyId]);

    return (
        <Box className="container">
            <Paper className="broker" elevation={0}>
                <Grid container spacing={1}>
                    <Grid className="avatar" item xs={12} sm={2}>
                        {!company?.companyLogo?.url && <Avatar alt="">{formatAcronym(company?.companyName)}</Avatar>}
                        {company?.companyLogo?.url && <Avatar src={company?.companyLogo?.url} alt="company-picture" />}
                    </Grid>
                    {isNotNilOrEmpty(company) && (
                        <Grid item xs={12} sm={5}>
                            <Grid container alignItems="flex-start" direction="column">
                            <Typography variant="body2" component="p">
                                {company?.companyName}
                            </Typography>
                            {showCompanyEmail ?
                                <Typography variant="body2" component="p">
                                    {company?.email}
                                </Typography>
                                : <Button color="primary" variant="text" style={{padding: 0}}
                                          onClick={() => setShowCompanyEmail(true)}>{t.SHOW_SELLER_EMAIL}</Button>
                            }
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Box>
    );
};

export default CompanyDetail;
