// @ts-nocheck
import React, {useEffect} from 'react';
import t from '../../texts';
import {GeoapifyContext, GeoapifyGeocoderAutocomplete} from '@geoapify/react-geocoder-autocomplete';
import {useSelector} from 'react-redux';
import {DISTRICT, DISTRICT_BY_REGION, REDUCER_ADVERT, REDUCER_CODEBOOK, REGION} from '../../utils/constant';
import {createAction, fetchCodebook, fetchCodebookParent} from '../../store/actions';
import {SET_ADDRESS} from '../../store/reducers/advert';
import {split} from 'ramda';
import {canBeSplit, isNilOrEmpty, isNotNilOrEmpty, removeDiacritics} from '../../utils/helpers';
import {ICodebookItem} from '../../utils/types';
import {getConfig} from '../../App';

const AddressAutocomplete = () => {
    const {addressAutocomplete} = useSelector((state: any) => state[REDUCER_ADVERT].address);
    const fetchRegions = fetchCodebook(REGION);
    const fetchDistricts = fetchCodebookParent(DISTRICT, DISTRICT_BY_REGION);
    const regionValues: ICodebookItem[] = useSelector((state: any) => state[REDUCER_CODEBOOK][REGION]);
    const districtValues = useSelector((state: any) => state[REDUCER_CODEBOOK][DISTRICT_BY_REGION]);
    const setAddress = createAction(SET_ADDRESS);
    const OKRES: string = 'OKRES ';

    const allCodebooksFetched = () => {
        return isNotNilOrEmpty(regionValues) && isNotNilOrEmpty(districtValues) && (Object.keys(districtValues).length === regionValues.length + 1);
    };

    useEffect(() => {
        if (isNotNilOrEmpty(regionValues)) {
            fetchRegions();
        }
    }, [fetchRegions]);

    useEffect(() => {
        if (isNotNilOrEmpty(regionValues) && (isNilOrEmpty(districtValues) || (Object.keys(districtValues).length !== regionValues.length + 1))) {
            regionValues.forEach((value: ICodebookItem) => {
                fetchDistricts({params: {qp1: value.code}});
            });
        }

    }, [regionValues]);

    const onPlaceSelect = (value: any) => {
        if (isNilOrEmpty(value)) return {};
        const {city, lat, lon, housenumber, street, state} = value.properties;
        let parsedCity = city;
        if (city.toUpperCase().indexOf(OKRES) !== -1) {
            parsedCity = city.slice(city.toUpperCase().indexOf(OKRES) + OKRES.length);
        }
        const filteredRegion = regionValues.filter(cbRegion => cbRegion.value.toUpperCase() === state.toUpperCase())[0];
        const districts: ICodebookItem[] = districtValues[filteredRegion.code];
        let filteredDistrict: ICodebookItem = districts.filter(cbDistrict => cbDistrict.value.toUpperCase() === city.toUpperCase())[0];
        if (!filteredDistrict) {
            const cityTokens: string[] = parsedCity.split(' ').map((part: string) => removeDiacritics(part.slice(0, 3).toUpperCase()));
            filteredDistrict = districts.filter(cbDistrict => {
                    const splitShortUpperCase = cbDistrict.shortcutCode?.split(' ').map((part: string) => removeDiacritics(part.slice(0, 3).toUpperCase()));
                    return splitShortUpperCase && cityTokens && splitShortUpperCase.filter(val => !cityTokens.includes(val)).length === 0;
                }
            )[0];
            if (!filteredDistrict) {
                const shortRegion = filteredRegion.shortcutCode?.slice(0, 4).toUpperCase();
                filteredDistrict = districts.filter(cbDistrict => {
                        const shortCutUpperCase = cbDistrict.shortcutCode?.toUpperCase();
                        return shortCutUpperCase && shortRegion && shortCutUpperCase.indexOf(shortRegion) >= 0;
                    }
                )[0];
            }
        }
        // TODO filter for CB value and set to District and Region dropdown
        setAddress({
            autocomplete: true,
            longitude: lon,
            latitude: lat,
            city: parsedCity,
            street,
            registrationNumber: canBeSplit(housenumber) ? split('/', housenumber)[0] : null,
            buildingNumber: canBeSplit(housenumber) ? split('/', housenumber)[1] : housenumber,
            region: filteredRegion,
            district: filteredDistrict
        });
        return {};
    };

    const onSuggectionChange = () => {
        return {};
    };


    return (
        allCodebooksFetched() ? (
            <GeoapifyContext apiKey={getConfig().geoApifyApiKey}>
                <GeoapifyGeocoderAutocomplete
                    placeholder={t.SEARCH_ADDRESS}
                    value={addressAutocomplete}
                    lang="sk"
                    limit={10}
                    placeSelect={onPlaceSelect}
                    suggestionsChange={onSuggectionChange}
                    filterByCountryCode={['sk']}
                />
            </GeoapifyContext>
        ) : null);
};

export default AddressAutocomplete;
