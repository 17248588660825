import t from '../texts';
import {ImageType, ERoutePaths} from '../enumerators';
import {MenuItemType} from './types';

export const GA_ID = 'G-LDXJSPPMLB';
export const RECAPTCHA_HEADER = 'X-Recaptcha-Response';
export const GA_DISABLE = `ga-disable-${GA_ID}`;

// local storage keys
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const AUTH_TOKEN_EXPIRE = 'AUTH_TOKEN_EXPIRE';
export const SEARCH_DATA_KEY = 'searchHistory';
export const LAST_SEARCH_DATA_KEY = 'lastSearch';
export const USER_FAVORITE = 'USER_FAVORITE';
export const USER_COOKIES_FUNCTIONAL = 'USER_COOKIES_FUNCTIONAL';
export const USER_COOKIES_MARKETING = 'USER_COOKIES_MARKETING';
export const USER_COOKIES_STATISTICS = 'USER_COOKIES_STATISTICS';

export const REDUCER_AUTH = 'auth';
export const REDUCER_CONTEXT = 'context';
export const REDUCER_CODEBOOK = 'codebook';
export const REDUCER_SEARCH = 'search';
export const REDUCER_ADVERT = 'advert';
export const REDUCER_OFFER_ITEM = 'offerItem';
export const REDUCER_REALITY_COMPANY = 'realityCompany';
export const REDUCER_IMAGES = 'images';
export const REDUCER_USER = 'user';
export const REDUCER_REAL_ESTATE = 'realEstate';

export const REAL_ESTATE_TYPE = 'REAL_ESTATE_TYPE';
export const OFFER_TYPE = 'OFFER_TYPE';
export const REAL_ESTATE_DISPOSITION = 'REAL_ESTATE_DISPOSITION';
export const REAL_ESTATE_STATE = 'REAL_ESTATE_STATE';
export const REGION = 'REGION';
export const DISTRICT = 'DISTRICT';
export const DISTRICT_BY_REGION = 'DISTRICT_BY_REGION';
export const HOUSE_TYPE = 'HOUSE_TYPE';
export const LOT_TYPE = 'LOT_TYPE';
export const REAL_ESTATE_OWNERSHIP = 'REAL_ESTATE_OWNERSHIP';
export const COMMERCIAL_TYPE = 'COMMERCIAL_TYPE';
export const BUILDING_MATERIAL_TYPE = 'BUILDING_MATERIAL_TYPE';
export const REAL_ESTATE_FEATURE_FLAG = 'REAL_ESTATE_FEATURE_FLAG';

export const META_APP_NAME = '• Skreality.sk';
export const META_TITLE_FULL = 'Skreality.sk • Slovenský trh realít';
export const META_DESCRIPTION_FULL = 'Najväčšia ponuka nehnuteľností a realít na Slovensku. Pozemky, domy, byty, garáže, developerské projekty, novostavby a mnoho ďalšieho. Či už ako nájom, kúpa alebo aukcia - práve u nás na skreality.sk nájdete, čo hľadáte';

export const REAL_ESTATE_TYPES = {
    APARTMENT: 'APARTMENT',
    LOT: 'LOT',
    HOUSE: 'HOUSE',
    COMMERCIAL: 'COMMERCIAL',
    OTHER: 'OTHER'
};

export const REGIONS_SLOVAKIA = {
    name: 'Slovakia',
    areas: [{
        code: 'BA',
        name: 'Bratislavský kraj',
        shape: 'poly',
        coords: '15, 128, 26, 125, 36, 129, 49, 119, 52, 122, 52, 122, 52, 122, 44, 131, 48, 133, 43, 138, 49, 144, 53, 151, 56, 154, 54, 161, 59, 164, 55, 169, 58, 172, 58, 175, 52, 180, 47, 176, 47, 182, 40, 181, 41, 187, 31, 197, 21, 191, 23, 181, 14, 175, 12, 159, 5, 148, 8, 137, 14, 129'

    }, {
        code: 'TA',
        name: 'Trnavský kraj',
        shape: 'poly',
        coords: '20, 125, 30, 123, 37, 127, 50, 117, 55, 122, 48, 128, 51, 132, 47, 138, 57, 152, 58, 160, 63, 162, 59, 168, 62, 175, 55, 181, 51, 179, 50, 183, 44, 182, 45, 187, 38, 195, 43, 198, 52, 206, 54, 215, 60, 219, 62, 224, 67, 224, 73, 230, 78, 222, 88, 214, 90, 195, 81, 182, 91, 176, 91, 160, 102, 144, 94, 134, 94, 131, 102, 126, 93, 120, 86, 120, 83, 115, 67, 118, 63, 111, 65, 104, 58, 95, 46, 86, 36, 90, 26, 101, 21, 110, 18, 120'

    }, {
        code: 'TN',
        name: 'Trenčiansky kraj',
        shape: 'poly',
        coords: '62, 97, 73, 99, 77, 93, 84, 93, 93, 86, 99, 86, 105, 74, 111, 75, 117, 72, 122, 64, 121, 61, 125, 52, 127, 44, 131, 41, 138, 40, 144, 40, 151, 51, 153, 56, 159, 61, 158, 69, 160, 78, 154, 81, 149, 85, 150, 89, 156, 88, 159, 85, 168, 88, 167, 99, 172, 105, 173, 111, 178, 114, 169, 122, 166, 128, 161, 126, 154, 134, 148, 134, 145, 136, 135, 143, 131, 144, 129, 137, 124, 133, 126, 128, 119, 121, 117, 117, 108, 112, 106, 113, 107, 121, 104, 124, 91, 119, 85, 119, 82, 114, 75, 115, 66, 118, 63, 111, 64, 105, 61, 97'

    }, {
        code: 'NR',
        name: 'Nitriansky kraj',
        shape: 'poly',
        coords: '105, 127, 110, 121, 107, 113, 116, 118, 125, 128, 123, 134, 130, 138, 131, 143, 136, 144, 144, 138, 145, 142, 149, 144, 149, 153, 152, 160, 147, 166, 148, 168, 153, 164, 159, 164, 166, 157, 172, 167, 171, 174, 168, 186, 176, 191, 183, 187, 190, 187, 190, 194, 186, 201, 170, 205, 168, 212, 162, 211, 164, 217, 162, 223, 167, 228, 161, 232, 153, 238, 143, 237, 140, 239, 135, 237, 123, 239, 110, 236, 98, 234, 93, 234, 83, 236, 72, 232, 74, 225, 76, 226, 80, 220, 87, 214, 89, 195, 81, 182, 90, 177, 90, 171, 90, 163, 102, 145, 94, 134, 98, 129, 104, 127'
    }, {
        code: 'ZA',
        name: 'Žilinský kraj',
        shape: 'poly',
        coords: '145, 40, 150, 30, 161, 22, 162, 17, 164, 17, 168, 18, 179, 19, 183, 17, 190, 18, 195, 17, 195, 26, 196, 28, 194, 30, 197, 32, 201, 33, 204, 31, 214, 33, 215, 28, 217, 28, 218, 23, 222, 18, 228, 18, 233, 11, 237, 9, 243, 13, 243, 23, 247, 29, 252, 29, 251, 35, 256, 39, 263, 36, 265, 41, 262, 50, 265, 52, 259, 60, 263, 64, 269, 64, 270, 72, 274, 72, 277, 76, 281, 76, 280, 80, 279, 87, 274, 88, 281, 99, 276, 100, 264, 99, 256, 99, 249, 96, 241, 93, 224, 95, 219, 101, 213, 102, 212, 97, 196, 98, 191, 102, 191, 107, 192, 113, 185, 116, 181, 111, 176, 111, 173, 104, 169, 98, 170, 87, 160, 84, 154, 87, 151, 85, 161, 79, 161, 71, 161, 62, 155, 56, 145, 40'
    }, {
        code: 'BB',
        name: 'Banskobystrický kraj',
        shape: 'poly',
        coords: '148, 137, 156, 136, 162, 128, 167, 129, 170, 124, 181, 113, 186, 117, 194, 113, 192, 102, 199, 99, 211, 98, 214, 103, 220, 101, 226, 97, 235, 95, 243, 95, 254, 100, 270, 101, 278, 102, 290, 105, 293, 103, 298, 107, 295, 112, 292, 116, 290, 123, 294, 128, 297, 129, 300, 140, 304, 143, 299, 154, 301, 159, 306, 160, 314, 160, 308, 166, 307, 172, 301, 178, 297, 184, 292, 182, 289, 186, 284, 184, 283, 189, 276, 195, 265, 198, 260, 193, 254, 194, 252, 187, 246, 188, 242, 183, 234, 187, 229, 190, 226, 201, 210, 201, 204, 204, 187, 201, 190, 194, 192, 186, 183, 186, 177, 189, 170, 185, 174, 167, 167, 155, 161, 157, 158, 162, 153, 162, 152, 155, 150, 151, 148, 142, 146, 137'

    }, {
        code: 'PO',
        name: 'Prešovský kraj',
        shape: 'poly',
        coords: '280, 85, 283, 75, 276, 72, 272, 70, 271, 65, 275, 59, 279, 58, 285, 67, 289, 64, 293, 51, 303, 47, 308, 48, 310, 38, 315, 42, 319, 39, 331, 43, 333, 39, 338, 42, 343, 40, 349, 49, 356, 54, 363, 55, 373, 47, 370, 42, 377, 40, 382, 45, 388, 37, 402, 44, 407, 41, 416, 40, 421, 45, 428, 51, 435, 48, 441, 53, 448, 60, 450, 70, 463, 76, 466, 76, 468, 79, 473, 82, 478, 80, 482, 86, 493, 88, 491, 99, 480, 107, 481, 111, 477, 115, 467, 113, 466, 107, 462, 107, 456, 110, 455, 115, 445, 118, 439, 113, 428, 109, 425, 112, 428, 122, 419, 125, 406, 120, 402, 112, 392, 108, 391, 113, 384, 118, 381, 113, 381, 109, 374, 107, 365, 105, 360, 99, 349, 96, 344, 91, 342, 96, 337, 93, 331, 99, 329, 94, 324, 93, 319, 90, 318, 94, 315, 91, 303, 97, 305, 101, 299, 106, 293, 102, 281, 101, 282, 97, 277, 89, 279, 85'

    }, {
        code: 'KE',
        name: 'Košický kraj',
        shape: 'poly',
        coords: '299, 108, 307, 101, 304, 97, 315, 93, 318, 96, 320, 93, 328, 96, 331, 101, 338, 96, 343, 98, 345, 94, 356, 100, 363, 105, 379, 111, 382, 119, 393, 115, 393, 111, 400, 112, 405, 122, 419, 127, 430, 122, 427, 112, 433, 111, 444, 119, 456, 117, 458, 111, 466, 107, 466, 115, 476, 117, 477, 125, 473, 130, 473, 139, 467, 141, 464, 146, 459, 150, 456, 156, 456, 176, 440, 177, 429, 178, 428, 182, 418, 179, 411, 165, 411, 158, 405, 158, 402, 152, 397, 147, 387, 151, 386, 157, 379, 153, 372, 156, 368, 159, 365, 155, 357, 155, 353, 149, 349, 150, 344, 146, 322, 150, 317, 152, 315, 159, 300, 156, 306, 142, 301, 139, 300, 130, 292, 123, 295, 114, 300, 108'

    }]
};

export const RESULT_SORT_VALUES = [
    {
        order: 1,
        label: t.ORDER_NEWEST, // Nejnovější
        value: 'publishedAt,DESC'
    },
    {
        order: 2,
        label: t.ORDER_OLDEST, // Nejstarší
        value: 'publishedAt,ASC'
    }, {
        order: 3,
        label: t.ORDER_CHEAPEST, // Nejlevnější price
        value: 'price,ASC'
    }, {
        order: 4,
        label: t.ORDER_EXPENSIVE, // Najdrahsie price
        value: 'price,DESC'
    }
];

export const NEW_ADVERT_STEPS = [
    {
        label: t.STEP_INFO,
        value: 0
    },
    {
        label: t.STEP_PROPERTIES,
        value: 1
    },
    {
        label: t.STEP_PHOTOS,
        value: 2
    },
    {
        label: t.STEP_ADDRESS,
        value: 3
    },
    {
        label: t.STEP_OVERVIEW,
        value: 4
    }
];

export const CURRENCIES = ['Kč', 'EU'];

export const STATUS_MAP = {
    EDIT: 'Rozpracovaný',
    PUBLISHED: 'Publikovaný',
    UNACTIVE: 'Neaktvíny'
};

export const ALERT_SEVERITY = {
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    SUCCESS: 'success'
};

export const NEARBY_PLACES_MAP = {
    BUS: 'Autobus',
    POST: 'Pošta',
    DOCTOR: 'Lekár',
    SCHOOL: 'Škola',
    TRAM: 'Električka',
    PHARMACY: 'Lekáreň',
    KINDERGARTEN: 'Škôlka',
    PUB: 'Gastronómia',
    SUPERMARKET: 'Potraviny',
    SPORT: 'Športovisko'
};

export const PLACEHOLDER_PROPERTY_IMAGE = {
    url: process.env.PUBLIC_URL + '/images/house-cover.jpg',
    type: ImageType.PLACEHOLDER
};

export const ADMIN_SECTIONS = {
    MY_ACCOUNT: 'MY_ACCOUNT',
    MY_ADVERT: 'MY_ADVERT',
    MY_FAVORITE: 'MY_FAVORITE',
    SEARCH_NOTIFICATION: 'SEARCH_NOTIFICATION'
};


export const AGENCY_SECTIONS = {
    AGENCIES_ADMINISTRATION: 'AGENCIES_ADMINISTRATION',
    AGENT_LIST: 'AGENT_LIST',
    AGENCIES_ADVERTS: 'AGENCIES_ADVERTS',
    ADVERT_IMPORT: 'ADVERT_IMPORT',
};

export const IMPORT_TYPES = {ZOZNAM_REALIT: 'ZOZNAM_REALIT', REALSOFT: 'REALSOFT'};

export const ImportTypeCodebook: any[] = [
    {value: t.ZOZNAM_REALIT, code: IMPORT_TYPES.ZOZNAM_REALIT},
    {value: t.REALSOFT, code: IMPORT_TYPES.REALSOFT},
];

export const IMPORT_STATUS = {
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED'
};

export const initialPayload = (dataType = null) => ({
    data: dataType,
    pagination: {},
    error: null,
    isLoading: false
});

export const AdministrationMenuItems = [
    {
        label: t.PERSONAL_DATA_PROTECTION,
        link: ERoutePaths.GDPR
    },
    {
        label: t.DOCUMENTS,
        link: ERoutePaths.DOCUMENTS
    },
    {
        label: t.BUSINESS_CONDITIONS,
        link: ERoutePaths.BUSINESS_CONDITIONS
    },
    {
        label: t.CONATACTS,
        link: ERoutePaths.CONTACTS
    }
] as MenuItemType[];
