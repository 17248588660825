import { Box } from '@material-ui/core';
import cx from 'classnames';
import React from 'react';
import './style.scss';

export default () => {
    return (
        <Box data-component="HowToAdvertise">
            <Box className="container">
                <Box className="section">
                    <h2>Ako inzerovať</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sint ista Graecorum; Quae
                        diligentissime contra Aristonem dicuntur a Chryippo. Sed ne, dum huic obsequor, vobis molestus
                        sim.
                    </p>
                    <h3>Quicquid porro animo cernimus</h3>
                    <p>
                        Ratio quidem vestra sic cogit. Quando enim Socrates, qui parens philosophiae iure dici potest,
                        quicquam tale fecit? Duae sunt enim res quoque, ne tu verba solum putes. Serpere anguiculos,
                        nare anaticulas, evolare merulas, cornibus uti videmus boves, nepas aculeis. Quae animi affectio
                        suum cuique tribuens atque hanc, quam dico. Tu enim ista lenius, hic Stoicorum more nos vexat.
                        Conferam tecum, quam cuique verso rem subicias.
                    </p>
                    <h3>Quod quidem nobis non saepe contingit</h3>
                    <p>
                        Quid, quod res alia tota est? Duo Reges: constructio interrete. Murenam te accusante defenderem.
                        An ea, quae per vinitorem antea consequebatur, per se ipsa curabit? Vide, quantum, inquam,
                        fallare, Torquate. Quis, quaeso, illum negat et bonum virum et comem et humanum fuisse? Si enim,
                        ut mihi quidem videtur, non explet bona naturae voluptas, iure praetermissa est.
                    </p>
                </Box>
            </Box>
        </Box>
    );
};
