import {useApiRequest} from '../store/api';
import {REDUCER_CODEBOOK} from '../utils/constant';
import {GET_ALL_CODEBOOKS, GET_CODEBOOK} from '../store/reducers/codebook';
import {useSelector} from 'react-redux';
import {useCallback, useEffect} from 'react';
import {singletonHook} from "react-singleton-hook";

const useCodebooks = singletonHook({
    codebooks: []
}, () => {
    const codebooks = useSelector((state: any) => state[REDUCER_CODEBOOK]);
    const isLoaded = useSelector((state: any) => state[REDUCER_CODEBOOK]).isLoaded;

    const fetchCodebook = useApiRequest(REDUCER_CODEBOOK, 'codebook/:codebookName', GET_CODEBOOK, {method: 'get'});
    const fetchAllCodebooks = useApiRequest(REDUCER_CODEBOOK, 'codebook/all', GET_ALL_CODEBOOKS, {method: 'get'});

    const fetchCodebooks = useCallback(async () => {
        try {
            if (!isLoaded) {
                fetchAllCodebooks();
            }
        } catch (e) {
            console.error(e);
        }
    }, [fetchAllCodebooks, isLoaded]);

    useEffect(() => {
        fetchCodebooks();
    }, []);

    return {
        codebooks,
    };
});

export default useCodebooks;
