// @ts-nocheck
import {createAction} from '../../store/actions';
import {SET_ACCESS_TOKEN, SET_AUTHENTICATE} from '../../store/reducers/auth';
import {
    ALERT_SEVERITY,
    AUTH_TOKEN,
    AUTH_TOKEN_EXPIRE,
    REDUCER_AUTH,
    REDUCER_REALITY_COMPANY,
    REDUCER_USER
} from '../../utils/constant';
import {useCallback, useEffect} from 'react';
import {isNilOrEmpty, isNotNilOrEmpty} from '../../utils/helpers';
import {useHistory, useLocation} from 'react-router-dom';
import {ERouteParameters, ERoutePaths} from '../../enumerators';
import {any, equals} from 'ramda';
import {useApiDataSelector} from '../../hooks/hooks';
import {removeStoredAccessToken} from '../../utils/storage';
import {useSelector} from 'react-redux';
import {GET_CURRENT_USER, GET_USER_PRIVILEGES} from '../../store/reducers/user';
import {useApiRequest} from '../../store/api';
import {SET_ALERT} from '../../store/reducers/context';
import t from '../../texts';
import {GET_REALITY_COMPANY_USER} from '../../store/reducers/realityCompany';
import useCodebooks from "../../hooks/useCodebooks";

const moment = require('moment');

const Auth = ({children}: { children: any }) => {
    const history = useHistory();
    const location = useLocation();

    const setAccessToken = createAction(SET_ACCESS_TOKEN);
    const setIsAuthenticate = createAction(SET_AUTHENTICATE);
    const accessToken = useApiDataSelector((state: any) => state[REDUCER_AUTH].accessToken);
    const {data: currentUser, isLoading} = useSelector(state => state[REDUCER_USER].currentUser);
    const {data: roles} = useSelector(state => state[REDUCER_USER].currentUserPriviledges);

    const getCurrentUser = useApiRequest(REDUCER_USER, 'user/me', GET_CURRENT_USER, {method: 'get'});
    const getPrivileges = useApiRequest(REDUCER_USER, 'user/me/privileges', GET_USER_PRIVILEGES, {method: 'get'});
    const getRealityCompanyForUser = useApiRequest(REDUCER_REALITY_COMPANY, 'reality-company', GET_REALITY_COMPANY_USER, {method: 'get'});
    const setAlert = createAction(SET_ALERT);
    const {codebooks} = useCodebooks();

    const getUser = useCallback(async () => {
        try {
            await getCurrentUser();
            await getPrivileges();
            await getRealityCompanyForUser();
        } catch (e) {
            console.error(e);
        }
    }, [getCurrentUser, getPrivileges, getRealityCompanyForUser]);

    const redirectToState = () => {
        // @ts-ignore
        const originalPath = isNotNilOrEmpty(location?.state) && location.state[ERouteParameters.ORIGINAL_PATH];
        const stateUri = new URLSearchParams(originalPath?.search)?.get('state');

        if (stateUri) {
            const decodedState = atob(stateUri);
            history.push(decodedState);
        } else if (originalPath) {
            history.push(originalPath);
        } else {
            history.push(ERoutePaths.ACCOUNT);
        }
    };

    useEffect(() => {
        const today = moment();
        if (isNilOrEmpty(accessToken)) {
            const storedAccessToken = localStorage.getItem(AUTH_TOKEN);
            const storedAccessTokenExpire = localStorage.getItem(AUTH_TOKEN_EXPIRE);

            if (isNotNilOrEmpty(storedAccessToken) && isNotNilOrEmpty(storedAccessTokenExpire)) {
                if (today.diff(storedAccessTokenExpire, 'hour') < 24) {
                    setAccessToken(storedAccessToken);
                    setIsAuthenticate(true);
                } else {
                    removeStoredAccessToken();
                    history.push(ERoutePaths.LOGIN);
                }
            } else {
                setIsAuthenticate(false);
            }
        }
    }, []);

    useEffect(() => {
        if (isNotNilOrEmpty(accessToken) && !isLoading) {
            getUser();
        }
    }, [accessToken]);

    useEffect(() => {
        if (isNotNilOrEmpty(accessToken) && isNotNilOrEmpty(currentUser) && isNotNilOrEmpty(roles)) {
            setIsAuthenticate(isNotNilOrEmpty(currentUser?.extId));
            if (any(equals(location.pathname), [ERoutePaths.AUTH0, ERoutePaths.LOGIN])) {
                redirectToState();
                setAlert({severity: ALERT_SEVERITY.SUCCESS, message: t.AUTH_SUCCESS});
            }
        }
    }, [currentUser, roles]);

    return codebooks.isLoaded ? children : null;
};
export default Auth;
