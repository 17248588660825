import { convertNoAgencyUsers, handleAction } from '../../utils/helpers';
import { initialPayload } from '../../utils/constant';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';

export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL';
export const RESEND_VERIFICATION_EMAIL_SUCCESS = `${RESEND_VERIFICATION_EMAIL}_SUCCESS`;
export const GET_CURRENT_USER_FAILED = `${GET_CURRENT_USER}_FAILED`;

export const GET_USER_PRIVILEGES = 'GET_USER_PRIVILEGES';

export const UPLOAD_USER_PHOTO = 'UPLOAD_USER_PHOTO';
export const UPLOAD_USER_PHOTO_SUCCESS = `${UPLOAD_USER_PHOTO}_SUCCESS`;
export const UPLOAD_USER_PHOTO_FAILED = `${UPLOAD_USER_PHOTO}_FAILED`;

export const EMAIL_TO_SELLER = 'EMAIL_TO_SELLER';
const EMAIL_TO_SELLER_REQUEST = `${EMAIL_TO_SELLER}_REQUEST`;
export const EMAIL_TO_SELLER_SUCCESS = `${EMAIL_TO_SELLER}_SUCCESS`;
const EMAIL_TO_SELLER_FAILED = `${EMAIL_TO_SELLER}_FAILED`;

export const RESET_EMAIL_FORM = 'RESET_EMAIL_FORM';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_REQUEST = `${UPDATE_USER}_REQUEST`;
export const UPDATE_USER_SUCCESS = `${UPDATE_USER}_SUCCESS`;
export const UPDATE_USER_FAILED = `${UPDATE_USER}_FAILED`;

export const GET_NO_AGENCY_USERS = 'GET_NO_AGENCY_USERS';
export const USER_CHIP_CLICKED = 'USER_CHIP_CLICKED';

export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL_REQUEST = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS = `${CONFIRM_EMAIL}_SUCCESS`;
export const CONFIRM_EMAIL_FAILED = `${CONFIRM_EMAIL}_FAILED`;

export const REMOVE_USER_FROM_COMPANY = 'REMOVE_USER_FROM_COMPANY';

export const initialState = {
    currentUser: initialPayload(null),
    emailToSeller: initialPayload(false),
    usersWithoutAgency: initialPayload([]),
    confirmEmail: initialPayload(false),
    currentUserPriviledges: initialPayload(null),
    resendVerification: initialPayload(null)
};

export function reducer(state = initialState, action) {

    const handlers = {
        [SET_CURRENT_USER]: {
            ...state,
            currentUser: action.payload
        },
        [GET_CURRENT_USER]: {
            ...state,
            currentUser: action.payload
        },
        [UPLOAD_USER_PHOTO]: {
            ...state,
            currentUser: action.payload
        },
        [UPLOAD_USER_PHOTO_SUCCESS]: {
            ...state,
            currentUser: action.payload
        },
        [GET_USER_PRIVILEGES]: {
            ...state,
            currentUserPriviledges: action.payload
        },
        [UPDATE_USER_REQUEST]: {
            ...state,
            currentUser: {
                ...action.payload,
                data: state.currentUser.data
            }
        },
        [UPDATE_USER_FAILED]: {
            ...state,
            currentUser: {
                ...action.payload,
                data: state.currentUser.data
            }
        },
        [UPDATE_USER_SUCCESS]: {
            ...state,
            currentUser: action.payload
        },
        [RESEND_VERIFICATION_EMAIL]: {
            ...state,
            resendVerification: action.payload
        },
        [EMAIL_TO_SELLER_REQUEST]: {
            ...state,
            emailToSeller: {
                ...action.payload,
                data: false
            }
        },
        [EMAIL_TO_SELLER_SUCCESS]: {
            ...state,
            emailToSeller: {
                ...action.payload,
                data: true
            }
        },
        [EMAIL_TO_SELLER_FAILED]: {
            ...state,
            emailToSeller: {
                ...action.payload,
                data: false
            }
        },
        [GET_NO_AGENCY_USERS]: {
            ...state,
            usersWithoutAgency: {
                ...action.payload,
                data: convertNoAgencyUsers(action.payload, action.type)
            }
        },
        [USER_CHIP_CLICKED]: {
            ...state,
            usersWithoutAgency: {
                ...state.usersWithoutAgency,
                data: [...state.usersWithoutAgency?.data?.map(user => (user.user.extId === action.payload?.user?.extId && !user.selected) ? {
                    ...user,
                    selected: true
                } : {
                    ...user,
                    selected: false
                })]
            }
        },
        [CONFIRM_EMAIL_REQUEST]: {
            ...state,
            confirmEmail: {
                ...action.payload,
                data: false
            }
        },
        [CONFIRM_EMAIL_SUCCESS]: {
            ...state,
            confirmEmail: {
                ...action.payload,
                data: true
            }
        },
        [CONFIRM_EMAIL_FAILED]: {
            ...state,
            confirmEmail: {
                ...action.payload,
                data: false
            }
        }
    };

    return handleAction(action.type, handlers) || state;
}
