import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, FormControl, Grid, MenuItem, Select, Tooltip} from '@material-ui/core';
import {Alert, Pagination} from '@material-ui/lab';
import cx from 'classnames';
import t from '../../texts';
import {Search as SearchIcon, Bell} from 'react-feather';
import './style.scss';
import {useSelector} from 'react-redux';
import {IPagination, ISearchResultContent} from '../../utils/types';
import {
    isNilOrEmpty,
    isNotNilOrEmpty,
    onChangeNumberHandler,
    onChangeStringHandler
} from '../../utils/helpers';
import {
    ALERT_SEVERITY, META_APP_NAME,
    REDUCER_AUTH,
    REDUCER_CONTEXT,
    REDUCER_SEARCH,
    RESULT_SORT_VALUES
} from '../../utils/constant';
import ResultItem from './ResultItem';
import {ERoutePaths} from '../../enumerators';
import {Link, useHistory} from 'react-router-dom';
import {createAction} from '../../store/actions';
import {SET_ALERT} from '../../store/reducers/context';
import Footer from '../Footer';
import Loading from '../Loading';
import {useResultMode} from '../../hooks/hooks';
import SellerDetail from '../ResultsList/SellerDetail';
import useSavedSearch from '../../hooks/useSavedSearch';
import CompanyDetail from '../ResultsList/CompanyDetail';
import {useScrollToTop} from '../Navigation/ScrollToTop';
import {find, propEq} from 'ramda';
import {useFilterBuilder} from "../../hooks/useFilterBuilder";
import {Helmet} from 'react-helmet';
import qs from 'qs';
import useUrlPageNumber from "../../hooks/useUrlPageNumber";
import MapExpander from "../Map/MapExpander";

interface IProps {
    result: any[],
    isLoading: boolean,
    linkToDetail: (offerItem: ISearchResultContent) => string,
    pagination: IPagination,
    setPagination: (pagination: any) => {},
}

const ResultList = ({result, isLoading, pagination, setPagination, linkToDetail}: IProps) => {
    const {push, location} = useHistory();
    const urlPageNumber = useUrlPageNumber();

    const {isSavedSearch, deleteSavedSearch, saveSearch} = useSavedSearch();

    const {userSearchTextFromFilter} = useFilterBuilder();
    const {isSeller, isCompany, isSearch} = useResultMode();

    const {isCollapsedView} = useSelector((state: any) => state[REDUCER_CONTEXT]);
    // @ts-ignore
    const isAuthenticated = useSelector(state => state[REDUCER_AUTH].isAuthenticated);
    const filter = useSelector((state: any) => state[REDUCER_SEARCH].filter);

    const [pageNumber, setPageNumber] = useState<number>(pagination?.page);
    const [sortBy, setSortBy] = useState<any>(find(propEq('value', pagination?.sort), RESULT_SORT_VALUES) || RESULT_SORT_VALUES[0]);
    const [pageSize, setPageSize] = useState<number>(pagination?.size);

    const setAlert = createAction(SET_ALERT);
    const [userSearchText, setUserSearchText] = useState('');

    useScrollToTop([pageNumber]);

    useEffect(() => {
        if (urlPageNumber) {
            setPageNumber(urlPageNumber);
        }
        const pagination = {
            page: pageNumber || 0,
            size: pageSize || 20,
            sort: sortBy?.value
        };
        setPagination(pagination);
    }, [pageSize, pageNumber, sortBy]);

    useEffect(() => {
        if (filter && filter.realEstateType) {
            setUserSearchText(userSearchTextFromFilter(filter));
        }
    }, [filter])

    const onSaveSearches = useCallback(() => {
        if (isAuthenticated) {
            if (!isSavedSearch) {
                saveSearch(filter);
            } else {
                deleteSavedSearch(filter);
            }
        } else {
            setAlert({severity: ALERT_SEVERITY.WARNING, message: t.IS_NOT_AUTHENTICATE});
        }
    }, [filter, deleteSavedSearch, isSavedSearch, setAlert, isAuthenticated]);

    const onPageChanged = (event: any, pageNumber: number) => {
        setPageNumber(pageNumber - 1);
        const obj = qs.parse(location.search, {ignoreQueryPrefix: true});
        obj.page = pageNumber.toString();
        location.search = qs.stringify(obj, {encode: false})
        push(location);
    };

    return (
        <Box data-component="ResultsList" p="0.5rem">
            <Loading isLoading={isLoading}/>
            <MapExpander/>
            <Box className={cx('container', {collapsed: isCollapsedView})}>
                <Grid container>
                    <Box className="searchHeadline pl">
                        <Helmet>
                            <title>{userSearchText + META_APP_NAME}</title>
                            <meta name="description" content={userSearchText}/>
                            <meta property="og:title" content={userSearchText + META_APP_NAME}/>
                            <meta property="og:description"
                                  content={userSearchText}/>
                        </Helmet>
                        {isSearch && <h2>{userSearchTextFromFilter(filter)}</h2>}
                    </Box>
                </Grid>
                <Grid container>
                    <Grid item sm={isCollapsedView ? 6 : 4}>
                        <Box className="searchButton pl">
                            {isSearch && (
                                <Link to={`${ERoutePaths.SEARCH + '/' + filter?.realEstateType?.urlFriendlyValue}`}>
                                    <Button
                                        startIcon={<SearchIcon width={24} height={24}/>}>{t.EDIT_QUERY}</Button>
                                </Link>
                            )}
                            {isCompany && (
                                <Link to={ERoutePaths.ESTATE_AGENCY_LIST}>
                                    <Button className="textButton">{t.AGENCIES_LIST}</Button>
                                </Link>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                {isSeller && <SellerDetail/>}
                {isCompany && <CompanyDetail/>}
                <Grid className="pl" container alignItems="center">
                    <Grid id="h2" className="left" item sm={5}>
                        <h2>
                            {isSeller && t.SELLER_ADVERTS}
                            {isCompany && t.AGENCIES_ADVERTS}
                            {isSearch && t.SEARCH_RESULT}
                            {isSearch && (
                                <span>
                                <Tooltip title={t.SAVE_QUERY} placement="top" onClick={onSaveSearches}>
                                <Bell className={cx('bell', isSavedSearch ? 'active' : '')}/>
                                </Tooltip>
                                </span>
                            )}
                        </h2>
                    </Grid>

                    <Grid id="selectBox" item sm={7}>
                        <Grid container alignItems="center">
                            <Grid className="selectBoxHeading" item sm={6}>Zobraziť inzerátov</Grid>

                            <Grid id="offersNumber" item sm={2}>
                                <FormControl variant="outlined" size="small">
                                    <Select
                                        className="left"
                                        value={pageSize}
                                        onChange={onChangeNumberHandler(setPageSize)}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item sm={4}>
                                <FormControl variant="outlined" size="small">
                                    <Select
                                        className="left"
                                        defaultValue={RESULT_SORT_VALUES[0]}
                                        value={sortBy}
                                        onChange={onChangeStringHandler(setSortBy)}
                                    >
                                        {RESULT_SORT_VALUES.map(
                                            (item: any) => (
                                                <MenuItem
                                                    key={item.order}
                                                    value={item}
                                                >
                                                    {item.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {result?.map((item: ISearchResultContent) => (
                    <ResultItem key={item.extId} item={item} linkToDetail={linkToDetail} pagination={pagination}/>
                ))}
                {isNilOrEmpty(result) && !isLoading
                    && <Alert className="alert" severity="warning">{t.EMPTY_SEARCH}</Alert>}

            </Box>

            {isNotNilOrEmpty(result) && (
                <Box className="pagination">
                    <Pagination
                        siblingCount={0}
                        showFirstButton
                        showLastButton
                        count={pagination?.totalPages || 1}
                        page={pageNumber + 1 || 1}
                        onChange={onPageChanged}
                    />
                </Box>
            )}
            <Footer/>
        </Box>
    );
};
export default ResultList;
