import {Box, Button, Grid, TextField} from '@material-ui/core';
import Layout from '../../layouts/Centered';
import Header from '../../components/Header';
import React, {useCallback, useState} from 'react';
import {isNotNilOrEmpty} from '../../utils/helpers';
import {authRequest} from '../../store/api';
import t from "../../texts";
import './style.scss';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {RESET_PASSWORD} from "../../store/reducers/auth";
import {resetPasswordValidation, validateForm} from "../../components/Form/validation";
import {useParamName} from "../../hooks/hooks";
import {ERouteParameters, ERoutePaths} from "../../enumerators";
import {useHistory} from "react-router-dom";
import Recaptcha from "../../components/Recaptcha";


const ResetPassword = () => {
    const {push} = useHistory();
    const resetPassword = authRequest(`forgotten-password/:confirmationId`, RESET_PASSWORD, {method: 'post'})
    const confirmationUuid = useParamName(ERouteParameters.CONFIRMATION_UUID);

    const [form, setForm] = useState<any>({} as any);
    const [formErrors, setFormErrors] = useState({} as any);

    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showNewPassword2, setShowNewPassword2] = useState<boolean>(false);

    const handleChange = useCallback((event: any) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    }, [setForm, form]);

    const setErrors = useCallback((error: any) => {
        setFormErrors((prevState: any) => ({
            ...prevState,
            ...error
        }));
    }, [setFormErrors]);


    const handleSubmit = useCallback(async (event) => {
        event?.preventDefault();
        try {
            setFormErrors({});
            await validateForm(resetPasswordValidation, form, setErrors);

            await resetPassword({
                body: {newPassword: form.newPassword},
                params: {confirmationId: confirmationUuid}
            });
            push(ERoutePaths.LOGIN);
        } catch (e) {
            console.error(e);
        }
    }, [setErrors, form, resetPassword]);

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    return (
        <Box data-component="ResetPassword">
            <Layout>
                <Header />
                <Grid container justify="center">
                    <Grid item>
                        <h3>{t.CHANGE_PASSWORD}</h3>
                    </Grid>
                </Grid>

                <Box my="1.5rem">
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid className="left blueText" item xs={12} sm={4}>{t.NEW_PASSWORD}</Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                type={showNewPassword ? 'text' : 'password'}
                                name="newPassword"
                                variant="outlined"
                                size="small"
                                required
                                value={form.newPassword}
                                inputProps={{
                                    minLength: 6,
                                }}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                onChange={handleChange}
                                error={isNotNilOrEmpty(formErrors?.newPassword)}
                                helperText={formErrors?.newPassword}
                            />
                        </Grid>
                        <Grid className="left blueText" item xs={12} sm={4}>{t.NEW_PASSWORD} znovu</Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                type={showNewPassword2 ? 'text' : 'password'}
                                name="newPassword2"
                                variant="outlined"
                                size="small"
                                required
                                value={form.newPassword2}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowNewPassword2(!showNewPassword2)}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showNewPassword2 ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                }}

                                onChange={handleChange}
                                error={isNotNilOrEmpty(formErrors?.newPassword2)}
                                helperText={formErrors?.newPassword2}
                            />
                        </Grid>
                    </Grid>
                </Box>


                <Grid container spacing={2} alignItems="center" justify="center">
                    <Grid item xs={4}>
                        <Button variant="outlined" color="primary" className="loginButton" onClick={handleSubmit}>{t.RESET_PASSWORD}</Button>
                    </Grid>
                </Grid>
            </Layout>
            <Recaptcha actionName="resetPassword" />
        </Box>
    )
        ;
};

export default ResetPassword;
