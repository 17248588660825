import {Box, Card, CardActionArea, CardContent, Grid, Typography} from '@material-ui/core';
import t from '../../../texts';
import React from 'react';
import {BookOpen, LogIn, Star, Upload} from 'react-feather';
import './style.scss';
import {ERouteParameters, ERoutePaths} from '../../../enumerators';
import {Link} from 'react-router-dom';
import {ADMIN_SECTIONS, ALERT_SEVERITY, REDUCER_USER} from '../../../utils/constant';
import {MenuItemType} from "../../../utils/types";
import {useApiDataSelector} from "../../../hooks/hooks";
import {createAction} from "../../../store/actions";
import {SET_ALERT} from "../../../store/reducers/context";

export default () => {
    const currentUser = useApiDataSelector((state: any) => state[REDUCER_USER].currentUser);
    const setAlert = createAction(SET_ALERT);

    const items = [{
        label: t.FAVORITE_OFFERS,
        iconElement: <Star width={20} height={20}/>,
        link: {
            pathname: ERoutePaths.ACCOUNT,
            state: {[ERouteParameters.ADMIN_ACTIVE_SECTION]: ADMIN_SECTIONS.MY_FAVORITE}
        }
    }, {
        label: t.ADD_NEW_OFFER,
        link: ERoutePaths.ADVERT_INFORMATION,
        iconElement: <Upload width={20} height={20}/>,
        disabled: !currentUser?.emailVerified,
    }, {
        label: t.AGENCY_PROFILE,
        link: ERoutePaths.ESTATE_AGENCY_ADMINISTRATION,
        iconElement: <LogIn width={20} height={20}/>,
        disabled: !currentUser?.emailVerified,
    }, {
        label: t.AGENCIES_LIST,
        link: ERoutePaths.ESTATE_AGENCY_LIST,
        iconElement: <BookOpen width={20} height={20}/>
    }] as MenuItemType[];

    const renderLink = (item: MenuItemType) => (
        <Card onClick={() => item.disabled && setAlert({
            severity: ALERT_SEVERITY.WARNING,
            message: t.BEFORE_LOGIN_ERROR
        })}>
            <CardActionArea>
                {item.iconElement}
                <CardContent>
                    <Typography variant="body2" component="h5">{item.label}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );

    return (
        <Box data-component="BottomMenu">
            <h3>{t.OTHERS}</h3>

            <Grid container justify="center" spacing={2}>
                {items.map((item: MenuItemType) => (
                    <Grid key={item.label} item xs={12} sm={3} className={item.disabled ? 'disabled' : ''}>
                        {/*@ts-ignore*/}
                        {item.disabled ? renderLink(item) : <Link to={item.link}>{renderLink(item)}</Link>}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
