import React from 'react';
import { Avatar, Box, Grid, List, ListItemAvatar, ListItemText, Paper } from '@material-ui/core';
import Footer from '../../components/Footer';
import './style.scss';
import { MenuItemType } from '../../utils/types';
import MenuListItem from '../../components/MyAccount/MenuListItem';

interface IProps {
    children: React.ReactNode;
    menuItems: MenuItemType[];
    activeSection?: string;
}

const Administration = ({ menuItems, children, activeSection }: IProps) => (
    <>
        <Box id="background" />
        <Grid container data-component="Layout_Administration" className="App">
            <Paper id="content-paper" elevation={1}>
                <Box id="navigationTop">
                    <List component="nav">
                        {menuItems.map((menuItem: MenuItemType) => (
                            <MenuListItem
                                item={menuItem}
                                key={`menu-item${menuItem.label}-top`}
                                selected={menuItem.section === activeSection}
                                disabled={menuItem.disabled}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        {menuItem.iconElement}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={menuItem.label} secondary={menuItem.secondary} />
                            </MenuListItem>
                        ))}
                    </List>
                </Box>
                <Box id="navigationLeft">
                    <List component="nav">
                        {menuItems.map((menuItem: MenuItemType) => (
                            <MenuListItem
                                item={menuItem}
                                key={`menu-item${menuItem.label}-nav`}
                                selected={menuItem.section === activeSection}
                                disabled={menuItem.disabled}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        {menuItem.iconElement}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={menuItem.label}
                                    secondary={menuItem.secondary}
                                />
                            </MenuListItem>
                        ))}
                    </List>
                </Box>
                <Box id="content-wrapper">
                    {children}
                </Box>
                <Footer />
            </Paper>
        </Grid>
    </>
);

export default Administration;
