import {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';
import {getBooleanItem} from "./utils/storage";
import {GA_DISABLE, GA_ID, USER_COOKIES_STATISTICS} from "./utils/constant";
import {singletonHook} from "react-singleton-hook";

const usePageTracking = singletonHook({
    initiateGA: () => {
    },
    removeGA: () => {
    }
}, () => {
    const {pathname} = useLocation();
    const [initialized, setInitialized] = useState(false);

    const initiateGA = useCallback(() => {
        if (!window.location.href.includes("localhost") && !initialized) {
            // @ts-ignore
            window[GA_DISABLE] = false;
            ReactGA.initialize(GA_ID);
            setInitialized(true);
        }
    }, [initialized, setInitialized]);

    useEffect(() => {
        const IS_USER_COOKIES_STATISTICS = getBooleanItem(USER_COOKIES_STATISTICS);
        if(IS_USER_COOKIES_STATISTICS) {
            initiateGA();
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.send({hitType: "pageview", page: pathname});
        }
    }, [pathname]);

    const removeGA = () => {
        // @ts-ignore
        window[GA_DISABLE] = true;
        setInitialized(false);
    }

    return {initiateGA, removeGA};
});

export default usePageTracking;
